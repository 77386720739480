import { Actions, AuthActionTypes } from './actions';
import { AuthState, initialAuthState } from './state';

export function AuthReducer(state = initialAuthState, action: Actions): AuthState {
  switch (action.type) {
    case AuthActionTypes.GET_USER_SUCCESS:
    case AuthActionTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        user: action.payload,
      };
    case AuthActionTypes.GET_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        organizations: action.payload,
      };
    case AuthActionTypes.LOGOUT_SUCCESS:
      return initialAuthState;
    case AuthActionTypes.GET_TOKENS_SUCCESS:
    case AuthActionTypes.REFRESH_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        accessToken: action.payload.access_token,
        refreshToken: action.payload.refresh_token,
      };
    default:
      return state;
  }
}
