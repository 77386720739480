import { Entity } from './entity';
import { StockTake } from './stock-take';
import { Organization } from './organization';

export class Inventory extends Entity {
  organization: Organization;
  skuCount: number;
  stockTakes: StockTake[];

  constructor(data?: any, restore: boolean = false) {
    super(data, restore);

    if (data) {
      this.organization = data.organization;
      this.skuCount = data.skuCount;
      this.stockTakes = data.stockTakes;
    }
  }
}
