import { Action, createReducer, on } from '@ngrx/store';
import { EditOverviewState, initialEditOverviewState } from './edit-overview-state';
import { Actions, EditOverviewActionTypes } from './edit-overview.actions';

export function EditOverviewReducer(
  state: EditOverviewState = initialEditOverviewState, action: Actions) {
  switch (action.type) {
    case EditOverviewActionTypes.GET_MEDICINE_BY_ID_SUCCESS:
      return {
        ...state,
        medicine: action.payload
      }
    case EditOverviewActionTypes.RESET_MEDICINE:
      return {
        ...state,
        medicine: null,
      }
    case EditOverviewActionTypes.GET_BRAND_BY_ID_SUCCESS:
      return {
        ...state,
        brand: action.payload
      }
    case EditOverviewActionTypes.RESET_BRAND:
      return {
        ...state,
        brand: null,
      }
    case EditOverviewActionTypes.UPDATE_MEDICINE_SUCCESS:
      return {
        ...state,
        medicine: action.payload,
      }
    case EditOverviewActionTypes.UPDATE_BRAND_SUCCESS:
      return {
        ...state,
        brand: action.payload,
      }
    default:
      return state;
  }
}
