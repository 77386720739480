import moment from 'moment';
import { Store } from '@ngrx/store';
import { User } from '../../models/user';
import { Role } from '../../models/role';
import { Injectable } from '@angular/core';
import { Brand } from '../../models/brand';
import { Order } from '../../models/order';
import { Keyword } from '../../models/keyword';
import { Message } from '../../models/message';
import { Translation } from '@ngneat/transloco';
import { McfInfo } from '../../models/mcf-info';
import { catchError, map } from 'rxjs/operators';
import { Medicine } from '../../models/medicine';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { Pricelist } from '../../models/pricelist';
import { Formulary } from '../../models/formulary';
import { Storeroom } from '../../models/storeroom';
import { Permission } from '../../models/permission';
import { MedicineForm } from '../../models/medicine-form';
import { AdminAction } from '../../../admin/shared/types';
import { LogOutAction } from '../../../store/auth/actions';
import { Region } from '../../interfaces/region.interface';
import { Email } from '../../../admin/shared/models/email';
import { Filter } from '../../interfaces/filter.interface';
import { OrderMedicine } from '../../models/order-medicine';
import { PricelistItem } from '../../models/pricelist-item';
import { EmailTemplate } from '../../models/email-template';
import { StockTakeItem } from '../../models/stock-take-item';
import { SettingsService } from '../settings/settings.service';
import { Pageable } from '../../interfaces/pageable.interface';
import { District } from '../../interfaces/district.interface';
import { ApiFilter } from '../../interfaces/api-filter.interface';
import { ISSUESTATUS, ORDERSTATUS, ORDERSTATUSES_TO_NOT_SHOW, SETTING } from '../../constants';
import { WithdrawalRequest } from '../../models/withdrawal-request';
import { ApiResponse } from '../../interfaces/api-response.interface';
import { OrderFilter } from '../../interfaces/order-filter.interface';
import { AdminOrder } from '../../../admin/shared/models/admin-order';
import { Denomination } from '../../interfaces/denomination.interface';
import { OrdersFilter } from '../../interfaces/orders-filter.interface';
import { FacilityType } from '../../interfaces/facility-type.interface';
import { PaymentMethod } from '../../interfaces/payment-method.interface';
import { EmailTemplatePreview } from '../../models/email-template-preview';
import { EmailTemplateVariable } from '../../models/email-template-variable';
import { UnknownMedicine } from '../../interfaces/unknown-medicine.interface';
import { DeliveryMedicine } from '../../interfaces/delivery-medicine.interface';
import { PrescribingLevel } from '../../interfaces/prescribing-level.interface';
import { RecoverEmailRequest } from '../../interfaces/forget-email-request.interface';
import { GenericSuccessAction, ValidationErrorsAction } from '../../../store/app/actions';
import { OrderJourney } from '../../../monitor/shared/interfaces/order-journey.interface';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { OrderMedicineCopy } from '../../../orders/order-details/shared/order-medicine-copy.interface';
import { OrderJourneyComment } from '../../../monitor/shared/interfaces/order-journey-comment.interface';
import { MissingMedicineContactRequest } from '../../../monitor/shared/interfaces/missing-medicine-contact-request.interface';
import { ProductCatalog } from '@shared/models/product-catalog';
import { PricelistItemWithBrandDto } from '@shared/interfaces/pricelistitem-with-brand';
import { Document } from '@shared/models/document';

@Injectable()
export class ApiService {
  private readonly _apiUrl: string;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private settings: SettingsService,
    private store: Store,
  ) {
    this._apiUrl = this.settings.get(SETTING.API_URL);
  }

  /*
    ========================================
                APP ENDPOINTS
    ========================================
  */
  getTranslations(lang: string): Observable<any> {
    return this.http
      .get<Translation>(`/assets/i18n/${lang}.json`)
      .pipe(catchError((err) => this._serverError(err)));
  }

  /*
    ========================================
                AUTH ENDPOINTS
    ========================================
  */
  getTokens(code: string): Observable<any> {
    let oAuthClientId = SETTING.OAUTH_CLIENT_ID;
    let oAuthRedirectUri = SETTING.OAUTH_REDIRECT_URI;
    if (window.location.href?.startsWith('https://new.')) {
      oAuthClientId = SETTING.OAUTH_CLIENT_ID_TEMP;
      oAuthRedirectUri = SETTING.OAUTH_REDIRECT_URI_TEMP;
    }

    const body = new HttpParams()
      .set('grant_type', 'authorization_code')
      .set('code', code)
      .set('client_id', this.settings.get(oAuthClientId))
      .set('client_secret', this.settings.get(SETTING.OAUTH_CLIENT_SECRET))
      .set('redirect_uri', `${this.settings.get(oAuthRedirectUri)}/home`);

    return this.http
      .post(`${this.settings.get(SETTING.AUTH_URL)}/token`, body.toString(), {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
      })
      .pipe(catchError((err) => this._serverError(err)));
  }

  refreshAccessToken(refreshToken: string): Observable<any> {
    let oAuthClientId = SETTING.OAUTH_CLIENT_ID;
    if (window.location.href?.startsWith('https://new.')) {
      oAuthClientId = SETTING.OAUTH_CLIENT_ID_TEMP;
    }

    const body = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('refresh_token', refreshToken)
      .set('client_id', this.settings.get(oAuthClientId))
      .set('client_secret', this.settings.get(SETTING.OAUTH_CLIENT_SECRET));

    return this.http
      .post(`${this.settings.get(SETTING.AUTH_URL)}/token`, body.toString(), {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
      })
      .pipe(catchError((err) => this._serverError(err)));
  }

  getAuthUser(): Observable<any> {
    return this.http
      .get(`${this._apiUrl}/users/me`)
      .pipe(catchError((err) => this._serverError(err)));
  }

  getUserById(userId: number): Observable<any> {
    return this.http
      .get(`${this._apiUrl}/users/${userId}`)
      .pipe(catchError((err) => this._serverError(err)));
  }

  updateProfile(user: User): Observable<any> {
    return this.http
      .put(`${this._apiUrl}/users/me`, user)
      .pipe(catchError((err) => this._serverError(err)));
  }

  requestResetPassword(email: string): Observable<string> {
    return this.http.post(`${this._apiUrl}/auth/password-reset`, { email }).pipe(
      map((res: any) => res.message),
      catchError((err) => this._serverError(err)),
    );
  }

  resetPassword(token: string, password: string): Observable<string> {
    return this.http.post(`${this._apiUrl}/auth/password-reset/${token}`, { password }).pipe(
      map((res: any) => res.message),
      catchError((err) => this._serverError(err)),
    );
  }

  recoverEmail(data: RecoverEmailRequest): Observable<any> {
    return this.http.post(`${this._apiUrl}/auth/recover-email`, data).pipe(
      map((res: any) => res.message),
      catchError((err) => this._serverError(err)),
    );
  }

  updatePassword(oldPassword: string, newPassword: string, email: string): Observable<string> {
    return this.http
      .put(`${this._apiUrl}/auth/password-update/${email}`, { oldPassword, newPassword })
      .pipe(
        map((res: any) => res.message),
        catchError((err) => this._serverError(err)),
      );
  }

  sendActivationLink(userId: number): Observable<any> {
    return this.http.post(`${this._apiUrl}/auth/send-activation-link`, { userId }).pipe(
      map((res: ApiResponse) => res.result),
      catchError((err) => this._serverError(err)),
    );
  }

  getAuthOrganizations(): Observable<any> {
    let url = `${this._apiUrl}/auth/organizations`;
    url = this._appendParamsToUrl(url, [{ key: 'sort', value: 'name,asc', operator: '=' }]);

    return this.http.get(url).pipe(catchError((err) => this._serverError(err)));
  }

  /*
    ========================================
              ROLE ENDPOINTS
    ========================================
  */
  getRoles(): Observable<any> {
    return this.http.get(`${this._apiUrl}/roles`).pipe(
      map((res: Pageable<Role>) => res.content),
      catchError((err) => this._serverError(err)),
    );
  }

  getRoleById(roleId: number): Observable<any> {
    return this.http
      .get(`${this._apiUrl}/roles/${roleId}`)
      .pipe(catchError((err) => this._serverError(err)));
  }

  createRole(role: any): Observable<any> {
    return this.http
      .post(`${this._apiUrl}/roles`, role)
      .pipe(catchError((err) => this._serverError(err)));
  }

  updateRole(role: any): Observable<any> {
    return this.http
      .put(`${this._apiUrl}/roles/${role.id}`, role)
      .pipe(catchError((err) => this._serverError(err)));
  }

  removeRole(roleId: number): Observable<any> {
    return this.http.delete(`${this._apiUrl}/roles/${roleId}`).pipe(
      map((res: any) => res.message),
      catchError((err) => this._serverError(err)),
    );
  }

  getPermissions(): Observable<any> {
    return this.http.get(`${this._apiUrl}/permissions`).pipe(
      map((res: Pageable<Permission>) => res.content),
      catchError((err) => this._serverError(err)),
    );
  }

  /*
    ========================================
              USERS ENDPOINTS
    ========================================
  */
  getUsers(): Observable<any> {
    return this.http.get(`${this._apiUrl}/users`).pipe(
      map((res: Pageable<User>) => res.content),
      catchError((err) => this._serverError(err)),
    );
  }

  createUser(user: any): Observable<any> {
    return this.http
      .post(`${this._apiUrl}/users`, user)
      .pipe(catchError((err) => this._serverError(err)));
  }

  updateUser(user: User): Observable<any> {
    return this.http
      .put(`${this._apiUrl}/users/${user.id}`, user)
      .pipe(catchError((err) => this._serverError(err)));
  }

  removeUser(userId: number): Observable<any> {
    return this.http.delete(`${this._apiUrl}/users/${userId}`).pipe(
      map((res: any) => res.message),
      catchError((err) => this._serverError(err)),
    );
  }

  reactivateUser(userId: number): Observable<any> {
    return this.http
      .put(`${this._apiUrl}/users/${userId}/reactivate`, null)
      .pipe(catchError((err) => this._serverError(err)));
  }

  /*
    ========================================
             ORGANIZATION ENDPOINTS
    ========================================
  */

  getOrganizations(filters?: ApiFilter[]): Observable<any> {
    let url = this._appendFilterToUrl(`${this._apiUrl}/organizations`, filters);
    url = this._appendParamsToUrl(url, [{ key: 'sort', value: 'name,asc', operator: '=' }]);

    return this.http.get(url).pipe(
      map((res: any) => res.content),
      catchError((err) => this._serverError(err)),
    );
  }

  getOrganizationById(organizationId: number): Observable<any> {
    return this.http
      .get(`${this._apiUrl}/organizations/${organizationId}`)
      .pipe(catchError((err) => this._serverError(err)));
  }

  createOrganization(organization: any): Observable<any> {
    return this.http
      .post(`${this._apiUrl}/organizations`, organization)
      .pipe(catchError((err) => this._serverError(err)));
  }

  updateOrganization(organization: any): Observable<any> {
    return this.http
      .put(`${this._apiUrl}/organizations/${organization.id}`, organization)
      .pipe(catchError((err) => this._serverError(err)));
  }

  removeOrganization(organizationId: number): Observable<any> {
    return this.http.delete(`${this._apiUrl}/organizations/${organizationId}`).pipe(
      map((res: any) => res.message),
      catchError((err) => this._serverError(err)),
    );
  }

  reactivateOrganization(organizationId: number, alsoReactivatePriceListItems: boolean): Observable<any> {
    return this.http
      .put(`${this._apiUrl}/organizations/${organizationId}/reactivate/${alsoReactivatePriceListItems}`, null)
      .pipe(catchError((err) => this._serverError(err)));
  }

  getDenominations(): Observable<any> {
    return this.http.get<Pageable<Denomination>>(`${this._apiUrl}/denominations`).pipe(
      map((res) => res.content),
      catchError((err) => this._serverError(err)),
    );
  }

  getDistricts(regionId?: number): Observable<any> {
    let url = `${this._apiUrl}/districts`;
    if (regionId) {
      url = `${url}?regionId=${regionId}`;
    }
    return this.http.get<Pageable<District>>(url).pipe(
      map((res) => res.content),
      catchError((err) => this._serverError(err)),
    );
  }

  getRegions(): Observable<any> {
    return this.http.get<Pageable<Region>>(`${this._apiUrl}/regions`).pipe(
      map((res) => res.content),
      catchError((err) => this._serverError(err)),
    );
  }

  getFacilityTypes(): Observable<any> {
    return this.http.get<Pageable<FacilityType>>(`${this._apiUrl}/facility-types`).pipe(
      map((res) => res.content),
      catchError((err) => this._serverError(err)),
    );
  }

  getOverview(type: string): Observable<any> {
    return this.http
      .get(`${this._apiUrl}/overview/${type}`)
      .pipe(catchError((err) => this._serverError(err)));
  }

  /*
    ========================================
                FORMULARY ENDPOINTS
    ========================================
  */

  createFormulary(formulary: Formulary): Observable<any> {
    return this.http
      .post(`${this._apiUrl}/formulary`, formulary)
      .pipe(catchError((err) => this._serverError(err)));
  }

  getFormulary(id: string | number): Observable<any> {
    return this.http
      .get(`${this._apiUrl}/formulary/${id}`)
      .pipe(catchError((err) => this._serverError(err)));
  }

  updateFormulary(formulary: Formulary): Observable<any> {
    return this.http
      .put(`${this._apiUrl}/formulary/${formulary.id}`, formulary)
      .pipe(catchError((err) => this._serverError(err)));
  }

  /*
    ========================================
              MEDICINE ENDPOINTS
    ========================================
  */
  getMedicines(filters: ApiFilter[] = []): Observable<any> {
    filters = [...filters];
    const uri = this._appendParamsToUrl(`${this._apiUrl}/medicines`, filters);

    return this.http.get<Pageable<Medicine>>(uri).pipe(
      map((res) => res.content),
      catchError((err) => this._serverError(err)),
    );
  }

  updateMedicine(medicine: Medicine): Observable<any> {
    return this.http
      .put(`${this._apiUrl}/medicines/${medicine.id}`, medicine)
      .pipe(catchError((err) => this._serverError(err)));
  }

  deleteUnknownMedicine(unknownMedicineId: number): Observable<any> {
    return this.http
      .delete(`${this._apiUrl}/unknown-medicines/${unknownMedicineId}`)
      .pipe(catchError((err) => this._serverError(err)));
  }

  getPreviouslyOrderedMedicines(filters: ApiFilter[] = null): Observable<any> {
    const uri = this._appendParamsToUrl(`${this._apiUrl}/medicines/previously-ordered`, filters);

    return this.http.get<Pageable<Medicine>>(uri).pipe(
      map((res) => res.content),
      catchError((err) => this._serverError(err)),
    );
  }

  getOrderAdvice(filters: ApiFilter[] = null): Observable<any> {
    const uri = this._appendParamsToUrl(`${this._apiUrl}/medicines/order-advice`, filters);

    return this.http.get<Pageable<Medicine>>(uri).pipe(
      map((res) => res.content),
      catchError((err) => this._serverError(err)),
    );
  }

  createMedicine(medicine: any): Observable<any> {
    return this.http
      .post(`${this._apiUrl}/medicines`, medicine)
      .pipe(catchError((err) => this._serverError(err)));
  }

  getUnknownMedicines(): Observable<any> {
    return this.http.get<Pageable<UnknownMedicine>>(`${this._apiUrl}/unknown-medicines`).pipe(
      map((res) => res.content),
      catchError((err) => this._serverError(err)),
    );
  }

  getMedicineForms(): Observable<any> {
    return this.http.get<Pageable<MedicineForm>>(`${this._apiUrl}/medicine-forms`).pipe(
      map((res) => res.content),
      catchError((err) => this._serverError(err)),
    );
  }

  sendUnknownMedicineEmail(unknownMedicineId: number): Observable<any> {
    return this.http
      .post<any>(`${this._apiUrl}/unknown-medicines/email`, { unknownMedicineId })
      .pipe(
        map((res) => res.message),
        catchError((err) => this._serverError(err)),
      );
  }

  updateUnknownMedicine(
    unknownMedicineId: number,
    payload: { medicine: any; brand: any; keyword: any },
  ): Observable<any> {
    return this.http
      .put(`${this._apiUrl}/unknown-medicines/${unknownMedicineId}`, {
        medicine: payload.medicine.id ? { id: payload.medicine.id } : payload.medicine,
        brand: payload.brand.id ? { id: payload.brand.id } : payload.brand,
        keywords: [payload.keyword.id ? { id: payload.keyword.id } : payload.keyword],
      })
      .pipe(catchError((err) => this._serverError(err)));
  }

  /*
    ========================================
              BRAND ENDPOINTS
    ========================================
  */
  getBrands(filters: ApiFilter[] = []): Observable<any> {
    filters = [
      ...filters,
      {
        key: 'sort',
        value: 'name',
        operator: '=',
      },
    ];

    const uri = this._appendParamsToUrl(`${this._apiUrl}/brands`, filters);

    return this.http.get<Pageable<Brand>>(uri).pipe(
      map((res) => res.content),
      catchError((err) => this._serverError(err)),
    );
  }

  updateBrand(brand: Brand): Observable<any> {
    return this.http
      .put(`${this._apiUrl}/brands/${brand.id}`, brand)
      .pipe(catchError((err) => this._serverError(err)));
  }

  createBrand(brand: any): Observable<any> {
    return this.http
      .post(`${this._apiUrl}/brands`, brand)
      .pipe(catchError((err) => this._serverError(err)));
  }

  /*
    ========================================
              KEYWORD ENDPOINTS
    ========================================
  */
  getKeywords(filters: ApiFilter[] = []): Observable<any> {
    filters = [
      ...filters,
      {
        key: 'sort',
        value: 'requiredText',
        operator: '=',
      },
    ];

    const uri = this._appendParamsToUrl(`${this._apiUrl}/keywords`, filters);
    return this.http.get<Pageable<Keyword>>(uri).pipe(
      map((res) => res.content),
      catchError((err) => this._serverError(err)),
    );
  }

  updateKeyword(keyword: Keyword): Observable<any> {
    return this.http
      .put(`${this._apiUrl}/keywords/${keyword.id}`, keyword)
      .pipe(catchError((err) => this._serverError(err)));
  }

  createKeywords(keywords: any): Observable<any> {
    return this.http
      .post(`${this._apiUrl}/keywords`, keywords)
      .pipe(catchError((err) => this._serverError(err)));
  }

  /*
    ========================================
              EMAIL TEMPLATES ENDPOINTS
    ========================================
  */
  getEmailTemplates(filters: ApiFilter[] = []): Observable<any> {
    const uri = this._appendParamsToUrl(`${this._apiUrl}/message-templates`, filters);

    return this.http.get<Pageable<EmailTemplate>>(uri).pipe(
      map((res) => res.content),
      catchError((err) => this._serverError(err)),
    );
  }

  getEmailTemplateVariables(filters: ApiFilter[] = []): Observable<any> {
    const uri = this._appendParamsToUrl(`${this._apiUrl}/message-templates/variables`, filters);

    return this.http.get<Pageable<EmailTemplateVariable>>(uri).pipe(
      map((res) => res.content),
      catchError((err) => this._serverError(err)),
    );
  }

  getEmailTemplatePreview(body: string, filters: ApiFilter[] = []): Observable<any> {
    const uri = this._appendParamsToUrl(`${this._apiUrl}/message-templates/preview`, filters);

    return this.http.post<EmailTemplatePreview>(uri, { body }).pipe(
      map((res) => res),
      catchError((err) => this._serverError(err)),
    );
  }

  updateEmailTemplate(form: any): Observable<any> {
    return this.http
      .put(`${this._apiUrl}/message-templates/${form.id}`, form)
      .pipe(catchError((err) => this._serverError(err)));
  }

  /*
    ========================================
              PRICE-LIST ENDPOINTS
    ========================================
  */
  getPricelists(): Observable<any> {
    return this.http.get<Pageable<Pricelist>>(`${this._apiUrl}/price-lists`).pipe(
      map((res) => res.content),
      catchError((err) => this._serverError(err)),
    );
  }

  getPrescribingLevels(): Observable<any> {
    return this.http.get<Pageable<PrescribingLevel>>(`${this._apiUrl}/prescribing-levels`).pipe(
      map((res) => res.content),
      catchError((err) => this._serverError(err)),
    );
  }

  /*
  ========================================
            INVENTORY ENDPOINTS
  ========================================
  */
  getLatestStockTake(organizationId: number): Observable<any> {
    return this.http
      .get(`${this._apiUrl}/stock-takes/organization/${organizationId}/latest`)
      .pipe(catchError((err) => this._serverError(err)));
  }

  getStockTakes(page: number = 0, pageSize: number = 25, filter: Filter = null): Observable<any> {
    let uri = this._transformFilterAndAppendToUrl(`${this._apiUrl}/stock-takes`, {
      filters: [
        {
          label: 'noInventory',
          prop: 'noInventory',
          value: true,
        },
      ],
    });
    uri = this.appendPagination(uri, page, pageSize);
    uri = this._transformFilterAndAppendToUrl(uri, filter);

    return this.http.get(uri).pipe(catchError((err) => this._serverError(err)));
  }

  requestRecount(stockTakeItem: StockTakeItem): Observable<any> {
    return this.http
      .put(`${this._apiUrl}/stock-take-items/request-recount`, {
        stockTakeItemId: stockTakeItem.id,
      })
      .pipe(catchError((err) => this._serverError(err)));
  }

  exportStockTakes(ids: number[]): Observable<any> {
    return this.http
      .get(`${this._apiUrl}/stock-takes/excel?ids=${ids.join(',')}`, { responseType: 'blob' })
      .pipe(catchError((err) => this._serverError(err)));
  }

  approveStockTakes(ids: number[]): Observable<any> {
    return this.http
      .put(`${this._apiUrl}/stock-takes/approve`, { stockTakeIds: ids })
      .pipe(catchError((err) => this._serverError(err)));
  }

  updateStockTake(stockTakeId: number, stockTakeItems: StockTakeItem[]): Observable<any> {
    return this.http
      .put(`${this._apiUrl}/stock-takes/${stockTakeId}`, {
        stockTakeItems: stockTakeItems.map((s) => ({ id: s.id, quantity: s.quantity })),
      })
      .pipe(catchError((err) => this._serverError(err)));
  }

  deleteStockTakeItem(id: number): Observable<any> {
    return this.http
      .delete(`${this._apiUrl}/stock-take-items/${id}`)
      .pipe(catchError((err) => this._serverError(err)));
  }

  createInventory(stockTakeIds: number[]): Observable<any> {
    return this.http
      .post(`${this._apiUrl}/v2/inventories`, { stockTakeIds })
      .pipe(catchError((err) => this._serverError(err)));
  }

  getInventories(page: number = 0, pageSize: number = 25, filter: Filter = null): Observable<any> {
    let uri = this.appendPagination(`${this._apiUrl}/v2/inventories`, page, pageSize);
    uri = this._transformFilterAndAppendToUrl(uri, filter);

    return this.http.get(uri).pipe(catchError((err) => this._serverError(err)));
  }

  exportInventories(ids: number[]): Observable<any> {
    return this.http
      .get(`${this._apiUrl}/v2/inventories/excel?ids=${ids.join(',')}`, { responseType: 'blob' })
      .pipe(catchError((err) => this._serverError(err)));
  }

  /*
  ========================================
            PRICELIST ITEMS ENDPOINTS
  ========================================
*/
  getPricelistItems(): Observable<any> {
    return this.http.get<Pageable<PricelistItem>>(`${this._apiUrl}/price-list-items`).pipe(
      map((res) => res.content),
      catchError((err) => this._serverError(err)),
    );
  }

  updatePricelistItem(pricelistItem: PricelistItem): Observable<any> {
    return this.http
      .put(`${this._apiUrl}/price-list-items/${pricelistItem.id}`, {
        medicineId: pricelistItem.medicineId,
        brandId: pricelistItem.brandId,
        unitPrice: pricelistItem.unitPrice,
        endDate: moment(pricelistItem.endDate).format('YYYY-MM-DDTh:mm:ss'),
        active: pricelistItem.active,
      })
      .pipe(catchError((err) => this._serverError(err)));
  }

  createPricelistItems(pricelistItems: any): Observable<any> {
    return this.http
      .post(`${this._apiUrl}/price-list-items`, pricelistItems)
      .pipe(catchError((err) => this._serverError(err)));
  }

  /*
  ========================================
            PRODUCT CATALOG ENDPOINTS
  ========================================
  */
  getProductCatalog(): Observable<any> {
    const uri = `${this._apiUrl}/product-catalog`;
    return this.http.get<ProductCatalog[]>(uri).pipe(catchError((err) => this._serverError(err)));
  }

  /*
    ========================================
              MESSAGE ENDPOINTS
    ========================================
  */
  getMessages(): Observable<any> {
    return this.http.get<Pageable<Message>>(`${this._apiUrl}/messages`).pipe(
      map((res) => res.content),
      catchError((err) => this._serverError(err)),
    );
  }

  createMessage(message: Message): Observable<any> {
    return this.http
      .post(`${this._apiUrl}/messages`, message)
      .pipe(catchError((err) => this._serverError(err)));
  }

  updateMessage(message: Message): Observable<any> {
    return this.http
      .put(`${this._apiUrl}/messages/${message.id}`, message)
      .pipe(catchError((err) => this._serverError(err)));
  }

  removeMessage(messageId: number): Observable<any> {
    return this.http.delete(`${this._apiUrl}/messages/${messageId}`).pipe(
      map((res: any) => res.message),
      catchError((err) => this._serverError(err)),
    );
  }

  /*
    ========================================
              ORDERS ENDPOINTS
    ========================================
  */
  getOrders(filter?: OrdersFilter, overrideOrganizationId = false): Observable<any> {
    const routeOrganizationId = this.route.snapshot.queryParams.organizationId;
    let url = `${this._apiUrl}/orders`;
    if (routeOrganizationId && overrideOrganizationId) {
      if (!filter) {
        url = `${url}/current`;
        filter = {
          type: 'current',
          organizationId: routeOrganizationId,
        };
      } else {
        filter.organizationId = routeOrganizationId;
      }
    }
    if (filter) {
      Object.keys(filter).forEach((key) => {
        if (`${filter[key]}` === `current`) {
          url = `${url}/current`;
        }
      });
      url = `${url}?`;

      Object.keys(filter).forEach((key) => {
        if (filter.hasOwnProperty(key)) {
          url = `${url}${key}=${filter[key]}&`;
        }
      });
      // remove trailing &
      url = url.slice(0, -1);
    }

    return this.http.get<Pageable<Order>>(url).pipe(
      map((res) => res.content),
      catchError((err) => this._serverError(err)),
    );
  }

  getOrder(orderId: number, orderFilter?: OrderFilter): Observable<any> {
    const url = this._appendOrderFilterToUrl(`${this._apiUrl}/orders/${orderId}`, orderFilter);

    return this.http.get(url).pipe(catchError((err) => this._serverError(err)));
  }

  createOrder(order: Order) {
    return this.http
      .post(`${this._apiUrl}/orders`, order.toApi())
      .pipe(catchError((err) => this._serverError(err)));
  }

  updateOrder(order: Order): Observable<any> {
    return this.http
      .put(`${this._apiUrl}/orders/${order.id}`, order.toApi())
      .pipe(catchError((err) => this._serverError(err)));
  }

  removeOrder(orderId: number): Observable<any> {
    return this.http.delete(`${this._apiUrl}/orders/${orderId}`).pipe(
      map((res: any) => res.message),
      catchError((err) => this._serverError(err)),
    );
  }

  updateProForma(orderId: number, status: string, payload: any): Observable<any> {
    return this.http
      .put(`${this._apiUrl}/orders/${orderId}/${status.replace('_', '-').toLowerCase()}`, payload)
      .pipe(catchError((err) => this._serverError(err)));
  }

  getPaymentMethods(): Observable<any> {
    return this.http.get<Pageable<PaymentMethod[]>>(`${this._apiUrl}/payment-methods`).pipe(
      map((res: any) => res.content),
      catchError((err) => this._serverError(err)),
    );
  }

  getMcfInfo(organizationId: number): Observable<any> {
    return this.http
      .get<Pageable<McfInfo>>(`${this._apiUrl}/mcf?organizationId=${organizationId}`)
      .pipe(
        map((res: any) => res.content),
        catchError((err) => this._serverError(err)),
      );
  }

  setPaymentMethods(orderId: number, paymentMethods: any[]): Observable<any> {
    return this.http
      .put(`${this._apiUrl}/orders/${orderId}/payment-selected`, { paymentMethods })
      .pipe(catchError((err) => this._serverError(err)));
  }

  updateOrderStatus(
    orderId: number,
    status: string,
    message?: string,
    orderSupplierIds?: number[],
  ) {
    return this.http
      .put(`${this._apiUrl}/orders/${orderId}/${status.replace('_', '-').toLowerCase()}`, {
        message,
        orderSupplierIds,
      })
      .pipe(catchError((err) => this._serverError(err)));
  }

  updateShipment(
    orderId: number,
    orderSupplierId: number,
    orderMedicines: any[],
    status,
    fullName: string,
    email: string,
    orderFilter?: OrderFilter,
    subShipments: OrderMedicineCopy[] = [],
  ): Observable<any> {
    const statusString = this._statusToUrl(status);
    const url = this._appendOrderFilterToUrl(
      `${this._apiUrl}/orders/${orderId}/${statusString}`,
      orderFilter,
    );

    const body = { orderSupplierId };
    if (status === ORDERSTATUS.SHIPPED) {
      body['orderMedicines'] = orderMedicines;
    } else {
      body['orderMedicineIds'] = orderMedicines.map(
        (orderMedicine) => orderMedicine.orderMedicineId,
      );
    }

    body['contactPersonName'] = fullName;
    body['contactPersonEmail'] = email;
    body['subShipments'] = subShipments;

    return this.http.put(url, body).pipe(catchError((err) => this._serverError(err)));
  }

  retryShipment(orderId: number, orderSupplierIds: number[]): Observable<any> {
    return this.http
      .put(`${this._apiUrl}/orders/${orderId}/proforma-resubmitted`, { orderSupplierIds })
      .pipe(catchError((err) => this._serverError(err)));
  }

  retryDeclinedProforma(
    orderId: number,
    orderSupplierIds: number[],
    message?: string,
  ): Observable<any> {
    return this.http
      .put(`${this._apiUrl}/orders/${orderId}/proforma-retried`, { orderSupplierIds, message })
      .pipe(catchError((err) => this._serverError(err)));
  }

  submitPod(orderId: number, orderSupplierId: number, files: any[]): Observable<any> {
    const formData: FormData = new FormData();
    for (const file of files) {
      formData.append('files', file);
    }

    return this.http
      .put(`${this._apiUrl}/orders/${orderId}/${orderSupplierId}/pod-submitted`, formData)
      .pipe(catchError((err) => this._serverError(err)));
  }

  submitDelivery(
    orderId: number,
    orderSupplierId: number,
    orderMedicines: DeliveryMedicine[],
    subDeliveries: OrderMedicineCopy[],
  ): Observable<any> {
    const endpoint = `${this._apiUrl}/orders/${orderId}/delivery`;

    return this.http
      .put(endpoint, {
        orderSupplierId,
        orderMedicines,
        subDeliveries,
      })
      .pipe(catchError((err) => this._serverError(err)));
  }

  cancelUndeliveredMedicines(
    orderId: number,
    orderSupplierId: number,
    orderMedicines: DeliveryMedicine[],
  ): Observable<any> {
    return this.http
      .put(`${this._apiUrl}/orders/${orderId}/cancel-undelivered-medicines`, {
        orderSupplierId,
        orderMedicines,
      })
      .pipe(catchError((err) => this._serverError(err)));
  }

  payOrder(order: AdminOrder, deliveryNumbers: number[], action: AdminAction): Observable<any> {
    const filterStatus = action === 'pay' ? ORDERSTATUS.DELIVERED : ORDERSTATUS.PAYMENT_MADE;
    const uri = action === 'pay' ? 'payment-made' : 'commission-paid';
    const body = [];

    order.orderMedicines
      .filter(
        (om) =>
          deliveryNumbers.includes(om.deliveryNumber) &&
          om.currentOrderMedicineStatus.status === filterStatus,
      )
      .forEach((om) => {
        const supplier = body.find((b) => b.orderSupplierId === om.orderSupplier.id);
        if (supplier != null) {
          supplier['deliveryNumbers'].push(om.deliveryNumber);
        } else {
          body.push({
            deliveryNumbers: [om.deliveryNumber],
            orderSupplierId: om.orderSupplier.id,
          });
        }
      });

    return this.http
      .put(`${this._apiUrl}/orders/${order.orderId}/${uri}`, { orderSuppliers: body })
      .pipe(catchError((err) => this._serverError(err)));
  }

  findNewPriceListItemsIfExist(orderId: number): Observable<any> {
    return this.http
      .get(`${this._apiUrl}/orders/${orderId}/new-price-list-items`)
      .pipe(catchError((err) => this._serverError(err)));
  }

  findOtherSuppliers(orderId: number): Observable<Map<number, PricelistItem[]>> {
    return this.http.get(`${this._apiUrl}/orders/${orderId}/other-suppliers`).pipe(
      catchError((err) => this._serverError(err)),
      map((data: any) => {
        const map = new Map<number, PricelistItem[]>();

        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            const numericKey = parseInt(key);
            const value : PricelistItemWithBrandDto[] = data[key];
            value.forEach(x => x.brand = x.brandNotIgnored);
            const priceListItems = value as PricelistItem[];

            map.set(numericKey, priceListItems);
          }
        }
        return map;
      }),
    );
  }

  /*
    ========================================
              ADMIN ORDERS ENDPOINTS
    ========================================
  */
  getAdminOrders(page: number = 0, pageSize: number = 10): Observable<any> {
    let uri = `${this._apiUrl}/orders/admin`;
    uri = this.appendPagination(uri, page, pageSize);

    return this.http.get<Pageable<Order>>(uri).pipe(catchError((err) => this._serverError(err)));
  }

  getFilteredAdminOrders(
    filter: Filter = null,
    page: number = 0,
    pageSize: number = 10,
  ): Observable<any> {
    let uri = this._transformFilterAndAppendToUrl(`${this._apiUrl}/orders/admin/search`, filter);

    uri = this.appendPagination(uri, page, pageSize);

    return this.http.get<Pageable<Order>>(uri).pipe(catchError((err) => this._serverError(err)));
  }

  resubmitPayment(
    orderId: number,
    paymentMethods: { paymentMethodId: number; orderSupplierId: number }[],
  ): Observable<any> {
    return this.http
      .put(`${this._apiUrl}/orders/${orderId}/payment-resubmitted`, { paymentMethods })
      .pipe(catchError((err) => this._serverError(err)));
  }

  /*
    ========================================
         WITHDRAWAL REQUEST ENDPOINTS
    ========================================
  */
  getWithdrawalRequests(organizationId?: number): Observable<any> {
    let url = `${this._apiUrl}/withdrawal-requests`;
    if (organizationId) {
      url = `${url}?organizationId=${organizationId}`;
    }
    return this.http.get<Pageable<WithdrawalRequest>>(url).pipe(
      map((res) => res.content),
      catchError((err) => this._serverError(err)),
    );
  }

  updateWithdrawalRequest(requestId: number, status: string): Observable<any> {
    return this.http
      .put(`${this._apiUrl}/withdrawal-requests/${requestId}`, { status })
      .pipe(catchError((err) => this._serverError(err)));
  }

  createWithdrawalRequest(withdrawalRequest: WithdrawalRequest): Observable<any> {
    const body = {
      organizationId: withdrawalRequest.organizationId,
      value: withdrawalRequest.requestedWithdrawalValue,
    };

    if (withdrawalRequest.orderId) {
      body['orderId'] = withdrawalRequest.orderId;
    }

    return this.http
      .post(`${this._apiUrl}/withdrawal-requests`, body)
      .pipe(catchError((err) => this._serverError(err)));
  }

  /*
    ========================================
              DOCUMENTS ENDPOINTS
    ========================================
  */
  getDocuments(
      filter: Filter = null,
      subOrderId: number = null,
      documentType: string = null,
      page: number = 0,
      pageSize: number = 25,
    ): Observable<Pageable<Document>> {
    let uri = this._transformFilterAndAppendToUrl(`${this._apiUrl}/documents`, {
      filters: [
        {
          label: 'orderSupplierId',
          prop: 'orderSupplierId',
          value: subOrderId,
        },
        {
          label: 'documentType',
          prop: 'documentType',
          value: documentType,
        },
      ],
    });

    if (filter) {
      uri = this._transformFilterAndAppendToUrl(uri, filter);
    }

    uri = this.appendPagination(uri, page, pageSize);

    return this.http.get<Pageable<Document>>(uri).pipe(
      map((res: any) => res),
      catchError((err) => this._serverError(err)),
    );
  }

  getAdminDocuments(
    filter: Filter,
    page: number = 0,
    pageSize: number = 25,
  ): Observable<Pageable<Document>> {
    let uri = this._transformFilterAndAppendToUrl(`${this._apiUrl}/documents/admin`, filter);
    uri = this.appendPagination(uri, page, pageSize);

    return this.http.get<Pageable<Document>>(uri).pipe(
      map((res: any) => res),
      catchError((err) => this._serverError(err)),
    );
  }

  removeDocument(documentId: number): Observable<any> {
    return this.http.delete(`${this._apiUrl}/documents/${documentId}`).pipe(
      map((res: any) => res.message),
      catchError((err) => this._serverError(err)),
    );
  }

  createDocument(document: any): Observable<any> {
    this.store.dispatch(new GenericSuccessAction('MESSAGES.API.UPLOAD_DOCUMENT_SUCCESS'));

    const documentBody = {
      displayName: document.name,
      documentType: document.type,
    };

    const documentBlob = new Blob([JSON.stringify(documentBody)], {
      type: 'application/json',
    });

    const formData: FormData = new FormData();
    formData.append('document', documentBlob);
    formData.append('file', document.file, document.file.name);

    return this.http
      .post(`${this._apiUrl}/documents`, formData)
      .pipe(catchError((err) => this._serverError(err)));
  }

  /*
    ========================================
              ORDER MONITOR ENDPOINTS
    ========================================
  */
  getOrderJourneys(): Observable<any> {
    return this.http.get<Pageable<OrderJourney>>(`${this._apiUrl}/order-monitor`).pipe(
      map((res: any) => res.content),
      catchError((err) => this._serverError(err)),
    );
  }

  getOrderJourney(orderId: number): Observable<any> {
    return this.http
      .get<Pageable<OrderJourney>>(`${this._apiUrl}/order-monitor/details?orderId=${orderId}`)
      .pipe(
        map((res: any) => res.content),
        catchError((err) => this._serverError(err)),
      );
  }

  getCommissionMonitors(): Observable<any> {
    return this.http.get<Pageable<OrderJourney>>(`${this._apiUrl}/commission-monitor`).pipe(
      map((res: any) => res.content),
      catchError((err) => this._serverError(err)),
    );
  }

  getFinanceMonitors(): Observable<any> {
    return this.http.get<Pageable<OrderJourney>>(`${this._apiUrl}/finance-monitor`).pipe(
      map((res: any) => res.content),
      catchError((err) => this._serverError(err)),
    );
  }

  getFinanceMonitor(orderId: number): Observable<any> {
    return this.http
      .get<Pageable<OrderJourney>>(`${this._apiUrl}/finance-monitor/details?orderId=${orderId}`)
      .pipe(
        map((res: any) => res.content),
        catchError((err) => this._serverError(err)),
      );
  }

  contactOrderJourney(
    orderSupplierId: number,
    contactType: string,
    to: string,
    cc: string,
    bcc: string,
    subject: string,
    message: string,
  ): Observable<any> {
    return this.http
      .post(`${this._apiUrl}/order-monitor/contact?contactType=${contactType}`, {
        to,
        cc: [cc],
        bcc: [bcc],
        subject,
        body: message,
        orderSupplierId,
      })
      .pipe(
        map((res: any) => res.message),
        catchError((err) => this._serverError(err)),
      );
  }

  delayOrderJourney(orderSupplierId: number, delay: number = 5): Observable<any> {
    const url = `${this._apiUrl}/order-monitor/${orderSupplierId}/issue`;

    return this.http
      .put(url, { days: delay, issueType: ISSUESTATUS.PENDING })
      .pipe(catchError((err) => this._serverError(err)));
  }

  //TODO: Replace mock implementation
  updateOrderJourneyStatus(orderSupplierId: number, status: string): Observable<any> {
    /*return this.http.put(`${this._apiUrl}/order-monitor/${orderSupplierId}/${this._statusToUrl(status)}`, {})
      .pipe(catchError(err => this._serverError(err)));*/

    // let mockJourney = mockOrderJourneys.find((_journey) => _journey.id === orderSupplierId);
    // if (mockJourney) {
    //   mockJourney = {...mockJourney, issue: status};
    // }
    return of(null);
    // return of(mockJourney);
  }

  closeOrderJourney(orderSupplierId: number, message?: string): Observable<any> {
    return this.http
      .put(`${this._apiUrl}/order-monitor/${orderSupplierId}/close`, { message })
      .pipe(catchError((err) => this._serverError(err)));
  }

  getMissingMedicines(orderId?: number, includeAll: boolean = false): Observable<any> {
    const filters: ApiFilter[] = [{ key: 'includeAll', value: includeAll, operator: '=' }];

    if (orderId) {
      filters.push({ key: 'orderId', value: orderId, operator: '=' });
    }

    let url = `${this._apiUrl}/missing-meds`;
    url = this._appendParamsToUrl(url, filters);
    return this.http.get<Pageable<OrderMedicine>>(url).pipe(
      map((res) => res.content),
      catchError((err) => this._serverError(err)),
    );
  }

  closeMissingMedicines(orderMedicineIds: number[], message?: string): Observable<any> {
    return this.http
      .put(`${this._apiUrl}/missing-meds/close`, { orderMedicineIds, message })
      .pipe(catchError((err) => this._serverError(err)));
  }

  delayMissingMedicines(orderMedicineIds: number[], days: number): Observable<any> {
    return this.http
      .put(`${this._apiUrl}/missing-meds/delay`, { orderMedicineIds, days })
      .pipe(catchError((err) => this._serverError(err)));
  }

  sendMissingMedicinesMessage(
    contactType: string,
    request: MissingMedicineContactRequest,
  ): Observable<any> {
    return this.http
      .post(`${this._apiUrl}/order-monitor/contact?contactType=${contactType}`, request)
      .pipe(
        map((res: any) => res.message),
        catchError((err) => this._serverError(err)),
      );
  }

  getOrderJourneyComments(orderId?: number): Observable<any> {
    return this.http
      .get<Pageable<OrderJourneyComment>>(
        `${this._apiUrl}/order-monitor/comments?orderId=${orderId}`,
      )
      .pipe(
        map((res) => res.content),
        catchError((err) => this._serverError(err)),
      );
  }

  createOrderJourneyComment(
    orderSupplierId: number,
    body: string,
    status: string,
  ): Observable<any> {
    return this.http
      .post(`${this._apiUrl}/order-monitor/comments`, { orderSupplierId, body, status })
      .pipe(catchError((err) => this._serverError(err)));
  }

  createOrderJourneyComments(form: any): Observable<any> {
    return this.http
      .post<Pageable<OrderJourneyComment>>(`${this._apiUrl}/order-monitor/comments/multiple`, form)
      .pipe(
        map((res) => res.content),
        catchError((err) => this._serverError(err)),
      );
  }

  /*
    ========================================
              STOREROOMS
    ========================================
  */
  getStorerooms(): Observable<any> {
    return this.http
      .get(`${this._apiUrl}/storerooms`)
      .pipe(catchError((err) => this._serverError(err)));
  }

  createStoreroom(storeroom: Storeroom): Observable<any> {
    return this.http
      .post(`${this._apiUrl}/storerooms`, storeroom)
      .pipe(catchError((err) => this._serverError(err)));
  }

  updateStoreroom(storeroom: Storeroom): Observable<any> {
    return this.http
      .put(`${this._apiUrl}/storerooms/${storeroom.id}`, storeroom)
      .pipe(catchError((err) => this._serverError(err)));
  }

  deleteStoreroom(id: number): Observable<any> {
    return this.http
      .delete(`${this._apiUrl}/storerooms/${id}`)
      .pipe(catchError((err) => this._serverError(err)));
  }

  /*
    ========================================
              VARIOUS ENDPOINTS
    ========================================
  */
  getReports(): Observable<any> {
    return this.http
      .get(`${this._apiUrl}/reports`)
      .pipe(catchError((err) => this._serverError(err)));
  }

  getEmails(filter: Filter, page: number = 0, pageSize: number = 25): Observable<Pageable<Email>> {
    let uri = this._transformFilterAndAppendToUrl(`${this._apiUrl}/message-queue`, filter);
    uri = this.appendPagination(uri, page, pageSize);

    return this.http.get<Pageable<Email>>(uri).pipe(
      map((res: any) => res),
      catchError((err) => this._serverError(err)),
    );
  }

  uploadPrices(pricelist: any): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', pricelist.file);

    return this.http
      .post(`${this._apiUrl}/excel/uploadNewPrices`, formData, { responseType: 'text' })
      .pipe(catchError((err) => this._serverError(err)));
  }

  private _transformFilterAndAppendToUrl(url: string, filter: Filter): string {
    if (!filter) {
      return url;
    }

    const filters: ApiFilter[] = [];
    if (filter.sortBy && filter.sortDirection) {
      filters.push({
        key: 'sort',
        value: `${filter.sortBy},${filter.sortDirection}`,
        operator: '=',
      });
    }

    if (filter.searchTerm) {
      filters.push({
        key: 'searchTerm',
        value: encodeURIComponent(filter.searchTerm),
        operator: '=',
      });
    }

    if (filter.filters) {
      filter.filters
        .filter((f) => f.value != null)
        .map((f) => {
          let value = f.value;
          if (f.value instanceof Date) {
            value = moment(f.value).format('YYYY-MM-DDTh:mm:ss');
          }

          filters.push({
            key: f.prop,
            value,
            operator: '=',
          });
        });
    }

    return this._appendParamsToUrl(url, filters);
  }

  private _appendFilterToUrl(url: string, filters: ApiFilter[]): string {
    if (filters?.length > 0) {
      url = `${url}?filter=`;
    }

    return this._appendParamsToUrl(url, filters, ',');
  }

  private _appendParamsToUrl(url: string, filters: ApiFilter[], separator: string = '&'): string {
    if (filters == null || filters.length === 0) {
      return url;
    }

    if (url.indexOf('?') === -1) {
      url += '?';
    }

    filters.forEach((filter: ApiFilter, index: number) => {
      if (index < filters.length && !url.endsWith('?') && !url.endsWith('=')) {
        url += separator;
      }

      url += `${filter.key}${filter.operator}${filter.value}`;
    });

    return url;
  }

  private _appendOrderFilterToUrl(url: string, orderFilter: OrderFilter): string {
    url = `${url}?order_medicine_statuses=!${ORDERSTATUS.CANCELLED}`;

    const toNotShow = orderFilter?.orderMedicineStatuses ?
      ORDERSTATUSES_TO_NOT_SHOW.filter(x => !orderFilter.orderMedicineStatuses.includes(x)) :
      ORDERSTATUSES_TO_NOT_SHOW;

    if (toNotShow.length != 0) {
      url = `${url},!${toNotShow.join(',!')}`;
    }


    if (orderFilter?.orderMedicineStatuses) {
      url = `${url},${orderFilter.orderMedicineStatuses.join(',')}`;
    }

    url = `${url}&order_supplier_statuses=!${ORDERSTATUS.CANCELLED}`;
    if (orderFilter?.orderSupplierStatuses) {
      url = `${url},${orderFilter.orderSupplierStatuses.join(',')}`;
    }
    return url;
  }

  private _statusToUrl(status: string): string {
    return status?.replace('_', '-').toLowerCase();
  }

  private appendPagination(uri: string, page: number, pageSize: number) {
    return this._appendParamsToUrl(uri, [
      {
        key: 'page',
        value: page,
        operator: '=',
      },
      {
        key: 'size',
        value: pageSize,
        operator: '=',
      },
    ]);
  }

  /**
   * Handle Http operation that failed.
   * @param response HttpErrorResponse - the actual error message from the server
   */
  public _serverError(response: any): Observable<string> {
    if (response instanceof HttpErrorResponse && response.status === 401) {
      if (response.error?.error_description.includes('Invalid refresh token')) {
        this.store.dispatch(new LogOutAction());
      }
      return of();
    }

    let errorMessage: string;
    if (response.error) {
      errorMessage = response.error.message;
      if (response.error.validationErrors?.length > 0) {
        this.store.dispatch(new ValidationErrorsAction(response.error.validationErrors));
      }
    } else {
      errorMessage = response.message;
    }

    return throwError(errorMessage);
  }
}
