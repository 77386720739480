import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuItem } from '../../interfaces/menu-item.interface';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent {
  @Input() orderMenuItems: MenuItem[];
  @Input() adminMenuItems: MenuItem[];
  @Input() monitorMenuItems: MenuItem[];
  @Input() cmsMenuItems: MenuItem[];
  @Output() closeMenu: EventEmitter<void> = new EventEmitter();
}
