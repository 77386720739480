import {Action } from '@ngrx/store';
import { User } from '../../shared/models/user';
import { ApiFilter } from '../../shared/interfaces/api-filter.interface';
import { Organization } from '../../shared/models/organization';
import { Role } from '../../shared/models/role';
import { Permission } from '../../shared/models/permission';
import { OverviewAction } from '../../shared/interfaces/overview-action.interface';
import { Order } from '../../shared/models/order';
import { Filter } from '../../shared/interfaces/filter.interface';
import { District } from '../../shared/interfaces/district.interface';
import { Region } from '../../shared/interfaces/region.interface';
import { FacilityType } from '../../shared/interfaces/facility-type.interface';
import { Denomination } from '../../shared/interfaces/denomination.interface';
import { AdminOrder } from '../../admin/shared/models/admin-order';
import { WithdrawalRequest } from '../../shared/models/withdrawal-request';
import { Document } from '../../shared/models/document';
import { Email } from '../../admin/shared/models/email';
import { AdminAction } from '../../admin/shared/types';
import { Pageable } from '../../shared/interfaces/pageable.interface';
import {PricelistItem} from "@models/pricelist-item";
import { MedicineNameId } from '@shared/interfaces/medicine-name-id.interface';
import { BrandNameId } from '@shared/interfaces/brand-name-id.interface';
import { SupplierWithIds } from '@shared/interfaces/supplier-with-ids.interface';




export enum AdminActionTypes {
  GET_USERS = '[Admin] Get Users',
  GET_USERS_SUCCESS = '[Admin] Get Users Success',
  CREATE_USER = '[Admin] Create User',
  CREATE_USER_SUCCESS = '[Admin] Create User Success',
  UPDATE_USER = '[Admin] Update User',
  UPDATE_USER_SUCCESS = '[Admin] Update User Success',
  REMOVE_USER = '[Admin] Remove User',
  REMOVE_USER_SUCCESS = '[Admin] Remove User Success',
  SOFT_REMOVE_USER_SUCCESS = '[Admin] Soft remove User Success',
  REACTIVATE_USER = '[Admin] Reactivate User',
  REACTIVATE_USER_SUCCESS = '[Admin] Reactivate User Success',
  SAVE_USERS_FILTER = '[Admin] Save Users Filter',

  GET_ORGANIZATIONS = '[Admin] Get Organizations',
  GET_ORGANIZATIONS_SUCCESS = '[Admin] Get Organizations Success',
  GET_ORGANIZATION = '[Admin] Get Organization',
  GET_ORGANIZATION_SUCCESS = '[Admin] Get Organization Success',
  CREATE_ORGANIZATION = '[Admin] Create Organization',
  CREATE_ORGANIZATION_SUCCESS = '[Admin] Create Organization Success',
  UPDATE_ORGANIZATION = '[Admin] Update Organization',
  UPDATE_ORGANIZATION_SUCCESS = '[Admin] Update Organization Success',
  REMOVE_ORGANIZATION = '[Admin] Remove Organization',
  REMOVE_ORGANIZATION_SUCCESS = '[Admin] Remove Organization Success',
  SOFT_REMOVE_ORGANIZATION_SUCCESS = '[Admin] Soft remove Organization Success',
  REACTIVATE_ORGANIZATION = '[Admin] Reactivate Organization',
  REACTIVATE_ORGANIZATION_SUCCESS = '[Admin] Reactivate Organization Success',
  GET_DENOMINATIONS = '[Admin] Get Denominations',
  GET_DENOMINATIONS_SUCCESS = '[Admin] Get Denominations Success',
  GET_DISTRICTS = '[Admin] Get Districts',
  GET_DISTRICTS_SUCCESS = '[Admin] Get Districts Success',
  GET_REGIONS = '[Admin] Get Regions',
  GET_REGIONS_SUCCESS = '[Admin] Get Regions Success',
  GET_FACILITY_TYPES = '[Admin] Get Facility types',
  GET_FACILITY_TYPES_SUCCESS = '[Admin] Get Facility types Success',
  SAVE_ORGANIZATIONS_FILTER = '[Admin] Save Organizations Filter',

  GET_ROLES = '[Admin] Get Roles',
  GET_ROLES_SUCCESS = '[Admin] Get Roles Success',
  GET_ROLE = '[Admin] Get Role',
  GET_ROLE_SUCCESS = '[Admin] Get Role Success',
  CREATE_ROLE = '[Admin] Create Role',
  CREATE_ROLE_SUCCESS = '[Admin] Create Role Success',
  UPDATE_ROLE = '[Admin] Update Role',
  UPDATE_ROLE_SUCCESS = '[Admin] Update Role Success',
  REMOVE_ROLE = '[Admin] Remove Role',
  REMOVE_ROLE_SUCCESS = '[Admin] Remove Role Success',
  GET_PERMISSIONS = '[Admin] Get Permissions',
  GET_PERMISSIONS_SUCCESS = '[Admin] Get Permissions Success',
  SAVE_ROLES_FILTER = '[Admin] Save Roles filter',

  GET_OVERVIEW = '[Admin] Get overview',
  GET_OVERVIEW_SUCCESS = '[Admin] Get overview success',

  GET_ORDERS = '[Admin] Get orders',
  GET_FILTERED_ORDERS = '[Admin] Get filtered orders',
  GET_ORDERS_SUCCESS = '[Admin] Get orders success',
  UPDATE_ORDER_STATUS = '[Admin] Update order status',
  UPDATE_ORDER_STATUS_SUCCESS = '[Admin] Update order status success',
  UPDATE_ORDERS_FILTER = '[Admin] Update orders filter',
  RESUBMIT_PAYMENT = '[Admin] Resubmit payment',
  RETRY_SHIPMENT = '[Admin] Retry shipment',
  RETRY_PROFORMA = '[Admin] Retry proforma',
  RESUBMIT_PAYMENT_SUCCESS = '[Admin] Resubmit payment success',
  PAY_ORDER = '[Admin] Pay order',
  PAY_ORDER_SUCCESS = '[Admin] Pay order success',

  GET_WITHDRAWAL_REQUESTS = '[Admin] Get withdrawal requests',
  GET_WITHDRAWAL_REQUESTS_SUCCESS = '[Admin] Get withdrawal requests success',
  UPDATE_WITHDRAWAL_REQUESTS = '[Admin] Update withdrawal requests',
  UPDATE_WITHDRAWAL_REQUESTS_SUCCESS = '[Admin] Update withdrawal requests success',
  UPDATE_WITHDRAWAL_REQUESTS_FILTER = '[Admin] Update withdrawal requests filter',

  GET_DOCUMENTS = '[Admin] Get documents',
  GET_DOCUMENTS_SUCCESS = '[Admin] Get documents success',
  SAVE_DOCUMENT_FILTER = '[Admin] Save document filter',

  GET_EMAILS = '[Admin] Get emails',
  GET_EMAILS_SUCCESS = '[Admin] Get emails success',
  SAVE_EMAILS_FILTER = '[Admin] Save emails filter',
}

/*
    ========================================
                USERS ACTIONS
    ========================================
*/

export class GetUsersAction implements Action {
  readonly type = AdminActionTypes.GET_USERS;

  constructor() {}
}

export class GetUsersSuccessAction implements Action {
  readonly type = AdminActionTypes.GET_USERS_SUCCESS;

  constructor(public payload: User[]) {}
}

export class CreateUserAction implements Action {
  readonly type = AdminActionTypes.CREATE_USER;

  constructor(public payload: any) {}
}

export class CreateUserSuccessAction implements Action {
  readonly type = AdminActionTypes.CREATE_USER_SUCCESS;

  constructor(public payload: User) {}
}

export class UpdateUserAction implements Action {
  readonly type = AdminActionTypes.UPDATE_USER;

  constructor(public payload: User) {}
}

export class UpdateUserSuccessAction implements Action {
  readonly type = AdminActionTypes.UPDATE_USER_SUCCESS;

  constructor(public payload: User) {}
}

export class RemoveUserAction implements Action {
  readonly type = AdminActionTypes.REMOVE_USER;

  constructor(public payload: number) {}
}

export class RemoveUserSuccessAction implements Action {
  readonly type = AdminActionTypes.REMOVE_USER_SUCCESS;

  constructor(public payload: number) {}
}

export class SoftRemoveUserSuccessAction implements Action {
  readonly type = AdminActionTypes.SOFT_REMOVE_USER_SUCCESS;
  constructor(public payload: number) {}
}

export class ReactivateUserAction implements Action {
  readonly type = AdminActionTypes.REACTIVATE_USER;
  constructor(public payload: number) {}
}

export class ReactivateUserSuccessAction implements Action {
  readonly type = AdminActionTypes.REACTIVATE_USER_SUCCESS;
  constructor(public payload: User) {}
}

export class SaveUsersFilterAction implements Action {
  readonly type = AdminActionTypes.SAVE_USERS_FILTER;
  constructor(public payload: Filter) {}
}

/*
  ========================================
           ORGANIZATIONS ACTIONS
  ========================================
*/
export class GetOrganizationsAction implements Action {
  readonly type = AdminActionTypes.GET_ORGANIZATIONS;

  constructor(public payload?: ApiFilter[]) {}
}

export class GetOrganizationsSuccessAction implements Action {
  readonly type = AdminActionTypes.GET_ORGANIZATIONS_SUCCESS;

  constructor(public payload: Organization[]) {}
}

export class GetOrganizationAction implements Action {
  readonly type = AdminActionTypes.GET_ORGANIZATION;

  constructor(public payload: number) {}
}

export class GetOrganizationSuccessAction implements Action {
  readonly type = AdminActionTypes.GET_ORGANIZATION_SUCCESS;

  constructor(public payload: Organization) {}
}

export class CreateOrganizationAction implements Action {
  readonly type = AdminActionTypes.CREATE_ORGANIZATION;

  constructor(public payload: any) {}
}

export class CreateOrganizationSuccessAction implements Action {
  readonly type = AdminActionTypes.CREATE_ORGANIZATION_SUCCESS;

  constructor(public payload: Organization) {}
}

export class UpdateOrganizationAction implements Action {
  readonly type = AdminActionTypes.UPDATE_ORGANIZATION;

  constructor(public payload: any) {}
}

export class UpdateOrganizationSuccessAction implements Action {
  readonly type = AdminActionTypes.UPDATE_ORGANIZATION_SUCCESS;

  constructor(public payload: Organization) {}
}

export class RemoveOrganizationAction implements Action {
  readonly type = AdminActionTypes.REMOVE_ORGANIZATION;

  constructor(public payload: number) {}
}

export class RemoveOrganizationSuccessAction implements Action {
  readonly type = AdminActionTypes.REMOVE_ORGANIZATION_SUCCESS;

  constructor(public payload: number) {}
}

export class SoftRemoveOrganizationSuccessAction implements Action {
  readonly type = AdminActionTypes.SOFT_REMOVE_ORGANIZATION_SUCCESS;

  constructor(public payload: number) {}
}

export class ReactivateOrganizationAction implements Action {
  readonly type = AdminActionTypes.REACTIVATE_ORGANIZATION;

  constructor(public organizationId: number, public alsoReactivatePriceListItems: boolean) {}
}

export class ReactivateOrganizationSuccessAction implements Action {
  readonly type = AdminActionTypes.REACTIVATE_ORGANIZATION_SUCCESS;

  constructor(public payload: Organization) {}
}

export class GetDenominationsAction implements Action {
  readonly type = AdminActionTypes.GET_DENOMINATIONS;
}

export class GetDenominationsSuccessAction implements Action {
  readonly type = AdminActionTypes.GET_DENOMINATIONS_SUCCESS;

  constructor(public payload: Denomination[]) {}
}

export class GetDistrictsAction implements Action {
  readonly type = AdminActionTypes.GET_DISTRICTS;

  constructor(public payload?: number) {}
}

export class GetDistrictsSuccessAction implements Action {
  readonly type = AdminActionTypes.GET_DISTRICTS_SUCCESS;

  constructor(public payload: District[]) {}
}

export class GetRegionsAction implements Action {
  readonly type = AdminActionTypes.GET_REGIONS;
}

export class GetRegionsSuccessAction implements Action {
  readonly type = AdminActionTypes.GET_REGIONS_SUCCESS;

  constructor(public payload: Region[]) {}
}

export class GetFacilityTypesAction implements Action {
  readonly type = AdminActionTypes.GET_FACILITY_TYPES;
}

export class GetFacilityTypesSuccessAction implements Action {
  readonly type = AdminActionTypes.GET_FACILITY_TYPES_SUCCESS;

  constructor(public payload: FacilityType[]) {}
}

export class SaveOrganizationsFilterAction implements Action {
  readonly type = AdminActionTypes.SAVE_ORGANIZATIONS_FILTER;
  constructor(public payload: Filter) {}
}

/*
  ========================================
                ROLES ACTIONS
  ========================================
*/
export class GetRolesAction implements Action {
  readonly type = AdminActionTypes.GET_ROLES;

  constructor() {}
}

export class GetRolesSuccessAction implements Action {
  readonly type = AdminActionTypes.GET_ROLES_SUCCESS;

  constructor(public payload: Role[]) {}
}

export class GetRoleAction implements Action {
  readonly type = AdminActionTypes.GET_ROLE;

  constructor(public payload: number) {}
}

export class GetRoleSuccessAction implements Action {
  readonly type = AdminActionTypes.GET_ROLE_SUCCESS;

  constructor(public payload: Role) {}
}

export class CreateRoleAction implements Action {
  readonly type = AdminActionTypes.CREATE_ROLE;

  constructor(public payload: any) {}
}

export class CreateRoleSuccessAction implements Action {
  readonly type = AdminActionTypes.CREATE_ROLE_SUCCESS;

  constructor(public payload: Role) {}
}

export class UpdateRoleAction implements Action {
  readonly type = AdminActionTypes.UPDATE_ROLE;

  constructor(public payload: any) {}
}

export class UpdateRoleSuccessAction implements Action {
  readonly type = AdminActionTypes.UPDATE_ROLE_SUCCESS;

  constructor(public payload: Role) {}
}

export class RemoveRoleAction implements Action {
  readonly type = AdminActionTypes.REMOVE_ROLE;

  constructor(public payload: number) {}
}

export class RemoveRoleSuccessAction implements Action {
  readonly type = AdminActionTypes.REMOVE_ROLE_SUCCESS;

  constructor(public payload: number) {}
}

export class GetPermissionsAction implements Action {
  readonly type = AdminActionTypes.GET_PERMISSIONS;
}

export class GetPermissionsSuccessAction implements Action {
  readonly type = AdminActionTypes.GET_PERMISSIONS_SUCCESS;

  constructor(public payload: Permission[]) {}
}

export class SaveRolesFilterAction implements Action {
  readonly type = AdminActionTypes.SAVE_ROLES_FILTER;
  constructor(public payload: Filter) {}
}

/*
  ========================================
              VARIOUS ACTIONS
  ========================================
*/
export class GetOverviewAction implements Action {
  readonly type = AdminActionTypes.GET_OVERVIEW;
}

export class GetOverviewSuccessAction implements Action {
  readonly type = AdminActionTypes.GET_OVERVIEW_SUCCESS;

  constructor(public payload: OverviewAction[]) {}
}

/*
  ========================================
          ADMIN ORDERS ACTIONS
  ========================================
*/
export class GetAdminOrdersAction implements Action {
  readonly type = AdminActionTypes.GET_ORDERS;

  constructor(public pageNumber = 0, public pageSize = 10) {}
}

export class GetFilteredAdminOrdersAction implements Action {
  readonly type = AdminActionTypes.GET_FILTERED_ORDERS;

  constructor(public filter: Filter = null, public pageNumber = 0, public pageSize = 10) {}
}

export class GetAdminOrdersSuccessAction implements Action {
  readonly type = AdminActionTypes.GET_ORDERS_SUCCESS;

  constructor(public payload: Pageable<Order>) {}
}

export class UpdateOrderStatusAction implements Action {
  readonly type = AdminActionTypes.UPDATE_ORDER_STATUS;

  constructor(
    public orderId: number,
    public status: string,
    public message?: string,
    public orderSupplierIds?: number[],
  ) {}
}

export class UpdateOrderStatusSuccessAction implements Action {
  readonly type = AdminActionTypes.UPDATE_ORDER_STATUS_SUCCESS;

  constructor(public payload: Order) {}
}

export class PayOrderAction implements Action {
  readonly type = AdminActionTypes.PAY_ORDER;

  constructor(
    public order: AdminOrder,
    public deliveryIds: number[],
    public adminAction: AdminAction,
  ) {}
}

export class PayOrderActionSuccessAction implements Action {
  readonly type = AdminActionTypes.PAY_ORDER_SUCCESS;

  constructor(public payload: Order) {}
}

export class ResubmitPaymentAction implements Action {
  readonly type = AdminActionTypes.RESUBMIT_PAYMENT;

  constructor(public payload: AdminOrder) {}
}

export class RetryShipmentAction implements Action {
  readonly type = AdminActionTypes.RETRY_SHIPMENT;

  constructor(public payload: AdminOrder) {}
}

export class ResubmitPaymentSuccessAction implements Action {
  readonly type = AdminActionTypes.RESUBMIT_PAYMENT_SUCCESS;

  constructor(public payload: Order) {}
}

export class UpdateOrdersFilterAction implements Action {
  readonly type = AdminActionTypes.UPDATE_ORDERS_FILTER;

  constructor(public payload: Filter) {}
}

export class RetryProformaAction implements Action {
  readonly type = AdminActionTypes.RETRY_PROFORMA;
  constructor(public payload: AdminOrder) {}
}

/*
  ========================================
        WITHDRAWAL REQUEST ACTIONS
  ========================================
*/
export class GetAdminWithdrawalRequestsAction implements Action {
  readonly type = AdminActionTypes.GET_WITHDRAWAL_REQUESTS;
}

export class GetWithdrawalRequestsSuccessAction implements Action {
  readonly type = AdminActionTypes.GET_WITHDRAWAL_REQUESTS_SUCCESS;

  constructor(public payload: WithdrawalRequest[]) {}
}

export class UpdateWithdrawalRequestAction implements Action {
  readonly type = AdminActionTypes.UPDATE_WITHDRAWAL_REQUESTS;

  constructor(public requestId: number, public status: string) {}
}

export class UpdateWithdrawalRequestSuccessAction implements Action {
  readonly type = AdminActionTypes.UPDATE_WITHDRAWAL_REQUESTS_SUCCESS;

  constructor(public payload: WithdrawalRequest) {}
}

export class UpdateWithdrawalRequestFilterAction implements Action {
  readonly type = AdminActionTypes.UPDATE_WITHDRAWAL_REQUESTS_FILTER;

  constructor(public payload: Filter) {}
}

/*
  ========================================
        DOCUMENTS ACTIONS
  ========================================
*/
export class GetAdminDocumentsAction implements Action {
  readonly type = AdminActionTypes.GET_DOCUMENTS;

  constructor(public filter: Filter, public pageSize = 25, public pageNumber = 0) {}
}

export class GetAdminDocumentsSuccessAction implements Action {
  readonly type = AdminActionTypes.GET_DOCUMENTS_SUCCESS;

  constructor(public payload: Pageable<Document>) {}
}

export class SaveAdminDocumentFilterAction implements Action {
  readonly type = AdminActionTypes.SAVE_DOCUMENT_FILTER;

  constructor(public payload: Filter) {}
}

/*
  ========================================
        EMAILS ACTIONS
  ========================================
*/
export class GetEmailsAction implements Action {
  readonly type = AdminActionTypes.GET_EMAILS;
  constructor(public filter: Filter, public page: number = 0, public pageSize: number = 25) {}
}

export class GetEmailsSuccessAction implements Action {
  readonly type = AdminActionTypes.GET_EMAILS_SUCCESS;
  constructor(public payload: Pageable<Email>) {}
}

export class SaveEmailsFilterAction implements Action {
  readonly type = AdminActionTypes.SAVE_EMAILS_FILTER;
  constructor(public payload: Filter) {}
}

export type Actions =
  | GetUsersAction
  | GetUsersSuccessAction
  | CreateUserAction
  | CreateUserSuccessAction
  | UpdateUserAction
  | UpdateUserSuccessAction
  | RemoveUserAction
  | RemoveUserSuccessAction
  | ReactivateUserAction
  | ReactivateUserSuccessAction
  | SoftRemoveUserSuccessAction
  | SaveUsersFilterAction
  | GetDenominationsAction
  | GetDenominationsSuccessAction
  | GetOrganizationsAction
  | GetOrganizationsSuccessAction
  | GetOrganizationAction
  | GetOrganizationSuccessAction
  | CreateOrganizationAction
  | CreateOrganizationSuccessAction
  | UpdateOrganizationAction
  | UpdateOrganizationSuccessAction
  | RemoveOrganizationAction
  | RemoveOrganizationSuccessAction
  | ReactivateOrganizationAction
  | ReactivateOrganizationSuccessAction
  | SoftRemoveOrganizationSuccessAction
  | SaveOrganizationsFilterAction
  | GetRolesAction
  | GetRolesSuccessAction
  | GetRoleAction
  | GetRoleSuccessAction
  | CreateRoleAction
  | CreateRoleSuccessAction
  | UpdateRoleAction
  | UpdateRoleSuccessAction
  | RemoveRoleAction
  | RemoveRoleSuccessAction
  | GetPermissionsAction
  | GetPermissionsSuccessAction
  | SaveRolesFilterAction
  | GetOverviewAction
  | GetOverviewSuccessAction
  | GetAdminOrdersAction
  | GetAdminOrdersSuccessAction
  | UpdateOrderStatusAction
  | UpdateOrderStatusSuccessAction
  | UpdateOrdersFilterAction
  | GetDistrictsAction
  | GetDistrictsSuccessAction
  | GetRegionsAction
  | GetRegionsSuccessAction
  | GetFacilityTypesAction
  | GetFacilityTypesSuccessAction
  | ResubmitPaymentAction
  | ResubmitPaymentSuccessAction
  | GetAdminWithdrawalRequestsAction
  | GetWithdrawalRequestsSuccessAction
  | UpdateWithdrawalRequestAction
  | UpdateWithdrawalRequestSuccessAction
  | UpdateWithdrawalRequestFilterAction
  | GetAdminDocumentsAction
  | GetAdminDocumentsSuccessAction
  | SaveAdminDocumentFilterAction
  | RetryProformaAction
  | RetryShipmentAction
  | GetEmailsAction
  | GetEmailsSuccessAction
  | SaveEmailsFilterAction
  | PayOrderAction
  | PayOrderActionSuccessAction
