import { AuthState } from './state';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { User } from '../../shared/models/user';
import { Organization } from '../../shared/models/organization';

export const selectAuthState: MemoizedSelector<object, AuthState> =
  createFeatureSelector<AuthState>('auth');

export const selectAuthUser: MemoizedSelector<object, User> =
  createSelector(selectAuthState, (state: AuthState) => state.user);

export const selectIsAuthenticated: MemoizedSelector<object, boolean> =
  createSelector(selectAuthState, (state: AuthState) => state.isAuthenticated);

export const selectOrganizations: MemoizedSelector<object, Organization[]> =
  createSelector(selectAuthState, (state: AuthState) => state.organizations);

export const selectAccessToken: MemoizedSelector<object, string> =
  createSelector(selectAuthState, (state: AuthState) => state.accessToken);

export const selectRefreshToken: MemoizedSelector<object, string> =
  createSelector(selectAuthState, (state: AuthState) => state.refreshToken);

