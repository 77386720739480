import { Component, HostListener, OnInit } from '@angular/core';
import {SettingsService} from './shared/providers/settings/settings.service';
import {WorkerService} from './shared/providers/worker/worker.service';
import { IconService } from './shared/providers/icon/icon.service';
import { LoaderService } from './shared/providers/loader/loader.service';
import { GoogleAnalyticsService } from './shared/providers/google-analytics/google-analytics.service';
import { SETTING } from './shared/constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit{
  constructor(
    private settings: SettingsService,
    private worker: WorkerService,
    private iconService: IconService,
    private loaderService: LoaderService,
    private analytics: GoogleAnalyticsService) {

    this._setHeightProp();
  }

  @HostListener('window:resize')
  private _setHeightProp() {
    // implementation by https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  ngOnInit(): void {
    this.settings.restoreAll();
    this.worker.checkNewVersion();
    this.iconService.registerIcons();
    this.loaderService.init();

    this.analytics.configure(this.settings.get(SETTING.ANALYTICS));
  }
}
