import { ErrorStateMatcher } from '@angular/material/core';
import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { SimpleChanges } from '@angular/core';
import { animationFrameScheduler, from, Observable, of, scheduled } from 'rxjs';
import { bufferCount, concatMap, delay, mergeMap, scan, tap } from 'rxjs/operators';
import { MODULES } from './constants';

export function convertDate(dateString: string): Date {
  if (isDate(dateString)) {
    const date: Date = new Date(dateString);
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes());
  }

  return null;
}

export function isDate(dateString: string): boolean {
  return dateString !== null && !isNaN(Date.parse(dateString));
}

export function readFile(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(window.btoa(reader.result as string));
    reader.onerror = (event) => reject(event);
    reader.onabort = (event) => reject(event);

    reader.readAsBinaryString(file);
  });
}

export class DirtyFieldErrorMatcher implements ErrorStateMatcher {
  constructor(private initialValue: any) {

  }

  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && control.value !== undefined && control.value !== '' && control.value !== this.initialValue;
  }
}

export function inputChanged(input: SimpleChanges, prop: string): boolean {
  return input[prop] &&
    input[prop].currentValue &&
    JSON.stringify(input[prop].currentValue) !== JSON.stringify(input[prop].previousValue);
}

export function nullOrEmpty(input: string): boolean {
  return input === '' || input === null || input === undefined;
}

export function nullOrEmptyList(input: any[]): boolean {
  return input == null || input.length <= 0;
}

export function dateDiff(date1: Date, date2: Date, interval: string = 'days') {
  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;

  // Weirdly enough, Typescript is not enforcing the type here, resulting in a bug: "setHours" is not a function
  if (typeof date1 === 'string') {
    date1 = new Date(date1);
  }

  if (typeof date2 === 'string') {
    date2 = new Date(date2);
  }

  if (interval === 'weeks' || interval === 'days') {
    date1.setHours(0, 0, 0, 0);
    date2.setHours(0, 0, 0, 0);
  }

  const timediff = date2.getTime() - date1.getTime();
  if (isNaN(timediff)) {
    return NaN;
  }

  switch (interval) {
    case 'years':
      return date2.getFullYear() - date1.getFullYear();
    case 'months':
      return (
        (date2.getFullYear() * 12 + date2.getMonth())
        -
        (date1.getFullYear() * 12 + date1.getMonth())
      );
    case 'weeks'  :
      return Math.floor(timediff / week);
    case 'days'   :
      return Math.floor(timediff / day);
    case 'hours'  :
      return Math.floor(timediff / hour);
    case 'minutes':
      return Math.floor(timediff / minute);
    case 'seconds':
      return Math.floor(timediff / second);
    default:
      return undefined;
  }
}

export function getModuleFromUrl(url: string): string {
  if (url.includes('/admin')) {
    return MODULES.ADMIN;
  } else if (url.includes('/monitor')) {
    return MODULES.MONITOR;
  } else if (url.includes('/cms')) {
    return MODULES.CMS;
  }

  return MODULES.ORDERS;
}

export function uniqBy(arr, fn, set = new Set()) {
  return arr.filter(el => (v => !set.has(v) && set.add(v))(typeof fn === 'function' ? fn(el) : el[fn]));
}

export function flatten(arr, result = []) {
  for (let i = 0, length = arr.length; i < length; i++) {
    const value = arr[i];
    if (Array.isArray(value)) {
      flatten(value, result);
    } else {
      result.push(value);
    }
  }
  return result;
}

export function lazyArray<T>(delayMs = 0, concurrency = 2) {
  let isFirstEmission = true;

  return (source$: Observable<T[]>) => {
    return source$.pipe(
      mergeMap((items) => {
        if (!isFirstEmission) {
          return of(items);
        }

        const items$ = from(items);

        return items$.pipe(
          bufferCount(concurrency),
          concatMap((value, index) => {
            const delayed = delay(index * delayMs);

            return scheduled(of(value), animationFrameScheduler).pipe(delayed);
          }),
          scan((acc: T[], steps: T[]) => {
            return [...acc, ...steps];
          }, []),
          tap((scannedItems: T[]) => {
            const scanDidComplete = scannedItems.length === items.length;

            if (scanDidComplete) {
              isFirstEmission = false;
            }
          })
        );
      })
    );
  };
}

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Returns a boolean if the given subdomain matches the url. If no subdomain is given
 * the user should not contain a subdomain
 * @param window The injected Window provider
 * @param subdomain The domain to check for
 * @param allowedSubdomains Allowed subdomains, if none allowed use whitelabel
 */
export function matchSubdomain(window: any, subdomain: string, allowedSubdomains: string[]): boolean {
  const matches = window.location.href.match(/(?:http[s]*:\/\/)*(.*?)\.(?=[^\/]*\..{2,5})/i);

  if ((subdomain == null && matches == null) ||
    ((matches == null || matches.every(s => !allowedSubdomains.includes(s))) && !allowedSubdomains.includes(subdomain))) {
    return true;
  }

  return matches != null && matches.includes(subdomain);
}

export function getUniqueListBasedOnId(list) {
  const tempArray = [];
  list?.forEach(item => {
    if (tempArray.findIndex(temp => temp.id === item.id) > -1) {
      return;
    }

    tempArray.push(item);
  });

  return tempArray;
}
