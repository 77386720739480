import { Entity } from './entity';

export class FormularyItem extends Entity {
  medicineId: number;
  brandId: number;
  forecast?: number;

  constructor(data?: any, restore: boolean = false) {
    super(data, restore);

    if (data) {
      this.brandId = data.brandId;
      this.medicineId = data.medicineId;
      this.forecast = data.forecast;
    }
  }
}
