import { OrderJourney } from '../../monitor/shared/interfaces/order-journey.interface';
import { OrderJourneyComment } from '../../monitor/shared/interfaces/order-journey-comment.interface';
import { OrderJourneyMedicine } from '../../monitor/shared/interfaces/order-journey-medicine.interface';

export interface OrderMonitorState {
  orderJourneys: OrderJourney[];
  orderJourney: OrderJourney[];
  commissionMonitorList: OrderJourney[];
  financeMonitorList: OrderJourney[];
  financeMonitor: OrderJourney[];
  orderJourneyComments: OrderJourneyComment[];
  missingMedicines: OrderJourneyMedicine[];
}

export const initialOrderMonitorState: OrderMonitorState = {
  orderJourneys: null,
  orderJourney: null,
  commissionMonitorList: null,
  financeMonitorList: null,
  financeMonitor: null,
  orderJourneyComments: null,
  missingMedicines: null
};

