import { createFeatureSelector, createSelector, MemoizedSelector, MemoizedSelectorWithProps } from '@ngrx/store';
import { OrdersState } from './state';
import { Medicine } from '../../shared/models/medicine';
import { Formulary } from '../../shared/models/formulary';
import { Brand } from '../../shared/models/brand';
import { Keyword } from '../../shared/models/keyword';
import { Pricelist } from '../../shared/models/pricelist';
import { PrescribingLevel } from '../../shared/interfaces/prescribing-level.interface';
import { Filter } from '../../shared/interfaces/filter.interface';
import { StockTake } from '../../shared/models/stock-take';
import { Message } from '../../shared/models/message';
import { Order } from '../../shared/models/order';
import { PaymentMethod } from '../../shared/interfaces/payment-method.interface';
import { McfInfo } from '../../shared/models/mcf-info';
import { OverviewAction } from '../../shared/interfaces/overview-action.interface';
import { Document } from '../../shared/models/document';
import { WithdrawalRequest } from '../../shared/models/withdrawal-request';
import { MedicineForm } from '../../shared/models/medicine-form';
import { Inventory } from '../../shared/models/inventory';
import { Pageable } from '../../shared/interfaces/pageable.interface';
import { Storeroom } from '../../shared/models/storeroom';
import {NewPriceListItemWithOldId} from "@interfaces/new-pricelist-item.interface";
import { PricelistItem } from '@shared/models/pricelist-item';
import { OrderSupplier } from '@shared/models/order-supplier';

export const selectOrdersState: MemoizedSelector<object, OrdersState> =
  createFeatureSelector<OrdersState>('orders');

export const selectFormulary: MemoizedSelector<object, Formulary> =
  createSelector(selectOrdersState, (state: OrdersState) => state.formulary);

export const selectMedicines: MemoizedSelector<object, Medicine[]> =
  createSelector(selectOrdersState, (state: OrdersState) => state.medicines);

export const selectPreviouslyOrderedMedicines: MemoizedSelector<object, Medicine[]> =
  createSelector(selectOrdersState, (state: OrdersState) => state.previouslyOrderedmedicines);

export const selectOrderAdvice: MemoizedSelector<object, Medicine[]> =
  createSelector(selectOrdersState, (state: OrdersState) => state.orderAdvice);

export const selectMedicineForms: MemoizedSelector<object, MedicineForm[]> =
  createSelector(selectOrdersState, (state: OrdersState) => state.medicineForms);

export const selectBrands: MemoizedSelector<object, Brand[]> =
  createSelector(selectOrdersState, (state: OrdersState) => state.brands);

export const selectKeywords: MemoizedSelector<object, Keyword[]> =
  createSelector(selectOrdersState, (state: OrdersState) => state.keywords);

export const selectPricelists: MemoizedSelector<object, Pricelist[]> =
  createSelector(selectOrdersState, (state: OrdersState) => state.pricelists);

export const selectPrescribingLevels: MemoizedSelector<object, PrescribingLevel[]> =
  createSelector(selectOrdersState, (state: OrdersState) => state.prescribingLevels);

export const selectMedicineFilter: MemoizedSelector<object, Filter> =
  createSelector(selectOrdersState, (state: OrdersState) => state.medicineFilter);

export const selectStockTake: MemoizedSelector<object, StockTake> =
  createSelector(selectOrdersState, (state: OrdersState) => state.stockTake);

export const selectStockTakes: MemoizedSelector<object, Pageable<StockTake>> =
  createSelector(selectOrdersState, (state: OrdersState) => state.stockTakes);

export const selectInventories: MemoizedSelector<object, Pageable<Inventory>> =
  createSelector(selectOrdersState, (state: OrdersState) => state.inventories);

export const selectMessages: MemoizedSelector<object, Message[]> =
  createSelector(selectOrdersState, (state: OrdersState) => state.messages);

export const selectCart: MemoizedSelector<object, Order> =
  createSelector(selectOrdersState, (state: OrdersState) => state.cart);

export const selectCurrentOrders: MemoizedSelector<object, Order[]> =
  createSelector(selectOrdersState, (state: OrdersState) => state.currentOrders);

export const selectPreviousOrders: MemoizedSelector<object, Order[]> =
  createSelector(selectOrdersState, (state: OrdersState) => state.previousOrders);

export const selectAllOrders: MemoizedSelector<object, Order[]> =
  createSelector(selectOrdersState, (state: OrdersState) => state.currentOrders ? state.currentOrders.concat(state.previousOrders) : []);

export const selectOrder: MemoizedSelector<object, Order> =
  createSelector(selectOrdersState, (state: OrdersState) => state.order);

export const selectPaymentMethods: MemoizedSelector<object, PaymentMethod[]> =
  createSelector(selectOrdersState, (state: OrdersState) => state.paymentMethods);

export const selectMcfInfo: MemoizedSelector<object, McfInfo> =
  createSelector(selectOrdersState, (state: OrdersState) => state.mcfInfo);

export const selectOverviewActions: MemoizedSelector<object, OverviewAction[]> =
  createSelector(selectOrdersState, (state: OrdersState) => state.actions);

export const selectDeliveryOrderFilter: MemoizedSelector<object, Filter> =
  createSelector(selectOrdersState, (state: OrdersState) => state.deliveryOrderFilter);

export const selectOrderFilter: MemoizedSelector<object, Filter> =
  createSelector(selectOrdersState, (state: OrdersState) => state.orderFilter);

export const selectDocuments: MemoizedSelector<object, Pageable<Document>> =
  createSelector(selectOrdersState, (state: OrdersState) => state.documents);

export const selectDocumentFilter: MemoizedSelector<object, Filter> =
  createSelector(selectOrdersState, (state: OrdersState) => state.documentFilter);

export const selectReport: MemoizedSelectorWithProps<object, { id }, any> =
  createSelector(selectOrdersState, (state: OrdersState, { id }) => state.reports?.find(report => report.id === id));

export const selectWithdrawalRequests: MemoizedSelector<object, WithdrawalRequest[]> =
  createSelector(selectOrdersState, (state: OrdersState) => state.withdrawalRequests);

export const selectStorerooms: MemoizedSelector<object, Storeroom[]> =
  createSelector(selectOrdersState, (state: OrdersState) => state.storerooms);

export const selectNewPriceListItems: MemoizedSelector<object, NewPriceListItemWithOldId[]> =
  createSelector(selectOrdersState, (state: OrdersState) => state.newPriceListItems);

export const selectOtherSuppliers: MemoizedSelector<object, Map<number, PricelistItem[]>> =
  createSelector(selectOrdersState, (state: OrdersState) => state.otherSuppliers);

export const selectPriceListItemToChange: MemoizedSelector<object, [number, PricelistItem, number]> =
  createSelector(selectOrdersState, (state: OrdersState) => state.priceListItemToChange);
