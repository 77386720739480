<app-header></app-header>
<mat-sidenav-container>
  <mat-sidenav mode="over" [opened]="openMenu$ | async" (closed)="closeMenu()" [fixedInViewport]="true"
               [fixedTopGap]="64" class="main-menu">
    <mat-list>
      <mat-list-item
        routerLink="/home"
        fragment="header"
        transloco="MENU.MAIN.HOME"
        (click)="closeMenu()"
      ></mat-list-item>

      <mat-list-item
        routerLink="/home"
        fragment="about"
        transloco="MENU.MAIN.ABOUT"
        (click)="closeMenu()"
        *appPermission="[PERMISSION.INVENTORIES.VIEW]"
      ></mat-list-item>

      <mat-list-item
        routerLink="/home"
        fragment="faq"
        transloco="MENU.MAIN.FAQ"
        (click)="closeMenu()"
      ></mat-list-item>

      <mat-list-item
        routerLink="/home"
        fragment="goals"
        transloco="MENU.MAIN.GOALS"
        (click)="closeMenu()"
      ></mat-list-item>
    </mat-list>
  </mat-sidenav>

  <mat-sidenav-content>

    <ng-container *transloco="let t">
      <div class="home-page">
        <section class="header-section" id="header">
          <div class="container">
            <div class="row">
              <div class="text-container col-12 col-lg-6">
                <h3 class="header">{{t('PAGES.HOME.SECTIONS.HEADER.TITLE')}}</h3>
                <div class="content" [innerHTML]="t('PAGES.HOME.SECTIONS.HEADER.CONTENT')"></div>
              </div>
              <div class="image-container d-none d-lg-inline-block col-12 col-lg-6">
                <img alt="tablet" srcset="/assets/images/header-tablet.webp 1x, /assets/images/header-tablet@2x.webp 2x"
                     src="/assets/images/header-tablet@2x.webp">
              </div>
            </div>
          </div>
        </section>

        <section class="logos-section" id="logos">
          <img src="/assets/images/chag_logo.webp" alt="CHAG logo" class="chag ">
          <img src="/assets/images/pharmaccess_logo.webp" alt="Pharmaccess logo" class="pharmaccess">
          <img src="/assets/images/nchs_logo.webp" alt="NCHS logo" class="nchs" *appWhiteLabel="'nchs'">
        </section>

        <section class="who-section" id="about">
          <div class="container">
            <div class="row">
              <div class="image-container col-12 col-lg-6 d-none d-lg-inline-block">
                <img alt="who we are" src="/assets/images/who-we-are.webp">
              </div>

              <div class="text-container col-12 col-lg-6" *appWhiteLabel="'nchs'">
                <h3 class="header-content">
                  {{t('PAGES.HOME.SECTIONS.ABOUT.TITLE_NCHS')}}
                </h3>
                <div class="content" [innerHTML]="t('PAGES.HOME.SECTIONS.ABOUT.CONTENT_NCHS')"></div>
              </div>

              <div class="text-container col-12 col-lg-6" *appWhiteLabel>
                <h3>
                  {{t('PAGES.HOME.SECTIONS.ABOUT.TITLE')}}
                </h3>
                <div class="content" [innerHTML]="t('PAGES.HOME.SECTIONS.ABOUT.CONTENT')"></div>
              </div>
            </div>
          </div>
        </section>

        <section class="features-section d-none d-lg-block" id="features">
          <div class="container">
            <div class="row">
              <div class="col-12 text-center">
                <h3>{{t('PAGES.HOME.SECTIONS.FEATURES.TITLE')}}</h3>
                <img alt="features" src="/assets/images/features.svg">
              </div>
            </div>
          </div>
        </section>

        <section class="supply-section" id="supply">
          <div class="container">
            <div class="row">
              <div class="text-container col-12 col-lg-6">
                <h3>{{t('PAGES.HOME.SECTIONS.SUPPLY.TITLE')}}</h3>
                <div class="content" [innerHTML]="t('PAGES.HOME.SECTIONS.SUPPLY.CONTENT')"></div>
              </div>
              <div class="image-container col-12 col-lg-6 d-none d-lg-inline-block">
                <img alt="supply tablet"
                     srcset="/assets/images/supply-tablet.webp 1x, /assets/images/supply-tablet@2x.webp 2x"
                     src="/assets/images/supply-tablet@2x.webp">
              </div>
            </div>
          </div>
        </section>

        <section class="faq-section" id="faq">
          <div class="container">
            <div class="row">
              <h3 class="col-12">{{t('PAGES.HOME.SECTIONS.FAQ.TITLE')}}</h3>
              <div class="image-container d-none d-lg-inline-block col-12 col-lg-6">
                <img srcset="/assets/images/faq.webp 1x, /assets/images/faq@2x.webp 2x" src="/assets/images/faq@2x.webp"
                     alt="frequently asked questions">
              </div>
              <app-faq class="col-12 col-lg-6" type="home"></app-faq>

            </div>
          </div>
        </section>

        <section class="numbers-section" id="goals">
          <div class="container">
            <h3 class="header">{{t('PAGES.HOME.SECTIONS.NUMBERS.TITLE')}}</h3>

            <div class="row">
              <div class="col-12 col-sm-6 col-md-3 ">
                <div class="number-container">
                  <div class="number">3600</div>
                  <div class="label">{{t('PAGES.HOME.SECTIONS.NUMBERS.ORDERS')}}</div>
                </div>
              </div>

              <div class="col-12 col-sm-6 col-md-3">
                <div class="number-container">
                  <div class="number">500</div>
                  <div class="label">{{t('PAGES.HOME.SECTIONS.NUMBERS.MEDICINES')}}</div>
                </div>
              </div>

              <div class="col-12 col-sm-6 col-md-3">
                <div class="number-container">
                  <div class="number">20</div>
                  <div class="label">{{t('PAGES.HOME.SECTIONS.NUMBERS.SUPPLIERS')}}</div>
                </div>
              </div>

              <div class="col-12 col-sm-6 col-md-3">
                <div class="number-container">
                  <div class="number">300</div>
                  <div class="label">{{t('PAGES.HOME.SECTIONS.NUMBERS.FACILITIES')}}</div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer class="footer">
          <div class="container">
            <div class="row">
              <div class="contact-container col-12 col-md-3" *appWhiteLabel="'nchs'">
                <div class="contact-item">{{t('ADDRESSES.NCHS.LABEL')}}</div>
                <div class="contact-item">{{t('ADDRESSES.NCHS.ADDRESS')}}</div>
                <div class="contact-item"><a [href]="'mailto:' + t('ADDRESSES.NCHS.EMAIL_ADDRESS')">{{t('ADDRESSES.NCHS.EMAIL_ADDRESS')}}</a></div>
                <div class="contact-item"><a [href]="'tel:' + t('ADDRESSES.NCHS.PHONE_NUMBER')">{{t('ADDRESSES.NCHS.PHONE_NUMBER')}}</a></div>
              </div>

              <div class="contact-container col-12 col-md-3">
                <div class="contact-item">{{t('ADDRESSES.CHAG.LABEL')}}</div>
                <div class="contact-item">{{t('ADDRESSES.CHAG.ADDRESS')}}</div>
                <div class="contact-item"><a [href]="'mailto:' + t('ADDRESSES.CHAG.EMAIL_ADDRESS')">{{t('ADDRESSES.CHAG.EMAIL_ADDRESS')}}</a></div>
                <div class="contact-item"><a [href]="'tel:' + t('ADDRESSES.CHAG.PHONE_NUMBER')">{{t('ADDRESSES.CHAG.PHONE_NUMBER')}}</a></div>
              </div>

              <div class="contact-container col-12 col-md-3">
                <div class="contact-item">{{t('ADDRESSES.PHARMACCESS.LABEL')}}</div>
                <div class="contact-item">{{t('ADDRESSES.PHARMACCESS.ADDRESS')}}</div>
                <div class="contact-item"><a [href]="'mailto:' + t('ADDRESSES.PHARMACCESS.EMAIL_ADDRESS')">{{t('ADDRESSES.PHARMACCESS.EMAIL_ADDRESS')}}</a></div>
                <div class="contact-item"><a [href]="'tel:' + t('ADDRESSES.PHARMACCESS.PHONE_NUMBER_GENERAL')">{{t('ADDRESSES.PHARMACCESS.PHONE_NUMBER_GENERAL')}}</a> / <a
                  [href]="'tel:' + t('ADDRESSES.PHARMACCESS.PHONE_NUMBER_FINANCE')">{{t('ADDRESSES.PHARMACCESS.PHONE_NUMBER_FINANCE')}}</a></div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </ng-container>

  </mat-sidenav-content>
</mat-sidenav-container>
