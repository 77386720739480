import { User } from '../../shared/models/user';
import { Organization } from '../../shared/models/organization';

export interface AuthState {
  isAuthenticated: boolean;
  user: User;
  organizations: Organization[];
  accessToken: string;
  refreshToken: string;
}

export const initialAuthState: AuthState = {
  isAuthenticated: false,
  user: null,
  organizations: [],
  accessToken: null,
  refreshToken: null
};
