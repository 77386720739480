import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../providers/auth/auth.service';
import { Inject, Injectable } from '@angular/core';
import { take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { WINDOW } from '../../tokens/dom.tokens';
import { SettingsService } from '../../providers/settings/settings.service';
import { SETTING } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private settings: SettingsService, @Inject(WINDOW) private window: any) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.isAuthenticated().pipe(
      take(1),
      tap(isAuthenticated => {
        if (isAuthenticated) {
          return;
        }

        this.rememberUrl();
        this.auth.logout();
      })
    );
  }

  private rememberUrl() {
    const existingUri = this.settings.get(SETTING.REDIRECT_URL, true);
    if (existingUri) {
      return;
    }

    let url = this.window.location.pathname.substring(1);
    if (this.window.location.hash) {
      url += this.window.location.hash;
    }

    if (this.window.location.search) {
      url += this.window.location.search;
    }

    if (url?.startsWith('/home') || url?.startsWith('home')) {
      return;
    }

    this.settings.set(SETTING.REDIRECT_URL, this.window.encodeURIComponent(url), true);
  }
}
