import { Action } from '@ngrx/store';
import { Brand } from '../../shared/models/brand';
import { Order } from '../../shared/models/order';
import { Keyword } from '../../shared/models/keyword';
import { Message } from '../../shared/models/message';
import { McfInfo } from '../../shared/models/mcf-info';
import { Medicine } from '../../shared/models/medicine';
import { Document } from '../../shared/models/document';
import { Formulary } from '../../shared/models/formulary';
import { Pricelist } from '../../shared/models/pricelist';
import { Inventory } from '../../shared/models/inventory';
import { Storeroom } from '../../shared/models/storeroom';
import { StockTake } from '../../shared/models/stock-take';
import { MedicineForm } from '../../shared/models/medicine-form';
import { Filter } from '../../shared/interfaces/filter.interface';
import { OrderMedicine } from '../../shared/models/order-medicine';
import { StockTakeItem } from '../../shared/models/stock-take-item';
import { Pageable } from '../../shared/interfaces/pageable.interface';
import { ApiFilter } from '../../shared/interfaces/api-filter.interface';
import { WithdrawalRequest } from '../../shared/models/withdrawal-request';
import { OrderFilter } from '../../shared/interfaces/order-filter.interface';
import { PaymentMethod } from '../../shared/interfaces/payment-method.interface';
import { OverviewAction } from '../../shared/interfaces/overview-action.interface';
import { PrescribingLevel } from '../../shared/interfaces/prescribing-level.interface';
import { DeliveryMedicine } from '../../shared/interfaces/delivery-medicine.interface';
import { OrderMedicineCopy } from '../../orders/order-details/shared/order-medicine-copy.interface';
import { NewPriceListItemWithOldId } from "@interfaces/new-pricelist-item.interface";
import {PricelistItem} from "@models/pricelist-item";
import { Organization } from '@shared/models/organization';


export enum OrdersActionTypes {
  CREATE_FORMULARY = '[Orders] Create Formulary',
  CREATE_FORMULARY_SUCCESS = '[Orders] Create Formulary Success',
  GET_FORMULARY = '[Orders] Get Formulary',
  GET_FORMULARY_SUCCESS = '[Orders] Get Formulary Success',
  UPDATE_FORMULARY = '[Orders] Update Formulary',
  UPDATE_FORMULARY_SUCCESS = '[Orders] Update Formulary Success',
  ADD_MEDICINE_TO_FORMULARY = '[Orders] Add medicine to formulary',
  REMOVE_MEDICINE_FROM_FORMULARY = '[Orders] Remove medicine from formulary',

  CREATE_STOREROOM = '[Orders] Create Storerooms',
  CREATE_STOREROOM_SUCCESS = '[Orders] Create Storerooms Success',
  GET_STOREROOMS = '[Orders] Get Storerooms',
  GET_STOREROOMS_SUCCESS = '[Orders] Get Storerooms Success',
  UPDATE_STOREROOM = '[Orders] Update Storerooms',
  UPDATE_STOREROOM_SUCCESS = '[Orders] Update Storerooms Success',
  DELETE_STOREROOM = '[Orders] Delete Storerooms',
  DELETE_STOREROOM_SUCCESS = '[Orders] Delete Storerooms Success',

  GET_MEDICINES = '[Orders] Get Medicines',
  GET_MEDICINES_SUCCESS = '[Orders] Get Medicines Success',
  GET_PREVIOUSLY_ORDERED_MEDICINES = '[Orders] Get Previously Orderded Medicines',
  GET_PREVIOUSLY_ORDERED_MEDICINES_SUCCESS = '[Orders] Get Previously Orderded Medicines Success',
  GET_ORDER_ADVICE = '[Orders] Get Order Advice',
  GET_ORDER_ADVICE_SUCCESS = '[Orders] Get Order Advice Success',
  CREATE_MEDICINE = '[Orders] Create Medicine',
  CREATE_MEDICINE_SUCCESS = '[Orders] Create Medicine Success',
  GET_MEDICINE_FORMS = '[Orders] Get unknown medicine forms',
  GET_MEDICINE_FORMS_SUCCESS = '[Orders] Get unknown medicine forms Success',

  GET_BRANDS = '[Orders] Get Brands',
  GET_BRANDS_SUCCESS = '[Orders] Get Brands Success',
  CREATE_BRAND = '[Orders] Create Brand',
  CREATE_BRAND_SUCCESS = '[Orders] Create Brand Success',

  GET_PRESCRIBING_LEVELS = '[Orders] Get Prescribing levels',
  GET_PRESCRIBING_LEVELS_SUCCESS = '[Orders] Get Prescribing levels Success',

  GET_PRICELISTS = '[Orders] Get Pricelists',
  GET_PRICELISTS_SUCCESS = '[Orders] Get Pricelists Success',

  GET_KEYWORDS = '[Orders] Get Keywords',
  GET_KEYWORDS_SUCCESS = '[Orders] Get Keywords Success',
  CREATE_KEYWORDS = '[Orders] Create Keywords',
  CREATE_KEYWORDS_SUCCESS = '[Orders] Create Keywords Success',

  SAVE_ORDER_FILTER = '[Orders] Save order filter',
  SAVE_DELIVERY_ORDER_FILTER = '[Orders] Save delivery order filter',
  SAVE_MEDICINE_FILTER = '[Orders] Save medicine filter',

  GET_LATEST_STOCK_TAKE = '[Orders] Get latest stock take',
  GET_LATEST_STOCK_TAKE_SUCCESS = '[Orders] Get latest stock take Success',

  GET_STOCK_TAKES = '[Orders] Get stock takes',
  GET_STOCK_TAKES_SUCCESS = '[Orders] Get stock takes success',
  MERGE_STOCK_TAKES = '[Orders] Merge stock takes',
  MERGE_STOCK_TAKES_SUCCESS = '[Orders] Merge stock takes success',
  REQUEST_RECOUNT = '[Orders] Put request recount',
  REQUEST_RECOUNT_SUCCESS = '[Orders] Put request recount success',
  EXPORT_STOCK_TAKES_ACTION = '[Orders] export stocktakes',
  EXPORT_STOCK_TAKES_ACTION_SUCCESS = '[Orders] export stocktakes success',
  APPROVE_STOCK_TAKES_ACTION = '[Orders] approve stocktakes',
  APPROVE_STOCK_TAKES_ACTION_SUCCESS = '[Orders] approve stocktakes success',
  UPDATE_STOCK_TAKE_ACTION = '[Orders] update stocktake',
  UPDATE_STOCK_TAKE_ACTION_SUCCESS = '[Orders] update stocktake success',
  DELETE_STOCK_TAKE_ITEM_ACTION = '[Orders] delete stocktake item',
  DELETE_STOCK_TAKE_ITEM_ACTION_SUCCESS = '[Orders] delete stocktake item success',
  GET_INVENTORIES = '[Orders] Get inventories',
  GET_INVENTORIES_SUCCESS = '[Orders] Get inventories success',
  EXPORT_INVENTORIES_ACTION = '[Orders] export inventories',
  EXPORT_INVENTORIES_ACTION_SUCCESS = '[Orders] export inventories success',

  GET_CURRENT_ORDERS = '[Orders] Get current orders',
  GET_CURRENT_ORDERS_SUCCESS = '[Orders] Get current orders Success',
  GET_PREVIOUS_ORDERS = '[Orders] Get previous orders',
  GET_PREVIOUS_ORDERS_SUCCESS = '[Orders] Get previous orders Success',
  GET_ORDER = '[Orders] Get order',
  GET_ORDER_SUCCESS = '[Orders] Get order Success',
  GET_DRAFT_ORDER = '[Orders] Get draft order',
  GET_DRAFT_ORDER_SUCCESS = '[Orders] Get draft order Success',
  MODIFY_ORDER = '[Orders] Modify order',
  MODIFY_ORDER_SUCCESS = '[Orders] Modify order Success',
  UPDATE_ORDER_DO_NOT_SWITCH = '[Orders] Update order, without switching to order overview',
  UPDATE_ORDER = '[Orders] Update order',
  UPDATE_ORDER_SUCCESS = '[Orders] Update order success',
  UPDATE_ORDER_STATUS = '[Orders] Update order status',
  UPDATE_ORDER_STATUS_SUCCESS = '[Orders] Update order status success',
  UPDATE_ORDER_STATUS_ERROR = '[Orders] Update order status error',

  UPDATE_PROFORMA = '[Orders] Update Proforma',
  UPDATE_PROFORMA_SUCCESS = '[Orders] Update Proforma success',

  UPDATE_SHIPMENT = '[Orders] Update shipment',
  UPDATE_SHIPMENT_SUCCESS = '[Orders] Update shipment success',

  SUBMIT_POD = '[Orders] Submit POD',
  SUBMIT_POD_SUCCESS = '[Orders] Submit POD success',

  SUBMIT_DELIVERY = '[Orders] Submit delivery',
  SUBMIT_DELIVERY_SUCCESS = '[Orders] Submit delivery success',
  CANCEL_UNDELIVERED_MEDICINES = '[Orders] Cancel undelivered medicines',
  CANCEL_UNDELIVERED_MEDICINES_SUCCESS = '[Orders] Cancel undelivered medicines success',

  ADD_MEDICINE_TO_CART = '[Orders] Add medicine to cart',
  REMOVE_MEDICINE_FROM_CART = '[Orders] Remove medicine from cart',
  CHANGE_PRICELISTITEM_SUPPLIER_IN_CART = '[Orders] Change pricelistitem supplier in cart',
  SET_PRICELISTITEM_TO_CHANGE = '[Orders] Set pricelistitem to change, to change the supplier',
  CLEAR_CART = '[Orders] Clear cart',
  GET_CART = '[Orders] Get cart',
  GET_CART_SUCCESS = '[Orders] Get cart success',
  UPDATE_CART = '[Orders] Update cart',
  UPDATE_CART_SUCCESS = '[Orders] Update cart success',
  FILTER_CART = '[Orders] Filter cart',
  APPROVE_NEW_UNIT_PRICE = '[Order] Approve new unit price',

  GET_MESSAGES = '[Orders] Get messages',
  GET_MESSAGES_SUCCESS = '[Orders] Get messages Success',
  CREATE_MESSAGE = '[Orders] Create message',
  CREATE_MESSAGE_SUCCESS = '[Orders] Create message Success',
  UPDATE_MESSAGE = '[Orders] Update message',
  UPDATE_MESSAGE_SUCCESS = '[Orders] Update message Success',
  REMOVE_MESSAGE = '[Orders] Remove message',
  REMOVE_MESSAGE_SUCCESS = '[Orders] Remove message Success',

  GET_PAYMENT_METHODS = '[Orders] Get payment methods',
  GET_PAYMENT_METHODS_SUCCESS = '[Orders] Get payment methods Success',
  SET_PAYMENT_METHODS = '[Orders] Set payment methods',
  SET_PAYMENT_METHODS_SUCCESS = '[Orders] Set payment methods Success',

  GET_MCF_INFO = '[Orders] Get MCF info',
  GET_MCF_INFO_SUCCESS = '[Orders] Get MCF info Success',

  GET_OVERVIEW_ACTIONS = '[Orders] Get Overview actions',
  GET_OVERVIEW_ACTIONS_SUCCESS = '[Orders] Get Overview actions Success',

  GET_DOCUMENTS = '[Orders] Get Documents',
  GET_DOCUMENTS_SUCCESS = '[Orders] Get Documents Success',
  REMOVE_DOCUMENT = '[Orders] Remove Documents',
  REMOVE_DOCUMENT_SUCCESS = '[Orders] Remove Documents Success',
  DOWNLOAD_DOCUMENT = '[Orders] Download Documents',
  SAVE_DOCUMENT_FILTER = '[Orders] Save Document Filter',
  CREATE_DOCUMENT = '[Orders] Create Document',
  CREATE_DOCUMENT_SUCCESS = '[Orders] Create Document Success',

  CREATE_WITHDRAWAL_REQUEST_AND_SET_PAYMENT_METHODS = '[Orders] Create withdrawal requests and set payment methods',
  CREATE_WITHDRAWAL_REQUESTS_AND_SET_PAYMENT_METHODS_SUCCESS = '[Orders] Create withdrawal requests and set payment methods success',
  CREATE_WITHDRAWAL_REQUEST = '[Orders] Create withdrawal request',
  CREATE_WITHDRAWAL_REQUEST_SUCCESS = '[Orders] Create withdrawal request success',
  GET_WITHDRAWAL_REQUESTS = '[Orders] Get withdrawal requests',
  GET_WITHDRAWAL_REQUESTS_SUCCESS = '[Orders] Get withdrawal requests success',

  GET_REPORTS = '[Orders] Get reports',
  GET_REPORTS_SUCCESS = '[Orders] Get reports success',
  UPLOAD_PRICES = '[Orders] Post Upload prices',
  UPLOAD_PRICES_SUCCES = '[Orders] Post Upload prices succes',
  FIND_NEW_PRICE_LIST_ITEMS_IF_EXIST = '[Orders] Find new pricelist items for outdated pricelist items',
  FIND_NEW_PRICE_LIST_ITEMS_IF_EXIST_SUCCESS = '[Orders] Find new pricelist items for outdated pricelist items success',

  FIND_OTHER_SUPPLIERS = "[Orders] Find other suppliers for product inside order",
  FIND_OTHER_SUPPLIERS_SUCCESS = "[Orders] Find other suppliers for product inside order success",
}

export class CreateFormularyAction implements Action {
  readonly type = OrdersActionTypes.CREATE_FORMULARY;
}

export class CreateFormularySuccessAction implements Action {
  readonly type = OrdersActionTypes.CREATE_FORMULARY_SUCCESS;

  constructor(public payload: Formulary) {}
}

export class GetFormularyAction implements Action {
  readonly type = OrdersActionTypes.GET_FORMULARY;

  constructor(public payload: string | number) {}
}

export class GetFormularySuccessAction implements Action {
  readonly type = OrdersActionTypes.GET_FORMULARY_SUCCESS;

  constructor(public payload: Formulary) {}
}

export class UpdateFormularyAction implements Action {
  readonly type = OrdersActionTypes.UPDATE_FORMULARY;

  constructor(public medicine: Medicine, public brand: Brand, public forecast: number) {}
}

export class UpdateFormularySuccessAction implements Action {
  readonly type = OrdersActionTypes.UPDATE_FORMULARY_SUCCESS;

  constructor(public payload: Formulary) {}
}

export class AddMedicineToFormularyAction implements Action {
  readonly type = OrdersActionTypes.ADD_MEDICINE_TO_FORMULARY;

  constructor(public medicine: Medicine, public brand: Brand, public forecast: number) {}
}

export class RemoveMedicineFromFormularyAction implements Action {
  readonly type = OrdersActionTypes.REMOVE_MEDICINE_FROM_FORMULARY;

  constructor(public medicine: Medicine, public brand: Brand) {}
}

export class GetMedicinesAction implements Action {
  readonly type = OrdersActionTypes.GET_MEDICINES;

  constructor(public params?: ApiFilter[]) {}
}

export class GetMedicinesSuccessAction implements Action {
  readonly type = OrdersActionTypes.GET_MEDICINES_SUCCESS;

  constructor(public payload: Medicine[]) {}
}

export class GetPreviouslyOrderedMedicinesAction implements Action {
  readonly type = OrdersActionTypes.GET_PREVIOUSLY_ORDERED_MEDICINES;

  constructor(public params?: ApiFilter[]) {}
}

export class GetPreviouslyOrderedMedicinesSuccessAction implements Action {
  readonly type = OrdersActionTypes.GET_PREVIOUSLY_ORDERED_MEDICINES_SUCCESS;

  constructor(public payload: Medicine[]) {}
}

export class GetOrderAdviceAction implements Action {
  readonly type = OrdersActionTypes.GET_ORDER_ADVICE;

  constructor(public params?: ApiFilter[]) {}
}

export class GetOrderAdviceSuccessAction implements Action {
  readonly type = OrdersActionTypes.GET_ORDER_ADVICE_SUCCESS;

  constructor(public payload: Medicine[]) {}
}

export class CreateMedicineAction implements Action {
  readonly type = OrdersActionTypes.CREATE_MEDICINE;

  constructor(public payload: any) {}
}

export class CreateMedicineSuccessAction implements Action {
  readonly type = OrdersActionTypes.CREATE_MEDICINE_SUCCESS;

  constructor(public payload: Medicine) {}
}

export class GetMedicineFormsAction implements Action {
  readonly type = OrdersActionTypes.GET_MEDICINE_FORMS;
}

export class GetMedicineFormsSuccessAction implements Action {
  readonly type = OrdersActionTypes.GET_MEDICINE_FORMS_SUCCESS;

  constructor(public payload: MedicineForm[]) {}
}

export class GetBrandsAction implements Action {
  readonly type = OrdersActionTypes.GET_BRANDS;
}

export class GetBrandsSuccessAction implements Action {
  readonly type = OrdersActionTypes.GET_BRANDS_SUCCESS;

  constructor(public payload: Brand[]) {}
}

export class CreateBrandAction implements Action {
  readonly type = OrdersActionTypes.CREATE_BRAND;

  constructor(public payload: any) {}
}

export class CreateBrandSuccessAction implements Action {
  readonly type = OrdersActionTypes.CREATE_BRAND_SUCCESS;

  constructor(public payload: Brand) {}
}

export class GetPrescribingLevelsAction implements Action {
  readonly type = OrdersActionTypes.GET_PRESCRIBING_LEVELS;
}

export class GetPrescribingLevelsSuccessAction implements Action {
  readonly type = OrdersActionTypes.GET_PRESCRIBING_LEVELS_SUCCESS;

  constructor(public payload: PrescribingLevel[]) {}
}

export class GetPricelistsAction implements Action {
  readonly type = OrdersActionTypes.GET_PRICELISTS;
}

export class GetPricelistsSuccessAction implements Action {
  readonly type = OrdersActionTypes.GET_PRICELISTS_SUCCESS;

  constructor(public payload: Pricelist[]) {}
}

export class GetKeywordsAction implements Action {
  readonly type = OrdersActionTypes.GET_KEYWORDS;
}

export class GetKeywordsSuccessAction implements Action {
  readonly type = OrdersActionTypes.GET_KEYWORDS_SUCCESS;

  constructor(public payload: Keyword[]) {}
}

export class CreateKeywordsAction implements Action {
  readonly type = OrdersActionTypes.CREATE_KEYWORDS;

  constructor(public payload: any) {}
}

export class CreateKeywordsSuccessAction implements Action {
  readonly type = OrdersActionTypes.CREATE_KEYWORDS_SUCCESS;

  constructor(public payload: Keyword) {}
}

export class SaveMedicineFilterAction implements Action {
  readonly type = OrdersActionTypes.SAVE_MEDICINE_FILTER;

  constructor(public payload: Filter) {}
}

export class SaveDeliveryOrderFilterAction implements Action {
  readonly type = OrdersActionTypes.SAVE_DELIVERY_ORDER_FILTER;

  constructor(public payload: Filter) {}
}

export class SaveOrderFilterAction implements Action {
  readonly type = OrdersActionTypes.SAVE_ORDER_FILTER;

  constructor(public payload: Filter) {}
}

export class GetLatestStockTakeAction implements Action {
  readonly type = OrdersActionTypes.GET_LATEST_STOCK_TAKE;
}

export class GetLatestStockTakeSuccessAction implements Action {
  readonly type = OrdersActionTypes.GET_LATEST_STOCK_TAKE_SUCCESS;

  constructor(public payload: StockTake) {}
}

export class GetStockTakesAction implements Action {
  readonly type = OrdersActionTypes.GET_STOCK_TAKES;

  constructor(public pageNumber = 0, public pageSize = 25, public filter: Filter = null) {}
}

export class GetStockTakesSuccessAction implements Action {
  readonly type = OrdersActionTypes.GET_STOCK_TAKES_SUCCESS;

  constructor(public payload: Pageable<StockTake>) {}
}

export class MergeStockTakeAction implements Action {
  readonly type = OrdersActionTypes.MERGE_STOCK_TAKES;

  constructor(public stockTakeIds: number[]) {}
}

export class MergeStockTakeSuccessAction implements Action {
  readonly type = OrdersActionTypes.MERGE_STOCK_TAKES_SUCCESS;

  constructor(public payload: Inventory) {}
}

export class RequestRecountAction implements Action {
  readonly type = OrdersActionTypes.REQUEST_RECOUNT;

  constructor(public stockTakeItem: StockTakeItem) {}
}

export class RequestRecountSuccessAction implements Action {
  readonly type = OrdersActionTypes.REQUEST_RECOUNT_SUCCESS;

  constructor(public payload: StockTakeItem) {}
}

export class ExportStockTakesAction implements Action {
  readonly type = OrdersActionTypes.EXPORT_STOCK_TAKES_ACTION;

  constructor(public ids: number[]) {}
}

export class ExportStockTakesSuccessAction implements Action {
  readonly type = OrdersActionTypes.EXPORT_STOCK_TAKES_ACTION_SUCCESS;

  constructor() {}
}

export class ApproveStockTakesAction implements Action {
  readonly type = OrdersActionTypes.APPROVE_STOCK_TAKES_ACTION;

  constructor(public ids: number[]) {}
}

export class ApproveStockTakesSuccessAction implements Action {
  readonly type = OrdersActionTypes.APPROVE_STOCK_TAKES_ACTION_SUCCESS;

  constructor(public payload: StockTake[]) {}
}

export class UpdateStockTakeAction implements Action {
  readonly type = OrdersActionTypes.UPDATE_STOCK_TAKE_ACTION;

  constructor(public id: number, public stockTakeItems: StockTakeItem[]) {}
}

export class UpdateStockTakeActionSuccess implements Action {
  readonly type = OrdersActionTypes.UPDATE_STOCK_TAKE_ACTION_SUCCESS;

  constructor(public payload: StockTake) {}
}

export class DeleteStockTakeItemAction implements Action {
  readonly type = OrdersActionTypes.DELETE_STOCK_TAKE_ITEM_ACTION;

  constructor(public id: number) {}
}

export class DeleteStockTakeItemActionSuccess implements Action {
  readonly type = OrdersActionTypes.DELETE_STOCK_TAKE_ITEM_ACTION_SUCCESS;

  constructor(public id: number) {}
}

export class ExportInventoriesAction implements Action {
  readonly type = OrdersActionTypes.EXPORT_INVENTORIES_ACTION;

  constructor(public ids: number[]) {}
}

export class ExportInventoriesSuccessAction implements Action {
  readonly type = OrdersActionTypes.EXPORT_INVENTORIES_ACTION_SUCCESS;

  constructor() {}
}

export class GetInventoriesAction implements Action {
  readonly type = OrdersActionTypes.GET_INVENTORIES;

  constructor(public pageNumber = 0, public pageSize = 25, public filter: Filter = null) {}
}

export class GetInventoriesSuccessAction implements Action {
  readonly type = OrdersActionTypes.GET_INVENTORIES_SUCCESS;

  constructor(public payload: Pageable<Inventory>) {}
}

export class GetMessagesAction implements Action {
  readonly type = OrdersActionTypes.GET_MESSAGES;
}

export class GetMessagesSuccessAction implements Action {
  readonly type = OrdersActionTypes.GET_MESSAGES_SUCCESS;

  constructor(public payload: Message[]) {}
}

export class CreateMessageAction implements Action {
  readonly type = OrdersActionTypes.CREATE_MESSAGE;

  constructor(public payload: Message) {}
}

export class CreateMessageSuccessAction implements Action {
  readonly type = OrdersActionTypes.CREATE_MESSAGE_SUCCESS;

  constructor(public payload: Message) {}
}

export class UpdateMessageAction implements Action {
  readonly type = OrdersActionTypes.UPDATE_MESSAGE;

  constructor(public payload: Message) {}
}

export class UpdateMessageSuccessAction implements Action {
  readonly type = OrdersActionTypes.UPDATE_MESSAGE_SUCCESS;

  constructor(public payload: Message) {}
}

export class RemoveMessageAction implements Action {
  readonly type = OrdersActionTypes.REMOVE_MESSAGE;

  constructor(public payload: number) {}
}

export class RemoveMessageSuccessAction implements Action {
  readonly type = OrdersActionTypes.REMOVE_MESSAGE_SUCCESS;

  constructor(public payload: number) {}
}

export class AddMedicineToCartAction implements Action {
  readonly type = OrdersActionTypes.ADD_MEDICINE_TO_CART;

  constructor(
    public medicine: Medicine,
    public brand: Brand,
    public supplier: Organization,
    public quantity: number) {}
}

export class RemoveMedicineFromCartAction implements Action {
  readonly type = OrdersActionTypes.REMOVE_MEDICINE_FROM_CART;

  constructor(
    public medicine: Medicine,
    public brand: Brand,
    public supplier: Organization,
    ) {}
}

export class ChangePriceListItemSupplierCartAction implements Action {
  readonly type = OrdersActionTypes.CHANGE_PRICELISTITEM_SUPPLIER_IN_CART;

  constructor(
    public oldPriceListItem: PricelistItem,
    public newPriceListItem: PricelistItem,
    public quantity: number) {}
}

export class SetPriceListItemToChangeAction implements Action {
  readonly type = OrdersActionTypes.SET_PRICELISTITEM_TO_CHANGE;

  constructor(
    public oldPriceListItemId: number,
    public newPriceListItem: PricelistItem,
    public quantity: number) {}
}

export class ClearCartAction implements Action {
  readonly type = OrdersActionTypes.CLEAR_CART;
}

export class FilterCartAction implements Action {
  readonly type = OrdersActionTypes.FILTER_CART;
}

export class GetCartAction implements Action {
  readonly type = OrdersActionTypes.GET_CART;

  constructor(public payload: number) {}
}

export class GetCartSuccessAction implements Action {
  readonly type = OrdersActionTypes.GET_CART_SUCCESS;

  constructor(public payload: Order) {}
}

export class UpdateCartAction implements Action {
  readonly type = OrdersActionTypes.UPDATE_CART;

  constructor(public payload: OrderMedicine) {}
}

export class UpdateCartSuccessAction implements Action {
  readonly type = OrdersActionTypes.UPDATE_CART_SUCCESS;

  constructor(public payload: Order) {}
}

export class ApproveNewUnitPriceAction implements Action {
  readonly type = OrdersActionTypes.APPROVE_NEW_UNIT_PRICE;

  constructor(public payload: {order: Order, oldPriceListItemId: number,
    newPriceListItem: PricelistItem}) {}
}

export class UpdateOrderAndDoNotSwitchAction implements Action {
  readonly type = OrdersActionTypes.UPDATE_ORDER_DO_NOT_SWITCH;

  constructor(public payload: Order) {}
}

export class UpdateOrderAction implements Action {
  readonly type = OrdersActionTypes.UPDATE_ORDER;

  constructor(public payload: Order) {}
}

export class UpdateOrderSuccessAction implements Action {
  readonly type = OrdersActionTypes.UPDATE_ORDER_SUCCESS;

  constructor(public payload: Order) {}
}

export class UpdateOrderStatusAction implements Action {
  readonly type = OrdersActionTypes.UPDATE_ORDER_STATUS;

  constructor(
    public orderId: number,
    public status: string,
    public message?: string,
    public orderSupplierIds?: number[],
  ) {}
}

export class UpdateOrderStatusSuccessAction implements Action {
  readonly type = OrdersActionTypes.UPDATE_ORDER_STATUS_SUCCESS;

  constructor(public payload: Order) {}
}

export class UpdateOrderStatusErrorAction implements Action {
  readonly type = OrdersActionTypes.UPDATE_ORDER_STATUS_ERROR;

  constructor(
    public orderId: number,
    public error: any,
  ) {}
}

export class GetCurrentOrdersAction implements Action {
  readonly type = OrdersActionTypes.GET_CURRENT_ORDERS;
}

export class GetCurrentOrdersSuccessAction implements Action {
  readonly type = OrdersActionTypes.GET_CURRENT_ORDERS_SUCCESS;

  constructor(public payload: Order[]) {}
}

export class GetPreviousOrdersAction implements Action {
  readonly type = OrdersActionTypes.GET_PREVIOUS_ORDERS;
}

export class GetPreviousOrdersSuccessAction implements Action {
  readonly type = OrdersActionTypes.GET_PREVIOUS_ORDERS_SUCCESS;

  constructor(public payload: Order[]) {}
}

export class GetOrderAction implements Action {
  readonly type = OrdersActionTypes.GET_ORDER;

  constructor(public orderId: number, public filter?: OrderFilter) {}
}

export class GetOrderSuccessAction implements Action {
  readonly type = OrdersActionTypes.GET_ORDER_SUCCESS;

  constructor(public payload: Order) {}
}

export class GetDraftOrder implements Action {
  readonly type = OrdersActionTypes.GET_DRAFT_ORDER;

  constructor(public payload: Order) {}
}

export class GetDraftOrderSuccess implements Action {
  readonly type = OrdersActionTypes.GET_DRAFT_ORDER_SUCCESS;

  constructor(public payload: Order) {}
}

export class ModifyOrderAction implements Action {
  readonly type = OrdersActionTypes.MODIFY_ORDER;

  constructor(public payload: number) {}
}

export class ModifyOrderSuccessAction implements Action {
  readonly type = OrdersActionTypes.MODIFY_ORDER_SUCCESS;

  constructor(public payload: Order) {}
}

export class UpdateProFormaAction implements Action {
  readonly type = OrdersActionTypes.UPDATE_PROFORMA;

  constructor(
    public orderId: number,
    public orderSupplierId: number,
    public status: string,
    public orderMedicines?: any,
    public message?: string,
  ) {}
}

export class UpdateProFormaSuccessAction implements Action {
  readonly type = OrdersActionTypes.UPDATE_PROFORMA_SUCCESS;

  constructor(public payload: Order) {}
}

export class GetPaymentMethodsAction implements Action {
  readonly type = OrdersActionTypes.GET_PAYMENT_METHODS;
}

export class GetPaymentMethodsSuccessAction implements Action {
  readonly type = OrdersActionTypes.GET_PAYMENT_METHODS_SUCCESS;

  constructor(public payload: PaymentMethod[]) {}
}

export class SetPaymentMethodsAction implements Action {
  readonly type = OrdersActionTypes.SET_PAYMENT_METHODS;

  constructor(public orderId: number, public paymentMethods: any[]) {}
}

export class SetPaymentMethodsSuccessAction implements Action {
  readonly type = OrdersActionTypes.SET_PAYMENT_METHODS_SUCCESS;

  constructor(public payload: Order) {}
}

export class GetMcfInfoAction implements Action {
  readonly type = OrdersActionTypes.GET_MCF_INFO;
}

export class GetMcfInfoSuccessAction implements Action {
  readonly type = OrdersActionTypes.GET_MCF_INFO_SUCCESS;

  constructor(public payload: McfInfo) {}
}

export class UpdateShipmentAction implements Action {
  readonly type = OrdersActionTypes.UPDATE_SHIPMENT;

  constructor(
    public orderId: number,
    public orderSupplierId: number,
    public orderMedicines: any,
    public status: string,
    public fullName?: string,
    public email?: string,
    public filter?: OrderFilter,
    public subShipments: OrderMedicineCopy[] = [],
  ) {}
}

export class UpdateShipmentSuccessAction implements Action {
  readonly type = OrdersActionTypes.UPDATE_SHIPMENT_SUCCESS;

  constructor(public payload: Order) {}
}

export class SubmitPodAction implements Action {
  readonly type = OrdersActionTypes.SUBMIT_POD;

  constructor(public orderId: number, public orderSupplierId: number, public files: any[]) {}
}

export class SubmitPodSuccessAction implements Action {
  readonly type = OrdersActionTypes.SUBMIT_POD_SUCCESS;

  constructor() {}
}

export class SubmitDeliveryAction implements Action {
  readonly type = OrdersActionTypes.SUBMIT_DELIVERY;

  constructor(
    public orderId: number,
    public orderSupplierId: number,
    public orderMedicines: DeliveryMedicine[],
    public subDeliveries: OrderMedicineCopy[],
  ) {}
}

export class SubmitDeliverySuccessAction implements Action {
  readonly type = OrdersActionTypes.SUBMIT_DELIVERY_SUCCESS;

  constructor(public payload: Order) {}
}

export class CancelUndeliveredMedicinesAction implements Action {
  readonly type = OrdersActionTypes.CANCEL_UNDELIVERED_MEDICINES;

  constructor(
    public orderId: number,
    public orderSupplierId: number,
    public orderMedicines: DeliveryMedicine[],
  ) {}
}

export class CancelUndeliveredMedicinesSuccessAction implements Action {
  readonly type = OrdersActionTypes.CANCEL_UNDELIVERED_MEDICINES_SUCCESS;

  constructor(public payload: Order) {}
}

export class GetOverviewActionsAction implements Action {
  readonly type = OrdersActionTypes.GET_OVERVIEW_ACTIONS;
}

export class GetOverviewActionsSuccessAction implements Action {
  readonly type = OrdersActionTypes.GET_OVERVIEW_ACTIONS_SUCCESS;

  constructor(public payload: OverviewAction[]) {}
}

export class GetDocumentsAction implements Action {
  readonly type = OrdersActionTypes.GET_DOCUMENTS;

  constructor(
      public filter: Filter = null,
      public subOrderId: number = null,
      public documentType: string = null,
      public pageSize = 25,
      public pageNumber = 0
    ) {}
}

export class GetDocumentsSuccessAction implements Action {
  readonly type = OrdersActionTypes.GET_DOCUMENTS_SUCCESS;

  constructor(public payload: Pageable<Document>) {}
}

export class RemoveDocumentAction implements Action {
  readonly type = OrdersActionTypes.REMOVE_DOCUMENT;

  constructor(public payload: number) {}
}

export class RemoveDocumentSuccessAction implements Action {
  readonly type = OrdersActionTypes.REMOVE_DOCUMENT_SUCCESS;

  constructor(public payload: number) {}
}

export class DownloadDocumentAction implements Action {
  readonly type = OrdersActionTypes.DOWNLOAD_DOCUMENT;

  constructor(public url: string, public fileName: string) {}
}

export class SaveDocumentFilterAction implements Action {
  readonly type = OrdersActionTypes.SAVE_DOCUMENT_FILTER;

  constructor(public payload: Filter) {}
}

export class CreateDocumentAction implements Action {
  readonly type = OrdersActionTypes.CREATE_DOCUMENT;

  constructor(public payload: any) {}
}

export class CreateDocumentSuccessAction implements Action {
  readonly type = OrdersActionTypes.CREATE_DOCUMENT_SUCCESS;

  constructor(public payload: Document) {}
}

export class CreateWithdrawalRequestAndSetPaymentMethodAction implements Action {
  readonly type = OrdersActionTypes.CREATE_WITHDRAWAL_REQUEST_AND_SET_PAYMENT_METHODS;

  constructor(
    public withdrawalRequest: WithdrawalRequest,
    public orderId: number,
    public paymentMethods: any[],
  ) {}
}

export class CreateWithdrawalRequestAndSetPaymentMethodSuccessAction implements Action {
  readonly type = OrdersActionTypes.CREATE_WITHDRAWAL_REQUESTS_AND_SET_PAYMENT_METHODS_SUCCESS;

  constructor(public payload: WithdrawalRequest) {}
}

export class GetWithdrawalRequestsAction implements Action {
  readonly type = OrdersActionTypes.GET_WITHDRAWAL_REQUESTS;
}

export class GetWithdrawalRequestsSuccessAction implements Action {
  readonly type = OrdersActionTypes.GET_WITHDRAWAL_REQUESTS_SUCCESS;

  constructor(public payload: WithdrawalRequest[]) {}
}

export class CreateWithdrawalRequestAction implements Action {
  readonly type = OrdersActionTypes.CREATE_WITHDRAWAL_REQUEST;

  constructor(public payload: number) {}
}

export class CreateWithdrawalRequestSuccessAction implements Action {
  readonly type = OrdersActionTypes.CREATE_WITHDRAWAL_REQUEST_SUCCESS;

  constructor(public payload: WithdrawalRequest) {}
}

export class GetReportsAction implements Action {
  readonly type = OrdersActionTypes.GET_REPORTS;
}

export class GetReportsSuccessAction implements Action {
  readonly type = OrdersActionTypes.GET_REPORTS_SUCCESS;

  constructor(public payload: any) {}
}

export class CreateStoreroomAction implements Action {
  readonly type = OrdersActionTypes.CREATE_STOREROOM;

  constructor(public storeroom: Storeroom) {}
}

export class CreateStoreroomSuccessAction implements Action {
  readonly type = OrdersActionTypes.CREATE_STOREROOM_SUCCESS;

  constructor(public payload: Storeroom) {}
}

export class GetStoreroomsAction implements Action {
  readonly type = OrdersActionTypes.GET_STOREROOMS;

  constructor() {}
}

export class GetStoreroomsSuccessAction implements Action {
  readonly type = OrdersActionTypes.GET_STOREROOMS_SUCCESS;

  constructor(public payload: Storeroom) {}
}

export class UpdateStoreroomAction implements Action {
  readonly type = OrdersActionTypes.UPDATE_STOREROOM;

  constructor(public storeroom: Storeroom) {}
}

export class UpdateStoreroomSuccessAction implements Action {
  readonly type = OrdersActionTypes.UPDATE_STOREROOM_SUCCESS;

  constructor(public payload: Storeroom) {}
}

export class UploadPricesAction implements Action {
  readonly type = OrdersActionTypes.UPLOAD_PRICES;

  constructor(public payload: number) {}
}

export class FindNewPriceListItemsIfExistAction implements Action {
  readonly type = OrdersActionTypes.FIND_NEW_PRICE_LIST_ITEMS_IF_EXIST;

  constructor(public payload: number) {}
}

export class FindNewPriceListItemsIfExistSuccessAction implements Action {
  readonly type = OrdersActionTypes.FIND_NEW_PRICE_LIST_ITEMS_IF_EXIST_SUCCESS;

  constructor(public payload: NewPriceListItemWithOldId) {}
}

export class FindOtherSuppliersAction implements Action {
  readonly type = OrdersActionTypes.FIND_OTHER_SUPPLIERS;

  constructor(public payload: number) {}
}

export class FindOtherSuppliersSuccessAction implements Action {
  readonly type = OrdersActionTypes.FIND_OTHER_SUPPLIERS_SUCCESS;

  constructor(public payload: Map<number, PricelistItem[]>) {}
}

export class UploadPricesSuccesAction implements Action {
  readonly type = OrdersActionTypes.UPLOAD_PRICES_SUCCES;

  constructor(public payload: any) {}
}

export class DeleteStoreroomAction implements Action {
  readonly type = OrdersActionTypes.DELETE_STOREROOM;

  constructor(public id: number) {}
}

export class DeleteStoreroomSuccessAction implements Action {
  readonly type = OrdersActionTypes.DELETE_STOREROOM_SUCCESS;

  constructor(public id: number) {}
}

export type Actions =
  | GetFormularyAction
  | GetFormularySuccessAction
  | CreateFormularyAction
  | CreateFormularySuccessAction
  | UpdateFormularyAction
  | UpdateFormularySuccessAction
  | GetMedicinesAction
  | GetMedicinesSuccessAction
  | GetPreviouslyOrderedMedicinesAction
  | GetPreviouslyOrderedMedicinesSuccessAction
  | GetMedicineFormsAction
  | GetMedicineFormsSuccessAction
  | CreateMedicineAction
  | CreateMedicineSuccessAction
  | GetBrandsAction
  | GetBrandsSuccessAction
  | CreateBrandAction
  | CreateBrandSuccessAction
  | GetKeywordsAction
  | GetKeywordsSuccessAction
  | CreateKeywordsAction
  | CreateKeywordsSuccessAction
  | GetPricelistsAction
  | GetPricelistsSuccessAction
  | AddMedicineToFormularyAction
  | RemoveMedicineFromFormularyAction
  | GetPrescribingLevelsAction
  | GetPrescribingLevelsSuccessAction
  | SaveMedicineFilterAction
  | GetLatestStockTakeAction
  | GetLatestStockTakeSuccessAction
  | GetMessagesAction
  | GetMessagesSuccessAction
  | CreateMessageAction
  | CreateMessageSuccessAction
  | UpdateMessageAction
  | UpdateMessageSuccessAction
  | RemoveMessageAction
  | RemoveMessageSuccessAction
  | AddMedicineToCartAction
  | ChangePriceListItemSupplierCartAction
  | SetPriceListItemToChangeAction
  | RemoveMedicineFromCartAction
  | GetCurrentOrdersAction
  | GetCurrentOrdersSuccessAction
  | GetPreviousOrdersAction
  | GetPreviousOrdersSuccessAction
  | GetOrderAction
  | GetOrderSuccessAction
  | GetDraftOrder
  | GetDraftOrderSuccess
  | ModifyOrderAction
  | ModifyOrderSuccessAction
  | UpdateProFormaAction
  | UpdateProFormaSuccessAction
  | GetCartAction
  | GetCartSuccessAction
  | ClearCartAction
  | FilterCartAction
  | UpdateCartAction
  | UpdateCartSuccessAction
  | ApproveNewUnitPriceAction
  | UpdateOrderStatusAction
  | UpdateOrderStatusSuccessAction
  | UpdateOrderStatusErrorAction
  | UpdateOrderAction
  | UpdateOrderSuccessAction
  | GetPaymentMethodsAction
  | GetPaymentMethodsSuccessAction
  | SetPaymentMethodsAction
  | SetPaymentMethodsSuccessAction
  | GetMcfInfoAction
  | GetMcfInfoSuccessAction
  | UpdateShipmentAction
  | UpdateShipmentSuccessAction
  | SubmitPodAction
  | SubmitPodSuccessAction
  | SubmitDeliveryAction
  | SubmitDeliverySuccessAction
  | CancelUndeliveredMedicinesAction
  | CancelUndeliveredMedicinesSuccessAction
  | GetOverviewActionsAction
  | GetOverviewActionsSuccessAction
  | SaveOrderFilterAction
  | SaveDeliveryOrderFilterAction
  | GetDocumentsAction
  | GetDocumentsSuccessAction
  | RemoveDocumentAction
  | RemoveDocumentSuccessAction
  | SaveDocumentFilterAction
  | CreateDocumentAction
  | CreateDocumentSuccessAction
  | CreateWithdrawalRequestAndSetPaymentMethodAction
  | CreateWithdrawalRequestAndSetPaymentMethodSuccessAction
  | GetReportsAction
  | GetReportsSuccessAction
  | GetWithdrawalRequestsAction
  | GetWithdrawalRequestsSuccessAction
  | CreateWithdrawalRequestAction
  | CreateWithdrawalRequestSuccessAction
  | GetOrderAdviceAction
  | GetOrderAdviceSuccessAction
  | GetStockTakesAction
  | GetStockTakesSuccessAction
  | MergeStockTakeAction
  | MergeStockTakeSuccessAction
  | RequestRecountAction
  | RequestRecountSuccessAction
  | ExportStockTakesAction
  | ExportStockTakesSuccessAction
  | GetInventoriesAction
  | GetInventoriesSuccessAction
  | ExportInventoriesAction
  | ExportInventoriesSuccessAction
  | ApproveStockTakesAction
  | ApproveStockTakesSuccessAction
  | UpdateStockTakeAction
  | UpdateStockTakeActionSuccess
  | DeleteStockTakeItemAction
  | DeleteStockTakeItemActionSuccess
  | CreateStoreroomAction
  | CreateStoreroomSuccessAction
  | GetStoreroomsAction
  | GetStoreroomsSuccessAction
  | UpdateStoreroomAction
  | UpdateStoreroomSuccessAction
  | DeleteStoreroomAction
  | DeleteStoreroomSuccessAction
  | UploadPricesAction
  | UploadPricesSuccesAction
  | FindNewPriceListItemsIfExistAction
  | FindNewPriceListItemsIfExistSuccessAction
  | FindOtherSuppliersAction
  | FindOtherSuppliersSuccessAction;
