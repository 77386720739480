import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {AppState} from './state';
import { Translation } from '@ngneat/transloco';

export const selectAppState: MemoizedSelector<object, AppState> =
  createFeatureSelector<AppState>('app');

export const selectLoadingMessage: MemoizedSelector<object, string> =
  createSelector(selectAppState, (state: AppState) => state.loadingMessage);

export const selectAlert: MemoizedSelector<object, {message: string, type: string}> =
  createSelector(selectAppState, (state: AppState) => state.alert);

export const selectTranslations: MemoizedSelector<object, Translation> =
  createSelector(selectAppState, (state: AppState) => state.translations);

export const selectSideNav: MemoizedSelector<object, boolean> =
  createSelector(selectAppState, (state: AppState) => state.sideNav);
