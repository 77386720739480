import { Entity } from './entity';

export class Storeroom extends Entity {
  id: number;
  organizationId: number;
  name: string;

  constructor(data?: any, restore: boolean = false) {
    super(data, restore);

    if (data) {
      this.id = data.id;
      this.organizationId = data.organizationId;
      this.name = data.name;
    }
  }
}
