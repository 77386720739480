export abstract class Entity {
  id?: number;
  active?: boolean;
  createdOn?: Date;
  updatedOn?: Date;
  deactivatedOn?: Date;

  constructor(data?: any, restore: boolean = false) {
    if (data) {
      this.id = data.id;
      this.active = data.active;
      this.updatedOn = data.updatedOn ? new Date(data.updatedOn) : null;
      this.createdOn = data.createdOn ? new Date(data.createdOn) : null;
      this.deactivatedOn = data.deactivatedOn ? new Date(data.deactivatedOn) : null;
    }
  }
}
