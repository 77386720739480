import * as actions from './actions';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { CmsActionTypes } from './actions';
import { select, Store } from '@ngrx/store';
import { Brand } from '../../shared/models/brand';
import { Keyword } from '../../shared/models/keyword';
import { Medicine } from '../../shared/models/medicine';
import { act, Actions, createEffect, ofType } from '@ngrx/effects';
import { MedicineForm } from '../../shared/models/medicine-form';
import { PricelistItem } from '../../shared/models/pricelist-item';
import { EmailTemplate } from '../../shared/models/email-template';
import { ApiService } from '../../shared/providers/api/api.service';
import { ProductCatalog } from '../../shared/models/product-catalog';
import { selectMedicineForms, selectPrescribingLevels } from './selectors';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { GenericFailureAction, GenericSuccessAction } from '../app/actions';
import { EmailTemplateVariable } from '../../shared/models/email-template-variable';

@Injectable()
export class CmsEffects {
  constructor(private actions$: Actions, private apiService: ApiService, private store: Store) {}

  
  getMedicines: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType<actions.GetMedicinesAction>(CmsActionTypes.GET_MEDICINES),
    switchMap(() =>
      this.apiService
        .getMedicines([
          {
            key: 'unfiltered',
            value: true,
            operator: '=',
          },
        ])
        .pipe(
          map((res) => {
            const medicines = res.map((med) => new Medicine(med));
            return new actions.GetMedicinesSuccessAction(medicines);
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
    ),
  ));

  
  updateMedicine: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType<actions.UpdateMedicineAction>(CmsActionTypes.UPDATE_MEDICINE),
    switchMap((action) =>
      this.apiService.updateMedicine(action.payload).pipe(
        map((res) => {
          this.store.dispatch(new GenericSuccessAction('MESSAGES.API.UPDATE_MEDICINE_SUCCESS'));
          return new actions.UpdateMedicineSuccessAction(new Medicine(res));
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));

  
  deleteUnknownMedicine: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType<actions.DeleteUnknownMedicineAction>(CmsActionTypes.DELETE_UNKNOWN_MEDICINE),
    switchMap((action) =>
      this.apiService.deleteUnknownMedicine(action.unknownMedicineId).pipe(
        map((res) => {
          this.store.dispatch(
            new GenericSuccessAction('MESSAGES.API.DELETE_UNKNOWN_MEDICINE_SUCCESS'),
          );
          return new actions.DeleteUnknownMedicineSuccessAction(action.unknownMedicineId);
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));

  
  getMedicineForms: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType<actions.GetMedicineFormsAction>(CmsActionTypes.GET_MEDICINE_FORMS),
    withLatestFrom(this.store.pipe(select(selectMedicineForms))),
    switchMap(([_, cachedForms]) => {
      if (cachedForms) {
        return of(new actions.GetMedicineFormsSuccessAction(cachedForms));
      }

      return this.apiService.getMedicineForms().pipe(
        map((res) => {
          const forms: MedicineForm[] = res.map((form) => new MedicineForm(form));
          return new actions.GetMedicineFormsSuccessAction(forms);
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      );
    }),
  ));

  
  getPrescribingLevels: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType<actions.GetPrescribingLevelsAction>(CmsActionTypes.GET_PRESCRIBING_LEVELS),
    withLatestFrom(this.store.pipe(select(selectPrescribingLevels))),
    switchMap(([_, cachedLevels]) => {
      if (cachedLevels) {
        return of(new actions.GetPrescribingLevelsSuccessAction(cachedLevels));
      }

      return this.apiService.getPrescribingLevels().pipe(
        map((res) => new actions.GetPrescribingLevelsSuccessAction(res)),
        catchError((err) => of(new GenericFailureAction(err))),
      );
    }),
  ));

  
  getBrands: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType<actions.GetBrandsAction>(CmsActionTypes.GET_BRANDS),
    switchMap(() =>
      this.apiService.getBrands().pipe(
        map((res) => {
          const brands = res.map((brand) => new Brand(brand));
          return new actions.GetBrandsSuccessAction(brands);
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));

  
  updateBrand: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType<actions.UpdateBrandAction>(CmsActionTypes.UPDATE_BRAND),
    switchMap((action) =>
      this.apiService.updateBrand(action.payload).pipe(
        map((res) => {
          this.store.dispatch(new GenericSuccessAction('MESSAGES.API.UPDATE_BRAND_SUCCESS'));
          return new actions.UpdateBrandSuccessAction(new Brand(res));
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));

  
  getKeywords: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType<actions.GetKeywordsAction>(CmsActionTypes.GET_KEYWORDS),
    switchMap(() =>
      this.apiService.getKeywords().pipe(
        map((res) => {
          const keywords = res.map((word) => new Keyword(word));
          return new actions.GetKeywordsSuccessAction(keywords);
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));

  
  updateKeyword: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType<actions.UpdateKeywordAction>(CmsActionTypes.UPDATE_KEYWORD),
    switchMap((action) =>
      this.apiService.updateKeyword(action.payload).pipe(
        map((res) => {
          this.store.dispatch(new GenericSuccessAction('MESSAGES.API.UPDATE_KEYWORD_SUCCESS'));
          return new actions.UpdateKeywordSuccessAction(new Keyword(res));
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));

  
  getPricelistItems: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType<actions.GetPricelistItemsAction>(CmsActionTypes.GET_PRICELIST_ITEMS),
    switchMap(() =>
      this.apiService.getPricelistItems().pipe(
        map((res) => {
          const pricelistItems = res.map((pricelistItem) => new PricelistItem(pricelistItem));
          return new actions.GetPricelistItemsSuccessAction(pricelistItems);
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));

  
  updatePricelistItem: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType<actions.UpdatePricelistItemAction>(CmsActionTypes.UPDATE_PRICELIST_ITEM),
    switchMap((action) =>
      this.apiService.updatePricelistItem(action.payload).pipe(
        map((res) => {
          this.store.dispatch(
            new GenericSuccessAction('MESSAGES.API.UPDATE_PRICELIST_ITEM_SUCCESS'),
          );
          return new actions.UpdatePricelistItemSuccessAction(new PricelistItem(res));
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));

  // @Effect()
  // getProductCatalog: Observable<any> = this.actions$.pipe(
  //   ofType<actions.GetProductCatalogAction>(CmsActionTypes.GET_PRODUCT_CATALOG),
  //   switchMap((action) =>
  //     this.apiService.getProductCatalog(action.filter, action.pageSize, action.pageNumber).pipe(
  //       map((res) => {
  //         res.content = res.content?.map((productCatalog) => new ProductCatalog(productCatalog));
  //         return new actions.GetProductCatalogSuccessAction(res);
  //       }),
  //       catchError((err) => of(new GenericFailureAction(err))),
  //     ),
  //   ),
  // );

  /*
    ========================================
          UNKNOWN MEDICINES EFFECTS
    ========================================
  */
  
  getUnknownMedicines: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType<actions.GetUnknownMedicinesAction>(CmsActionTypes.GET_UNKNOWN_MEDICINES),
    switchMap(() =>
      this.apiService.getUnknownMedicines().pipe(
        map((res) => new actions.GetUnknownMedicinesSuccessAction(res)),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));

  
  sendUnknownMedicineEmail: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType<actions.SendUnknownMedicineEmailAction>(CmsActionTypes.SEND_UNKNOWN_MEDICINE_EMAIL),
    switchMap((action) =>
      this.apiService.sendUnknownMedicineEmail(action.payload).pipe(
        map((res) => {
          this.store.dispatch(new GenericSuccessAction(res));
          return new actions.SendUnknownMedicineEmailSuccessAction(res);
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));

  
  updateUnknownMedicine: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType<actions.UpdateUnknownMedicineAction>(CmsActionTypes.UPDATE_UNKNOWN_MEDICINE),
    switchMap((action) =>
      this.apiService.updateUnknownMedicine(action.unknownMedicineId, action.payload).pipe(
        map((res) => {
          this.store.dispatch(
            new GenericSuccessAction('MESSAGES.API.UPDATE_UNKNOWN_MEDICINES_SUCCESS'),
          );
          return new actions.UpdateUnknownMedicineSuccessAction(res);
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));

  /*
    ========================================
          EMAIL TEMPLATES EFFECTS
    ========================================
  */
  
  getEmailTemplates: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType<actions.GetEmailTemplatesAction>(CmsActionTypes.GET_EMAIL_TEMPLATES),
    switchMap(() =>
      this.apiService.getEmailTemplates().pipe(
        map((res) => {
          const emailTemplates = res.map((emailTemplate) => new EmailTemplate(emailTemplate));
          return new actions.GetEmailTemplatesSuccessAction(emailTemplates);
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));
  
  getEmailTemplateVariables: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType<actions.GetEmailTemplateVariablesAction>(CmsActionTypes.GET_EMAIL_TEMPLATE_VARIABLES),
    switchMap(() =>
      this.apiService.getEmailTemplateVariables().pipe(
        map((res) => {
          const emailTemplateVariables = res.map((variable) => new EmailTemplateVariable(variable));
          return new actions.GetEmailTemplateVariablesSuccessAction(emailTemplateVariables);
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));

  
  updateEmailTemplate: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType<actions.UpdateEmailTemplateAction>(CmsActionTypes.UPDATE_EMAIL_TEMPLATE),
    switchMap((action) =>
      this.apiService.updateEmailTemplate(action.payload).pipe(
        map((res) => {
          this.store.dispatch(
            new GenericSuccessAction('MESSAGES.API.UPDATE_EMAIL_TEMPLATE_SUCCESS'),
          );
          return new actions.UpdateEmailTemplateSuccessAction(res);
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));
}
