<app-auth-header></app-auth-header>

<div class="not-found-page">
  <app-breadcrumb [crumbs]="crumbs" class="breadcrumbs"></app-breadcrumb>
  <div class="mb-4" transloco="PAGES.NOT_FOUND.MESSAGE"></div>
  <button mat-raised-button (click)="back()" color="primary" class="icon-btn">
    <mat-icon>arrow_back</mat-icon>
    {{"BUTTONS.GO_TO_PREVIOUS_PAGE" | transloco}}
  </button>
</div>
