<div class="base-filter" *transloco="let t">
  <div class="filter-container row no-gutters">
    <div class="search-input-wrapper" [class]="sortOptions == null ? 'col-12' : 'col-8'">
      <input [formControl]="searchCtrl"
             type="text"
             placeholder="{{t(searchPlaceHolder)}}"
             class="search-input"
      >
      <fa-icon icon="search"></fa-icon>
    </div>

    <div class="sort-wrapper col-4 text-end" *ngIf="sortOptions">
      <span class="filter-label">{{t('FILTER.SORT_BY')}}:</span>
      <mat-select class="filter-select text-start" [formControl]="sortCtrl">
        <mat-select-trigger>
          <span class="filter-select-trigger" *ngIf="sortCtrl.value">
            {{t(sortCtrl.value.label)}}
          </span>
        </mat-select-trigger>
        <mat-option *ngFor="let option of sortOptions" [value]="option">
          {{t(option.label)}}
        </mat-option>
      </mat-select>
    </div>
  </div>

  <div class="filter-container row no-gutters">
    <div class="filter-container-divided">
      <div class="filter-container-left">
        <span class="filter-label">{{t('FILTER.FILTERS')}}:</span>
      </div>
      <div class="filter-container-right">
        <ng-container *ngFor="let filter of filterOptions">
          <ng-container *ngIf="filter.type === 'select'">
            <button mat-flat-button class="filter-select-btn" [matMenuTriggerFor]="filterMenu">
              <span class="filter-select-btn__label">{{t(filter.groupName)}}</span>
              <span class="mat-select-arrow-wrapper">
            <span class="filter-select-btn__arrow"></span>
          </span>
            </button>

            <mat-menu #filterMenu="matMenu" class="chip-menu">
              <button mat-menu-item
                      *ngFor="let option of filter.items"
                      (click)="addFilter(option, filter)"
                      [disabled]="hasFilter(option.value, filter.prop)"
                      [transloco]="option.label"
                      [translocoParams]="option.labelParams"
              ></button>
            </mat-menu>
          </ng-container>
          <div class="date-picker-container" *ngIf="filter.type === 'date'">
            <mat-datepicker #picker></mat-datepicker>
            <input matInput [matDatepicker]="picker" (dateInput)="addDateFilter($event.value, filter)">
            <button mat-flat-button class="filter-select-btn" (click)="picker.open()">
              <span class="filter-select-btn__label">{{t(filter.groupName)}}</span>
              <span class="mat-select-arrow-wrapper">
            <span class="filter-select-btn__arrow"></span>
          </span>
            </button>
          </div>
        </ng-container>
      </div>

    </div>
  </div>

  <ng-container *ngIf="selectedFilters.length > 0">
    <div class="active-filters-header">
      <span class="mb-2 font-size-lg">{{'FILTER.ACTIVE_FILTERS' | transloco: {amount: selectedFilters.length} }}</span>
      <button (click)="clearFilters()" mat-stroked-button
              class="mb-2 font-size-lg">{{'FILTER.CLEAR' | transloco: {amount: selectedFilters.length} }}</button>
    </div>
    <div class="filter-container row no-gutters">
      <div class="col-12">
        <mat-chip-listbox class="selected-filters">
          <mat-chip
            class="filter-item"
            color="primary"
            *ngFor="let filter of selectedFilters"
            removable
            (removed)="removeFilter(filter)"
          >
            <span class="selected-filter">{{t(filter.groupName)}}: {{t(filter.label)}}</span>
            <fa-icon icon="times" matChipRemove></fa-icon>
          </mat-chip>
        </mat-chip-listbox>
      </div>
    </div>
  </ng-container>
</div>
