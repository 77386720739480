import { AfterViewInit, Component, HostBinding, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '../../shared/tokens/dom.tokens';
import { AuthService } from '../../shared/providers/auth/auth.service';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ForgetPasswordDialogComponent } from '../../shared/components/dialogs/forget-password-dialog/forget-password-dialog.component';
import { RecoverEmailDialogComponent } from '../../shared/components/dialogs/recover-email-dialog/recover-email-dialog.component';
import { BaseComponent } from '../../shared/components/base/base.component';
import { SetSideNavAction } from '../../store/app/actions';
import { select, Store } from '@ngrx/store';
import { selectSideNav } from '../../store/app/selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
})
export class HomePage extends BaseComponent implements OnInit, AfterViewInit {
  fragment: string;
  openMenu$: Observable<boolean>;
  @HostBinding() class = 'page';
  email: string;

  constructor(
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: any,
    private auth: AuthService,
    private dialog: MatDialog,
    private store: Store,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.route.fragment.pipe(takeUntil(this._unsubscribe$)).subscribe((fragment) => {
      if (fragment) {
        if (fragment === 'password-forget') {
          this._openForgotPassword();
        } else if (fragment === 'recover-email') {
          this._openRecoverEmail();
        } else {
          this.fragment = fragment;
          this._scroll();
        }
      }
    });

    // get the code from the query after login, to get the tokens
    this.route.queryParams.pipe(takeUntil(this._unsubscribe$)).subscribe((param) => {
      if (param.code) {
        this.auth.getTokens(param.code);
      }
      if (param.logout) {
        this.auth.loggedOut();
      }
    });

    this.openMenu$ = this.store.pipe(select(selectSideNav));
  }

  ngAfterViewInit() {
    this._scroll();
  }

  closeMenu() {
    this.store.dispatch(new SetSideNavAction(false));
  }

  private _openForgotPassword() {
    this.dialog
      .open(ForgetPasswordDialogComponent, {
        panelClass: 'forget-password-dialog',
      })
      .afterClosed()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((data) => {
        if (data) {
          this.auth.requestResetPassword(data);
        }
      });
  }

  private _openRecoverEmail() {
    this.dialog
      .open(RecoverEmailDialogComponent, {
        panelClass: 'recover-email-dialog',
      })
      .afterClosed()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((data) => {
        if (data) {
          this.auth.recoverEmail(data);
        }
      });
  }

  private _scroll() {
    const element = this.document.querySelector('#' + this.fragment);
    if (element) {
      setTimeout(() => element.scrollIntoView({ behavior: 'smooth' }), 100);
    }
  }
}
