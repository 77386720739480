import { Translation } from '@ngneat/transloco';

export interface AppState {
  loadingMessage: string;
  alert: {
    message: string;
    type: string;
    params: any;
  };
  translations: Translation;
  sideNav: boolean;
}

export const initialAppState: AppState = {
  loadingMessage: null,
  alert: null,
  translations: null,
  sideNav: false
};
