import { Entity } from './entity';

export class EmailTemplateRelated extends Entity {
  emailTemplateId: number;
  emailTemplateName: string;

  constructor(data?: any, restore: boolean = false) {
    super(data, restore);

    if (data) {
      this.emailTemplateId = data.emailTemplateId;
      this.emailTemplateName = data.emailTemplateName;
    }
  }
}
