import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-form-errors',
  templateUrl: './form-errors.component.html',
})
export class FormErrorsComponent {
  @Input() formCtrl: AbstractControl;
  @Input() patternTranslateKey: string;
}
