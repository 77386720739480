import { Injectable } from '@angular/core';
import { VideoDialogComponent } from '../../components/dialogs/video-dialog/video-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  constructor(private dialog: MatDialog) {
  }

  async openVideo(url: string, title?: string) {
    this.dialog.open(VideoDialogComponent, {
      panelClass: 'video-modal',
      data: {url, title}
    });
  }
}
