import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CreateOverviewActionTypes } from './create-overview.actions';

import * as createOverviewAction from './create-overview.actions';
import * as appActions from '../app/actions';
import * as selectionSearchableNamesAction from '../selection-searchable-names/selection-searchable-names.actions';
import * as searchableNamesAction from '../searchable-names/searchable-names.actions';

import { GenericFailureAction } from '../app/actions';
import { ProductApiService } from '@shared/providers/api/product-api.service';
import { PricelistItem } from '@shared/models/pricelist-item';
import { ApiService } from '@shared/providers/api/api.service';

@Injectable()
export class CreateOverviewEffects {
  constructor(
    private actions: Actions,

    private productApiService: ProductApiService,
    private apiService: ApiService,
  ) {}

  getMedicineById: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(CreateOverviewActionTypes.GET_MEDICINE_BY_ID),
      mergeMap((action: any) =>
        this.productApiService.getMedicineById(action.payload).pipe(
          map((medicine) => {
            return new createOverviewAction.GetMedicineByIdSuccessAction(medicine);
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  getBrandById: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(CreateOverviewActionTypes.GET_BRAND_BY_ID),
      mergeMap((action: any) =>
        this.productApiService.getBrandById(action.payload).pipe(
          map((brand) => {
            return new createOverviewAction.GetBrandByIdSuccessAction(brand);
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  createProduct: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(CreateOverviewActionTypes.CREATE_PRODUCT),
      mergeMap((action: any) =>
        this.productApiService.createProduct(action.payload).pipe(
          map((result: PricelistItem) => {
            return new createOverviewAction.CreateProductSuccessAction({
              createProduct: action.payload,
              newPriceListItem: result,
            });
          }),
          catchError((err) => of(new GenericFailureAction(err.error.message))),
        ),
      ),
    ),
  );

  createProductSuccessMessage: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(CreateOverviewActionTypes.CREATE_PRODUCT_SUCCESS),
      map(() => {
        return new appActions.GenericSuccessAction('MESSAGES.API.CREATED_PRODUCT');
      }),
    ),
  );

  updateSearchableNamesAfterCreatingProduct: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(CreateOverviewActionTypes.CREATE_PRODUCT_SUCCESS),
      mergeMap((action: any) =>
        of(new searchableNamesAction.CreateProductSuccessAction(action.payload)),
      ),
      catchError((err) => of(new GenericFailureAction(err))),
    ),
  );

  ResetSelectionSearchableNamesAfterCreatingProduct: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(CreateOverviewActionTypes.CREATE_PRODUCT_SUCCESS),
      mergeMap(() => of(new selectionSearchableNamesAction.ResetStateAction())),
    ),
  );

  getProductCatalog: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(CreateOverviewActionTypes.GET_PRODUCT_CATALOG),
      mergeMap(() =>
        this.apiService.getProductCatalog().pipe(
          map((res) => {
            return new createOverviewAction.GetProductCatalogSuccessAction(res);
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );


}
