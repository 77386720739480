import { initialOrderMonitorState, OrderMonitorState } from './state';
import { Actions, OrderMonitorActionTypes } from './actions';
import { OrderJourney } from '../../monitor/shared/interfaces/order-journey.interface';

export function OrderMonitorReducer(state: OrderMonitorState = initialOrderMonitorState, action: Actions) {
  let orderJourneys: OrderJourney[];
  let idx: number;

  switch (action.type) {
    case OrderMonitorActionTypes.GET_ORDER_JOURNEYS_SUCCESS:
      return {
        ...state,
        orderJourneys: action.payload
      };
    case OrderMonitorActionTypes.GET_ORDER_JOURNEY_SUCCESS:
      return {
        ...state,
        orderJourney: action.payload
      };
    case OrderMonitorActionTypes.GET_COMMISSION_MONITORS_SUCCESS:
      return {
        ...state,
        commissionMonitorList: action.payload
      };
    case OrderMonitorActionTypes.GET_FINANCE_MONITORS_SUCCESS:
      return {
        ...state,
        financeMonitorList: action.payload
      };
    case OrderMonitorActionTypes.GET_FINANCE_MONITOR_SUCCESS:
      return {
        ...state,
        financeMonitor: action.payload
      };
    case OrderMonitorActionTypes.GET_ORDER_JOURNEY_COMMENTS_SUCCESS:
      return {
        ...state,
        orderJourneyComments: action.payload
      };
    case OrderMonitorActionTypes.CREATE_ORDER_JOURNEY_COMMENT_SUCCESS:
      return {
        ...state,
        orderJourneyComments: [...state.orderJourneyComments, action.payload]
      };
    case OrderMonitorActionTypes.CREATE_ORDER_JOURNEY_COMMENTS_SUCCESS:
      return {
        ...state,
        orderJourneyComments: null
      };
      case OrderMonitorActionTypes.GET_MISSING_MEDICINES_SUCCESS:
      return {
        ...state,
        missingMedicines: action.payload
      };
    case OrderMonitorActionTypes.UPDATE_ORDER_JOURNEY_STATUS_SUCCESS:
    case OrderMonitorActionTypes.DELAY_ORDER_JOURNEY_SUCCESS:
    case OrderMonitorActionTypes.CLOSE_ORDER_JOURNEY_SUCCESS:
      orderJourneys = [...state.orderJourneys];
      idx = orderJourneys.findIndex(_journey => _journey.id === action.payload.id);
      if (idx !== -1) {
        orderJourneys.splice(idx, 1, action.payload);
      }

      return {
        ...state,
        orderJourneys
      };
    case OrderMonitorActionTypes.CLOSE_MISSING_MEDICINES_SUCCESS:
    case OrderMonitorActionTypes.DELAY_MISSING_MEDICINES_SUCCESS:
      const missingMedicines = [...state.missingMedicines];

      action.payload.forEach(medicine => {
        idx = missingMedicines.findIndex(_med => _med.id === medicine.id);
        if (idx !== -1) {
          missingMedicines.splice(idx, 1, medicine);
        }
      });

      return {
        ...state,
        missingMedicines
      };
    default:
      return state;
  }
}
