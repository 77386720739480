import { Entity } from './entity';
import { PaymentMethod } from '../interfaces/payment-method.interface';
import { EmailTemplateRecipient } from './email-template-recipient';
import { EmailTemplateRelated } from './email-template-related';

export class EmailTemplate extends Entity {
  statusTrigger: string;
  name: string;
  subject: string;
  body: string;
  shortBody: string;
  paymentMethod: PaymentMethod;
  recipients: EmailTemplateRecipient[];
  interrelatedEmailTemplates: EmailTemplateRelated[];
  shouldAttachDocument: boolean;
  whtExempt: boolean;
  shouldSkipCatholics: boolean;
  catholicEmail: boolean;

  constructor(data?: any, restore: boolean = false) {
    super(data, restore);

    if (data) {
      this.statusTrigger = data.statusTrigger;
      this.name = data.name;
      this.subject = data.subject;
      this.body = data.body;
      this.shortBody = data.shortBody;
      this.paymentMethod = data.paymentMethod;
      this.shouldAttachDocument = data.shouldAttachDocument;
      this.whtExempt = data.whtExempt;
      this.shouldSkipCatholics = data.shouldSkipCatholics;
      this.catholicEmail = data.catholicEmail;

      this.recipients = data.recipients == null ? [] :
        data.recipients.map(recipient => new EmailTemplateRecipient(recipient));

      this.interrelatedEmailTemplates = data.interrelatedEmailTemplates == null ? [] :
        data.interrelatedEmailTemplates.map(related => new EmailTemplateRelated(related));
    }
  }
}
