import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
})
export class FaqComponent {
  private _allQuestions: FaqItem[];
  questions: FaqItem[];

  @Input()
  set type(val: string) {
    this.questions = this._allQuestions.filter(question => val === 'all' || question.type === val);
  }

  constructor() {
    this._allQuestions = [
      {
        question: 'FAQ.HOME.ORDER_FAQ.QUESTION',
        answer: 'FAQ.HOME.ORDER_FAQ.ANSWER',
        type: 'home',
        collapsed: true
      },
      {
        question: 'FAQ.HOME.MED4ALL.QUESTION',
        answer: 'FAQ.HOME.MED4ALL.ANSWER',
        type: 'home',
        collapsed: true
      },
      {
        question: 'FAQ.HOME.GHANA_ONLY.QUESTION',
        answer: 'FAQ.HOME.GHANA_ONLY.ANSWER',
        type: 'home',
        collapsed: true
      },
      {
        question: 'FAQ.HOME.BENEFITS_PROVIDERS.QUESTION',
        answer: 'FAQ.HOME.BENEFITS_PROVIDERS.ANSWER',
        type: 'home',
        collapsed: true
      },
      {
        question: 'FAQ.HOME.BENEFITS_SUPPLIERS.QUESTION',
        answer: 'FAQ.HOME.BENEFITS_SUPPLIERS.ANSWER',
        type: 'home',
        collapsed: true
      },
      {
        question: 'FAQ.HOME.BENEFITS_PATIENTS.QUESTION',
        answer: 'FAQ.HOME.BENEFITS_PATIENTS.ANSWER',
        type: 'home',
        collapsed: true
      },
      {
        question: 'FAQ.HOME.TYPE_OF_PRODUCTS.QUESTION',
        answer: 'FAQ.HOME.TYPE_OF_PRODUCTS.ANSWER',
        type: 'home',
        collapsed: true
      },
      {
        question: 'FAQ.HOME.COSTS.QUESTION',
        answer: 'FAQ.HOME.COSTS.ANSWER',
        type: 'home',
        collapsed: true
      },
      {
        question: 'FAQ.HOME.BETTER_PRICING.QUESTION',
        answer: 'FAQ.HOME.BETTER_PRICING.ANSWER',
        type: 'home',
        collapsed: true
      },
      {
        question: 'FAQ.HOME.JOIN_PROVIDER.QUESTION',
        answer: 'FAQ.HOME.JOIN_PROVIDER.ANSWER',
        type: 'home',
        collapsed: true
      },
      {
        question: 'FAQ.HOME.JOIN_SUPPLIER.QUESTION',
        answer: 'FAQ.HOME.JOIN_SUPPLIER.ANSWER',
        type: 'home',
        collapsed: true
      },
      {
        question: 'FAQ.HOME.AFTER_JOIN.QUESTION',
        answer: 'FAQ.HOME.AFTER_JOIN.ANSWER',
        type: 'home',
        collapsed: true
      },
      {
        question: 'FAQ.DOCUMENTS.UPLOAD.QUESTION',
        answer: 'FAQ.DOCUMENTS.UPLOAD.ANSWER',
        type: 'documents',
        collapsed: true
      },
      {
        question: 'FAQ.SUPPORT.SHOPPING_CART.QUESTION',
        answer: 'FAQ.SUPPORT.SHOPPING_CART.ANSWER',
        type: 'support',
        collapsed: true
      },
      {
        question: 'FAQ.SUPPORT.ALTER_ORDER.QUESTION',
        answer: 'FAQ.SUPPORT.ALTER_ORDER.ANSWER',
        type: 'support',
        collapsed: true
      },
      {
        question: 'FAQ.SUPPORT.PAYMENT_OPTIONS.QUESTION',
        answer: 'FAQ.SUPPORT.PAYMENT_OPTIONS.ANSWER',
        type: 'support',
        collapsed: true
      },
      {
        question: 'FAQ.SUPPORT.TRANSPORTATION_COSTS.QUESTION',
        answer: 'FAQ.SUPPORT.TRANSPORTATION_COSTS.ANSWER',
        type: 'support',
        collapsed: true
      },
      {
        question: 'FAQ.SUPPORT.RECEIVE_ORDER.QUESTION',
        answer: 'FAQ.SUPPORT.RECEIVE_ORDER.ANSWER',
        type: 'support',
        collapsed: true
      },
      {
        question: 'FAQ.SUPPORT.FULL_ORDER.QUESTION',
        answer: 'FAQ.SUPPORT.FULL_ORDER.ANSWER',
        type: 'support',
        collapsed: true
      },
      {
        question: 'FAQ.SUPPORT.RETURNS.QUESTION',
        answer: 'FAQ.SUPPORT.RETURNS.ANSWER',
        type: 'support',
        collapsed: true
      },
      {
        question: 'FAQ.SUPPORT.SUGGESTION.QUESTION',
        answer: 'FAQ.SUPPORT.SUGGESTION.ANSWER',
        type: 'support',
        collapsed: true
      },
    ];
  }

}

interface FaqItem {
  question: string;
  answer: string;
  type: string;
  collapsed: boolean;
}
