import { PrescribingLevel } from '@shared/interfaces/prescribing-level.interface';
import { Brand } from '@shared/models/brand';
import { Keyword } from '@shared/models/keyword';
import { Medicine } from '@shared/models/medicine';
import { MedicineForm } from '@shared/models/medicine-form';
import { Organization } from '@shared/models/organization';
import { PricelistItem } from '@shared/models/pricelist-item';

export interface EditOverviewState {
  isLoading: boolean

  medicine: Medicine,
  brand: Brand,
}

export const initialEditOverviewState: EditOverviewState = {
  isLoading: true,

  medicine: null,
  brand: null,
};

