<app-header></app-header>

<div class="update-password-page">
  <div class="page-container">
    <h2 class="header" transloco="PAGES.UPDATE_PASSWORD.HEADER"></h2>
    <form class="mt-5">
      <mat-form-field>
        <mat-label transloco="USER.PASSWORD"></mat-label>
        <input id="passwordInput" type="password" [formControl]="passwordCtrl" required matInput />
        <mat-error id="passwordError" *ngIf="passwordCtrl.dirty && passwordCtrl.invalid">
          <span *ngIf="passwordCtrl.hasError('required')" transloco="MESSAGES.FORM.PASSWORD_REQUIRED_ERROR"></span>
          <span *ngIf="passwordCtrl.hasError('pattern')" transloco="MESSAGES.FORM.PASSWORD_PATTERN_ERROR"></span>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label transloco="PAGES.UPDATE_PASSWORD.CONFIRM_PASSWORD"></mat-label>
        <input id="confirmPasswordInput" type="password" [formControl]="confirmPasswordCtrl" required matInput />
        <mat-error id="confirmPasswordError" *ngIf="confirmPasswordCtrl.dirty && confirmPasswordCtrl.invalid">
          <span *ngIf="confirmPasswordCtrl.hasError('required')" transloco="PAGES.UPDATE_PASSWORD.CONFIRM_PASSWORD_REQUIRED_ERROR"></span>
          <span *ngIf="confirmPasswordCtrl.hasError('pattern')" transloco="MESSAGES.FORM.PASSWORD_PATTERN_ERROR"></span>
        </mat-error>
      </mat-form-field>

      <div id="passwordMatchError" *ngIf="confirmPasswordCtrl.value !== passwordCtrl.value" class="mt-2 warning" transloco="PAGES.UPDATE_PASSWORD.PASSWORD_MATCH_ERROR"></div>

      <div class="text-end full-width mt-3">
        <button mat-raised-button
                color="primary"
                (click)="updatePassword()"
                [disabled]="!(passwordCtrl.valid && confirmPasswordCtrl.valid)"
                transloco="BUTTONS.UPDATE_PASSWORD">
        </button>
      </div>
    </form>
  </div>
</div>
