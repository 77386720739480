import { Entity } from './entity';
import { ORDERSTATUS } from '../constants';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { OrderStatus } from '../interfaces/order-status.interface';
import { Organization } from './organization';
import { OrderMedicine } from './order-medicine';
import { OrderSupplier } from './order-supplier';
import { Medicine } from './medicine';
import { Brand } from './brand';

export class Order extends Entity {
  currentOrderStatus: OrderStatus;
  organization: Organization;
  orderMedicines: OrderMedicine[];
  orderSuppliers: OrderSupplier[];
  subOrderId: number;
  totalValue: number;
  forwardedOn?: Date;

  constructor(data?: any, restore: boolean = false) {
    super(data, restore);

    if (data) {
      this.subOrderId = data.subOrderId;
      this.currentOrderStatus = data.currentOrderStatus ?? {status: ORDERSTATUS.DRAFT};
      this.organization = data.organization ? new Organization(data.organization) : null;
      this.totalValue = data.totalValue;
      this.forwardedOn = data.forwardedOn;
      this.orderMedicines = data.orderMedicines ? data.orderMedicines.map(medicine => new OrderMedicine(medicine, restore)) : [];
      this.orderSuppliers = data.orderSuppliers ? data.orderSuppliers.map(supplier => new OrderSupplier(supplier, restore)) : [];
    }
  }

  get statusIcon(): IconProp {
    switch (this.currentOrderStatus.status) {
      case ORDERSTATUS.DRAFT:
        return 'shopping-cart';
      case ORDERSTATUS.DELIVERED:
        return 'clipboard-check';
      case ORDERSTATUS.INITIATED:
      case ORDERSTATUS.REJECTED:
      case ORDERSTATUS.APPROVED:
      case ORDERSTATUS.MODIFIED:
        return 'cog';
      case ORDERSTATUS.PAYMENT_SELECTED:
        return 'coins';
    }
  }

  get unit(): string {
    return this.orderMedicines?.length > 0 ? this.orderMedicines[0].priceListItem.unit : 'GHS';
  }

  hasOutdatedPricelistItems(): boolean {
    return this.orderMedicines.filter(medicine => medicine.priceListItem.outdated).length > 0;
  }

  hasMedicine(medicine: Medicine, brand: Brand, supplier: Organization): boolean {
    return this.getMedicine(medicine, brand, supplier) != null;
  }

  getMedicine(medicine: Medicine, brand: Brand, supplier: Organization): OrderMedicine {
    return medicine
      ? this.orderMedicines
        .find(item =>
          item.medicine.id === medicine.id &&
          (!brand || (brand && item.brand.id === brand.id)) &&
          (!supplier || (supplier && item.orderSupplier.supplier.id === supplier.id)))
      : null;
  }

  addMedicine(medicine: Medicine, brand: Brand, supplier: Organization, quantity: number) {
    if (!this.hasMedicine(medicine, brand, supplier)) {
      this.orderMedicines.push(new OrderMedicine({
        brand,
        medicine,
        quantity,
        priceListItem: medicine.getPricelistItem(brand, supplier),
      }));
    }
  }

  removeMedicine(medicine: Medicine, brand: Brand, supplier: Organization) {
    const index = medicine ? this.orderMedicines.findIndex(item =>
      item.medicine.id === medicine.id &&
      item.brand.id === brand.id &&
      item.orderSupplier.supplier.id === supplier.id
      ) : -1;
    if (index !== -1) {
      this.orderMedicines.splice(index, 1);
    }
  }

  toApi() {
    return {
      organizationId: this.organization.id,
      priceListItems: this.orderMedicines.map((med: OrderMedicine) => ({
        quantity: med.quantity,
        paymentMethodId: null,
        id: med.priceListItem.id
      }))
    };
  }

  getOrderMedicines() {
    if (this.subOrderId) {
      return this.orderMedicines.filter(medicine => medicine.orderSupplier.id === this.subOrderId);
    }
    return this.orderMedicines;
  }

  hasOrderMedicinesInStatuses(orderStatuses: string[]): boolean {
    return this.orderMedicines.some(_medicine => orderStatuses.includes(_medicine.currentOrderMedicineStatus.status));
  }
}
