import { Action } from '@ngrx/store';
import { Medicine } from '../../shared/models/medicine';
import { Keyword } from '../../shared/models/keyword';
import { Brand } from '../../shared/models/brand';
import { MedicineForm } from '../../shared/models/medicine-form';
import { UnknownMedicine } from '../../shared/interfaces/unknown-medicine.interface';
import { PricelistItem } from '../../shared/models/pricelist-item';
import { GetPricelistsAction } from '../orders/actions';
import { EmailTemplate } from '../../shared/models/email-template';
import { EmailTemplateVariable } from '../../shared/models/email-template-variable';
import { Filter } from '../../shared/interfaces/filter.interface';
import { Pageable } from '../../shared/interfaces/pageable.interface';
import { ProductCatalog } from '../../shared/models/product-catalog';

export enum CmsActionTypes {
  GET_MEDICINES = '[CMS] Get medicines',
  GET_MEDICINES_SUCCESS = '[CMS] Get medicines success',
  UPDATE_MEDICINE = '[CMS] Update medicine',
  UPDATE_MEDICINE_SUCCESS = '[CMS] Update medicine success',
  GET_MEDICINE_FORMS = '[CMS] Get medicine forms',
  GET_MEDICINE_FORMS_SUCCESS = '[CMS] Get medicine forms Success',
  GET_PRESCRIBING_LEVELS = '[CMS] Get prescribing levels',
  GET_PRESCRIBING_LEVELS_SUCCESS = '[CMS] Get prescribing levels Success',

  GET_BRANDS = '[CMS] Get brands',
  GET_BRANDS_SUCCESS = '[CMS] Get brands success',
  UPDATE_BRAND = '[CMS] Update brand',
  UPDATE_BRAND_SUCCESS = '[CMS] Update brand success',

  GET_KEYWORDS = '[CMS] Get keywords',
  GET_KEYWORDS_SUCCESS = '[CMS] Get keywords success',
  UPDATE_KEYWORD = '[CMS] Update keyword',
  UPDATE_KEYWORD_SUCCESS = '[CMS] Update keyword success',

  GET_PRICELIST_ITEMS = '[CMS] Get pricelist items',
  GET_PRICELIST_ITEMS_SUCCESS = '[CMS] Get pricelist items success',
  UPDATE_PRICELIST_ITEM = '[CMS] Update pricelist item',
  UPDATE_PRICELIST_ITEM_SUCCESS = '[CMS] Update pricelist item success',

  GET_PRODUCT_CATALOG = '[CMS] Get product catalog',
  GET_PRODUCT_CATALOG_SUCCESS = '[CMS] Get product catalog success',

  GET_UNKNOWN_MEDICINES = '[Admin] Get Unknown Medicines',
  GET_UNKNOWN_MEDICINES_SUCCESS = '[Admin] Get Unknown Medicines Success',
  UPDATE_UNKNOWN_MEDICINE = '[Admin] Update Unknown Medicine',
  UPDATE_UNKNOWN_MEDICINE_SUCCESS = '[Admin] Update Unknown Medicine Success',
  DELETE_UNKNOWN_MEDICINE = '[Admin] Delete Unknown Medicine',
  DELETE_UNKNOWN_MEDICINE_SUCCESS = '[Admin] Delete Unknown Medicine Success',
  SEND_UNKNOWN_MEDICINE_EMAIL = '[Admin] Send unknown medicine email',
  SEND_UNKNOWN_MEDICINE_EMAIL_SUCCESS = '[Admin] Send unknown medicine email Success',

  GET_EMAIL_TEMPLATES = '[CMS] Get email templates',
  GET_EMAIL_TEMPLATES_SUCCESS = '[CMS] Get email templates success',
  GET_EMAIL_TEMPLATE_VARIABLES = '[CMS] Get email template variables',
  GET_EMAIL_TEMPLATE_VARIABLES_SUCCESS = '[CMS] Get email template variables success',
  UPDATE_EMAIL_TEMPLATE = '[CMS] Update email template',
  UPDATE_EMAIL_TEMPLATE_SUCCESS = '[CMS] Update email template success',
}

export class GetMedicinesAction implements Action {
  readonly type = CmsActionTypes.GET_MEDICINES;
}

export class GetMedicinesSuccessAction implements Action {
  readonly type = CmsActionTypes.GET_MEDICINES_SUCCESS;
  constructor(public payload: Medicine[]) {}
}

export class GetMedicineFormsAction implements Action {
  readonly type = CmsActionTypes.GET_MEDICINE_FORMS;
}

export class GetMedicineFormsSuccessAction implements Action {
  readonly type = CmsActionTypes.GET_MEDICINE_FORMS_SUCCESS;
  constructor(public payload: MedicineForm[]) {}
}

export class GetPrescribingLevelsAction implements Action {
  readonly type = CmsActionTypes.GET_PRESCRIBING_LEVELS;
}

export class GetPrescribingLevelsSuccessAction implements Action {
  readonly type = CmsActionTypes.GET_PRESCRIBING_LEVELS_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateMedicineAction implements Action {
  readonly type = CmsActionTypes.UPDATE_MEDICINE;
  constructor(public payload: Medicine) {}
}

export class UpdateMedicineSuccessAction implements Action {
  readonly type = CmsActionTypes.UPDATE_MEDICINE_SUCCESS;
  constructor(public payload: Medicine) {}
}

export class GetBrandsAction implements Action {
  readonly type = CmsActionTypes.GET_BRANDS;
}

export class GetBrandsSuccessAction implements Action {
  readonly type = CmsActionTypes.GET_BRANDS_SUCCESS;
  constructor(public payload: Brand[]) {}
}

export class UpdateBrandAction implements Action {
  readonly type = CmsActionTypes.UPDATE_BRAND;
  constructor(public payload: Brand) {}
}

export class UpdateBrandSuccessAction implements Action {
  readonly type = CmsActionTypes.UPDATE_BRAND_SUCCESS;
  constructor(public payload: Brand) {}
}

export class GetKeywordsAction implements Action {
  readonly type = CmsActionTypes.GET_KEYWORDS;
}

export class GetKeywordsSuccessAction implements Action {
  readonly type = CmsActionTypes.GET_KEYWORDS_SUCCESS;
  constructor(public payload: Keyword[]) {}
}

export class GetEmailTemplatesAction implements Action {
  readonly type = CmsActionTypes.GET_EMAIL_TEMPLATES;
}

export class GetEmailTemplatesSuccessAction implements Action {
  readonly type = CmsActionTypes.GET_EMAIL_TEMPLATES_SUCCESS;
  constructor(public payload: EmailTemplate[]) {}
}

export class GetEmailTemplateVariablesAction implements Action {
  readonly type = CmsActionTypes.GET_EMAIL_TEMPLATE_VARIABLES;
}

export class GetEmailTemplateVariablesSuccessAction implements Action {
  readonly type = CmsActionTypes.GET_EMAIL_TEMPLATE_VARIABLES_SUCCESS;
  constructor(public payload: EmailTemplateVariable[]) {}
}

export class UpdateEmailTemplateAction implements Action {
  readonly type = CmsActionTypes.UPDATE_EMAIL_TEMPLATE;
  constructor(public payload: any) {}
}

export class UpdateEmailTemplateSuccessAction implements Action {
  readonly type = CmsActionTypes.UPDATE_EMAIL_TEMPLATE_SUCCESS;
  constructor(public payload: EmailTemplate) {}
}

export class UpdateKeywordAction implements Action {
  readonly type = CmsActionTypes.UPDATE_KEYWORD;
  constructor(public payload: Keyword) {}
}

export class UpdateKeywordSuccessAction implements Action {
  readonly type = CmsActionTypes.UPDATE_KEYWORD_SUCCESS;
  constructor(public payload: Keyword) {}
}

export class GetPricelistItemsAction implements Action {
  readonly type = CmsActionTypes.GET_PRICELIST_ITEMS;
}

export class GetPricelistItemsSuccessAction implements Action {
  readonly type = CmsActionTypes.GET_PRICELIST_ITEMS_SUCCESS;
  constructor(public payload: PricelistItem[]) {}
}

export class UpdatePricelistItemAction implements Action {
  readonly type = CmsActionTypes.UPDATE_PRICELIST_ITEM;
  constructor(public payload: PricelistItem) {}
}

export class UpdatePricelistItemSuccessAction implements Action {
  readonly type = CmsActionTypes.UPDATE_PRICELIST_ITEM_SUCCESS;
  constructor(public payload: PricelistItem) {}
}

export class GetProductCatalogAction implements Action {
  readonly type = CmsActionTypes.GET_PRODUCT_CATALOG;
  constructor(public filter: Filter = null, public pageSize: number = 25, public pageNumber: number = 0) {
  }
}

export class GetProductCatalogSuccessAction implements Action {
  readonly type = CmsActionTypes.GET_PRODUCT_CATALOG_SUCCESS;
  constructor(public payload: Pageable<ProductCatalog>) {}
}

/*
  ========================================
          UNKNOWN MEDICINE ACTIONS
  ========================================
*/
export class GetUnknownMedicinesAction implements Action {
  readonly type = CmsActionTypes.GET_UNKNOWN_MEDICINES;
}

export class GetUnknownMedicinesSuccessAction implements Action {
  readonly type = CmsActionTypes.GET_UNKNOWN_MEDICINES_SUCCESS;
  constructor(public payload: UnknownMedicine[]) {}
}

export class UpdateUnknownMedicineAction implements Action {
  readonly type = CmsActionTypes.UPDATE_UNKNOWN_MEDICINE;
  constructor(public unknownMedicineId: number, public payload: {medicine: any, brand: any, keyword: any}) {}
}

export class UpdateUnknownMedicineSuccessAction implements Action {
  readonly type = CmsActionTypes.UPDATE_UNKNOWN_MEDICINE_SUCCESS;
  constructor(public payload: UnknownMedicine) {}
}


export class DeleteUnknownMedicineAction implements Action {
  readonly type = CmsActionTypes.DELETE_UNKNOWN_MEDICINE;
  constructor(public unknownMedicineId: number) {}
}

export class DeleteUnknownMedicineSuccessAction implements Action {
  readonly type = CmsActionTypes.DELETE_UNKNOWN_MEDICINE_SUCCESS;
  constructor(public payload: number) {}
}

export class SendUnknownMedicineEmailAction implements Action {
  readonly type = CmsActionTypes.SEND_UNKNOWN_MEDICINE_EMAIL;
  constructor(public payload: number) {}
}

export class SendUnknownMedicineEmailSuccessAction implements Action {
  readonly type = CmsActionTypes.SEND_UNKNOWN_MEDICINE_EMAIL_SUCCESS;
  constructor(public payload: string) {}
}

export type Actions =
  | GetMedicinesAction
  | GetMedicinesSuccessAction
  | UpdateMedicineAction
  | UpdateMedicineSuccessAction
  | GetMedicineFormsAction
  | GetMedicineFormsSuccessAction
  | GetPrescribingLevelsAction
  | GetPrescribingLevelsSuccessAction
  | GetBrandsAction
  | GetBrandsSuccessAction
  | UpdateBrandAction
  | UpdateBrandSuccessAction
  | GetKeywordsAction
  | GetKeywordsSuccessAction
  | UpdateKeywordAction
  | UpdateKeywordSuccessAction
  | GetUnknownMedicinesAction
  | GetUnknownMedicinesSuccessAction
  | UpdateUnknownMedicineAction
  | UpdateUnknownMedicineSuccessAction
  | DeleteUnknownMedicineAction
  | DeleteUnknownMedicineSuccessAction
  | SendUnknownMedicineEmailAction
  | SendUnknownMedicineEmailSuccessAction
  | GetPricelistsAction
  | GetPricelistItemsSuccessAction
  | UpdatePricelistItemAction
  | UpdatePricelistItemSuccessAction
  | GetEmailTemplatesAction
  | GetEmailTemplatesSuccessAction
  | UpdateEmailTemplateAction
  | UpdateEmailTemplateSuccessAction
  | GetEmailTemplateVariablesAction
  | GetEmailTemplateVariablesSuccessAction
  | GetProductCatalogAction
  | GetProductCatalogSuccessAction
  ;
