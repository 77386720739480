import { AppState, initialAppState } from './state';
import { AppActionTypes } from './actions';
import { AuthActionTypes } from '../auth/actions';
import { AdminActionTypes } from '../admin/actions';
import { OrdersActionTypes } from '../orders/actions';
import { OrderMonitorActionTypes } from '../order-monitor/actions';
import { CmsActionTypes } from '../cms/actions';
import { SearchableNamesActionTypes } from '../searchable-names/searchable-names.actions';
import { CreateOverviewActionTypes } from '../create-overview/create-overview.actions';
import { EditOverviewActionTypes } from '../edit-overview/edit-overview.actions';
import { SelectionSearchableNamesActionTypes } from '../selection-searchable-names/selection-searchable-names.actions';

export function AppReducer(state = initialAppState, action): AppState {
  switch (action.type) {
    case AuthActionTypes.RESET_PASSWORD:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.RESET_PASSWORD'
      };
    case AdminActionTypes.GET_USERS:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.GET_USERS'
      };
    case AdminActionTypes.CREATE_USER:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.CREATE_USER'
      };
    case AuthActionTypes.LOGOUT:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.LOGGING_OUT'
      };
    case AuthActionTypes.RECOVER_EMAIL:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.RECOVER_EMAIL'
      };
    case AuthActionTypes.UPDATE_PASSWORD:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.UPDATE_PASSWORD'
      };
    case AdminActionTypes.GET_PERMISSIONS:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.GET_PERMISSIONS'
      };
    case AdminActionTypes.GET_ROLES:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.GET_ROLES'
      };
    case AuthActionTypes.GET_ORGANIZATIONS:
    case AdminActionTypes.GET_ORGANIZATIONS:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.GET_ORGANIZATIONS'
      };
    case CmsActionTypes.GET_UNKNOWN_MEDICINES:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.GET_UNKNOWN_MEDICINES'
      };
    case OrdersActionTypes.GET_ORDER:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.GET_ORDER'
      };
    case AuthActionTypes.GET_USER:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.LOGGING_IN'
      };
    case OrdersActionTypes.UPDATE_ORDER_STATUS:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.UPDATE_ORDER_STATUS'
      };
    case OrdersActionTypes.UPDATE_ORDER:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.UPDATE_ORDER'
      };
    case OrdersActionTypes.GET_DOCUMENTS:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.LOADING'
      };
    case OrdersActionTypes.GET_DOCUMENTS_SUCCESS:
     return {
        ...state,
        loadingMessage: null
      };
    case AdminActionTypes.GET_DOCUMENTS:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.LOADING'
      };
    case AdminActionTypes.GET_DOCUMENTS_SUCCESS:
     return {
        ...state,
        loadingMessage: null
      };
    case AdminActionTypes.GET_ORDERS:
    case AdminActionTypes.GET_FILTERED_ORDERS:
    case OrdersActionTypes.GET_CURRENT_ORDERS:
    case OrdersActionTypes.GET_PREVIOUS_ORDERS:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.GET_ORDERS'
      };
    case OrdersActionTypes.UPDATE_SHIPMENT:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.UPDATE_SHIPMENT'
      };
    case OrdersActionTypes.GET_MEDICINES:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.GET_MEDICINES'
      };
    case AdminActionTypes.UPDATE_WITHDRAWAL_REQUESTS:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.UPDATE_WITHDRAWAL_REQUESTS'
      };
    case OrdersActionTypes.ADD_MEDICINE_TO_CART:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.ADD_MEDICINE_TO_CART'
      };
    case OrdersActionTypes.REMOVE_MEDICINE_FROM_CART:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.REMOVE_MEDICINE_FROM_CART'
      };
    case OrdersActionTypes.UPDATE_CART:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.UPDATE_CART'
      };
    case OrdersActionTypes.CREATE_WITHDRAWAL_REQUEST:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.CREATE_WITHDRAWAL_REQUEST'
      };
    case OrdersActionTypes.GET_WITHDRAWAL_REQUESTS:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.GET_WITHDRAWAL_REQUESTS'
      };
    case OrdersActionTypes.GET_PREVIOUSLY_ORDERED_MEDICINES:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.GET_PREVIOUSLY_ORDERED_MEDICINES'
      };
    case OrdersActionTypes.GET_ORDER_ADVICE:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.GET_ORDER_ADVICE'
      };
    case OrderMonitorActionTypes.CLOSE_MISSING_MEDICINES:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.CLOSE_MISSING_MEDICINES'
      };
    case CmsActionTypes.UPDATE_MEDICINE:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.UPDATE_MEDICINE'
      };
    case CmsActionTypes.UPDATE_BRAND:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.UPDATE_BRAND'
      };
    case CmsActionTypes.UPDATE_KEYWORD:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.UPDATE_KEYWORD'
      };
    case CmsActionTypes.UPDATE_UNKNOWN_MEDICINE:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.UPDATE_UNKNOWN_MEDICINE'
      };
    case OrderMonitorActionTypes.GET_FINANCE_MONITOR:
    case OrderMonitorActionTypes.GET_ORDER_JOURNEYS:
    case OrderMonitorActionTypes.GET_COMMISSION_MONITORS:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.GET_ORDERS'
      };
    case OrderMonitorActionTypes.CREATE_ORDER_JOURNEY_COMMENTS:
    case AdminActionTypes.GET_EMAILS:
    case AdminActionTypes.RESUBMIT_PAYMENT:
    case OrderMonitorActionTypes.DELAY_MISSING_MEDICINES:
    case OrdersActionTypes.SUBMIT_POD:
    case OrdersActionTypes.GET_STOCK_TAKES:
    case OrdersActionTypes.MERGE_STOCK_TAKES:
    case OrdersActionTypes.GET_INVENTORIES:
    case OrdersActionTypes.EXPORT_STOCK_TAKES_ACTION:
    case OrdersActionTypes.EXPORT_INVENTORIES_ACTION:
    case OrdersActionTypes.APPROVE_STOCK_TAKES_ACTION:
    case OrdersActionTypes.UPDATE_STOCK_TAKE_ACTION:
    case OrdersActionTypes.DELETE_STOCK_TAKE_ITEM_ACTION:
    case OrdersActionTypes.CREATE_STOREROOM:
    case OrdersActionTypes.GET_STOREROOMS:
    case OrdersActionTypes.UPDATE_STOREROOM:
    case OrdersActionTypes.DELETE_STOREROOM:
    case CmsActionTypes.GET_PRODUCT_CATALOG:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.LOADING'
      };
    case CmsActionTypes.UPDATE_MEDICINE_SUCCESS:
    case CmsActionTypes.UPDATE_BRAND_SUCCESS:
    case CmsActionTypes.UPDATE_KEYWORD_SUCCESS:
    case OrderMonitorActionTypes.CLOSE_MISSING_MEDICINES_SUCCESS:
    case OrdersActionTypes.GET_ORDER_ADVICE_SUCCESS:
    case OrdersActionTypes.GET_PREVIOUSLY_ORDERED_MEDICINES_SUCCESS:
    case OrdersActionTypes.UPDATE_CART_SUCCESS:
    case OrdersActionTypes.CREATE_WITHDRAWAL_REQUEST_SUCCESS:
    case OrdersActionTypes.GET_WITHDRAWAL_REQUESTS_SUCCESS:
    case OrdersActionTypes.UPDATE_SHIPMENT_SUCCESS:
    case OrdersActionTypes.EXPORT_STOCK_TAKES_ACTION_SUCCESS:
    case OrdersActionTypes.EXPORT_INVENTORIES_ACTION_SUCCESS:
    case OrdersActionTypes.APPROVE_STOCK_TAKES_ACTION_SUCCESS:
    case OrdersActionTypes.UPDATE_STOCK_TAKE_ACTION_SUCCESS:
    case OrdersActionTypes.DELETE_STOCK_TAKE_ITEM_ACTION_SUCCESS:
    case OrdersActionTypes.CREATE_STOREROOM_SUCCESS:
    case OrdersActionTypes.GET_STOREROOMS_SUCCESS:
    case OrdersActionTypes.UPDATE_STOREROOM_SUCCESS:
    case OrdersActionTypes.DELETE_STOREROOM_SUCCESS:
    case AdminActionTypes.UPDATE_WITHDRAWAL_REQUESTS_SUCCESS:
    case CmsActionTypes.UPDATE_UNKNOWN_MEDICINE_SUCCESS:
    case OrdersActionTypes.GET_MEDICINES_SUCCESS:
    case OrdersActionTypes.GET_ORDER_SUCCESS:
    case OrdersActionTypes.GET_CURRENT_ORDERS_SUCCESS:
    case OrdersActionTypes.GET_PREVIOUS_ORDERS_SUCCESS:
    case OrdersActionTypes.UPDATE_ORDER_STATUS_SUCCESS:
    case OrdersActionTypes.UPDATE_ORDER_SUCCESS:
    case OrdersActionTypes.SUBMIT_POD_SUCCESS:
    case OrdersActionTypes.GET_STOCK_TAKES_SUCCESS:
    case OrdersActionTypes.MERGE_STOCK_TAKES_SUCCESS:
    case OrdersActionTypes.GET_INVENTORIES_SUCCESS:
    case OrdersActionTypes.FIND_OTHER_SUPPLIERS:
    case OrdersActionTypes.FIND_OTHER_SUPPLIERS_SUCCESS:
    case AdminActionTypes.CREATE_USER_SUCCESS:
    case AdminActionTypes.GET_ORDERS_SUCCESS:
    case AdminActionTypes.GET_USERS_SUCCESS:
    case AdminActionTypes.GET_PERMISSIONS_SUCCESS:
    case AdminActionTypes.GET_ROLES_SUCCESS:
    case AdminActionTypes.GET_EMAILS_SUCCESS:
    case AuthActionTypes.GET_ORGANIZATIONS_SUCCESS:
    case AdminActionTypes.GET_ORGANIZATIONS_SUCCESS:
    case CmsActionTypes.GET_UNKNOWN_MEDICINES_SUCCESS:
    case AuthActionTypes.GET_USER_SUCCESS:
    case AuthActionTypes.LOGOUT_SUCCESS:
    case AuthActionTypes.REFRESH_ACCESS_TOKEN_SUCCESS:
    case AppActionTypes.GENERIC_FAILURE:
    case AppActionTypes.GENERIC_FAILURE_EXPECTED:
    case AppActionTypes.GENERIC_SUCCESS:
    case AppActionTypes.CLEAR_LOADER:
    case OrderMonitorActionTypes.GET_FINANCE_MONITOR_SUCCESS:
    case OrderMonitorActionTypes.GET_ORDER_JOURNEYS_SUCCESS:
    case OrderMonitorActionTypes.GET_COMMISSION_MONITORS_SUCCESS:
    case OrderMonitorActionTypes.CREATE_ORDER_JOURNEY_COMMENTS_SUCCESS:
    case OrderMonitorActionTypes.DELAY_MISSING_MEDICINES_SUCCESS:
    case AdminActionTypes.RESUBMIT_PAYMENT_SUCCESS:
    case CmsActionTypes.GET_PRODUCT_CATALOG_SUCCESS:
      return {
        ...state,
        loadingMessage: null
      };
    case OrderMonitorActionTypes.GET_FINANCE_MONITORS:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.LOADING'
      };
    case OrderMonitorActionTypes.GET_FINANCE_MONITORS_SUCCESS:
      return {
        ...state,
        loadingMessage: null
      };
    case OrderMonitorActionTypes.GET_MISSING_MEDICINES:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.LOADING'
      };


    case SearchableNamesActionTypes.GET_ACTIVE_PRICE_LIST_ITEMS:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.LOADING'
      };
    case SearchableNamesActionTypes.GET_ACTIVE_PRICE_LIST_ITEMS_SUCCESS:
      return {
        ...state,
        loadingMessage: null
      };
    case SearchableNamesActionTypes.GET_SUPPLIER_WITH_IDS:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.LOADING'
      };
    case SearchableNamesActionTypes.GET_SUPPLIER_WITH_IDS_SUCCESS:
      return {
        ...state,
        loadingMessage: null
      };
    case SearchableNamesActionTypes.GET_BRAND_NAMES:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.LOADING'
      };
    case SearchableNamesActionTypes.GET_BRAND_NAMES_SUCCESS:
      return {
        ...state,
        loadingMessage: null
      };
    case SearchableNamesActionTypes.GET_MEDICINE_NAMES:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.LOADING'
      };
    case SearchableNamesActionTypes.GET_MEDICINE_NAMES_SUCCESS:
      return {
        ...state,
        loadingMessage: null
      };
    case SearchableNamesActionTypes.GET_MANUFACTURER_NAMES:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.LOADING'
      };
    case SearchableNamesActionTypes.GET_MANUFACTURER_NAMES_SUCCESS:
      return {
        ...state,
        loadingMessage: null
      };

    case CreateOverviewActionTypes.GET_BRAND_BY_ID:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.LOADING'
      };
    case CreateOverviewActionTypes.GET_BRAND_BY_ID_SUCCESS:
      return {
        ...state,
        loadingMessage: null
      };
    case CreateOverviewActionTypes.GET_MEDICINE_BY_ID:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.LOADING'
      };
    case CreateOverviewActionTypes.GET_MEDICINE_BY_ID_SUCCESS:
      return {
        ...state,
        loadingMessage: null
      };
    case CreateOverviewActionTypes.GET_SUPPLIER_BY_NAME:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.LOADING'
      };
    case CreateOverviewActionTypes.GET_SUPPLIER_BY_NAME_SUCCESS:
      return {
        ...state,
        loadingMessage: null
      };
    case CreateOverviewActionTypes.CREATE_PRODUCT:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.LOADING'
      };
    case CreateOverviewActionTypes.CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        loadingMessage: null
      };



    case CmsActionTypes.GET_PRESCRIBING_LEVELS:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.LOADING'
      };
    case CmsActionTypes.GET_PRESCRIBING_LEVELS_SUCCESS:
      return {
        ...state,
        loadingMessage: null
      };
    case CmsActionTypes.GET_MEDICINE_FORMS:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.LOADING'
      };
    case CmsActionTypes.GET_MEDICINE_FORMS_SUCCESS:
      return {
        ...state,
        loadingMessage: null
      };

    case EditOverviewActionTypes.GET_MEDICINE_BY_ID:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.LOADING'
      };
    case EditOverviewActionTypes.GET_MEDICINE_BY_ID_SUCCESS:
      return {
        ...state,
        loadingMessage: null
      };
    case EditOverviewActionTypes.GET_BRAND_BY_ID:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.LOADING'
      };
    case EditOverviewActionTypes.GET_BRAND_BY_ID_SUCCESS:
      return {
        ...state,
        loadingMessage: null
      };
    case EditOverviewActionTypes.UPDATE_MEDICINE:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.LOADING'
      };
    case EditOverviewActionTypes.UPDATE_MEDICINE_SUCCESS:
      return {
        ...state,
        loadingMessage: null
      };
    case EditOverviewActionTypes.UPDATE_BRAND:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.LOADING'
      };
    case EditOverviewActionTypes.UPDATE_BRAND_SUCCESS:
      return {
        ...state,
        loadingMessage: null
      };
    case EditOverviewActionTypes.DEACTIVATE_BRAND:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.LOADING'
      };
    case EditOverviewActionTypes.DEACTIVATE_BRAND_SUCCESS:
      return {
        ...state,
        loadingMessage: null
      };
    case EditOverviewActionTypes.DEACTIVATE_MEDICINE:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.LOADING'
      };
    case EditOverviewActionTypes.DEACTIVATE_MEDICINE_SUCCESS:
      return {
        ...state,
        loadingMessage: null
      };

    case SelectionSearchableNamesActionTypes.UPDATE_PRICELIST_ITEM:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.LOADING'
      };
    case SelectionSearchableNamesActionTypes.UPDATE_PRICELIST_ITEM_SUCCESS:
      return {
        ...state,
        loadingMessage: null
      };

    case SelectionSearchableNamesActionTypes.DEACTIVATE_PRICELIST_ITEM:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.LOADING'
      };
    case SelectionSearchableNamesActionTypes.DEACTIVATE_PRICELIST_ITEM_SUCCESS:
      return {
        ...state,
        loadingMessage: null
      };

    case AdminActionTypes.GET_OVERVIEW:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.LOADING'
      };
    case AdminActionTypes.GET_OVERVIEW_SUCCESS:
      return {
        ...state,
        loadingMessage: null
      };

    case OrdersActionTypes.GET_MESSAGES:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.LOADING'
      };
    case OrdersActionTypes.GET_MESSAGES_SUCCESS:
      return {
        ...state,
        loadingMessage: null
      };

    case CreateOverviewActionTypes.GET_PRODUCT_CATALOG:
      return {
        ...state,
        loadingMessage: 'MESSAGES.LOADER.LOADING'
      };
    case CreateOverviewActionTypes.GET_PRODUCT_CATALOG_SUCCESS:
      return {
        ...state,
        loadingMessage: null
      };






    case OrderMonitorActionTypes.GET_MISSING_MEDICINES_SUCCESS:
      return {
        ...state,
        loadingMessage: null
      };
    case AppActionTypes.GET_TRANSLATIONS_SUCCESS:
      return {
        ...state,
        translations: action.payload
      };
    case AppActionTypes.SET_ALERT:
      return {
        ...state,
        alert: {
          message: action.message,
          type: action.alertType,
          params: action.params
        }
      };
    case AppActionTypes.CLEAR_ALERT:
      return {
        ...state,
        alert: null
      };
    case AppActionTypes.SET_SIDENAV:
      return {
        ...state,
        sideNav: action.payload != null ? action.payload : !state.sideNav
      };
    default:
      return state;
  }
}
