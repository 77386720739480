import { Component, HostBinding, Input } from '@angular/core';
import { Crumb } from '../../interfaces/crumb.interface';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
})
export class BreadcrumbComponent extends BaseComponent {
  @Input() crumbs: Crumb[];
  @HostBinding() class = 'breadcrumb-component';

  constructor() {
    super();
  }
}
