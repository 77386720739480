import { Entity } from './entity';
import { Organization } from './organization';
import { Medicine } from './medicine';
import { Brand } from './brand';

export class PricelistItem extends Entity {
  unitPrice: number;
  unit: string;
  cleanUnitPrice: string;
  preferred: boolean;
  brandId: number;
  medicineId: number;
  medicine: Medicine;
  brand: Brand;
  outdated: boolean;
  supplier: Organization;
  endDate: Date;

  orderedQuantity = 0;

  constructor(data?: any, restore: boolean = false) {
    super(data, restore);

    if (data) {
      this.unitPrice = data.unitPrice;
      this.unit = data.unit;
      this.cleanUnitPrice = data.cleanUnitPrice;
      this.preferred = data.preferred;
      this.medicineId = data.medicineId;
      this.medicine = data.medicine;
      this.brandId = data.brandId;
      this.brand = data.brand;
      this.outdated = data.outdated;
      this.supplier = data.supplier ? new Organization(data.supplier) : null;
      this.endDate = data.endDate;
    }
  }
}
