<div class="confirmation-dialog-container" *transloco="let t">
  <div class="dialog-header">
    <h2 [transloco]="title"></h2>
    <button mat-icon-button (click)="close(false)">
      <fa-icon icon="times"></fa-icon>
    </button>
  </div>

  <div class="question-container" [innerHTML]="question | transloco: questionParams"></div>

  <div class="full-width mt-2 confirmation-dialog__button-container">
    <section class="confirmation-dialog__button-container--left">
      <mat-checkbox [(ngModel)]="receiveAdminConfirmationPopup" color="primary" *ngIf="showCheckbox">{{t('DIALOGS.CONFIRMATION.DO_NOT_SHOW_AGAIN')}}</mat-checkbox>
    </section>

    <div class="confirmation-dialog__button-container--right">
      <ng-container *ngIf="actions?.length; else defaultBtns">
        <ng-container *ngFor="let button of actions" [ngSwitch]="button.type">
          <button *ngSwitchCase="'mat-button'"
                  mat-button
                  color="primary"
                  (click)="handleActionClose(button)"
                  [transloco]="button.label"
                  class="mr-2"
          ></button>
          <button *ngSwitchCase="'mat-stroked-button'"
                  mat-raised-button
                  color="primary"
                  (click)="handleActionClose(button)"
                  [transloco]="button.label"
                  class="mr-2"
          ></button>
          <button *ngSwitchDefault
                  mat-raised-button
                  color="primary"
                  (click)="handleActionClose(button)"
                  [transloco]="button.label"
                  class="mr-2"
          ></button>
        </ng-container>
      </ng-container>

      <ng-template #defaultBtns>
        <button mat-button
                color="primary"
                (click)="close(false)"
                transloco="BUTTONS.NO"
                class="mr-2"
        >
        </button>
        <button mat-raised-button
                color="primary"
                (click)="close(true)"
                transloco="BUTTONS.YES">
        </button>
      </ng-template>
    </div>
  </div>
</div>
