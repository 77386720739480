import { Entity } from './entity';

export class Keyword extends Entity {
  brandId: number;
  language: string;
  requiredText: string;
  text: string;
  internalProductIdSupplier: string;
  gtin: string;
  imageLocation: string;
  thumbLocation: string;
  onCurrentPricelistitem: boolean;

  constructor(data?: any, restore: boolean = false) {
    super(data, restore);

    if (data) {
      this.brandId = data.brandId;
      this.language = data.language;
      this.requiredText = data.requiredText;
      this.text = data.text;
      this.internalProductIdSupplier = data.internalProductIdSupplier;
      this.gtin = data.gtin;
      this.imageLocation = data.imageLocation;
      this.thumbLocation = data.thumbLocation;
      this.onCurrentPricelistitem = data.onCurrentPricelistitem;
    }
  }

  static newKeyword(): Keyword {
    return new Keyword({
      language: 'en',
      requiredText: null,
      text: null,
      internalProductIdSupplier: null,
      gtin: null,
      onCurrentPricelistitem: false
    });
  }
}
