import { Injectable } from '@angular/core';
import { TranslocoLoader } from '@ngneat/transloco';
import { ApiService } from '../api/api.service';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private apiService: ApiService) {}

  getTranslation(lang: string) {
    return this.apiService.getTranslations(lang);
  }
}
