import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { environment } from '../environments/environment';
import { NotFoundPage } from './pages/not-found/not-found.page';
import { HomePage } from './pages/home/home.page';
import { RootStoreModule } from './store/root-store.module';
import { SharedModule } from './shared/shared.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { UpdatePasswordPage } from './pages/update-password/update-password-page.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TRANSLOCO_CONFIG, TRANSLOCO_LOADER, translocoConfig, TranslocoModule } from '@ngneat/transloco';
import { TranslocoHttpLoader } from './shared/providers/transloco-http-loader/transloco-htt-loader.service';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';
import { MaintenancePage } from './pages/maintenance/maintenance.page';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundPage,
    HomePage,
    UpdatePasswordPage,
    MaintenancePage,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RootStoreModule,
    HttpClientModule,
    SharedModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    BrowserAnimationsModule,
    TranslocoModule,
    TranslocoMessageFormatModule.init()
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['en', 'sw', 'fr'],
        defaultLang: 'en',
        fallbackLang: 'en',
        reRenderOnLangChange: false,
        prodMode: true
      })
    },
    {
      provide: TRANSLOCO_LOADER,
      useClass: TranslocoHttpLoader
    },
    {
      provide: LOCALE_ID,
      useValue: 'en-GH'
    }
  ]
})
export class AppModule {
}
