import { User } from '../../models/user';
import { getModuleFromUrl } from '../../utils';
import { MODULE_CONFIG } from '../../constants';
import { WINDOW } from '../../tokens/dom.tokens';
import { filter, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base/base.component';
import { NavigationEnd, Router } from '@angular/router';
import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '../../providers/auth/auth.service';

@Component({
  selector: 'app-module-toggle',
  templateUrl: './module-toggle.component.html',
})
export class ModuleToggleComponent extends BaseComponent implements OnInit {
  currentModule: string;
  moduleConfig: any[];

  constructor(
    private router: Router,
    @Inject(WINDOW) private window: any,
    private auth: AuthService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    // get initial module
    this.currentModule = getModuleFromUrl(this.window.location.href);

    this.auth
      .getUser()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((user) => {
        this.moduleConfig = MODULE_CONFIG.filter((config) => user.hasPermission(config.permission));
        this.setStartingUrlForModule(user);
      });

    // listen for route changes to update the current module
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._unsubscribe$),
      )
      .subscribe(
        (event: NavigationEnd) => (this.currentModule = getModuleFromUrl(event.urlAfterRedirects)),
      );
  }

  private setStartingUrlForModule(user: User) {
    this.moduleConfig.forEach((c) => {
      if (c.urls == null) {
        return;
      }

      for (const url of c.urls) {
        if (user.hasPermission(url.permission)) {
          c.url = url.url;

          break;
        }
      }
    });
  }
}
