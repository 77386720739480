import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'quantityFilter',
  pure: false
})
export class QuantityFilterPipe implements PipeTransform {

  transform(value: any[], amount: number = 0, operator: '>' | '<' | '=' = '>'): any[] {
    return value ? value.filter(item => {
      switch (operator) {
        case '<':
          return item.quantity < amount;
        case '>':
          return item.quantity > amount;
        case '=':
          return item.quantity === amount;
      }
    }) : [];
  }

}
