import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { GenericFailureAction, GenericSuccessAction } from '../app/actions';
import * as selectionSearchableNamesAction from './selection-searchable-names.actions';
import * as searchableNamesAction from '../searchable-names/searchable-names.actions';
import { PriceUpdateOverviewService } from "@providers/api/priceupdate-overview.service";
import { SupplierWithIds } from '@shared/interfaces/supplier-with-ids.interface';
import { PricelistItem } from '@shared/models/pricelist-item';
import { SelectionSearchableNamesActionTypes } from './selection-searchable-names.actions';
import { ProductApiService } from '@shared/providers/api/product-api.service';
import { DeactivatedItems } from '@shared/interfaces/deactivated-items.interface';
import { SearchableNamesActionTypes } from '../searchable-names/searchable-names.actions';

@Injectable()
export class SelectionSearchableNamesEffects {
  constructor(
    private actions: Actions,

    private priceUpdateOverviewService: PriceUpdateOverviewService,
    private productApiService: ProductApiService,
  ) { }


  updatePricelistItem: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(SelectionSearchableNamesActionTypes.UPDATE_PRICELIST_ITEM),
    mergeMap((action: any) =>
      this.priceUpdateOverviewService.updatePricelistItem(action.payload).pipe(
        map((priceListItem) =>
          new selectionSearchableNamesAction.UpdatePriceListItemSuccessAction(
            { priceListItem: priceListItem, oldPriceListItemId: action.payload.id }
          )
        ),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));


  setSearchablePriceLisItems: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(SelectionSearchableNamesActionTypes.UPDATE_PRICELIST_ITEM_SUCCESS),
    mergeMap((action: any) =>
      of(new searchableNamesAction.UpdateSearchablePriceListItemsAfterPriceListItemSuccessAction(
        {
          priceListItem: action.payload.priceListItem, oldPriceListItemId:
            action.payload.oldPriceListItemId
        })
      )
    )
  ));

  updatePricelistItemGenericSuccess: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(SelectionSearchableNamesActionTypes.UPDATE_PRICELIST_ITEM_SUCCESS),
    mergeMap(() =>
      of(new GenericSuccessAction('MESSAGES.API.UPDATE_PRICELIST_ITEM_SUCCESS'))
    )));


  deactivatePriceListItem: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(SelectionSearchableNamesActionTypes.DEACTIVATE_PRICELIST_ITEM),
      mergeMap((action: any) =>
        this.productApiService.deactivatePriceListItem(action.payload).pipe(
          map((deactivatedItems: DeactivatedItems) => {
            return new selectionSearchableNamesAction.DeactivatePriceListItemSuccessAction(deactivatedItems)
          }),
          catchError((err) => of(new GenericFailureAction(err.error.message))),
        ),
      )
    )
  );

  deactivatePriceListItemSuccessMessage: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(SelectionSearchableNamesActionTypes.DEACTIVATE_PRICELIST_ITEM_SUCCESS),
      mergeMap(() => of(new GenericSuccessAction('MESSAGES.API.DEACTIVATE_PRICELIST_ITEM'))),
    ),
  );

  ResetPriceListItemSelectionSearchableNamesAfterDeactivatePriceListItemSuccess: Observable<any> = createEffect(
    () =>
      this.actions.pipe(
        ofType(SearchableNamesActionTypes.DEACTIVATE_ITEMS_SUCCESS),
        mergeMap(() => of(new selectionSearchableNamesAction.ResetStateAction())),
      ),
  );

  updateSearchableNamesAfterDeactivatePriceListItemSuccess: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(SelectionSearchableNamesActionTypes.DEACTIVATE_PRICELIST_ITEM_SUCCESS),
      mergeMap((action: any) =>
        of(new searchableNamesAction.DeactivateItemsSuccessAction(action.payload)),
      ),
    ),
  );
}
