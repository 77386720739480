import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SearchableNamesReducer } from './searchable-names.reducer';
import { SearchableNamesEffects } from './searchable-names.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('searchableNames', SearchableNamesReducer),
    EffectsModule.forFeature([SearchableNamesEffects])
  ],
  providers: [SearchableNamesEffects]
})
export class SearchableNamesStoreModule {
}
