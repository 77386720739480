import { Medicine } from '../../shared/models/medicine';
import { Brand } from '../../shared/models/brand';
import { Keyword } from '../../shared/models/keyword';
import { PrescribingLevel } from '../../shared/interfaces/prescribing-level.interface';
import { MedicineForm } from '../../shared/models/medicine-form';
import { UnknownMedicine } from '../../shared/interfaces/unknown-medicine.interface';
import { PricelistItem } from '../../shared/models/pricelist-item';
import { EmailTemplate } from '../../shared/models/email-template';
import { EmailTemplateVariable } from '../../shared/models/email-template-variable';
import { Pageable } from '../../shared/interfaces/pageable.interface';
import { ProductCatalog } from '../../shared/models/product-catalog';

export interface CmsState {
  medicine: Medicine;
  medicines: Medicine[];
  brands: Brand[];
  brand: Brand;
  keywords: Keyword[];
  keyword: Keyword;
  medicineForms: MedicineForm[];
  prescribingLevels: PrescribingLevel[];
  unknownMedicines: UnknownMedicine[];
  pricelistItems: PricelistItem[];
  pricelistItem: PricelistItem;
  emailTemplates: EmailTemplate[];
  emailTemplateVariables: EmailTemplateVariable[];
  productCatalog: Pageable<ProductCatalog>;
}

export const initialCmsState: CmsState = {
  medicine: null,
  medicines: null,
  brand: null,
  brands: null,
  keyword: null,
  keywords: null,
  medicineForms: null,
  prescribingLevels: null,
  unknownMedicines: null,
  pricelistItems: null,
  pricelistItem: null,
  emailTemplates: null,
  emailTemplateVariables: null,
  productCatalog: null
};
