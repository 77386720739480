import { BrandNameId } from "@shared/interfaces/brand-name-id.interface";
import { MedicineNameId } from "@shared/interfaces/medicine-name-id.interface";
import { SupplierWithIds } from "@shared/interfaces/supplier-with-ids.interface";
import { PricelistItem } from "@shared/models/pricelist-item";

export interface SearchableNamesState {
  brandNames: BrandNameId[];
  medicineNames: MedicineNameId[];
  pricelistItems: PricelistItem[];
  suppliers: SupplierWithIds[];
  manufacturers: SupplierWithIds[];
}

export const initialSearchableNamesState: SearchableNamesState = {
  brandNames: [],
  medicineNames: [],
  pricelistItems: [],
  suppliers: [],
  manufacturers: [],
}
