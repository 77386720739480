import { Entity } from './entity';

export class EmailTemplateVariable extends Entity {
  name: string;
  description: string;
  type: string;

  constructor(data?: any, restore: boolean = false) {
    super(data, restore);

    if (data) {
      this.name = data.name;
      this.description = data.description;
      this.type = data.type;
    }
  }
}
