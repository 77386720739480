import { AppState, initialAppState } from './app/state';
import { AuthState, initialAuthState } from './auth/state';
import { initialOrdersState, OrdersState } from './orders/state';
import { AdminState, initialAdminState } from './admin/state';
import { initialOrderMonitorState, OrderMonitorState } from './order-monitor/state';
import { CmsState, initialCmsState } from './cms/state';
import { initialSearchableNamesState, SearchableNamesState } from './searchable-names/searchable-names-state';
import { initialSelectionSearchableNamesState, SelectionSearchableNamesState } from './selection-searchable-names/selection-searchable-names-state';
import { CreateOverviewState, initialCreateOverviewState } from './create-overview/create-overview-state';
import { EditOverviewState, initialEditOverviewState } from './edit-overview/edit-overview-state';

export interface RootState {
  app: AppState;
  auth: AuthState;
  admin: AdminState;
  orders: OrdersState;
  orderJourney: OrderMonitorState;
  cms: CmsState;
  searchableNames: SearchableNamesState;
  selectionSearchableNames: SelectionSearchableNamesState;
  createOverview: CreateOverviewState;
  editOverview: EditOverviewState;
}

export const initialRootState: RootState = {
  app: initialAppState,
  auth: initialAuthState,
  orders: initialOrdersState,
  admin: initialAdminState,
  orderJourney: initialOrderMonitorState,
  cms: initialCmsState,
  searchableNames: initialSearchableNamesState,
  selectionSearchableNames: initialSelectionSearchableNamesState,
  createOverview: initialCreateOverviewState,
  editOverview: initialEditOverviewState
};
