import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { OverviewAction } from '../../interfaces/overview-action.interface';
import { inputChanged } from '../../utils';

@Component({
  selector: 'app-home-actions',
  templateUrl: './home-actions.component.html',
  styleUrls: ['./home-actions.component.scss']
})
export class HomeActionsComponent implements OnChanges {

  @Input() actionType: string;
  @Input() actions: OverviewAction[];
  filteredActions: OverviewAction[];

  ngOnChanges(changes: SimpleChanges): void {
    if (inputChanged(changes, 'actions')) {
      this.filteredActions = this.actions?.filter(action => action.actionType === this.actionType);
    }
  }

}
