import {ROOT_EFFECTS_INIT} from '@ngrx/effects';
import {INIT, UPDATE} from '@ngrx/store';
import {environment} from '../../../environments/environment';
import {initialRootState} from '../root-state';
import * as Flatted from 'flatted';

const INIT_ACTIONS: string[] = [ROOT_EFFECTS_INIT, INIT, UPDATE];
import { cloneDeep } from 'lodash';
import { storageKey } from './storageKey';

function keepMinimalLocalStorageState(nextState) {
  localStorage.clear();

  // We cannot save the entire database into the local storage
  // Keep what is necessary, clean what we don't need
  const cleanState = cloneDeep(initialRootState);
  cleanState['auth'] = nextState['auth'];
  cleanState['app'] = nextState['app'];
  cleanState['orders']['cart'] = nextState['orders']['cart'];
  localStorage.setItem(storageKey, Flatted.stringify(cleanState));
}

export function backupReducer(reducer) {
  return (state, action) => {
    const nextState = reducer(state, action);

    // Dirty fix, better than seeing MESSAGES.LOADER.*
    if (nextState['app']['loadingMessage']?.includes('MESSAGES.LOADER')) {
      nextState['app']['loadingMessage'] = 'Loading...';
    }

    // save the current state
    if (INIT_ACTIONS.indexOf(action.type) === -1) {
      try {
        localStorage.setItem(storageKey, Flatted.stringify(nextState));
      } catch (e) {
        keepMinimalLocalStorageState(nextState);
      }
    }
    return nextState;
  };
}
