<div class="profile-dialog-container" *transloco="let t">
  <div class="dialog-header">
    <h2>{{t('DIALOGS.PROFILE.TITLE')}}</h2>
    <button mat-icon-button (click)="close()">
      <fa-icon icon="times"></fa-icon>
    </button>
  </div>

  <div class="form-container">

    <form [formGroup]="profileForm" class="profile-form bordered-container">
      <h2>{{t("DIALOGS.PROFILE.ACCOUNT_INFORMATION")}}</h2>

      <mat-form-field>
        <mat-label>{{t("USER.FIRST_NAME")}}</mat-label>
        <input type="text" id="firstNameInput" formControlName="firstName" required matInput/>
        <mat-error
          *ngIf="firstName.invalid && firstName.dirty">{{t("MESSAGES.FORM.FIRST_NAME_REQUIRED_ERROR")}}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{t("USER.LAST_NAME")}}</mat-label>
        <input type="text" id="lastNameInput" formControlName="lastName" required matInput/>
        <mat-error
          *ngIf="lastName.invalid && lastName.dirty">{{t("MESSAGES.FORM.LAST_NAME_REQUIRED_ERROR")}}</mat-error>
      </mat-form-field>


      <div [ngClass]="{'bordered-container': isEmailEdited()}">
        <mat-form-field>
          <mat-label>{{t("USER.EMAIL_ADDRESS")}}</mat-label>
          <input type="text" id="emailInput" formControlName="email" #emailInput matInput/>
        </mat-form-field>

        <div *ngIf="isEmailEdited()">
          <!-- <div class="mt-2 bordered-container"> -->
            <mat-form-field >
              <mat-label>{{t("USER.CONFIRM_EMAIL_ADDRESS")}}</mat-label>
              <input type="text" id="emailConfirmationInput" formControlName="confirmEmail" matInput/>
            </mat-form-field>

            <div id="emailMatchError" *ngIf="!areEmailsEqual()" class="mat-error" transloco="MESSAGES.FORM.EMAIL_MATCH_ERROR"></div>
            <div id="emailMatchError" *ngIf="areEmailsEqual()" class="mat-info" transloco="MESSAGES.FORM.EDITING_EMAIL_WILL_LOG_YOU_OUT"></div>
          <!-- </div> -->
        </div>
      </div>

      <mat-form-field>
        <mat-label>{{t("USER.ROLE")}}</mat-label>
        <input type="text" id="userRole" formControlName="role" matInput readonly/>
      </mat-form-field>

      <app-tel-input formControlName="phoneNumber"></app-tel-input>
    </form>

    <form [formGroup]="passwordForm" class="mt-2 bordered-container">
      <h2>{{t("DIALOGS.PROFILE.PASSWORD")}}</h2>

      <mat-form-field>
        <mat-label>{{t("DIALOGS.PROFILE.OLD_PASSWORD")}}</mat-label>
        <input type="password" formControlName="oldPassword" required matInput/>
        <mat-error *ngIf="oldPassword.dirty && oldPassword.invalid">
          <span *ngIf="oldPassword.hasError('required')">{{t("MESSAGES.FORM.PASSWORD_REQUIRED_ERROR")}}</span>
          <span *ngIf="oldPassword.hasError('pattern')">{{t("MESSAGES.FORM.PASSWORD_PATTERN_ERROR")}}</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label transloco="DIALOGS.PROFILE.NEW_PASSWORD"></mat-label>
        <input type="password" formControlName="newPassword" required matInput/>
        <mat-error *ngIf="newPassword.dirty && newPassword.invalid">
          <span *ngIf="newPassword.hasError('required')">{{t("MESSAGES.FORM.PASSWORD_REQUIRED_ERROR")}}</span>
          <span *ngIf="newPassword.hasError('pattern')">{{t("MESSAGES.FORM.PASSWORD_PATTERN_ERROR")}}</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label transloco="DIALOGS.PROFILE.CONFIRM_PASSWORD"></mat-label>
        <input id="confirmPasswordInput" type="password" formControlName="confirmPassword" required matInput/>
        <mat-error id="confirmPasswordError" *ngIf="confirmPassword.dirty && confirmPassword.invalid">
          <span
            *ngIf="confirmPassword.hasError('required')">{{t("DIALOGS.PROFILE.CONFIRM_PASSWORD_REQUIRED_ERROR")}}</span>
          <span *ngIf="confirmPassword.hasError('pattern')">{{t("MESSAGES.FORM.PASSWORD_PATTERN_ERROR")}}</span>
        </mat-error>
      </mat-form-field>

      <div *ngIf="!arePasswordsEqual()" class="password-match-error"
           transloco="DIALOGS.PROFILE.PASSWORD_MATCH_ERROR"></div>

      <div class="text-end full-width mt-3">
        <button mat-raised-button
                color="primary"
                (click)="updatePassword()"
                [disabled]="isPasswordFormInValid()"
                transloco="BUTTONS.UPDATE_PASSWORD">
        </button>
      </div>
    </form>

    <form [formGroup]="profileForm" class="mt-2 bordered-container">
      <h2>{{t("DIALOGS.PROFILE.PREFERENCES")}}</h2>

      <mat-checkbox formControlName="receiveEmails" color="primary">{{t("DIALOGS.PROFILE.RECEIVE_EMAILS")}}</mat-checkbox>
      <mat-checkbox formControlName="receiveAdminConfirmationPopup" color="primary">{{t("DIALOGS.PROFILE.ADMIN_POPUP")}}</mat-checkbox>
    </form>
  </div>

  <label class="form-label full-width margin-top mt-3">* {{'MESSAGES.FORM.REQUIRED_FIELDS' | transloco}}</label>

  <div class="button-bar">
    <button mat-stroked-button
            class="mr-3"
            (click)="close()"
            transloco="BUTTONS.CANCEL"
    ></button>

    <button mat-raised-button
            color="primary"
            [disabled]="isProfileFromInvalid()"
            (click)="save()"
            transloco="BUTTONS.SAVE_PROFILE"
    ></button>
  </div>
</div>
