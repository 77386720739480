import { BrandCreatorForm } from "@app/cms/create-overview/components/brand-creator-form/brand-creator-form.component";
import { SupplierSelectorForm } from "@app/cms/create-overview/components/supplier-selector/supplier-selector.component";
import { Action } from "@ngrx/store";
import { CreateProduct } from "@shared/interfaces/create-product.interface";
import { Brand } from "@shared/models/brand";
import { Medicine } from "@shared/models/medicine";
import { PricelistItem } from "@shared/models/pricelist-item";
import { ProductCatalog } from "@shared/models/product-catalog";


export enum CreateOverviewActionTypes {
  GET_MEDICINE_BY_ID = "[CreateOverviewActionTypes] Get Medicine by id",
  GET_MEDICINE_BY_ID_SUCCESS = "[CreateOverviewActionTypes] Get Medicine by id success action",

  GET_BRAND_BY_ID = "[CreateOverviewActionTypes] Get Brand by id",
  GET_BRAND_BY_ID_SUCCESS = "[CreateOverviewActionTypes] Get Brand by id success action",

  GET_SUPPLIER_BY_NAME = "[CreateOverviewActionTypes] Get Supplier by name",
  GET_SUPPLIER_BY_NAME_SUCCESS = "[CreateOverviewActionTypes] Get Supplier by name success action",

  RESET_MEDICINE = "[CreateOverviewActionTypes] Set medicine to null",
  RESET_BRAND = "[CreateOverviewActionTypes] Set brand to null",
  RESET_SUPPLIER = "[CreateOverviewActionTypes] Set supplier to null",

  CREATE_PRODUCT = "[CreateOverviewActionTypes] Create a product",
  CREATE_PRODUCT_SUCCESS = "[CreateOverviewActionTypes] Create a product success",

  GET_PRODUCT_CATALOG = "[CreateOverviewActionTypes] Get product catalog",
  GET_PRODUCT_CATALOG_SUCCESS = "[CreateOverviewActionTypes] Get product catalog success",
}

export class GetMedicineByIdAction implements Action {
  readonly type = CreateOverviewActionTypes.GET_MEDICINE_BY_ID;
  constructor(public payload: number) { }
}

export class GetMedicineByIdSuccessAction implements Action {
  readonly type = CreateOverviewActionTypes.GET_MEDICINE_BY_ID_SUCCESS;
  constructor(public payload: Medicine) { }
}

export class GetBrandByIdAction implements Action {
  readonly type = CreateOverviewActionTypes.GET_BRAND_BY_ID;
  constructor(public payload: number) { }
}

export class GetBrandByIdSuccessAction implements Action {
  readonly type = CreateOverviewActionTypes.GET_BRAND_BY_ID_SUCCESS;
  constructor(public payload: Brand) { }
}

export class ResetMedicineAction implements Action {
  readonly type = CreateOverviewActionTypes.RESET_MEDICINE;
  constructor() { }
}

export class ResetBrandAction implements Action {
  readonly type = CreateOverviewActionTypes.RESET_BRAND;
  constructor() { }
}

export class CreateProductAction implements Action {
  readonly type = CreateOverviewActionTypes.CREATE_PRODUCT;
  constructor(public payload: CreateProduct) { }
}

export class CreateProductSuccessAction implements Action {
  readonly type = CreateOverviewActionTypes.CREATE_PRODUCT_SUCCESS;
  constructor(public payload: { createProduct: CreateProduct, newPriceListItem: PricelistItem}) { }
}

export class GetProductCatalogAction implements Action {
  readonly type = CreateOverviewActionTypes.GET_PRODUCT_CATALOG;
  constructor() { }
}

export class GetProductCatalogSuccessAction implements Action {
  readonly type = CreateOverviewActionTypes.GET_PRODUCT_CATALOG_SUCCESS;
  constructor(public payload: ProductCatalog[]) { }
}



export type Actions =
  | GetMedicineByIdAction
  | GetMedicineByIdSuccessAction
  | GetBrandByIdAction
  | GetBrandByIdSuccessAction
  | ResetMedicineAction
  | ResetBrandAction
  | CreateProductAction
  | CreateProductSuccessAction
  | GetProductCatalogAction
  | GetProductCatalogSuccessAction
