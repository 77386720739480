import { Entity } from './entity';
import { Brand } from './brand';
import { PricelistItem } from './pricelist-item';
import { MedicineForm } from './medicine-form';
import { Organization } from './organization';
import { PrescribingLevel } from '@shared/interfaces/prescribing-level.interface';

export class Medicine extends Entity {
  brands: Brand[];
  priceListItems: PricelistItem[];
  genericName: string;
  medicineForm: MedicineForm;
  formId: number;
  strength: string;
  atcCode: string;
  barcode: string;
  code: string;
  activeIngredients: string;
  prescribingLevel: PrescribingLevel;
  routeOfAdministration: string;
  dailyDefinedDose: string;
  coldChainData: string;
  psychotropicSubstance: boolean;
  chemicalSubstance: boolean;
  controlledSubstance: boolean;
  quantity: number;
  onCurrentPricelistitem: boolean;

  constructor(data?: any, restore: boolean = false) {
    super(data, restore);

    if (data) {
      this.genericName = data.genericName;
      this.brands = data.brands ? data.brands.map(brand => new Brand(brand, restore)) : [];
      this.medicineForm = data.medicineForm ? new MedicineForm(data.medicineForm, restore) : null;
      this.formId = data.medicineForm ? data.medicineForm.id : null;
      if (!this.formId) {
        this.formId = data.formId ? data.formId : null;
      }
      this.strength = data.strength;
      this.atcCode = data.atcCode;
      this.barcode = data.barcode;
      this.code = data.code;
      this.activeIngredients = data.activeIngredients;
      this.prescribingLevel = data.prescribingLevel;
      this.routeOfAdministration = data.routeOfAdministration;
      this.dailyDefinedDose = data.dailyDefinedDose;
      this.coldChainData = data.coldChainData;
      this.psychotropicSubstance = data.psychotropicSubstance;
      this.chemicalSubstance = data.chemicalSubstance;
      this.controlledSubstance = data.controlledSubstance;

      // properties for previously ordered medicines
      this.quantity = data.quantity;

      this.priceListItems = data.priceListItems ? data.priceListItems.map(item => {
        const pli = new PricelistItem(item, restore);
        pli.medicine = this;
        pli.brand = this.brands?.find(b => b.id === pli.brandId);

        return pli;
      }) : null;
      this.onCurrentPricelistitem = data.onCurrentPricelistitem;
    }
  }

  static newMedicine(): Medicine {
    return new Medicine({
      form: '',
      formId: '',
      chemicalSubstance: '',
      coldChainData: '',
      controlledSubstance: '',
      psychotropicSubstance: '',
      routeOfAdministration: '',
      prescribingLevel: '',
      onCurrentPricelistitem: false
    });
  }

  getPricelistItem(brand: Brand, supplier: Organization): PricelistItem {
    if (!this.priceListItems || !this.priceListItems.length) {
      return null;
    }

    return this.priceListItems.find(_item => _item.brandId === brand.id &&
      _item.medicineId === this.id &&
      _item.supplier.id === supplier.id
    );
  }

  get hasPricelist(): boolean {
    return this.priceListItems.length > 0;
  }
}
