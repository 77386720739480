import { cloneDeep } from 'lodash';
import { User } from '../../shared/models/user';
import { Role } from '../../shared/models/role';
import { Actions, AdminActionTypes } from './actions';
import { AdminState, initialAdminState } from './state';
import { Organization } from '../../shared/models/organization';
import { WithdrawalRequest } from '../../shared/models/withdrawal-request';
import { ORDERSTATUS, WITHDRAWALREQUESTSTATUS } from '../../shared/constants';
import { PricelistItem } from '@shared/models/pricelist-item';
import { Brand } from '@shared/models/brand';
import { BrandNameId } from '@shared/interfaces/brand-name-id.interface';
import { MedicineNameId } from '@shared/interfaces/medicine-name-id.interface';
import { SupplierWithIds } from '@shared/interfaces/supplier-with-ids.interface';

export function AdminReducer(state: AdminState = initialAdminState, action: Actions) {
  let users: User[];
  let idx: number;
  let user: User;
  let organization: Organization;
  let organizations: Organization[];
  let roles: Role[];
  let requests: WithdrawalRequest[];

  switch (action.type) {
    case AdminActionTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
      };
    case AdminActionTypes.CREATE_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        users: [...state.users, action.payload],
      };
    case AdminActionTypes.UPDATE_USER_SUCCESS:
    case AdminActionTypes.REACTIVATE_USER_SUCCESS:
      users = [...state.users];
      idx = users.findIndex((_user) => _user.id === action.payload.id);
      if (idx !== -1) {
        users.splice(idx, 1, action.payload);
      }

      return {
        ...state,
        user: action.payload,
        users,
      };
    case AdminActionTypes.SOFT_REMOVE_USER_SUCCESS:
      users = [...state.users];
      idx = users.findIndex((_user) => _user.id === action.payload);
      if (idx !== -1) {
        user = new User({ ...users[idx], active: false }, true);
        users.splice(idx, 1, user);
      }

      return {
        ...state,
        user,
        users,
      };
    case AdminActionTypes.REMOVE_USER_SUCCESS:
      users = [...state.users];
      idx = users.findIndex((_user) => _user.id === action.payload);
      if (idx !== -1) {
        users.splice(idx, 1);
      }
      return {
        ...state,
        users,
      };
    case AdminActionTypes.SAVE_USERS_FILTER:
      return {
        ...state,
        usersFilter: action.payload,
      };
    case AdminActionTypes.GET_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        organizations: action.payload,
      };
    case AdminActionTypes.CREATE_ORGANIZATION_SUCCESS:
    case AdminActionTypes.GET_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organization: action.payload,
        organizations: [...state.organizations, action.payload],
      };
    case AdminActionTypes.UPDATE_ORGANIZATION_SUCCESS:
    case AdminActionTypes.REACTIVATE_ORGANIZATION_SUCCESS:
      organizations = [...state.organizations];
      idx = state.organizations.findIndex((_org) => _org.id === action.payload.id);
      if (idx !== -1) {
        organizations.splice(idx, 1, action.payload);
      }

      return {
        ...state,
        organization: action.payload,
        organizations,
      };
    case AdminActionTypes.SOFT_REMOVE_ORGANIZATION_SUCCESS:
      organizations = [...state.organizations];
      idx = state.organizations.findIndex((_org) => _org.id === action.payload);
      if (idx !== -1) {
        organization = new Organization({ ...organizations[idx], active: false }, true);
        organizations.splice(idx, 1, organization);
      }

      return {
        ...state,
        organization,
        organizations,
      };
    case AdminActionTypes.REMOVE_ORGANIZATION_SUCCESS:
      organizations = [...state.organizations];
      organizations.splice(
        state.organizations.findIndex((_org) => _org.id === action.payload),
        1,
      );

      return {
        ...state,
        organizations,
      };
    case AdminActionTypes.SAVE_ORGANIZATIONS_FILTER:
      return {
        ...state,
        organizationsFilter: action.payload,
      };
    case AdminActionTypes.GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload,
      };
    case AdminActionTypes.CREATE_ROLE_SUCCESS:
    case AdminActionTypes.GET_ROLE_SUCCESS:
      return {
        ...state,
        role: action.payload,
        roles: [...state.roles, action.payload],
      };
    case AdminActionTypes.UPDATE_ROLE_SUCCESS:
      roles = [...state.roles];
      roles.splice(
        state.roles.findIndex((_org) => _org.id === action.payload.id),
        1,
        action.payload,
      );

      return {
        ...state,
        role: action.payload,
        roles,
      };
    case AdminActionTypes.REMOVE_ROLE_SUCCESS:
      roles = [...state.roles];
      roles.splice(
        state.roles.findIndex((_org) => _org.id === action.payload),
        1,
      );
      return {
        ...state,
        roles,
      };
    case AdminActionTypes.GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: action.payload,
      };
    case AdminActionTypes.SAVE_ROLES_FILTER:
      return {
        ...state,
        rolesFilter: action.payload,
      };
    case AdminActionTypes.GET_OVERVIEW_SUCCESS:
      return {
        ...state,
        overviewActions: action.payload,
      };
    case AdminActionTypes.GET_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload,
      };
    case AdminActionTypes.RESUBMIT_PAYMENT_SUCCESS:
    case AdminActionTypes.UPDATE_ORDER_STATUS_SUCCESS:
    case AdminActionTypes.PAY_ORDER_SUCCESS:
      const adminOrders = cloneDeep(state.orders);
      idx = adminOrders.content.findIndex((_order) => _order.id === action.payload.id);
      if (idx !== -1) {
        // remove the order if it is cancelled
        if (action.payload.currentOrderStatus.status === ORDERSTATUS.CANCELLED) {
          adminOrders.content.splice(idx, 1);
          adminOrders.totalElements -= 1;
        } else {
          adminOrders.content.splice(idx, 1, action.payload);
        }
      } else {
        adminOrders.content.push(action.payload);
        adminOrders.totalElements += 1;
      }

      return {
        ...state,
        orders: adminOrders,
      };
    case AdminActionTypes.UPDATE_ORDERS_FILTER:
      return {
        ...state,
        ordersFilter: action.payload,
      };
    case AdminActionTypes.GET_DENOMINATIONS_SUCCESS:
      return {
        ...state,
        denominations: action.payload,
      };
    case AdminActionTypes.GET_DISTRICTS_SUCCESS:
      return {
        ...state,
        districts: action.payload,
      };
    case AdminActionTypes.GET_REGIONS_SUCCESS:
      return {
        ...state,
        regions: action.payload,
      };
    case AdminActionTypes.GET_FACILITY_TYPES_SUCCESS:
      return {
        ...state,
        facilityTypes: action.payload,
      };
    case AdminActionTypes.GET_WITHDRAWAL_REQUESTS_SUCCESS:
      return {
        ...state,
        withdrawalRequests: action.payload,
      };
    case AdminActionTypes.UPDATE_WITHDRAWAL_REQUESTS_SUCCESS:
      idx = state.withdrawalRequests.findIndex((req) => req.id === action.payload.id);
      if (idx !== -1) {
        requests = [...state.withdrawalRequests];
        if (action.payload.status === WITHDRAWALREQUESTSTATUS.CANCELLED) {
          requests.splice(idx, 1);
        } else {
          requests.splice(idx, 1, action.payload);
        }
      }
      return {
        ...state,
        withdrawalRequests: requests,
      };
    case AdminActionTypes.UPDATE_WITHDRAWAL_REQUESTS_FILTER:
      return {
        ...state,
        withdrawalRequestsFilter: action.payload,
      };
    case AdminActionTypes.GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: action.payload,
      };
    case AdminActionTypes.SAVE_DOCUMENT_FILTER:
      return {
        ...state,
        documentFilter: action.payload,
      };
    case AdminActionTypes.SAVE_EMAILS_FILTER:
      return {
        ...state,
        emailsFilter: action.payload,
      };
    case AdminActionTypes.GET_EMAILS_SUCCESS:
      return {
        ...state,
        emails: action.payload,
      };
  default:
      return state;
  }
}
