import { Entity } from './entity';
import { Medicine } from './medicine';
import { FormularyItem } from './formulary-item';
import { Brand } from './brand';

export class Formulary extends Entity {
  organizationId: number;
  formularyItems: FormularyItem[];

  constructor(data?: any, restore: boolean = false) {
    super(data, restore);

    if (data) {
      this.organizationId = data.organizationId;
      this.formularyItems = data.formularyItems ? data.formularyItems.map(item => new FormularyItem(item)) : [];
    }
  }

  hasMedicine(medicine: Medicine, brand: Brand): boolean {
    return this.getMedicine(medicine, brand) !== undefined;
  }

  getMedicine(medicine: Medicine, brand: Brand): FormularyItem {
    return this.formularyItems.find(item => item.medicineId === medicine?.id && item.brandId === brand.id);
  }

  addMedicine(medicine: Medicine, brand: Brand, forecast: number) {
    if (!this.hasMedicine(medicine, brand)) {
      this.formularyItems.push({
        medicineId: medicine.id,
        brandId: brand.id,
        forecast
      });
    }
  }

  removeMedicine(medicine: Medicine, brand: Brand) {
    const index = this.formularyItems.findIndex(item => item.medicineId === medicine.id && item.brandId === brand.id);
    if (index !== -1) {
      this.formularyItems.splice(index, 1);
    }
  }
}
