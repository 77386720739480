import { matchSubdomain } from '../../utils';
import { WINDOW } from '../../tokens/dom.tokens';
import { BaseComponent } from '../../components/base/base.component';
import { Directive, Inject, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appWhiteLabel]',
})
export class WhitelabelDirective extends BaseComponent implements OnInit {
  @Input() appWhiteLabel: string;

  constructor(
    @Inject(WINDOW) private window: any,
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (matchSubdomain(this.window, this.appWhiteLabel, ['nchs'])) {
      if (this.viewContainer.length === 0) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    } else {
      this.viewContainer.clear();
    }
  }
}
