import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { BaseComponent } from '../../shared/components/base/base.component';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.page.html',
})
export class NotFoundPage extends BaseComponent {
  constructor(private location: Location) {
    super();
    this.crumbs = [{label: 'PAGES.NOT_FOUND.TITLE'}];
  }

  back() {
    this.location.back();
  }

}
