import { Entity } from './entity';
import { StockTakeItem } from './stock-take-item';
import { Medicine } from './medicine';
import { Brand } from './brand';
import { User } from './user';
import { Storeroom } from './storeroom';

export class StockTake extends Entity {
  organizationId: number;
  stockTakeItems: StockTakeItem[];
  stockTakeUnknownMedicineItems: StockTakeItem[];
  submittedOn: Date;
  createdBy: number;
  createdByUser: User;
  locationId: number;
  storeroom: Storeroom;
  longitude: number;
  latitude: number;
  quantity: number;
  approved: boolean;

  constructor(data?: any, restore: boolean = false) {
    super(data, restore);
    if (data) {
      this.organizationId = data.organizationId;
      this.locationId = data.locationId;
      this.stockTakeItems = [...this.getStockTakeItems(data), ...this.getUnknownStockTakeItems(data)];
      this.submittedOn = data.submittedOn ? new Date(data.submittedOn) : null;
      this.createdBy = data.createdBy;
      this.storeroom = new Storeroom(data.storeroom);
      this.createdByUser = new User(data.createdByUser);
      this.quantity = data.quantity;
      this.approved = data.approved;
    }
  }

  private getUnknownStockTakeItems(data: any) {
    return data.stockTakeUnknownMedicineItems ? data.stockTakeUnknownMedicineItems.map(item => new StockTakeItem(item)) : [];
  }

  private getStockTakeItems(data: any) {
    return data.stockTakeItems ? data.stockTakeItems.map(item => new StockTakeItem(item)) : [];
  }

  hasMedicine(medicine: Medicine, brand: Brand): boolean {
    return this.getMedicine(medicine, brand) !== undefined;
  }

  getMedicine(medicine: Medicine, brand: Brand): StockTakeItem {
    return this.stockTakeItems.find(item => item.medicine.id === medicine.id && item.brand.id === brand.id);
  }
}
