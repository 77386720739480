import { REGEX, SETTING } from '../../constants';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SettingsService } from '../../providers/settings/settings.service';
import {
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

/** Custom `MatFormFieldControl` for telephone number input. */
/** TODO: refactor and remove unused code */
@Component({
  selector: 'app-tel-input',
  templateUrl: 'tel-input.component.html',
  styleUrls: ['tel-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TelInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: TelInputComponent,
      multi: true,
    },
  ],
})
export class TelInputComponent implements OnDestroy, ControlValueAccessor {
  static nextId = 0;

  public ngUnsubscribe: Subject<void>;
  private _required = false;
  private _disabled = false;

  @ViewChild('country') countryInput: HTMLInputElement;
  @ViewChild('phone') phoneInput: HTMLInputElement;
  @HostBinding('id') id = `tel-input-${TelInputComponent.nextId++}`;
  @Input() placeholder: string;

  parts: UntypedFormGroup;
  onChange = (_: any) => {};
  onTouched = () => {};

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
  }

  @Input()
  get value(): string | null {
    const {
      value: { country, phone },
    } = this.parts;
    return `${country} ${phone}`;
  }
  set value(tel: string | null) {
    if (tel) {
      const splitPhoneNumber = tel.split(' ');
      if (splitPhoneNumber.length > 1) {
        const country: string = splitPhoneNumber[0];
        const phone: string = splitPhoneNumber[1];

        this.parts.setValue({ country, phone });
      } else {
        const phone: string = splitPhoneNumber[0];
        this.parts.setValue({ country: '', phone });
      }
    } else {
      this.parts.setValue({ country: this.settings.get(SETTING.COUNTRY_CODE), phone: '' });
    }
  }

  get countryCtrl(): AbstractControl {
    return this.parts.get('country');
  }

  get phoneCtrl(): AbstractControl {
    return this.parts.get('phone');
  }

  constructor(formBuilder: UntypedFormBuilder, private settings: SettingsService) {
    this.ngUnsubscribe = new Subject<void>();

    this.parts = formBuilder.group({
      country: [null, [Validators.minLength(3), Validators.maxLength(4)]],
      phone: [null, [Validators.pattern(REGEX.PHONE_NUMBER)]],
    });

    // update the model
    this.parts.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.onChange(this.value));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  writeValue(tel: string | null): void {
    this.value = tel;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate() {
    const countryError = this.countryCtrl.invalid;
    const phoneError = this.phoneCtrl.invalid;
    if (countryError || phoneError) {
      const errors = {};
      if (countryError) {
        errors['countryError'] = countryError;
      }
      if (phoneError) {
        errors['phoneError'] = phoneError;
      }
      return errors;
    }
    return null;
  }
}
