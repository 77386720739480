import { Entity } from './entity';
import { Medicine } from './medicine';
import { Brand } from './brand';
import { Storeroom } from './storeroom';

export class StockTakeItem extends Entity {
  brand: Brand;
  stock: number;
  expiryDate: Date;
  outOfStock: string;
  quantity: number;
  previousQuantity: number;
  quantityInUnits: number;
  medicine: Medicine;
  requiresRecount: boolean;
  batchExpiryDateOne: Date;
  batchNumberOne: string;
  batchExpiryDateTwo: Date;
  batchNumberTwo: string;
  storeroom: Storeroom;
  url: string;
  medicineUnknown: boolean;

  constructor(data?: any, restore: boolean = false) {
    super(data, restore);
    if (data) {
      this.expiryDate = data.expiryDate ? new Date(data.expiryDate) : null;
      this.outOfStock = data.outOfStock;
      this.quantity = data.quantity;
      this.previousQuantity = data.previousQuantity;
      this.quantityInUnits = data.quantityInUnits;
      this.brand = data.brand ? new Brand(data.brand, restore) : null;
      this.stock = data.stock;
      this.medicine = data.medicine ? new Medicine(data.medicine, restore) : null;
      this.requiresRecount = data.requiresRecount;
      this.batchExpiryDateOne = data.batchExpiryDateOne;
      this.batchNumberOne = data.batchNumberOne;
      this.batchExpiryDateTwo = data.batchExpiryDateTwo;
      this.batchNumberTwo = data.batchNumberTwo;
      this.storeroom = new Storeroom(data.storeroom);
      this.url = data.url;
      this.medicineUnknown = data.medicineUnknown;
    }
  }
}
