import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trash',
  pure: false
})
export class TrashPipe implements PipeTransform {

  transform(value: any[]): any[] {
    return value ? value.filter(item => !item.removed) : [];
  }

}
