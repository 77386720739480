import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import * as searchableNamesActions from './searchable-names.actions';
import { Store } from '@ngrx/store';
import { PriceUpdateOverviewService } from "@providers/api/priceupdate-overview.service";
import { SupplierWithIds } from '@shared/interfaces/supplier-with-ids.interface';
import { PricelistItem } from '@shared/models/pricelist-item';
import { SearchableNamesActionTypes } from './searchable-names.actions';
import { GenericFailureAction, GenericSuccessAction } from '@app/store/app/actions';


@Injectable()
export class SearchableNamesEffects {
  constructor(
    private actions: Actions,
    private store: Store,

    private priceUpdateOverviewService: PriceUpdateOverviewService,
  ) { }

  
  getActivePriceListItems: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(SearchableNamesActionTypes.GET_ACTIVE_PRICE_LIST_ITEMS),
    mergeMap(() =>
      this.priceUpdateOverviewService.getActivePriceListItems().pipe(
        map((priceListItems) => {
          return new searchableNamesActions.GetActivePriceListItemsSuccessAction(
              priceListItems);
        }),
        catchError((err) => of(new GenericFailureAction(err)))
      )),
  ))

  
  getSupplierWithIds: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(SearchableNamesActionTypes.GET_SUPPLIER_WITH_IDS),
    mergeMap(() =>
      this.priceUpdateOverviewService.getSupplierWithIds().pipe(
        map((supplierWithIds) => {
          return new searchableNamesActions.GetSupplierWithIdsSuccessAction(supplierWithIds);
        }),
        catchError((err) => of(new GenericFailureAction(err)))
      )),
  ))

  
  getManufacturers: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(SearchableNamesActionTypes.GET_MANUFACTURER_NAMES),
    mergeMap(() =>
      this.priceUpdateOverviewService.getManufacturerNames().pipe(
        map((supplierWithIds) => {
          return new searchableNamesActions.GetManufacturerNamesSuccessAction(supplierWithIds);
        }),
        catchError((err) => of(new GenericFailureAction(err)))
      )),
  ))



  
  getBrandNames: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(SearchableNamesActionTypes.GET_BRAND_NAMES),
    mergeMap(() =>
      this.priceUpdateOverviewService.getBrandNames().pipe(
        map((brandNameIds) => {
          return new searchableNamesActions.GetBrandNamesSuccessAction(brandNameIds);
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    )
  ));

  
  getMedicineNames: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(SearchableNamesActionTypes.GET_MEDICINE_NAMES),
    mergeMap(() =>
      this.priceUpdateOverviewService.getMedicineNames().pipe(
        map((medicineNameIds) => {
          return new searchableNamesActions.GetMedicineNamesSuccessAction(medicineNameIds);
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    )
  ));
}
