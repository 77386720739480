import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../../providers/auth/auth.service';
import { BaseComponent } from '../../components/base/base.component';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appPermission]',
})
export class PermissionDirective extends BaseComponent implements OnInit {
  @Input() appPermission: string[];
  @Input() appPermissionOpts: { all?: boolean; entityType?: string };

  constructor(
    private auth: AuthService,
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.auth
      .getUser()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((user) => {
        let hasPermission = true;
        if (user) {
          if (this.appPermissionOpts?.all) {
            hasPermission = this.appPermission.every((permissionName) =>
              user.hasPermission(permissionName),
            );
          } else {
            hasPermission = this.appPermission.some((permissionName) =>
              user.hasPermission(permissionName),
            );
          }

          if (this.appPermissionOpts?.entityType) {
            hasPermission =
              hasPermission && user.organization.type === this.appPermissionOpts?.entityType;
          }
        }

        if (hasPermission) {
          if (this.viewContainer.length === 0) {
            this.viewContainer.createEmbeddedView(this.templateRef);
          }
        } else {
          this.viewContainer.clear();
        }
      });
  }
}
