import { FilterItem } from './filter-item.interface';

export interface FilterGroup {
  groupName: string;
  prop: FilterGroupProp;
  items?: FilterItem[];
  type: FilterGroupType;
}

export enum FilterGroupType {
  select = 'select',
  date = 'date'
}

export enum FilterGroupProp {
  currentStatus = 'currentStatus',
  paymentMethod = 'paymentMethod',
  updatedOn = 'updatedOn',
  active = 'active',
  selected = 'selected',
  controlledSubstance = 'controlledSubstance',
  chemicalSubstance = 'chemicalSubstance',
  psychotropicSubstance = 'psychotropicSubstance',
  status = 'status',
  supplier = 'supplier',
  issueStatus = 'issueStatus',
  messageType = 'messageType',
  daysSinceDelivery = 'daysSinceDelivery',
  overdue = 'overdue',
  network = 'network',
  denomination = 'denomination',
  deleted = 'deleted',
  organizationType = 'organizationType',
  organizationName = 'organizationName',
  createdOn = 'createdOn',
  deliveredOn = 'deliveredOn',
  lastXDays = 'lastXDays',
  actionRequired = 'actionRequired',
  sentAfter = 'sentAfter',
  missingMeds = 'missingMeds',
  medicineStatus = 'medicineStatus',
  storeroomId = 'storeroomId',
  userId = 'userId',
  dateFrom = 'dateFrom',
  dateTo = 'dateTo',
  strength = 'strength',
  packSize = 'packSize',
  language = 'language',
  medicineFormId = 'medicineFormId',
  scanable = 'scanable',
  sellable = 'sellable'
}
