import { User } from '../../shared/models/user';
import { Organization } from '../../shared/models/organization';
import { Role } from '../../shared/models/role';
import { Permission } from '../../shared/models/permission';
import { OverviewAction } from '../../shared/interfaces/overview-action.interface';
import { Order } from '../../shared/models/order';
import { Filter } from '../../shared/interfaces/filter.interface';
import { SORT_DIRECTION } from '../../shared/constants';
import { District } from '../../shared/interfaces/district.interface';
import { Region } from '../../shared/interfaces/region.interface';
import { FacilityType } from '../../shared/interfaces/facility-type.interface';
import { Denomination } from '../../shared/interfaces/denomination.interface';
import { WithdrawalRequest } from '../../shared/models/withdrawal-request';
import { Document } from '../../shared/models/document';
import { Email } from '../../admin/shared/models/email';
import { Pageable } from '../../shared/interfaces/pageable.interface';
import { FilterGroupProp, FilterGroupType } from '@shared/interfaces/filter-group.interface';

export interface AdminState {
  user: User;
  users: User[];
  organizations: Organization[];
  organization: Organization;
  denominations: Denomination[];
  districts: District[];
  regions: Region[];
  facilityTypes: FacilityType[];
  roles: Role[];
  role: Role;
  permissions: Permission[];
  overviewActions: OverviewAction[];
  orders: Pageable<Order>;
  ordersFilter: Filter;
  usersFilter: Filter;
  rolesFilter: Filter;
  organizationsFilter: Filter;
  withdrawalRequests: WithdrawalRequest[];
  withdrawalRequestsFilter: Filter;
  documents: Pageable<Document>;
  documentFilter: Filter;
  emails: Pageable<Email>;
  emailsFilter: Filter;
}

export const initialAdminState: AdminState = {
  user: null,
  users: null,
  organizations: null,
  organization: null,
  roles: null,
  role: null,
  permissions: null,
  overviewActions: null,
  orders: null,
  ordersFilter: {
    filters: [],
    searchTerm: null,
    sortBy: 'updatedOn',
    sortDirection: SORT_DIRECTION.DESC,
    pageSize: 10,
  },
  usersFilter: {
    filters: [
      {
        groupName: 'USERS_FILTER.DELETED.GROUP_NAME',
        label: 'COMMON.NO',
        prop: FilterGroupProp.deleted,
        value: false,
      }
    ],
    searchTerm: null,
    sortBy: 'name',
    sortDirection: SORT_DIRECTION.ASC
  },
  rolesFilter: {
    filters: [],
    searchTerm: null,
    sortBy: 'name',
    sortDirection: SORT_DIRECTION.ASC
  },
  organizationsFilter: {
    filters: [
      {
        groupName: 'USERS_FILTER.DELETED.GROUP_NAME',
        label: 'COMMON.NO',
        prop: FilterGroupProp.deleted,
        value: false,
      }
    ],
    searchTerm: null,
    sortBy: 'name',
    sortDirection: SORT_DIRECTION.ASC
  },
  facilityTypes: null,
  regions: null,
  districts: null,
  denominations: null,
  withdrawalRequests: null,
  withdrawalRequestsFilter: {
    filters: [],
    searchTerm: null,
    sortBy: 'withdrawalDate',
    sortDirection: SORT_DIRECTION.DESC
  },
  documents: null,
  documentFilter: {
    sortDirection: SORT_DIRECTION.DESC,
    sortBy: 'createdOn',
    searchTerm: null
  },
  emails: null,
  emailsFilter: {
    sortDirection: SORT_DIRECTION.DESC,
    sortBy: 'createdOn',
    searchTerm: null,
    filters: []
  }
};
