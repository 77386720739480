import { BrandNameId } from '@shared/interfaces/brand-name-id.interface';
import { initialSearchableNamesState, SearchableNamesState } from './searchable-names-state';
import { Actions, SearchableNamesActionTypes } from './searchable-names.actions';
import { MedicineNameId } from '@shared/interfaces/medicine-name-id.interface';
import { PricelistItem } from '@shared/models/pricelist-item';
import { DeactivateBrandAction } from '../edit-overview/edit-overview.actions';
import { CreateProduct } from '@shared/interfaces/create-product.interface';
import { BrandCreatorForm } from '@app/cms/create-overview/components/brand-creator-form/brand-creator-form.component';
import { Medicine } from '@shared/models/medicine';
import { SupplierSelectorForm } from '@app/cms/create-overview/components/supplier-selector/supplier-selector.component';
import { SupplierWithIds } from '@shared/interfaces/supplier-with-ids.interface';

export function SearchableNamesReducer(
  state: SearchableNamesState = initialSearchableNamesState,
  action: Actions,
) {
  switch (action.type) {
    case SearchableNamesActionTypes.GET_ACTIVE_PRICE_LIST_ITEMS_SUCCESS:
      return {
        ...state,
        pricelistItems: action.payload,
      };
    case SearchableNamesActionTypes.GET_SUPPLIER_WITH_IDS_SUCCESS:
      return {
        ...state,
        suppliers: action.payload,
      };
    case SearchableNamesActionTypes.GET_MANUFACTURER_NAMES_SUCCESS:
      return {
        ...state,
        manufacturers: action.payload,
      };
    case SearchableNamesActionTypes.GET_BRAND_NAMES_SUCCESS:
      return {
        ...state,
        brandNames: action.payload,
      };
    case SearchableNamesActionTypes.GET_MEDICINE_NAMES_SUCCESS:
      return {
        ...state,
        medicineNames: action.payload,
      };
    case SearchableNamesActionTypes.UPDATE_PRICELIST_ITEM_SUCCESS:
      const indexPriceListItem = state.pricelistItems.findIndex(
        (i) => i.id === action.payload.oldPriceListItemId,
      );

      const newPriceListItems = [...state.pricelistItems];

      newPriceListItems[indexPriceListItem] = action.payload.priceListItem;

      return {
        ...state,
        pricelistItems: newPriceListItems,
      };
    case SearchableNamesActionTypes.UPDATE_MEDICINE_SUCCESS:
      const newMedicineNames = [...state.medicineNames];

      const indexMedicineName = newMedicineNames.findIndex(
        (medicineName) => medicineName.id === action.payload.id,
      );

      const newMedicineName: MedicineNameId = {
        id: action.payload.id,
        medicineName: action.payload.genericName,
        priceListItemIds: newMedicineNames[indexMedicineName].priceListItemIds,
      };

      newMedicineNames[indexMedicineName] = newMedicineName;

      return {
        ...state,
        medicineNames: newMedicineNames,
      };
    case SearchableNamesActionTypes.UPDATE_BRAND_SUCCESS:
      const newBrandNames = [...state.brandNames];
      const indexBrandName = newBrandNames.findIndex(
        (brandName) => brandName.id === action.payload.id,
      );

      let hasDuplicateBrandName = newBrandNames[indexBrandName].hasDuplicateBrandName;

      if (action.payload.name != newBrandNames[indexBrandName].brandName) {
        hasDuplicateBrandName =
          newBrandNames.find((x) => x.brandName == action.payload.name) != undefined;

        // If new one has duplicate set name to also has duplicate name
        if (hasDuplicateBrandName) {
          let brandNameToChange;

          newBrandNames.forEach((x, i) => {
            if (x.brandName == action.payload.name) {
              brandNameToChange = { ...newBrandNames[i] };
              brandNameToChange.hasDuplicateBrandName = true;
              newBrandNames[i] = brandNameToChange;
            }
          });
        }
      }

      const newBrandName: BrandNameId = {
        id: action.payload.id,
        brandName: action.payload.name,
        packSize: action.payload.packSize,
        manufacturerId: action.payload.manufacturerId,
        manufacturerName: action.payload.manufacturer.name,
        priceListItemIds: newBrandNames[indexBrandName].priceListItemIds,
        hasDuplicateBrandName: hasDuplicateBrandName,
      };

      newBrandNames[indexBrandName] = newBrandName;

      return {
        ...state,
        brandNames: newBrandNames,
      };
    case SearchableNamesActionTypes.DEACTIVATE_ITEMS_SUCCESS:
      const deactivatedItems = action.payload;
      const newMedicineNamesAfterDeactivation: MedicineNameId[] = [...state.medicineNames];
      const newBrandNamesAfterDeactivation: BrandNameId[] = [...state.brandNames];
      const newPriceListItemsAfterDeactivation: PricelistItem[] = [...state.pricelistItems];
      let indexDeactivatedMedicineName;
      let indexDeactivatedBrandName;
      let indexDeactivatedPriceListItem;

      if (deactivatedItems.medicineIds && deactivatedItems.medicineIds?.length != 0) {
        deactivatedItems.medicineIds.forEach((deactivatedMedicineId) => {
          indexDeactivatedMedicineName = state.medicineNames.findIndex(
            (medicineName) => deactivatedMedicineId == medicineName.id,
          );

          if (indexDeactivatedMedicineName != -1) {
            newMedicineNamesAfterDeactivation.splice(indexDeactivatedMedicineName, 1);
          }
        });
      }

      if (deactivatedItems.brandIds && deactivatedItems.brandIds.length != 0) {
        deactivatedItems.brandIds.forEach((deactivatedBrandNameId) => {
          indexDeactivatedBrandName = state.brandNames.findIndex(
            (brandName) => deactivatedBrandNameId == brandName.id,
          );

          if (indexDeactivatedBrandName != -1) {
            newBrandNamesAfterDeactivation.splice(indexDeactivatedBrandName, 1);
          }
        });
      }

      if (deactivatedItems.priceListItemIds && deactivatedItems.priceListItemIds.length != 0) {
        deactivatedItems.priceListItemIds.forEach((deactivatedPriceListItemId) => {
          indexDeactivatedPriceListItem = state.pricelistItems.findIndex(
            (priceListItem) => deactivatedPriceListItemId == priceListItem.id,
          );

          if (indexDeactivatedPriceListItem != -1) {
            newPriceListItemsAfterDeactivation.splice(indexDeactivatedPriceListItem, 1);
          }
        });
      }

      return {
        ...state,
        medicineNames: newMedicineNamesAfterDeactivation,
        brandNames: newBrandNamesAfterDeactivation,
        pricelistItems: newPriceListItemsAfterDeactivation,
        suppliers: state.suppliers,
        manufactures: state.manufacturers,
      };
    case SearchableNamesActionTypes.CREATE_PRODUCT_SUCCESS:
      const { createProduct, newPriceListItem } = action.payload;
      const { brandFormDto, medicineDto, supplierFormDto } = createProduct;

      const newBrandNamesAfterCreateProduct = setBrandNamesAfterCreateProduct(
        state,
        brandFormDto,
        newPriceListItem,
      );

      const newMedicineNamesAfterCreateProduct = setMedicineNamesAfterCreateProduct(
        state,
        medicineDto,
        newPriceListItem,
      );

      const newSuppliersAfterCreateProduct = setSupplierNamesAfterCreateProduct(
        state,
        supplierFormDto,
        newPriceListItem,
      );

      const newPriceListItemsAfterCreateProduct: PricelistItem[] = [
        ...state.pricelistItems,
        newPriceListItem
      ]

      return {
        ...state,
        medicineNames: newMedicineNamesAfterCreateProduct.sort((a, b) => a.medicineName < b.medicineName ? -1 : 1),
        brandNames: newBrandNamesAfterCreateProduct.sort((a, b) => a.brandName < b.brandName ? -1 : 1),
        suppliers: newSuppliersAfterCreateProduct,
        pricelistItems: newPriceListItemsAfterCreateProduct,
      };
    default:
      return state;
  }
}

function createNewBrandNameId(
  brandFormDto: BrandCreatorForm,
  newPriceListItem: PricelistItem,
): BrandNameId {
  return {
    id: newPriceListItem.brandId,
    brandName: brandFormDto.brand.name,
    packSize: brandFormDto.newPackSize ? brandFormDto.newPackSize : brandFormDto.brand.packSize,
    manufacturerId: brandFormDto.newManufacturerId
      ? brandFormDto.newManufacturerId
      : brandFormDto.manufacturerId,
    manufacturerName: brandFormDto.newManufacturerName
      ? brandFormDto.newManufacturerName
      : brandFormDto.brand.manufacturer.name,
    priceListItemIds: [newPriceListItem.id],
    hasDuplicateBrandName: false,
  };
}

function updateBrandNameIdWithManufacturerOrAndPackSize(
  newCreatedBrandName : BrandNameId,
  brandFormDto : BrandCreatorForm,
  newPriceListItem: PricelistItem,
): BrandNameId {
  newCreatedBrandName.manufacturerId =
    brandFormDto.newManufacturerId ?? newCreatedBrandName.manufacturerId;
  newCreatedBrandName.manufacturerName =
    brandFormDto.newManufacturerName ?? newCreatedBrandName.manufacturerName;
  newCreatedBrandName.packSize = brandFormDto.newPackSize ?? newCreatedBrandName.packSize;
  newCreatedBrandName.hasDuplicateBrandName = true;
  newCreatedBrandName.priceListItemIds = [newPriceListItem.id];

  return newCreatedBrandName;
}

function setBrandNamesAfterCreateProduct(
  state: SearchableNamesState,
  brandFormDto: BrandCreatorForm,
  newPriceListItem: PricelistItem,
): BrandNameId[] {
  const newBrandNamesAfterCreateProduct = [...state.brandNames];

  let newCreatedBrandName: BrandNameId;

  if (!brandFormDto.brand.id) {
    newCreatedBrandName = createNewBrandNameId(brandFormDto, newPriceListItem);
    newBrandNamesAfterCreateProduct.push(newCreatedBrandName);
  } else {
    const indexBrandNameCreateProduct = newBrandNamesAfterCreateProduct.findIndex(
      (brandName) => brandName.id == brandFormDto.brand.id,
    );

    if (indexBrandNameCreateProduct != -1) {
      newCreatedBrandName = { ...newBrandNamesAfterCreateProduct[indexBrandNameCreateProduct] };

      if (brandFormDto.newManufacturerId ?? brandFormDto.newPackSize) {
        newCreatedBrandName = updateBrandNameIdWithManufacturerOrAndPackSize(
          newCreatedBrandName,
          brandFormDto,
          newPriceListItem,
        );

        newBrandNamesAfterCreateProduct.push(newCreatedBrandName);
        newBrandNamesAfterCreateProduct[indexBrandNameCreateProduct] = {
          ...newBrandNamesAfterCreateProduct[indexBrandNameCreateProduct],
          hasDuplicateBrandName: true,
        };
      } else {
        // Update existing brand price list item ids
        const newPriceListItemsForBrandAfterCreateUpdate = [
          ...newBrandNamesAfterCreateProduct[indexBrandNameCreateProduct].priceListItemIds,
          newPriceListItem.id,
        ];

        newBrandNamesAfterCreateProduct[indexBrandNameCreateProduct] = {
          ...newBrandNamesAfterCreateProduct[indexBrandNameCreateProduct],
          priceListItemIds: newPriceListItemsForBrandAfterCreateUpdate,
        };
      }
    }
  }

  return newBrandNamesAfterCreateProduct;
}

function setMedicineNamesAfterCreateProduct(
  state: SearchableNamesState,
  medicineDto: Medicine,
  newPriceListItem: PricelistItem,
): MedicineNameId[] {
  const newMedicineNamesAfterCreateProduct = [...state.medicineNames];
  let newCreatedMedicineName;

  if (!medicineDto.id) {
    newCreatedMedicineName = {
      id: newPriceListItem.medicineId,
      medicineName: medicineDto.genericName,
      priceListItemIds: [newPriceListItem.id],
    };

    newMedicineNamesAfterCreateProduct.push(newCreatedMedicineName);
  } else {
    let indexMedicineNameCreateProduct = newMedicineNamesAfterCreateProduct.findIndex(
      (medicineName) => medicineName.id == medicineDto.id,
    );

    if (indexMedicineNameCreateProduct != -1) {
      const newPriceListItemsForMedicineAfterCreateUpdate = [
        ...newMedicineNamesAfterCreateProduct[indexMedicineNameCreateProduct].priceListItemIds,
        newPriceListItem.id,
      ];

      newMedicineNamesAfterCreateProduct[indexMedicineNameCreateProduct] = {
        ...newMedicineNamesAfterCreateProduct[indexMedicineNameCreateProduct],
        priceListItemIds: newPriceListItemsForMedicineAfterCreateUpdate,
      };
    }
  }

  return newMedicineNamesAfterCreateProduct;
}

 function setSupplierNamesAfterCreateProduct(
  state: SearchableNamesState,
  supplierFormDto: SupplierSelectorForm,
  newPriceListItem: PricelistItem,
): SupplierWithIds[] {
  const newSuppliersAfterCreateProduct = [...state.suppliers];

  let indexSupplierNameCreateProduct = newSuppliersAfterCreateProduct.findIndex(
    (supplier) => supplier.id == supplierFormDto.supplierId,
  );

  if (indexSupplierNameCreateProduct != -1) {
    let newPriceListItemsForSupplierAfterCreateProduct = [
      ...newSuppliersAfterCreateProduct[indexSupplierNameCreateProduct].priceListItemIds,
      newPriceListItem.id,
    ];

    newSuppliersAfterCreateProduct[indexSupplierNameCreateProduct] = {
      ...newSuppliersAfterCreateProduct[indexSupplierNameCreateProduct],
      priceListItemIds: newPriceListItemsForSupplierAfterCreateProduct,
    };
  }

  return newSuppliersAfterCreateProduct;
}
