import { Entity } from '../../../shared/models/entity';
import { EmailDocument } from './email-document';
import { MessageType } from '../enums/MessageType';

export class Email extends Entity {
  addressesTo: string[];
  addressesBCC: string[];
  addressesCC: string[];
  subject: string;
  body: string;
  sent: boolean;
  inProgress: boolean;
  retries: number;
  maxRetries: number;
  exception: string;
  document: EmailDocument;
  attachments: string[];
  orderId: number;
  orderSupplierId: number;
  providerName: string;
  supplierName: string;
  messageType: MessageType;

  constructor(data?: any, restore: boolean = false) {
    super(data, restore);

    if (data) {
      this.orderId = data.orderId;
      this.orderSupplierId = data.orderSupplierId;
      this.supplierName = data.supplierName;
      this.providerName = data.providerName;
      this.addressesTo = data.addressesTo;
      this.addressesBCC = data.addressesBCC;
      this.addressesCC = data.addressesCC;
      this.subject = data.subject;
      this.body = data.body;
      this.sent = data.sent;
      this.inProgress = data.inProgress;
      this.retries = data.retries;
      this.maxRetries = data.maxRetries;
      this.exception = data.exception;
      this.document = data.document;
      this.attachments = data.attachments;
      this.messageType = data.messageType;
    }
  }

  get addresses(): string {
    return [...this.addressesTo, ...this.addressesBCC, ...this.addressesCC].join(', ');
  }

  get status(): string {
    return !this.sent ? 'failed' : 'sent';
  }
}
