import { AfterViewInit, Directive, ElementRef, HostBinding, Inject, Input } from '@angular/core';
import { WINDOW } from '../../tokens/dom.tokens';

@Directive({
  selector: 'img[appLazyLoad]'
})
export class LazyLoadDirective implements AfterViewInit {
  @HostBinding('attr.src') srcAttr = null;
  @Input() src: string;

  constructor(private el: ElementRef, @Inject(WINDOW) private window: any) {}

  ngAfterViewInit() {
    this._canLazyLoad() ? this._lazyLoadImage() : this._loadImage();
  }

  private _canLazyLoad() {
    return this.window && this.window.hasOwnProperty('IntersectionObserver');
  }

  private _lazyLoadImage() {
    const obs = new this.window.IntersectionObserver(entries => {
      entries.forEach(({ isIntersecting }) => {
        if (isIntersecting) {
          this._loadImage();
          obs.unobserve(this.el.nativeElement);
        }
      });
    });
    obs.observe(this.el.nativeElement);
  }

  private _loadImage() {
    this.srcAttr = this.src;
  }
}
