import { Subject } from 'rxjs';
import { Crumb } from '../../interfaces/crumb.interface';
import { ORDERSTATUS, PERMISSION } from '../../constants';
import { Directive, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class BaseComponent implements OnInit, OnDestroy {
  protected _unsubscribe$: Subject<void>;
  public crumbs: Crumb[];
  public PERMISSION: any;
  public ORDERSTATUS: any;

  constructor() {
    this._unsubscribe$ = new Subject<void>();
    this.PERMISSION = PERMISSION;
    this.ORDERSTATUS = ORDERSTATUS;
  }

  ngOnInit(): void {
    if (environment.platformUnderMaintenance && !window.location.href.endsWith('/maintenance')) {
      window.location.href = '/maintenance';
    }
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
