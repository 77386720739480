<div class="admin-toggle" *transloco="let t">
  <span class="toggle-label">{{t('BUTTONS.MODULE_TOGGLE_LABEL')}}:</span>
  <mat-button-toggle-group name="adminToggle" aria-label="Toggle between admin and orders" [value]="currentModule">
    <mat-button-toggle *ngFor="let config of moduleConfig"
      [checked]="currentModule === config.module"
      [value]="config.module"
      [routerLink]="config.url"
    >
      {{t('BUTTONS.MODULE_TOGGLE_' + config.module.replace('-', '_').toUpperCase())}}
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
