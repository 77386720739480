import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EditOverviewEffects } from './edit-overview.effects';
import { EditOverviewReducer } from './edit-overview.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('editOverview', EditOverviewReducer),
    EffectsModule.forFeature([EditOverviewEffects]),
  ],
})
export class EditOverviewStoreModule {}
