import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { SettingsService } from './providers/settings/settings.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './providers/auth-interceptor/auth-interceptor';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiService } from './providers/api/api.service';
import { AuthService } from './providers/auth/auth.service';
import { LoaderService } from './providers/loader/loader.service';
import { NotificationService } from './providers/notification/notification.service';
import { TranslocoModule } from '@ngneat/transloco';
import { HeaderComponent } from './components/header/header.component';
import { FaqComponent } from './components/faq/faq.component';
import { CollapseDirective } from './directives/collapse/collapse';
import { VideoDialogComponent } from './components/dialogs/video-dialog/video-dialog.component';
import { VideoService } from './providers/video/video.service';
import { AuthGuard } from './guards/auth/auth.guard';
import { ForgetPasswordDialogComponent } from './components/dialogs/forget-password-dialog/forget-password-dialog.component';
import { DOM_PROVIDERS } from './tokens/dom.tokens';
import { RecoverEmailDialogComponent } from './components/dialogs/recover-email-dialog/recover-email-dialog.component';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { IconService } from './providers/icon/icon.service';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { NotificationComponent } from './components/notification/notification.component';
import { LoaderDialogComponent } from './components/dialogs/loader-dialog/loader-dialog.component';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatCardModule } from '@angular/material/card';
import { SafeHtmlPipe } from './pipes/safe-html/safe-html.pipe';
import { SafeStylePipe } from './pipes/safe-style/safe-style.pipe';
import { SafeResourcePipe } from './pipes/safe-resource/safe-resourc.pipe';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { PermissionGuard } from './guards/permission/permission.guard';
import { PermissionDirective } from './directives/permission/permission.directive';
import { ConfirmationDialogComponent } from './components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { AuthHeaderComponent } from './components/auth-header/auth-header.component';
import { ProfileDialogComponent } from './components/dialogs/profile-dialog/profile-dialog.component';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { AlertComponent } from './components/alert/alert.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ModuleToggleComponent } from './components/module-toggle/module-toggle.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouteTransformerDirective } from './directives/route-transformer/route-transformer.directive';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { NumbersOnlyDirective } from './directives/numbers-only/numbers-only.directive';
import { OrderMessageDialogComponent } from './components/dialogs/order-message-dialog/order-message-dialog.component';
import { MonitoringService } from './providers/monitoring/monitoring.service';
import { ErrorHandlerService } from './providers/error-handler/error-handler.service';
import { QuantityFilterPipe } from './pipes/quantity-filter/quantity-filter.pipe';
import { TrashPipe } from './pipes/trash/trash.pipe';
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule } from '@angular/material/radio';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MessagesComponent } from './components/messages/messages.component';
import { HomeActionsComponent } from './components/home-actions/home-actions.component';
import { MobileDirective } from './directives/mobile/mobile.directive';
import { DesktopDirective } from './directives/desktop/desktop.directive';
import { TelInputComponent } from './components/tel-input/tel-input.component';
import { OrganizationTypeGuard } from './guards/organization-type/organization-type.guard';
import { OrderByPipe } from './pipes/order-by/order-by.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { HighlightPipe } from './pipes/highlight/highlight.pipe';
import { LazyLoadDirective } from './directives/lazy-load/lazy-load.directive';
import { ConfirmDialog } from './providers/confirm-dialog/confirm-dialog';
import { FormErrorsComponent } from './components/forms/form-errors/form-errors.component';
import { GoogleAnalyticsService } from './providers/google-analytics/google-analytics.service';
import { WhitelabelDirective } from './directives/whitelabel/whitelabel.directive';
import { DragAndDropDirective } from './directives/drag-and-drop/drag-and-drop.directive';
import { UploadedPodListComponent } from './components/uploaded-pod-list/uploaded-pod-list.component';
import { BaseFilterComponent } from './components/base-filter/base-filter.component';

const modules = [
  ReactiveFormsModule,
  FormsModule,
  CommonModule,
  RouterModule,
  TranslocoModule,
  MatDialogModule,
  MatToolbarModule,
  MatButtonModule,
  MatIconModule,
  MatMenuModule,
  MatSelectModule,
  MatListModule,
  MatCheckboxModule,
  MatProgressSpinnerModule,
  MatExpansionModule,
  MatInputModule,
  MatSnackBarModule,
  FontAwesomeModule,
  MatCardModule,
  MatTooltipModule,
  MatSlideToggleModule,
  MatChipsModule,
  MatButtonToggleModule,
  MatTabsModule,
  MatSidenavModule,
  MatAutocompleteModule,
  MatRadioModule,
  MatDatepickerModule,
  MatNativeDateModule,
  InfiniteScrollModule
];

const providers = [
  SettingsService,
  ApiService,
  AuthService,
  LoaderService,
  NotificationService,
  VideoService,
  DOM_PROVIDERS,
  IconService,
  MonitoringService,
  DatePipe,
  CurrencyPipe,
  ConfirmDialog,
  GoogleAnalyticsService
];

const components = [
  HeaderComponent,
  FaqComponent,
  VideoDialogComponent,
  ForgetPasswordDialogComponent,
  RecoverEmailDialogComponent,
  NotificationComponent,
  LoaderDialogComponent,
  BreadcrumbComponent,
  ConfirmationDialogComponent,
  AuthHeaderComponent,
  ProfileDialogComponent,
  AlertComponent,
  ModuleToggleComponent,
  SideMenuComponent,
  OrderMessageDialogComponent,
  MessagesComponent,
  HomeActionsComponent,
  TelInputComponent,
  FormErrorsComponent,
  BaseFilterComponent
];

const directives = [
  CollapseDirective,
  PermissionDirective,
  RouteTransformerDirective,
  NumbersOnlyDirective,
  MobileDirective,
  DesktopDirective,
  LazyLoadDirective,
  WhitelabelDirective,
  DragAndDropDirective
];

const guards = [
  AuthGuard,
  PermissionGuard,
  OrganizationTypeGuard
];

const pipes = [
  SafeHtmlPipe,
  SafeStylePipe,
  SafeResourcePipe,
  QuantityFilterPipe,
  TrashPipe,
  OrderByPipe,
  HighlightPipe
];

@NgModule({
  declarations: [
    ...components,
    ...directives,
    ...pipes,
    UploadedPodListComponent,
  ],
  imports: [
    modules,
  ],
  exports: [
    ...modules,
    ...components,
    ...directives,
    ...pipes,
    UploadedPodListComponent
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ...providers,
        ...guards,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
        {
          provide: MAT_DIALOG_DEFAULT_OPTIONS,
          useValue: {
            hasBackdrop: true,
            disableClose: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: 'dialog'
          }
        },
        {
          provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
          useValue: {
            duration: 4000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center',
            action: 'Close',
            panelClass: 'default'
          }
        },
        {
          provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
          useValue: {
            floatLabel: 'auto',
            appearance: 'fill'
          }
        },
        {
          provide: MAT_RADIO_DEFAULT_OPTIONS,
          useValue: {color: 'primary'},
        },
        {
          provide: ErrorHandler,
          useClass: ErrorHandlerService
        },
        {
          provide: MAT_DATE_LOCALE,
          useValue: 'en-GH'
        },
        {
          provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
          useValue: {position: 'above'}
        }
      ]
    };
  }
}
