import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthStoreModule } from './auth/auth-store.module';
import { AppStoreModule } from './app/app-store.module';
import { StoreModule } from '@ngrx/store';
import { META_REDUCERS } from './meta-reducers';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../environments/environment';
import { OrdersStoreModule } from './orders/orders-store.module';
import { AdminStoreModule } from './admin/admin-store.module';
import { OrderMonitorStoreModule } from './order-monitor/order-monitor-store.module';
import { CmsStoreModule } from './cms/cms-store.module';
import { EditOverviewStoreModule } from './edit-overview/edit-overview-store.module';
import { SearchableNamesStoreModule } from './searchable-names/searchable-names-store.module';
import { SelectionSearchableNamesStoreModule } from './selection-searchable-names/selection-searchable-names-store.module';
import { CreateOverviewStoreModule } from './create-overview/create-overview-store.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AuthStoreModule,
    AppStoreModule,
    AdminStoreModule,
    SearchableNamesStoreModule,
    SelectionSearchableNamesStoreModule,
    OrdersStoreModule,
    OrderMonitorStoreModule,
    CmsStoreModule,
    CreateOverviewStoreModule,
    EditOverviewStoreModule,
    StoreModule.forRoot({}, { metaReducers: META_REDUCERS }),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      name: 'Med4All DevTools',
      maxAge: 25,
      logOnly: environment.production,
      // stateSanitizer: () => ({ hidden: "Inclusion crashes devtools" })
    }),
  ],
})
export class RootStoreModule {}
