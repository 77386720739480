import { ENVIRONMENT } from '../app/shared/constants';

export const environment = {
  production: true,
  environment: ENVIRONMENT.TESTING,
  apiUrl: 'https://med4all-server.azurewebsites.net/api',
  authUrl: 'https://med4all-authorization-server.azurewebsites.net/oauth',
  appUrl: 'https://med4all-frontend-test.azurewebsites.net',
  oAuthRedirectUri: 'https://med4all-frontend-test.azurewebsites.net',
  oAuthClientId: 'med4all-client-new',
  oAuthClientSecret: 'password',
  namespace: 'med4all',
  linkedin: 'https://www.linkedin.com/company/pharm-access-foundation/',
  debug: false,
  instrumentationKey: 'f26e200f-d09f-487a-9749-2c492a494c55',
  analytics: 'UA-144863893-4',
  countryCode: '+233',
  mcfInfoUp2date: false,
  platformUnderMaintenance: false,
};

