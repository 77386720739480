import { Brand } from "@shared/models/brand";
import { Medicine } from "@shared/models/medicine";
import { Organization } from "@shared/models/organization";
import { ProductCatalog } from "@shared/models/product-catalog";

export interface CreateOverviewState {
  medicine: Medicine;
  brand: Brand;
  isLoading: boolean;
  isCreating: boolean;
  productCatalog: ProductCatalog[];
}

export const initialCreateOverviewState = {
  medicine: null,
  brand: null,
  isLoading: false,
  isCreating: false,
  productCatalog: []
}
