import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { AuthService } from '../../shared/providers/auth/auth.service';
import { REGEX } from '../../shared/constants';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password-page.component.html',
})
export class UpdatePasswordPage {
  passwordCtrl: UntypedFormControl;
  confirmPasswordCtrl: UntypedFormControl;

  constructor(private auth: AuthService, private route: ActivatedRoute) {
    this.passwordCtrl = new UntypedFormControl('', [Validators.required, Validators.pattern(REGEX.PASSWORD)]);
    this.confirmPasswordCtrl = new UntypedFormControl('', [Validators.required, Validators.pattern(REGEX.PASSWORD)]);
  }

  async updatePassword() {
    if (this.passwordCtrl.value === this.confirmPasswordCtrl.value) {
      const password: string = this.passwordCtrl.value;
      const token: string = this.route.snapshot.queryParams['token'];

      this.auth.resetPassword(token, password);
    }
  }


}
