import { Component } from '@angular/core';
import { SettingsService } from '../../providers/settings/settings.service';
import { SETTING } from '../../constants';
import { Store } from '@ngrx/store';
import { SetSideNavAction } from '../../../store/app/actions';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  authUrl: string;

  constructor(private settings: SettingsService, private store: Store) {
    let oAuthClientId = SETTING.OAUTH_CLIENT_ID;
    if (window.location.href?.startsWith('https://new.')) {
      oAuthClientId = SETTING.OAUTH_CLIENT_ID_TEMP;
    }

    if (environment.platformUnderMaintenance) {
      this.authUrl = '/maintenance';
      return;
    }

    this.authUrl = `${this.settings.get(SETTING.AUTH_URL)}/authorize?grant_type=authorization_code&response_type=code&client_id=${this.settings.get(oAuthClientId)}`;
  }

  openMenu() {
    this.store.dispatch(new SetSideNavAction());
  }
}
