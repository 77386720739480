import { cloneDeep } from 'lodash';
import { saveAs } from 'file-saver';
import { Observable, of } from 'rxjs';
import * as orderActions from './actions';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { User } from '../../shared/models/user';
import { Brand } from '../../shared/models/brand';
import { Order } from '../../shared/models/order';
import { selectAuthUser } from '../auth/selectors';
import { Keyword } from '../../shared/models/keyword';
import { Message } from '../../shared/models/message';
import { McfInfo } from '../../shared/models/mcf-info';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Medicine } from '../../shared/models/medicine';
import { ActivatedRoute, Router } from '@angular/router';
import { Pricelist } from '../../shared/models/pricelist';
import { Formulary } from '../../shared/models/formulary';
import { Inventory } from '../../shared/models/inventory';
import { Storeroom } from '../../shared/models/storeroom';
import { StockTake } from '../../shared/models/stock-take';
import { MedicineForm } from '../../shared/models/medicine-form';
import { ApiService } from '../../shared/providers/api/api.service';
import { StockTakeItem } from '../../shared/models/stock-take-item';
import { WithdrawalRequest } from '../../shared/models/withdrawal-request';
import { GenericFailureAction, GenericFailureExpectedAction, GenericSuccessAction } from '../app/actions';
import { OverviewAction } from '../../shared/interfaces/overview-action.interface';
import { SettingsService } from '../../shared/providers/settings/settings.service';
import { catchError, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import {
  FindNewPriceListItemsIfExistAction,
  GetOrderAdviceSuccessAction,
  OrdersActionTypes,
  SetPaymentMethodsAction,
  UpdateOrderStatusErrorAction,
} from './actions';
import {
  selectCart,
  selectFormulary,
  selectOrderAdvice,
  selectPreviouslyOrderedMedicines,
  selectStockTake,
} from './selectors';

import {
  ORDERSTATUS,
  ORGANIZATION_SETTING,
  ORGANIZATIONTYPE,
  PERMISSION,
  SETTING,
} from '../../shared/constants';
import { OrdersService } from '@app/orders/shared/providers/orders.service';
import { Document } from '../../shared/models/document';

@Injectable()
export class OrdersEffects {
  constructor(
    private actions: Actions,
    private apiService: ApiService,
    private orderService: OrdersService,
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private settings: SettingsService,
  ) {}

  createFormulary: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.CreateFormularyAction>(OrdersActionTypes.CREATE_FORMULARY),
      withLatestFrom(
        this.store.pipe(select(selectAuthUser)),
        this.store.pipe(select(selectFormulary)),
      ),
      map(([_, user, formulary]) => {
        if (formulary && formulary.organizationId === user.organization.id) {
          return new orderActions.CreateFormularySuccessAction(formulary);
        }
        return new orderActions.CreateFormularySuccessAction(
          new Formulary({ organizationId: user.organization.id }),
        );
      }),
    ),
  );

  addToFormulary: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.AddMedicineToFormularyAction>(
        OrdersActionTypes.ADD_MEDICINE_TO_FORMULARY,
      ),
      withLatestFrom(this.store.pipe(select(selectFormulary))),
      map(([action, formulary]) => {
        const newFormulary = new Formulary(cloneDeep(formulary), true);
        newFormulary.addMedicine(action.medicine, action.brand, action.forecast);
        return newFormulary;
      }),
      switchMap((formulary) =>
        (formulary.id != null
          ? this.apiService.updateFormulary(formulary)
          : this.apiService.createFormulary(formulary)
        ).pipe(
          map((res) => {
            this.store.dispatch(new GenericSuccessAction('MESSAGES.API.ADD_TO_FORMULARY_SUCCESS'));
            return new orderActions.UpdateFormularySuccessAction(new Formulary(res));
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  removeFromFormulary: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.RemoveMedicineFromFormularyAction>(
        OrdersActionTypes.REMOVE_MEDICINE_FROM_FORMULARY,
      ),
      withLatestFrom(this.store.pipe(select(selectFormulary))),
      map(([action, formulary]) => {
        const newFormulary = new Formulary(cloneDeep(formulary), true);
        newFormulary.removeMedicine(action.medicine, action.brand);
        return newFormulary;
      }),
      switchMap((formulary) =>
        this.apiService.updateFormulary(formulary).pipe(
          map((res) => {
            this.store.dispatch(
              new GenericSuccessAction('MESSAGES.API.REMOVE_FROM_FORMULARY_SUCCESS'),
            );
            return new orderActions.UpdateFormularySuccessAction(new Formulary(res));
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  getFormulary: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.GetFormularyAction>(OrdersActionTypes.GET_FORMULARY),
      withLatestFrom(this.store.pipe(select(selectFormulary))),
      switchMap(([action, formulary]) => {
        if (formulary) {
          return of(new orderActions.GetFormularySuccessAction(formulary));
        }

        return this.apiService.getFormulary(action.payload).pipe(
          map((res) => new orderActions.GetFormularySuccessAction(new Formulary(res))),
          catchError((err) => of(new GenericFailureAction(err))),
        );
      }),
    ),
  );

  updateFormulary: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.UpdateFormularyAction>(OrdersActionTypes.UPDATE_FORMULARY),
      withLatestFrom(this.store.pipe(select(selectFormulary))),
      filter(([action, formulary]) => {
        return (
          formulary.formularyItems.find(
            (_item) => _item.brandId === action.brand.id && _item.medicineId === action.medicine.id,
          ) !== undefined
        );
      }),
      map(([action, formulary]) => {
        const newFormulary = new Formulary(cloneDeep(formulary), true);
        // update the forecast of the formulary item
        if (action.forecast > 0) {
          const formularyItem = newFormulary.formularyItems.find(
            (_item) => _item.brandId === action.brand.id && _item.medicineId === action.medicine.id,
          );

          if (formularyItem) {
            formularyItem.forecast = action.forecast;
          }
        }
        return newFormulary;
      }),
      switchMap((newFormulary: Formulary) =>
        this.apiService.updateFormulary(newFormulary).pipe(
          tap(() =>
            this.store.dispatch(new GenericSuccessAction('MESSAGES.API.UPDATE_FORMULARY_SUCCESS')),
          ),
          map((res) => new orderActions.UpdateFormularySuccessAction(new Formulary(res))),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  getMedicines: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.GetMedicinesAction>(OrdersActionTypes.GET_MEDICINES),
      switchMap((action) =>
        this.apiService.getMedicines(action.params).pipe(
          map((res) => {
            const medicines: Medicine[] = res.map((medicine) => new Medicine(medicine));
            return new orderActions.GetMedicinesSuccessAction(medicines);
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  getPreviouslyOrderedMedicines: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.GetPreviouslyOrderedMedicinesAction>(
        OrdersActionTypes.GET_PREVIOUSLY_ORDERED_MEDICINES,
      ),
      withLatestFrom(this.store.pipe(select(selectPreviouslyOrderedMedicines))),
      switchMap(([action, cachedMedicines]) => {
        if (cachedMedicines && cachedMedicines.length > 0) {
          return of(new orderActions.GetPreviouslyOrderedMedicinesSuccessAction(cachedMedicines));
        }

        return this.apiService.getPreviouslyOrderedMedicines(action.params).pipe(
          map((res) => {
            const medicines: Medicine[] = res.map((medicine) => new Medicine(medicine));
            return new orderActions.GetPreviouslyOrderedMedicinesSuccessAction(medicines);
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        );
      }),
    ),
  );

  getOrderAdvice: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.GetOrderAdviceAction>(OrdersActionTypes.GET_ORDER_ADVICE),
      withLatestFrom(this.store.pipe(select(selectOrderAdvice))),
      switchMap(([action, orderAdvice]) => {
        if (orderAdvice && orderAdvice.length > 0) {
          return of(new GetOrderAdviceSuccessAction(orderAdvice));
        }

        return this.apiService.getOrderAdvice(action.params).pipe(
          map((res) => {
            const medicines: Medicine[] = res.map((medicine) => new Medicine(medicine));
            return new orderActions.GetOrderAdviceSuccessAction(medicines);
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        );
      }),
    ),
  );

  createMedicine: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.CreateMedicineAction>(OrdersActionTypes.CREATE_MEDICINE),
      switchMap((action) =>
        this.apiService.createMedicine(action.payload).pipe(
          map((res) => new orderActions.CreateMedicineSuccessAction(new Medicine(res))),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  getMedicineForms: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.GetMedicineFormsAction>(OrdersActionTypes.GET_MEDICINE_FORMS),
      switchMap(() =>
        this.apiService.getMedicineForms().pipe(
          map((res) => {
            const forms: MedicineForm[] = res.map((form) => new MedicineForm(form));
            return new orderActions.GetMedicineFormsSuccessAction(forms);
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  getBrands: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.GetBrandsAction>(OrdersActionTypes.GET_BRANDS),
      switchMap(() =>
        this.apiService.getBrands().pipe(
          map((res) => {
            const brands: Brand[] = res.map((brand) => new Brand(brand));
            return new orderActions.GetBrandsSuccessAction(brands);
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  createBrand: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.CreateBrandAction>(OrdersActionTypes.CREATE_BRAND),
      switchMap((action) =>
        this.apiService.createBrand(action.payload).pipe(
          map((res) => new orderActions.CreateBrandSuccessAction(new Brand(res))),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  getKeywords: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.GetKeywordsAction>(OrdersActionTypes.GET_KEYWORDS),
      switchMap(() =>
        this.apiService.getKeywords().pipe(
          map((res) => {
            const keywords: Keyword[] = res.map((keyword) => new Keyword(keyword));
            return new orderActions.GetKeywordsSuccessAction(keywords);
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  createKeywords: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.CreateKeywordsAction>(OrdersActionTypes.CREATE_KEYWORDS),
      switchMap((action) =>
        this.apiService.createKeywords(action.payload).pipe(
          map((res) => new orderActions.CreateKeywordsSuccessAction(new Keyword(res))),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  getPricelists: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.GetPricelistsAction>(OrdersActionTypes.GET_PRICELISTS),
      switchMap(() =>
        this.apiService.getPricelists().pipe(
          map((res) => {
            const pricelists: Pricelist[] = res.map((pricelist) => new Pricelist(pricelist));
            return new orderActions.GetPricelistsSuccessAction(pricelists);
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  getPrescribingLevels: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.GetPrescribingLevelsAction>(OrdersActionTypes.GET_PRESCRIBING_LEVELS),
      switchMap(() =>
        this.apiService.getPrescribingLevels().pipe(
          map((res) => new orderActions.GetPrescribingLevelsSuccessAction(res)),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  getLatestStockTake: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.GetLatestStockTakeAction>(OrdersActionTypes.GET_LATEST_STOCK_TAKE),
      withLatestFrom(
        this.store.pipe(select(selectAuthUser)),
        this.store.pipe(select(selectStockTake)),
      ),
      filter(([_, user]) => user.organization.type === ORGANIZATIONTYPE.FACILITY),
      switchMap(([_, user, cachedStockTake]) => {
        if (cachedStockTake && cachedStockTake.organizationId === user.organization.id) {
          return of(new orderActions.GetLatestStockTakeSuccessAction(cachedStockTake));
        }

        return this.apiService.getLatestStockTake(user.organization.id).pipe(
          map((res) => {
            const stockTake: StockTake = new StockTake(res);
            return new orderActions.GetLatestStockTakeSuccessAction(stockTake);
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        );
      }),
    ),
  );

  getStockTakes: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.GetStockTakesAction>(OrdersActionTypes.GET_STOCK_TAKES),
      switchMap((action) =>
        this.apiService.getStockTakes(action.pageNumber, action.pageSize, action.filter).pipe(
          take(1),
          map((paginatedStockTakes) => {
            paginatedStockTakes.content = paginatedStockTakes.content.map(
              (stockTake) => new StockTake(stockTake),
            );

            return new orderActions.GetStockTakesSuccessAction(paginatedStockTakes);
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  mergeStockTakes: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.MergeStockTakeAction>(OrdersActionTypes.MERGE_STOCK_TAKES),
      switchMap((action) =>
        this.apiService.createInventory(action.stockTakeIds).pipe(
          take(1),
          map((inventory) => {
            this.store.dispatch(new GenericSuccessAction('MESSAGES.API.MERGE_INVENTORY_SUCCESS'));
            return new orderActions.MergeStockTakeSuccessAction(new Inventory(inventory));
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  requestRecount: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.RequestRecountAction>(OrdersActionTypes.REQUEST_RECOUNT),
      switchMap((action) =>
        this.apiService.requestRecount(action.stockTakeItem).pipe(
          take(1),
          map((stockTakeItem) => {
            this.store.dispatch(new GenericSuccessAction('MESSAGES.API.REQUEST_RECOUNT_SUCCESS'));
            return new orderActions.RequestRecountSuccessAction(new StockTakeItem(stockTakeItem));
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  exportStockTakes: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.ExportStockTakesAction>(OrdersActionTypes.EXPORT_STOCK_TAKES_ACTION),
      switchMap((action) =>
        this.apiService.exportStockTakes(action.ids).pipe(
          take(1),
          map((response) => {
            const blob = new Blob([response], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const filename = 'Stocktakes';
            saveAs(blob, filename);

            return new orderActions.ExportStockTakesSuccessAction();
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  approveStockTakes: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.ApproveStockTakesAction>(OrdersActionTypes.APPROVE_STOCK_TAKES_ACTION),
      switchMap((action) =>
        this.apiService.approveStockTakes(action.ids).pipe(
          take(1),
          map((stockTakes) => {
            this.store.dispatch(
              new GenericSuccessAction('MESSAGES.API.APPROVE_STOCK_TAKE_SUCCESS'),
            );
            return new orderActions.ApproveStockTakesSuccessAction(stockTakes);
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  updateStockTake: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.UpdateStockTakeAction>(OrdersActionTypes.UPDATE_STOCK_TAKE_ACTION),
      switchMap((action) =>
        this.apiService.updateStockTake(action.id, action.stockTakeItems).pipe(
          take(1),
          map((stockTake) => {
            this.store.dispatch(new GenericSuccessAction('MESSAGES.API.UPDATE_STOCK_TAKE_SUCCESS'));
            return new orderActions.UpdateStockTakeActionSuccess(stockTake);
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  deleteStockTakeItem: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.DeleteStockTakeItemAction>(
        OrdersActionTypes.DELETE_STOCK_TAKE_ITEM_ACTION,
      ),
      switchMap((action) =>
        this.apiService.deleteStockTakeItem(action.id).pipe(
          take(1),
          map(() => {
            this.store.dispatch(new GenericSuccessAction('MESSAGES.API.DELETE_STOCK_TAKE_SUCCESS'));
            return new orderActions.DeleteStockTakeItemActionSuccess(action.id);
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  getInventories: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.GetInventoriesAction>(OrdersActionTypes.GET_INVENTORIES),
      switchMap((action) =>
        this.apiService.getInventories(action.pageNumber, action.pageSize, action.filter).pipe(
          take(1),
          map((paginatedInventories) => {
            paginatedInventories.content.map((inventory) => new Inventory(inventory));

            return new orderActions.GetInventoriesSuccessAction(paginatedInventories);
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  exportInventories: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.ExportInventoriesAction>(OrdersActionTypes.EXPORT_INVENTORIES_ACTION),
      switchMap((action) =>
        this.apiService.exportInventories(action.ids).pipe(
          take(1),
          map((response) => {
            const blob = new Blob([response], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const filename = 'Inventories';
            saveAs(blob, filename);

            return new orderActions.ExportInventoriesSuccessAction();
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  getMessages: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.GetMessagesAction>(OrdersActionTypes.GET_MESSAGES),
      switchMap(() =>
        this.apiService.getMessages().pipe(
          take(1),
          map((res) => {
            const messages: Message[] = res.map((message) => new Message(message));
            return new orderActions.GetMessagesSuccessAction(messages);
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  createMessage: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.CreateMessageAction>(OrdersActionTypes.CREATE_MESSAGE),
      withLatestFrom(this.store.pipe(select(selectAuthUser))),
      switchMap(([action, user]) =>
        this.apiService.createMessage({ ...action.payload, messageByUserId: user.id }).pipe(
          map((res) => new orderActions.CreateMessageSuccessAction(new Message(res))),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  updateMessage: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.UpdateMessageAction>(OrdersActionTypes.UPDATE_MESSAGE),
      switchMap((action) =>
        this.apiService.updateMessage(action.payload).pipe(
          map((res) => new orderActions.UpdateMessageSuccessAction(new Message(res))),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  removeMessage: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.RemoveMessageAction>(OrdersActionTypes.REMOVE_MESSAGE),
      switchMap((action) =>
        this.apiService.removeMessage(action.payload).pipe(
          map((res) => {
            this.store.dispatch(new GenericSuccessAction(res));
            return new orderActions.RemoveMessageSuccessAction(action.payload);
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  addToCart: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.AddMedicineToCartAction>(OrdersActionTypes.ADD_MEDICINE_TO_CART),
      withLatestFrom(this.store.pipe(select(selectCart)), this.store.pipe(select(selectAuthUser))),
      map(([action, cart, user]) => {
        const organizationId = this.route.snapshot.queryParams.organizationId;
        const newCart: Order = new Order(
          {
            ...cloneDeep(cart),
            organization: { ...user.organization, id: organizationId ?? user.organization.id },
          },
          true,
        );
        newCart.addMedicine(action.medicine, action.brand, action.supplier, action.quantity);
        return newCart;
      }),
      switchMap((newCart: Order) =>
        (newCart.id
          ? this.apiService.updateOrder(newCart)
          : this.apiService.createOrder(newCart)
        ).pipe(
          map((res) => new orderActions.UpdateCartSuccessAction(new Order(res))),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  removeFromCart: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.RemoveMedicineFromCartAction>(
        OrdersActionTypes.REMOVE_MEDICINE_FROM_CART,
      ),
      withLatestFrom(this.store.pipe(select(selectCart))),
      map(([action, cart]) => {
        const newCart = new Order(cloneDeep(cart), true);
        newCart.removeMedicine(action.medicine, action.brand, action.supplier);
        return newCart;
      }),
      switchMap((newCart: Order) =>
        this.apiService.updateOrder(newCart).pipe(
          map((res) => new orderActions.UpdateCartSuccessAction(new Order(res))),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  changePriceListItemSupplierInCart: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.ChangePriceListItemSupplierCartAction>(
        OrdersActionTypes.CHANGE_PRICELISTITEM_SUPPLIER_IN_CART,
      ),
      withLatestFrom(this.store.pipe(select(selectCart)), this.store.pipe(select(selectAuthUser))),
      map(([action, cart, user]) => {
        const organizationId = this.route.snapshot.queryParams.organizationId;
        const newCart: Order = new Order(
          {
            ...cloneDeep(cart),
            organization: { ...user.organization, id: organizationId ?? user.organization.id },
          },
          true,
        );

        newCart.orderMedicines.forEach((item) => {
          if (
            item.medicine.id === action.oldPriceListItem.medicine.id &&
            item.brand.id === action.oldPriceListItem.brand.id &&
            item.orderSupplier.supplier.id === action.oldPriceListItem.supplier.id
          ) {
            item.priceListItem = action.newPriceListItem;
            item.quantity = action.quantity;
          }
        });

        return newCart;
      }),
      switchMap((newCart: Order) =>
        this.apiService.updateOrder(newCart).pipe(
          map((res) => new orderActions.UpdateCartSuccessAction(new Order(res))),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  updateCart: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.UpdateCartAction>(OrdersActionTypes.UPDATE_CART),
      withLatestFrom(this.store.pipe(select(selectCart))),
      map(([action, cart]) => {
        const newCart = new Order(cloneDeep(cart), true);

        // update the quantity of the cart item
        if (action.payload.quantity > 0) {
          const orderItem = this.orderService.getOrderItemFromOrderMedicines(
            newCart.orderMedicines,
            action.payload.medicine,
            action.payload.brand,
            action.payload.orderSupplier.supplier,
          );

          if (orderItem) {
            // Set Modifiable items
            orderItem.quantity = action.payload.quantity;
          }
        } else {
          // if the quantity is 0, remove the cart item
          newCart.removeMedicine(
            action.payload.medicine,
            action.payload.brand,
            action.payload.orderSupplier.supplier,
          );
        }
        return newCart;
      }),
      switchMap((newCart: Order) =>
        this.apiService.updateOrder(newCart).pipe(
          tap(() =>
            this.store.dispatch(new GenericSuccessAction('MESSAGES.API.UPDATE_CART_MESSAGE')),
          ),
          map((res) => new orderActions.UpdateCartSuccessAction(new Order(res))),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  approveNewUnitPrice: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.ApproveNewUnitPriceAction>(OrdersActionTypes.APPROVE_NEW_UNIT_PRICE),
      withLatestFrom(this.store.pipe(select(selectCart))),
      map(([action, cart]) => {
        const newCart = new Order(cloneDeep(cart), true);

        newCart.orderMedicines.forEach((x) => {
          if (x.priceListItem.id === action.payload.oldPriceListItemId) {
            x.priceListItem = action.payload.newPriceListItem;
            return;
          }
        });

        return newCart;
      }),
      switchMap((newCart: Order) =>
        this.apiService.updateOrder(newCart).pipe(
          tap(() =>
            this.store.dispatch(new GenericSuccessAction('MESSAGES.API.UPDATE_CART_MESSAGE')),
          ),
          map((res) => new orderActions.UpdateCartSuccessAction(new Order(res))),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  clearCart: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.ClearCartAction>(OrdersActionTypes.CLEAR_CART),
      withLatestFrom(this.store.pipe(select(selectCart)), this.store.pipe(select(selectAuthUser))),
      map(([_, cart, user]) => {
        const organizationId = this.route.snapshot.queryParams.organizationId;
        return new Order(
          {
            id: cart.id,
            organization: { ...user.organization, id: organizationId ?? user.organization.id },
          },
          true,
        );
      }),
      switchMap((newCart: Order) =>
        this.apiService.updateOrder(newCart).pipe(
          map((res) => new orderActions.UpdateCartSuccessAction(new Order(res))),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  filterCart: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.FilterCartAction>(OrdersActionTypes.FILTER_CART),
      withLatestFrom(this.store.pipe(select(selectCart))),
      map(
        ([_, cart]) =>
          new Order(
            {
              ...cloneDeep(cart),
              orderMedicines: cart.orderMedicines.filter(
                (medicine) => !medicine.priceListItem.outdated,
              ),
            },
            true,
          ),
      ),
      switchMap((newCart: Order) =>
        this.apiService.updateOrder(newCart).pipe(
          map((res) => {
            this.store.dispatch(new GenericSuccessAction('MESSAGES.API.FILTER_CART_SUCCESS'));
            return new orderActions.UpdateCartSuccessAction(new Order(res));
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  getCart: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.GetCartAction>(OrdersActionTypes.GET_CART),
      switchMap((action) =>
        this.apiService
          .getOrders(
            {
              organizationId: action.payload,
              type: 'current',
              status: ORDERSTATUS.DRAFT,
            },
            true,
          )
          .pipe(
            map((res) => {
              const cart: Order = res.length > 0 ? new Order(res[0]) : new Order({});
              return new orderActions.GetCartSuccessAction(cart);
            }),
            catchError((err) => of(new GenericFailureAction(err))),
          ),
      ),
    ),
  );

  updateOrderStatus: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.UpdateOrderStatusAction>(OrdersActionTypes.UPDATE_ORDER_STATUS),
      switchMap((action) =>
        this.apiService
          .updateOrderStatus(action.orderId, action.status, action.message, action.orderSupplierIds)
          .pipe(
            map((res) => {
              this.store.dispatch(
                new GenericSuccessAction('MESSAGES.API.UPDATE_ORDER_STATUS_SUCCESS'),
              );
              this.router.navigate(['/orders/overview'], { queryParamsHandling: 'merge' });
              return new orderActions.UpdateOrderStatusSuccessAction(new Order(res));
            }),
            catchError((err) =>
              of(new UpdateOrderStatusErrorAction(action.orderId, err))
            ),
          ),
      ),
    ),
  );

  updateOrderStatusErrorFindNewPriceListItems: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.UpdateOrderStatusErrorAction>(OrdersActionTypes.UPDATE_ORDER_STATUS_ERROR),
      switchMap((action) =>
          of(new FindNewPriceListItemsIfExistAction(action.orderId))
      ),
    ),
  );

  updateOrderStatusErrorGenericFailureMessage: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.UpdateOrderStatusErrorAction>(OrdersActionTypes.UPDATE_ORDER_STATUS_ERROR),
      switchMap((action) =>
        of(new GenericFailureExpectedAction(action.error))
      ),
    ),
  );

  getCurrentOrders: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.GetCurrentOrdersAction>(OrdersActionTypes.GET_CURRENT_ORDERS),
      withLatestFrom(this.store.pipe(select(selectCart)), this.store.pipe(select(selectAuthUser))),
      switchMap(([_, cart, user]) =>
        this.apiService
          .getOrders({ organizationId: user.organization.id, type: 'current' }, true)
          .pipe(
            map((res) => {
              const orders: Order[] = res.map((order) => new Order(order));

              // if the user is allowed to create orders, there is a cart and the cart is not yet stored in the database, add it to the orders
              if (
                user.hasPermission(PERMISSION.ORDERS.CREATE) &&
                orders.find((_order) => _order.currentOrderStatus.status == ORDERSTATUS.DRAFT) === undefined
              ) {
                orders.unshift(new Order({}));
              }

              return new orderActions.GetCurrentOrdersSuccessAction(orders);
            }),
            catchError((err) => of(new GenericFailureAction(err))),
          ),
      ),
    ),
  );

  getPreviousOrders: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.GetPreviousOrdersAction>(OrdersActionTypes.GET_PREVIOUS_ORDERS),
      withLatestFrom(this.store.pipe(select(selectAuthUser))),
      switchMap(([_, user]) =>
        this.apiService
          .getOrders({ organizationId: user.organization.id, type: 'previous' }, true)
          .pipe(
            map((res) => {
              const orders: Order[] = res.map((order) => new Order(order));
              return new orderActions.GetPreviousOrdersSuccessAction(orders);
            }),
            catchError((err) => of(new GenericFailureAction(err))),
          ),
      ),
    ),
  );

  getOrder: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.GetOrderAction>(OrdersActionTypes.GET_ORDER),
      switchMap((action) =>
        this.apiService.getOrder(action.orderId, action.filter).pipe(
          map(
            (res) =>
              new orderActions.GetOrderSuccessAction(
                new Order({ ...res, subOrderId: action.filter.subOrderId }),
              ),
          ),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  updateOrder: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.UpdateOrderAction>(OrdersActionTypes.UPDATE_ORDER),
      switchMap((action) =>
        this.apiService.updateOrder(action.payload).pipe(
          map((res) => {
            this.router.navigate(['/orders/overview'], { queryParamsHandling: 'merge' });
            return new orderActions.UpdateOrderSuccessAction(new Order(res));
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  getDraftOrder: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.GetDraftOrder>(OrdersActionTypes.GET_DRAFT_ORDER),
      switchMap((action) => {
        const order = action.payload;
        let orderIsInCart = false;

        if (!order || !order.id) {
          this.router.navigate([`/orders/medicines`], {
            queryParamsHandling: 'merge',
          });

          return of(new orderActions.GetDraftOrderSuccess(new Order()));
        }

        this.store.pipe(select(selectCart)).pipe(take(1)).subscribe(x => {
          orderIsInCart = x.id == order.id;

          if (orderIsInCart) {
            this.router.navigate([`/orders/medicines`], {
              queryParamsHandling: 'merge',
            });

            return new orderActions.GetDraftOrderSuccess(new Order(x));
          }
        });

        return this.apiService.getOrder(order.id).pipe(
          map((res) => {
            this.router.navigate([`/orders/medicines`], {
              queryParamsHandling: 'merge',
            });
            return new orderActions.GetDraftOrderSuccess(new Order(res));
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        );
      }),
    ),
  );

  modifyOrder: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.ModifyOrderAction>(OrdersActionTypes.MODIFY_ORDER),
      switchMap((action) =>
        this.apiService.getOrder(action.payload).pipe(
          map((res) => {
            this.router.navigate([`/orders/order/${action.payload}/modify`], {
              queryParamsHandling: 'merge',
            });
            return new orderActions.ModifyOrderSuccessAction(new Order(res));
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  updateProForma: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.UpdateProFormaAction>(OrdersActionTypes.UPDATE_PROFORMA),
      switchMap((action) =>
        this.apiService
          .updateProForma(action.orderId, action.status, {
            orderSupplierId: action.orderSupplierId,
            orderMedicines: action.orderMedicines,
            message: action.message,
          })
          .pipe(
            map((res) => {
              this.store.dispatch(
                new GenericSuccessAction(`MESSAGES.API.${action.status.toUpperCase()}_SUCCESS`),
              );
              this.router.navigate(['/orders/overview'], { queryParamsHandling: 'merge' });
              return new orderActions.UpdateProFormaSuccessAction(new Order(res));
            }),
            catchError((err) => of(new GenericFailureAction(err))),
          ),
      ),
    ),
  );

  getPaymentMethods: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.GetPaymentMethodsAction>(OrdersActionTypes.GET_PAYMENT_METHODS),
      switchMap(() => {
        return this.apiService.getPaymentMethods().pipe(
          map((res) => {
            let paymentMethods = res.sort((a, b) => (a.name > b.name ? 1 : -1));
            return new orderActions.GetPaymentMethodsSuccessAction(paymentMethods);
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        );
      }),
    ),
  );

  setPaymentMethods: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.SetPaymentMethodsAction>(OrdersActionTypes.SET_PAYMENT_METHODS),
      switchMap((action) =>
        this.apiService.setPaymentMethods(action.orderId, action.paymentMethods).pipe(
          map((res) => {
            this.store.dispatch(
              new GenericSuccessAction('MESSAGES.API.SET_PAYMENT_METHODS_SUCCESS'),
            );
            this.router.navigate(['/orders/overview'], { queryParamsHandling: 'merge' });
            return new orderActions.SetPaymentMethodsSuccessAction(new Order(res));
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  getMcfInfo: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.GetMcfInfoAction>(OrdersActionTypes.GET_MCF_INFO),
      withLatestFrom(this.store.pipe(select(selectAuthUser))),
      switchMap(([_, user]) =>
        this.apiService.getMcfInfo(user.organization.id).pipe(
          map((res) => {
            let info: McfInfo = res.length > 0 ? new McfInfo(res[0]) : null;
            if (!info) {
              info = new McfInfo({
                organization: user.organization,
                mcfId: 0,
                totalCreditLimit: 0,
                totalOutstanding: 0,
                lastWithdrawalDate: null,
                creditSpace: 0,
                indicative: true,
              });
            }

            return new orderActions.GetMcfInfoSuccessAction(info);
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  updateShipment: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.UpdateShipmentAction>(OrdersActionTypes.UPDATE_SHIPMENT),
      switchMap((action) =>
        this.apiService
          .updateShipment(
            action.orderId,
            action.orderSupplierId,
            action.orderMedicines,
            action.status,
            action.fullName,
            action.email,
            action.filter,
            action.subShipments,
          )
          .pipe(
            map((res) => {
              this.router.navigate(['/orders/overview'], { queryParamsHandling: 'merge' });
              return new orderActions.UpdateShipmentSuccessAction(new Order(res));
            }),
            catchError((err) => of(new GenericFailureAction(err))),
          ),
      ),
    ),
  );

  submitPod: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.SubmitPodAction>(OrdersActionTypes.SUBMIT_POD),
      switchMap((action) =>
        this.apiService.submitPod(action.orderId, action.orderSupplierId, action.files).pipe(
          map((res) => {
            this.store.dispatch(new GenericSuccessAction('MESSAGES.API.SUBMIT_POD_SUCCESS'));

            return new orderActions.SubmitPodSuccessAction();
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  submitDelivery: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.SubmitDeliveryAction>(OrdersActionTypes.SUBMIT_DELIVERY),
      switchMap((action) =>
        this.apiService
          .submitDelivery(
            action.orderId,
            action.orderSupplierId,
            action.orderMedicines,
            action.subDeliveries,
          )
          .pipe(
            map((res) => {
              this.store.dispatch(new GenericSuccessAction('MESSAGES.API.SUBMIT_DELIVERY_SUCCESS'));
              this.navigateBack();

              return new orderActions.SubmitDeliverySuccessAction(new Order(res));
            }),
            catchError((err) => of(new GenericFailureAction(err))),
          ),
      ),
    ),
  );

  cancelUndeliveredMedicines: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.CancelUndeliveredMedicinesAction>(
        OrdersActionTypes.CANCEL_UNDELIVERED_MEDICINES,
      ),
      switchMap((action) =>
        this.apiService
          .cancelUndeliveredMedicines(action.orderId, action.orderSupplierId, action.orderMedicines)
          .pipe(
            map((res) => {
              this.store.dispatch(
                new GenericSuccessAction('MESSAGES.API.CANCEL_UNDELIVERED_MEDICINES_SUCCESS'),
              );
              this.navigateBack();
              return new orderActions.CancelUndeliveredMedicinesSuccessAction(new Order(res));
            }),
            catchError((err) => of(new GenericFailureAction(err))),
          ),
      ),
    ),
  );

  getOverviewActions: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.GetOverviewActionsAction>(OrdersActionTypes.GET_OVERVIEW_ACTIONS),
      withLatestFrom(this.store.pipe(select(selectAuthUser))),
      switchMap(([_, user]) =>
        this.apiService.getOverview('orders').pipe(
          map((res) => {
            const actions: OverviewAction[] = res
              .map((_action) => {
                switch (_action.id) {
                  case 'numberOfOrdersAwaitingAttention':
                    return {
                      ..._action,
                      label: 'PAGES.ORDERS_HOME.ACTIONS.WAITING_APPROVAL',
                      link: '/orders/overview',
                      actionType: 'action',
                    };
                  case 'numberOfDaysSinceLastStockTake':
                    const frequency = user.organization.getSetting(
                      ORGANIZATION_SETTING.INVENTORY_FREQUENCY,
                    );
                    if (_action.number === -1) {
                      return {
                        ..._action,
                        label: 'PAGES.ORDERS_HOME.ACTIONS.NO_STOCK_TAKE',
                        actionType: 'action',
                      };
                    } else if (frequency > _action.number) {
                      return {
                        ..._action,
                        label: 'PAGES.ORDERS_HOME.ACTIONS.UP_TO_DATE_STOCK_TAKE',
                        actionType: 'action',
                      };
                    } else {
                      return {
                        ..._action,
                        label: 'PAGES.ORDERS_HOME.ACTIONS.LAST_STOCK_TAKE',
                        actionType: 'action',
                      };
                    }
                  case 'numberOfOrdersAwaitingActionColleague':
                    return {
                      ..._action,
                      label: 'PAGES.ORDERS_HOME.ORDER_UPDATES.ORDERS_WAITING',
                      actionType: 'order',
                    };
                  case 'numberOfOrdersAwaitingActionMed4all':
                    return {
                      ..._action,
                      label: 'PAGES.ORDERS_HOME.ORDER_UPDATES.ORDERS_WAITING_MED4ALL',
                      actionType: 'order',
                    };
                  case 'currentCreditSpace':
                    return {
                      ..._action,
                      label: 'PAGES.ORDERS_HOME.ORGANIZATION.CREDIT_SPACE',
                      actionType: 'organization',
                    };
                }
              }) //default actions, not from the API
              .concat([
                {
                  id: 'analytics',
                  actionType: 'organization',
                  label: 'PAGES.ORDERS_HOME.ORGANIZATION.ANALYTICS',
                },
                {
                  id: 'download',
                  actionType: 'document',
                  label: 'PAGES.ORDERS_HOME.DOCUMENTS.DOWNLOAD',
                  link: '/orders/documents',
                },
                {
                  id: 'fullOrderOverview',
                  actionType: 'order',
                  label: 'PAGES.ORDERS_HOME.ORDER_UPDATES.ORDER_OVERVIEW',
                  link: '/orders/overview',
                },
              ]);

            // temporarily disable the credit space action
            const idx = actions.findIndex((_action) => _action.id === 'currentCreditSpace');
            if (!this.settings.get(SETTING.MCF_INFO) && idx !== -1) {
              actions.splice(idx, 1, {
                id: 'currentCreditSpace',
                actionType: 'organization',
                resourceType: 'mcf',
                label: 'PAGES.ORDERS_HOME.ORGANIZATION.CREDIT_SPACE_TEMP',
              });
            }
            return new orderActions.GetOverviewActionsSuccessAction(actions);
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  getDocuments: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.GetDocumentsAction>(OrdersActionTypes.GET_DOCUMENTS),
      switchMap((action) =>
        this.apiService.getDocuments(
            action.filter,
            action.subOrderId,
            action.documentType,
            action.pageNumber,
            action.pageSize
          ).pipe(
          map((pageableDocuments) => {
            pageableDocuments.content.map((doc) => new Document(doc));
            return new orderActions.GetDocumentsSuccessAction(pageableDocuments);
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  removeDocument: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.RemoveDocumentAction>(OrdersActionTypes.REMOVE_DOCUMENT),
      switchMap((action) =>
        this.apiService.removeDocument(action.payload).pipe(
          map((res) => {
            this.store.dispatch(new GenericSuccessAction(res));
            return new orderActions.RemoveDocumentSuccessAction(action.payload);
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  downloadDocument: Observable<any> = createEffect(
    () =>
      this.actions.pipe(
        ofType<orderActions.DownloadDocumentAction>(OrdersActionTypes.DOWNLOAD_DOCUMENT),
        tap(async (action) => {
          try {
            const response = await fetch(action.url);
            const file = await response.blob();
            const extension = action.url.split('.').pop();
            let fileName = action.fileName;
            if (extension && extension !== action.url && !fileName.includes(extension)) {
              fileName = `${fileName}.${extension}`;
            }
            saveAs(file, fileName);
          } catch (err) {
            this.store.dispatch(new GenericFailureAction('PAGES.DOCUMENTS.FILE_NOT_FOUND'));
          }
        }),
      ),
    { dispatch: false },
  );

  createDocument: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.CreateDocumentAction>(OrdersActionTypes.CREATE_DOCUMENT),
      switchMap((action) =>
        this.apiService.createDocument(action.payload).pipe(
          map((res) => {
            const document: Document = new Document(res);
            return new orderActions.CreateDocumentSuccessAction(document);
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  createWithdrawalRequestAndPostPaymentMethods: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.CreateWithdrawalRequestAndSetPaymentMethodAction>(
        OrdersActionTypes.CREATE_WITHDRAWAL_REQUEST_AND_SET_PAYMENT_METHODS,
      ),
      switchMap((action) =>
        this.apiService.createWithdrawalRequest(action.withdrawalRequest).pipe(
          map((response) => {
            this.store.dispatch(new SetPaymentMethodsAction(action.orderId, action.paymentMethods));

            return new orderActions.CreateWithdrawalRequestAndSetPaymentMethodSuccessAction(
              new WithdrawalRequest(response),
            );
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  createWithdrawalRequest: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.CreateWithdrawalRequestAction>(
        OrdersActionTypes.CREATE_WITHDRAWAL_REQUEST,
      ),
      withLatestFrom(this.store.pipe(select(selectAuthUser))),
      switchMap(([action, user]) => {
        const request = new WithdrawalRequest({
          organizationId: user.organization.id,
          requestedWithdrawalValue: action.payload,
        });
        return this.apiService.createWithdrawalRequest(request).pipe(
          map(
            (response) =>
              new orderActions.CreateWithdrawalRequestSuccessAction(
                new WithdrawalRequest(response),
              ),
          ),
          catchError((err) => of(new GenericFailureAction(err))),
        );
      }),
    ),
  );

  getWithdrawalRequests: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.GetWithdrawalRequestsAction>(OrdersActionTypes.GET_WITHDRAWAL_REQUESTS),
      withLatestFrom(this.store.pipe(select(selectAuthUser))),
      switchMap(([_, user]) =>
        this.apiService.getWithdrawalRequests(user.organization.id).pipe(
          map((res) => {
            const requests = res.map((req) => new WithdrawalRequest(req));
            return new orderActions.GetWithdrawalRequestsSuccessAction(requests);
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  getReports: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.GetReportsAction>(OrdersActionTypes.GET_REPORTS),
      switchMap((action) =>
        this.apiService.getReports().pipe(
          map((response) => new orderActions.GetReportsSuccessAction(response)),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  getStorerooms: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.GetStoreroomsAction>(OrdersActionTypes.GET_STOREROOMS),
      switchMap((action) =>
        this.apiService.getStorerooms().pipe(
          map(
            (response) =>
              new orderActions.GetStoreroomsSuccessAction(
                response.content.map((s) => new Storeroom(s)),
              ),
          ),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  createStoreroom: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.CreateStoreroomAction>(OrdersActionTypes.CREATE_STOREROOM),
      switchMap((action) =>
        this.apiService.createStoreroom(action.storeroom).pipe(
          map((response) => new orderActions.CreateStoreroomSuccessAction(new Storeroom(response))),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  uploadPrices: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.UploadPricesAction>(OrdersActionTypes.UPLOAD_PRICES),
      switchMap((action) => {
        return this.apiService.uploadPrices(action.payload).pipe(
          map((response) => {
            this.store.dispatch(new GenericSuccessAction(response));
            return new orderActions.UploadPricesSuccesAction(response);
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        );
      }),
    ),
  );

  findNewPriceListItemIfExist: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.FindNewPriceListItemsIfExistAction>(
        OrdersActionTypes.FIND_NEW_PRICE_LIST_ITEMS_IF_EXIST,
      ),
      switchMap((action) => {
        return this.apiService.findNewPriceListItemsIfExist(action.payload).pipe(
          map((response) => {
            return new orderActions.FindNewPriceListItemsIfExistSuccessAction(response);
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        );
      }),
    ),
  );

  findOtherSuppliers: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.FindOtherSuppliersAction>(OrdersActionTypes.FIND_OTHER_SUPPLIERS),
      switchMap((action) => {
        return this.apiService.findOtherSuppliers(action.payload).pipe(
          map((response) => {
            return new orderActions.FindOtherSuppliersSuccessAction(response);
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        );
      }),
    ),
  );

  updateStoreroom: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.UpdateStoreroomAction>(OrdersActionTypes.UPDATE_STOREROOM),
      switchMap((action) =>
        this.apiService.updateStoreroom(action.storeroom).pipe(
          map((response) => new orderActions.UpdateStoreroomSuccessAction(new Storeroom(response))),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  deleteStoreroom: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType<orderActions.DeleteStoreroomAction>(OrdersActionTypes.DELETE_STOREROOM),
      switchMap((action) =>
        this.apiService.deleteStoreroom(action.id).pipe(
          map((response) => new orderActions.DeleteStoreroomSuccessAction(action.id)),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  private navigateBack() {
    this.store.pipe(select(selectAuthUser)).subscribe((user: User) => {
      if (!user || !user.hasPermission(PERMISSION.ADMIN.VIEW)) {
        this.router.navigate(['/orders/overview'], { queryParamsHandling: 'merge' });
      }

      this.router.navigate(['/admin/orders']);
    });
  }
}
