import { ErrorHandler, Injectable } from '@angular/core';
import { MonitoringService } from '../monitoring/monitoring.service';
import { SettingsService } from '../settings/settings.service';
import { SETTING } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService extends ErrorHandler  {

  constructor(private monitor: MonitoringService, private settings: SettingsService) {
    super();
  }

  handleError(error: Error) {
    this.monitor.logException(error);
    if (!this.settings.get(SETTING.PRODUCTION)) {
      console.error(error);
    }
  }
}
