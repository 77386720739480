import { BrandNameId } from "@shared/interfaces/brand-name-id.interface";
import { initialSelectionSearchableNamesState, SelectionSearchableNamesState } from "./selection-searchable-names-state";
import { SelectionSearchableNamesActionTypes } from "./selection-searchable-names.actions";
import { Actions } from './selection-searchable-names.actions';
import { MedicineNameId } from "@shared/interfaces/medicine-name-id.interface";

export function SelectionSearchableNamesReducer(
  state: SelectionSearchableNamesState = initialSelectionSearchableNamesState, action: Actions) {
  switch (action.type) {
    case SelectionSearchableNamesActionTypes.UPDATE_PRICELIST_ITEM_SUCCESS:
      return {
        ...state,
        selectedPriceListItem: action.payload.priceListItem,
      }
    case SelectionSearchableNamesActionTypes.SELECT_MEDICINE:
      return {
        ...state,
        selectedMedicineName: action.payload,
      };
    case SelectionSearchableNamesActionTypes.SELECT_BRAND:
      return {
        ...state,
        selectedBrandName: action.payload,
      };
    case SelectionSearchableNamesActionTypes.SELECT_SUPPLIER:
      return {
        ...state,
        selectedSupplier: action.payload,
      }
    case SelectionSearchableNamesActionTypes.SELECT_MANUFACTURER:
      return {
        ...state,
        selectedManufacturer: action.payload,
      };
    case SelectionSearchableNamesActionTypes.SELECT_PRICELIST_ITEM:
      return {
        ...state,
        selectedPriceListItem: action.payload,
      };
    case SelectionSearchableNamesActionTypes.FILTER_MEDICINE:
      return {
        ...state,
        medicineNameSearchTerm: action.payload
      };
    case SelectionSearchableNamesActionTypes.FILTER_BRAND:
      return {
        ...state,
        brandNameSearchTerm: action.payload
      };
    case SelectionSearchableNamesActionTypes.FILTER_SUPPLIER:
      return {
        ...state,
        supplierSearchTerm: action.payload
      };
    case SelectionSearchableNamesActionTypes.FILTER_MANUFACTURER:
      return {
        ...state,
        manufacturerSearchTerm: action.payload
      };
    case SelectionSearchableNamesActionTypes.RESET_BRAND:
      return {
        ...state,
        selectedBrandName: null,
        brandNameSearchTerm: null,
      };
    case SelectionSearchableNamesActionTypes.RESET_BRAND_AND_MANUFACTURER:
      return {
        ...state,
        selectedBrandName: null,
        brandNameSearchTerm: null,
        selectedManufacturer: null,
        manufacturerSearchTerm: null,
      };
    case SelectionSearchableNamesActionTypes.RESET_MEDICINE:
      return {
        ...state,
        selectedMedicineName: null,
        medicineNameSearchTerm: null,
      };
    case SelectionSearchableNamesActionTypes.RESET_SUPPLIER:
      return {
        ...state,
        supplierSearchTerm: null,
        selectedSupplier: null,
      };
    case SelectionSearchableNamesActionTypes.RESET_MANUFACTURER:
      return {
        ...state,
        selectedManufacturer: null,
        manufacturerSearchTerm: null,
      };
    case SelectionSearchableNamesActionTypes.RESET_STATE:
      return {
        ...state,
        selectedBrandName: null,
        brandNameSearchTerm: null,
        selectedMedicineName: null,
        medicineNameSearchTerm: null,
        selectedSupplier: null,
        supplierSearchTerm: null,
        selectedManufacturer: null,
        manufacturerSearchTerm: null,
        selectedPriceListItem: null,
      };
    case SelectionSearchableNamesActionTypes.UPDATE_BRAND_SUCCESS:
      const updatedBrandName : BrandNameId = {...state.selectedBrandName};
      updatedBrandName.brandName = action.payload.name;
      updatedBrandName.packSize = action.payload.packSize;
      updatedBrandName.manufacturerId = action.payload.manufacturerId;
      updatedBrandName.manufacturerName = action.payload.manufacturer.name;

      return {
        ...state,
        selectedBrandName: updatedBrandName,
      };
    case SelectionSearchableNamesActionTypes.UPDATE_MEDICINE_SUCCESS:
      const updatedMedicineName : MedicineNameId = {...state.selectedMedicineName};
      updatedMedicineName.medicineName = action.payload.genericName;

      return {
        ...state,
        selectedMedicineName: updatedMedicineName,
      };
    default:
      return state;
  }
}
