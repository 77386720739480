<mat-toolbar class="app-header">
  <button class="d-inline-block d-md-none menu-btn" mat-icon-button (click)="openMenu()">
    <fa-icon icon="bars"></fa-icon>
  </button>

  <a class="logo" routerLink="/home"><img src="assets/images/Med4All-logo.webp"/></a>
  <span class="spacer"></span>

  <div class="d-none d-md-flex">
    <button mat-button routerLink="/home" fragment="header" transloco="MENU.MAIN.HOME"></button>
    <button mat-button routerLink="/home" fragment="about" transloco="MENU.MAIN.ABOUT"></button>
    <button mat-button class="d-none d-lg-inline-block" routerLink="/home" fragment="features" transloco="MENU.MAIN.FEATURES"></button>
    <button mat-button routerLink="/home" fragment="faq" transloco="MENU.MAIN.FAQ"></button>
    <button mat-button routerLink="/home" fragment="goals" transloco="MENU.MAIN.GOALS"></button>
  </div>
  <a mat-raised-button color="primary" [href]="authUrl" transloco="MENU.MAIN.LOGIN"></a>
</mat-toolbar>
