/*
  Implementation copied from https://devblogs.microsoft.com/premier-developer/angular-how-to-add-application-insights-to-an-angular-spa/
 */
import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { SettingsService } from '../settings/settings.service';
import { SETTING } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class MonitoringService {
  appInsights: ApplicationInsights;
  trackingEnabled: boolean;

  constructor(private settings: SettingsService) {
    this.trackingEnabled = this.settings.get(SETTING.INSTRUMENTATION_KEY) !== undefined;

    if (this.trackingEnabled) {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: this.settings.get(SETTING.INSTRUMENTATION_KEY),
          enableAutoRouteTracking: true
        }
      });
      this.appInsights.loadAppInsights();
    }
  }

  logPageView(name?: string, uri?: string) {
    if (this.trackingEnabled) {
      this.appInsights.trackPageView({name, uri});
    }
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    if (this.trackingEnabled) {
      this.appInsights.trackEvent({name}, properties);
    }
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    if (this.trackingEnabled) {
      this.appInsights.trackMetric({ name, average }, properties);
    }
  }

  logException(exception: Error, severityLevel?: number) {
    if (this.trackingEnabled) {
      this.appInsights.trackException({ exception, severityLevel });
    }
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    if (this.trackingEnabled) {
      this.appInsights.trackTrace({ message}, properties);
    }
  }
}
