import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-order-message-dialog',
  templateUrl: './order-message-dialog.component.html',
  styleUrls: ['./order-message-dialog.component.scss']
})
export class OrderMessageDialogComponent implements OnInit {
  message: string;

  constructor(private dialogRef: MatDialogRef<OrderMessageDialogComponent>, @Inject(MAT_DIALOG_DATA) private dialogData: any) { }

  ngOnInit(): void {
    console.log(this.dialogData.message);
    this.message = this.dialogData.message;
  }

  close() {
    this.dialogRef.close();
  }

}
