import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Entity } from '../../../models/entity';
import { ConfirmationAction } from '../../../interfaces/confirmation-action.interface';
import { AuthService } from '../../../providers/auth/auth.service';
import { DialogType } from '../../../models/dialog-type';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html'
})
export class ConfirmationDialogComponent implements OnInit {
  title: string;
  question: string;
  questionParams: any;
  entity: Entity;
  actions: ConfirmationAction[];
  dialogType: DialogType;
  showCheckbox = false;
  receiveAdminConfirmationPopup = false;

  constructor(private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
              private authService: AuthService,
              @Inject(MAT_DIALOG_DATA) private dialogData: any) {
  }

  ngOnInit(): void {
    this.question = this.dialogData.question;
    this.questionParams = this.dialogData.questionParams;
    this.entity = this.dialogData.entity;
    this.actions = this.dialogData.actions;
    this.title = this.dialogData.title || 'DIALOGS.CONFIRMATION.TITLE';
    this.dialogType = this.dialogData.dialogType;

    if (this.dialogType === DialogType.ADMIN) {
      this.showCheckbox = true;
    }
  }

  close(confirmation: boolean) {
    this.handleDoNotShowAgain();

    this.dialogRef.close(confirmation);
  }

  handleActionClose(action: ConfirmationAction) {
    if (typeof action.handler === 'function') {
      action.handler();
    }

    this.dialogRef.close();
  }

  private handleDoNotShowAgain() {
    if (!this.receiveAdminConfirmationPopup) {
      return;
    }

    this.authService.updateAdminConfirmPreference(false);
  }
}
