import { Component, OnInit } from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-forget-password-dialog',
  templateUrl: './forget-password-dialog.component.html',
})
export class ForgetPasswordDialogComponent implements OnInit {
  emailCtrl: UntypedFormControl;

  constructor(private dialogRef: MatDialogRef<ForgetPasswordDialogComponent>) { }

  ngOnInit(): void {
    this.emailCtrl = new UntypedFormControl(null, [Validators.required, Validators.email]);
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    if (this.emailCtrl.valid) {
      this.dialogRef.close(this.emailCtrl.value);
    }
  }
}
