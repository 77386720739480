import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { User } from '../../shared/models/user';
import { AdminState } from './state';
import { Organization } from '../../shared/models/organization';
import { Role } from '../../shared/models/role';
import { Permission } from '../../shared/models/permission';
import { OverviewAction } from '../../shared/interfaces/overview-action.interface';
import { Order } from '../../shared/models/order';
import { Filter } from '../../shared/interfaces/filter.interface';
import { Denomination } from '../../shared/interfaces/denomination.interface';
import { District } from '../../shared/interfaces/district.interface';
import { Region } from '../../shared/interfaces/region.interface';
import { FacilityType } from '../../shared/interfaces/facility-type.interface';
import { WithdrawalRequest } from '../../shared/models/withdrawal-request';
import { Document } from '../../shared/models/document';
import { Email } from '../../admin/shared/models/email';
import { Pageable } from '../../shared/interfaces/pageable.interface';

export const selectAdminState: MemoizedSelector<object, AdminState> =
  createFeatureSelector<AdminState>('admin');

export const selectUsers: MemoizedSelector<object, User[]> =
  createSelector(selectAdminState, (state: AdminState) => state.users);

export const selectUsersFilter: MemoizedSelector<object, Filter> =
  createSelector(selectAdminState, (state: AdminState) => state.usersFilter);

export const selectOrganization: MemoizedSelector<object, Organization> =
  createSelector(selectAdminState, (state: AdminState) => state.organization);

export const selectOrganizations: MemoizedSelector<object, Organization[]> =
  createSelector(selectAdminState, (state: AdminState) => state.organizations);

export const selectOrganizationsFilter: MemoizedSelector<object, Filter> =
  createSelector(selectAdminState, (state: AdminState) => state.organizationsFilter);

export const selectRole: MemoizedSelector<object, Role> =
  createSelector(selectAdminState, (state: AdminState) => state.role);

export const selectRoles: MemoizedSelector<object, Role[]> =
  createSelector(selectAdminState, (state: AdminState) => state.roles);

export const selectPermissions: MemoizedSelector<object, Permission[]> =
  createSelector(selectAdminState, (state: AdminState) => state.permissions);

export const selectRolesFilter: MemoizedSelector<object, Filter> =
  createSelector(selectAdminState, (state: AdminState) => state.rolesFilter);

export const selectOverviewActions: MemoizedSelector<object, OverviewAction[]> =
  createSelector(selectAdminState, (state: AdminState) => state.overviewActions);

export const selectAdminOrders: MemoizedSelector<object, Pageable<Order>> =
  createSelector(selectAdminState, (state: AdminState) => state.orders);

export const selectAdminOrdersFilter: MemoizedSelector<object, Filter> =
  createSelector(selectAdminState, (state: AdminState) => state.ordersFilter);

export const selectDenominations: MemoizedSelector<object, Denomination[]> =
  createSelector(selectAdminState, (state: AdminState) => state.denominations);

export const selectDistricts: MemoizedSelector<object, District[]> =
  createSelector(selectAdminState, (state: AdminState) => state.districts);

export const selectRegions: MemoizedSelector<object, Region[]> =
  createSelector(selectAdminState, (state: AdminState) => state.regions);

export const selectFacilityTypes: MemoizedSelector<object, FacilityType[]> =
  createSelector(selectAdminState, (state: AdminState) => state.facilityTypes);

export const selectAdminLoans: MemoizedSelector<object, WithdrawalRequest[]> =
  createSelector(selectAdminState, (state: AdminState) => state.withdrawalRequests);

export const selectAdminLoansFilter: MemoizedSelector<object, Filter> =
  createSelector(selectAdminState, (state: AdminState) => state.withdrawalRequestsFilter);

export const selectAdminDocuments: MemoizedSelector<object, Pageable<Document>> =
  createSelector(selectAdminState, (state: AdminState) => state.documents);

export const selectAdminDocumentsFilter: MemoizedSelector<object, Filter> =
  createSelector(selectAdminState, (state: AdminState) => state.documentFilter);

export const selectEmails: MemoizedSelector<object, Pageable<Email>> =
  createSelector(selectAdminState, (state: AdminState) => state.emails);

export const selectEmailsFilter: MemoizedSelector<object, Filter> =
  createSelector(selectAdminState, (state: AdminState) => state.emailsFilter);
