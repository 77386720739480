import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SelectionSearchableNamesReducer } from './selection-searchable-names.reducer';
import { SelectionSearchableNamesEffects } from './selection-searchable-names.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('selectionSearchableNames', SelectionSearchableNamesReducer),
    EffectsModule.forFeature([SelectionSearchableNamesEffects])
  ],
  providers: [SelectionSearchableNamesEffects]
})
export class SelectionSearchableNamesStoreModule {
}
