<div [formGroup]="parts" class="tel-input-container">
  <mat-form-field class="country-input mr-2">
    <mat-label transloco="USER.COUNTRY_CODE"></mat-label>
    <input formControlName="country"
           size="4"
           maxLength="4"
           matInput
    >
    <mat-error *ngIf="countryCtrl.invalid && countryCtrl.dirty" transloco="MESSAGES.FORM.PHONE_NUMBER_COUNTRY_INVALID_ERROR"></mat-error>

  </mat-form-field>

  <mat-form-field class="phone-input">
    <mat-label transloco="USER.PHONE_NUMBER"></mat-label>
    <input formControlName="phone"
           maxLength="15"
           size="15"
           matInput>
    <mat-error *ngIf="phoneCtrl.invalid && phoneCtrl.dirty" transloco="MESSAGES.FORM.PHONE_NUMBER_INVALID_ERROR"></mat-error>
  </mat-form-field>
</div>
