import { Entity } from './entity';
import { User } from './user';
import { Organization } from './organization';

export class Document extends Entity {
  organization: Organization;
  uploader: User;
  file: any;
  name: string;
  type: string;
  url: string;

  constructor(data?: any, restore: boolean = false) {
    super(data, restore);

    if (data) {
      this.organization = data.organization ? new Organization(data.organization) : null;
      this.uploader = data.uploader;
      this.file = data.file;
      this.name = data.name;
      this.type = data.type;
      this.url = data.url;
    }
  }
}
