import { Entity } from './entity';
import { Organization } from './organization';

export class Brand extends Entity {
  medicineId: number;
  keywords: string[];
  name: string;
  shortName: string;
  packSize: number;
  packSizeText: string;
  packSizeHeight: string;
  packSizeLength: string;
  packSizeWeight: string;
  packSizeWidth: string;
  unitOfMeasure: string;
  minimumOrderQuantity: number;
  supplier: Organization;
  manufacturer: Organization;
  manufacturerId: number;
  fdaRegistrationNumber: number;
  fdaRegistrationExpirationDate: Date;
  onCurrentPricelistitem: boolean

  constructor(data?: any, restore: boolean = false) {
    super(data, restore);

    if (data) {
      this.medicineId = data.medicineId;
      this.keywords = data.keywords;
      this.name = data.name;
      this.shortName = data.shortName;
      this.minimumOrderQuantity = data.minimumOrderQuantity;
      this.supplier = data.supplier
        ? new Organization(data.supplier)
        : new Organization({ name: 'PharmAccess' });
      this.manufacturer = data.manufacturer ? new Organization(data.manufacturer) : null;
      this.manufacturerId = data.manufacturerId ? data.manufacturerId : this.manufacturer?.id;
      this.fdaRegistrationNumber = data.fdaRegistrationNumber;
      this.fdaRegistrationExpirationDate = data.fdaRegistrationExpirationDate
        ? new Date(data.fdaRegistrationExpirationDate)
        : null;
      this.packSize = data.packSize;
      this.packSizeText = data.packSizeText;
      this.packSizeHeight = data.packSizeHeight;
      this.packSizeLength = data.packSizeLength;
      this.packSizeWeight = data.packSizeWeight;
      this.packSizeWidth = data.packSizeWidth;
      this.unitOfMeasure = data.unitOfMeasure;
      this.onCurrentPricelistitem = data.onCurrentPricelistitem;
    }
  }

  static newBrand(): Brand {
    return new Brand({
      keywords: [],
      supplier: null,
      name: null,
      medicineId: null,
      manufacturerId: null,
      packSize: null,
      minimumOrderQuantity: null,
      fdaRegistrationExpirationDate: null,
      fdaRegistrationNumber: null,
      packSizeHeight: null,
      packSizeLength: null,
      packSizeWeight: null,
      packSizeWidth: null,
      unitOfMeasure: null,
      onCurrentPricelistitem: false
    });
  }
}
