import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { FilterGroup } from '../../interfaces/filter-group.interface';
import { UntypedFormControl } from '@angular/forms';
import { Filter } from '../../interfaces/filter.interface';
import { FilterItem } from '../../interfaces/filter-item.interface';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { inputChanged } from '../../utils';
import { BaseComponent } from '../base/base.component';
import { cloneDeep } from 'lodash';
import { FilterSortOption } from '../../interfaces/medicine-filter-sort.interface';

@Component({
  selector: 'app-base-filter',
  templateUrl: 'base-filter.component.html',
})
export class BaseFilterComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() private searchPlaceholder: string;
  @Input() filter: Filter = {};
  @Input() filterOptions: FilterGroup[];

  _sortOptions: FilterSortOption[];
  @Input() set sortOptions(sort: FilterSortOption[]) {
    this._sortOptions = sort;

    if (this._sortOptions != null)
      this.sortCtrl.patchValue(this._sortOptions[0], { emitEvent: false });
  }

  get sortOptions(): FilterSortOption[] {
    return this._sortOptions;
  }

  @Output() filterChange: EventEmitter<Filter> = new EventEmitter<Filter>();

  searchCtrl: UntypedFormControl;
  selectedFilters: FilterItem[] = [];

  sortCtrl: UntypedFormControl;
  protected _allFilterOptions: FilterGroup[];

  constructor(protected datePipe: DatePipe) {
    super();
    this.searchCtrl = new UntypedFormControl('');
    this.sortCtrl = new UntypedFormControl();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.searchCtrl.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(500), takeUntil(this._unsubscribe$))
      .subscribe(() => {
        this._onChanged();
      });

    this.sortCtrl.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(200), takeUntil(this._unsubscribe$))
      .subscribe(() => this._onChanged());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (inputChanged(changes, 'filter')) {
      this.selectedFilters = cloneDeep(changes['filter'].currentValue.filters);
      this.searchCtrl.patchValue(changes['filter'].currentValue.searchTerm);
    }

    if (inputChanged(changes, 'filterProps')) {
      this.filterOptions = this.getMappedFilterOptions(this._allFilterOptions);
    }
  }

  addFilter(option: FilterItem, group: FilterGroup) {
    if (this.hasFilter(option, group.prop)) return;

    if (option.value !== 'all') {
      this.selectedFilters.push({ ...option, prop: group.prop, groupName: group.groupName });
    } else {
      group.items
        .filter((f) => f.value !== 'all')
        .forEach((f) =>
          this.selectedFilters.push({ ...f, prop: group.prop, groupName: group.groupName }),
        );
    }

    this._onChanged();
  }

  addDateFilter(date: Date, group: FilterGroup) {
    date.setHours(0, 0, 0, 0);
    if (!this.hasFilter(date, group.prop)) {
      this.selectedFilters.push({
        label: this.datePipe.transform(date, 'dd-MM-yyyy'),
        value: date,
        prop: group.prop,
        groupName: group.groupName,
      });
      this._onChanged();
    }
  }

  removeFilter(filter: FilterItem) {
    const filterIdx = this.selectedFilters.findIndex(
      (_filter) => _filter.value === filter.value && _filter.prop === filter.prop,
    );
    if (filterIdx !== -1) {
      this.selectedFilters.splice(filterIdx, 1);
      this._onChanged();
    }
  }

  hasFilter(value: any, prop: string): boolean {
    return value && prop
      ? this.selectedFilters.findIndex(
          (_filter) => _filter.value === value && _filter.prop === prop,
        ) !== -1
      : false;
  }

  private _onChanged() {
    const filter: Filter = {
      searchTerm: this.searchCtrl.value,
      filters: cloneDeep(this.selectedFilters),
      sortBy: this.sortCtrl?.value?.prop,
      sortDirection: this.sortCtrl?.value?.direction,
    };

    this.filterChange.emit(filter);
  }

  private getMappedFilterOptions(filterOptions: FilterGroup[]): FilterGroup[] {
    return filterOptions.map((option) => {
      option.items?.sort((a, b) => {
        if (a.label > b.label || b.value === 'all') {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        return 0;
      });

      return option;
    });
  }

  clearFilters() {
    this.selectedFilters.length = 0;
    this._onChanged();
  }

  get searchPlaceHolder() {
    return this.searchPlaceholder ?? 'COMMON.SEARCH';
  }
}
