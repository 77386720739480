import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OrganizationsService } from '../../../../admin/organizations/providers/organizations.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Organization } from '../../../models/organization';
import { AuthService } from '../../../providers/auth/auth.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-recover-password-dialog',
  templateUrl: './recover-email-dialog.component.html',
})
export class RecoverEmailDialogComponent implements OnInit {
  recoverEmailForm: UntypedFormGroup;
  organizations$: Observable<Organization[]>;

  get firstNameCtrl(): AbstractControl {
    return this.recoverEmailForm.get('firstName');
  }

  get lastNameCtrl(): AbstractControl {
    return this.recoverEmailForm.get('lastName');
  }

  get emailCtrl(): AbstractControl {
    return this.recoverEmailForm.get('email');
  }

  get organizationCtrl(): AbstractControl {
    return this.recoverEmailForm.get('organizationId');
  }

  get commentCtrl(): AbstractControl {
    return this.recoverEmailForm.get('comment');
  }

  constructor(
    private dialogRef: MatDialogRef<RecoverEmailDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.recoverEmailForm = this.formBuilder.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      organizationId: [null, Validators.required],
      comment: [null, Validators.required]
    });

    this.organizations$ = this.authService.getOrganizations();
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    if (this.recoverEmailForm.valid) {
      this.dialogRef.close(this.recoverEmailForm.value);
    }
  }
}
