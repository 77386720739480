import { BrandNameId } from "@shared/interfaces/brand-name-id.interface";
import { MedicineNameId } from "@shared/interfaces/medicine-name-id.interface";
import { SupplierWithIds } from "@shared/interfaces/supplier-with-ids.interface";
import { PricelistItem } from "@shared/models/pricelist-item";

export interface SelectionSearchableNamesState {
  selectedBrandName?: BrandNameId;
  brandNameSearchTerm?: string;
  selectedMedicineName?: MedicineNameId;
  medicineNameSearchTerm?: string;
  selectedPriceListItem?: PricelistItem;
  selectedSupplier?: SupplierWithIds;
  supplierSearchTerm?: string;
  selectedManufacturer?: SupplierWithIds;
  manufacturerSearchTerm?: string;
}

export const initialSelectionSearchableNamesState: SelectionSearchableNamesState = {
  selectedBrandName: null,
  brandNameSearchTerm: null,
  selectedMedicineName: null,
  medicineNameSearchTerm: null,
  selectedSupplier: null,
  supplierSearchTerm: null,
  selectedPriceListItem: null,
  selectedManufacturer: null,
  manufacturerSearchTerm: null,
};

