import { Injectable } from '@angular/core';
import { AuthService } from '../../providers/auth/auth.service';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getModuleFromUrl } from '../../utils';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

  constructor(private auth: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    let hasPermission = false;
    return this.auth
      .getUser()
      .pipe(
        map(user => {
          // user should be logged in
          if (user) {
            // the route should have permissions defined
            if (route.data && route.data.permissions) {
              hasPermission = route.data.permissions.some(permission => user.hasPermission(permission));
            } else {
              // without permissions, the route is open
              hasPermission = true;
            }
          } else {
            // if not logged in, the user is not allowed to proceed, but logged out
            this.auth.logout();
            hasPermission = false;
          }

          if (!hasPermission) {
            this.router.navigate([getModuleFromUrl(this.router.url), 'home']);
          }

          return hasPermission;
        })
      );
  }
}
