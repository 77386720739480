import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { FaConfig, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faExclamationCircle,
  faTruck,
  faSearch,
  faChevronUp,
  faChevronDown,
  faTimes,
  faPlus,
  faUsers,
  faEdit,
  faUserTimes,
  faTrash,
  faArrowLeft,
  faUserTag,
  faEye,
  faPaperPlane,
  faSave,
  faCopy,
  faList,
  faCheckCircle,
  faEllipsisV,
  faInfo,
  faBell,
  faArrowUp,
  faArrowDown,
  faUpload,
  faCube,
  faFilePdf,
  faShoppingCart,
  faClipboardCheck,
  faCog,
  faCoins,
  faCartPlus,
  faPhoneAlt,
  faEnvelope,
  faComments,
  faBars,
  faComment,
  faClock,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';
import { faFontAwesome, faLinkedin } from '@fortawesome/free-brands-svg-icons';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private faIconLibrary: FaIconLibrary,
    private faConfig: FaConfig,
  ) {}

  registerIcons() {
    this.iconRegistry.addSvgIcon(
      'login_arrow',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/login-arrow.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'logout_arrow',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/logout-arrow.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'shopping-order',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/shopping-order.svg'),
    );

    // NOTE:: Add New Icons like this, otherwise bundle size will increase
    this.faIconLibrary.addIcons(
      faExclamationCircle,
      faTruck,
      faSearch,
      faChevronUp,
      faChevronDown,
      faTimes,
      faPlus,
      faUsers,
      faEdit,
      faUserTimes,
      faTrash,
      faArrowLeft,
      faUserTag,
      faEye,
      faPaperPlane,
      faSave,
      faCopy,
      faList,
      faCheckCircle,
      faEllipsisV,
      faInfo,
      faBell,
      faArrowUp,
      faArrowDown,
      faUpload,
      faCube,
      faFilePdf,
      faShoppingCart,
      faClipboardCheck,
      faCog,
      faCoins,
      faCartPlus,
      faPhoneAlt,
      faEnvelope,
      faComments,
      faBars,
      faLinkedin,
      faFontAwesome,
      faComment,
      faClock,
      faDownload,
    );

    this.faConfig.fallbackIcon = faCog;
  }
}
