import { Inject, Injectable } from '@angular/core';
import { AnalyticsPageView } from '../../interfaces/analytics-pageview.interface';
import { interval, ReplaySubject, throwError, timer } from 'rxjs';
import { AnalyticsEvent } from '../../interfaces/analytics-event.interface';
import { filter, first, switchMap, takeUntil } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from '../../tokens/dom.tokens';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  private readonly queue: ReplaySubject<unknown[]> = new ReplaySubject<unknown[]>();

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: any
  ) {}

  configure(trackingId: string): void {
    if (!trackingId) {
      return;
    }

    const gtagScript: HTMLScriptElement = this.document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + trackingId;
    this.document.head.append(gtagScript);

    this._gtag('config', trackingId, {
      send_page_view: false,
      anonymize_ip: true
    });

    // configure router
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((e: NavigationEnd) => {
      this.pageView({path: e.urlAfterRedirects});
    });

    const timer$ = timer(20_000)
      .pipe(
        switchMap(() => throwError(new Error('Could not load Google Analytics')))
      );

    interval(50)
      .pipe(
        takeUntil(timer$),
        filter(() => Boolean((this.window).gtag)),
        first(),
        switchMap(() => this.queue),
      )
      .subscribe(args => (this.window).gtag(...args));
  }

  set(key: any, value?: any): void {
    if (typeof key !== 'string' && typeof key !== 'object') {
      throw new TypeError(`Expected \`fieldName\` to be of type \`string\` or \`object\`, got \`${typeof key}\``);
    }

    if (typeof key === 'string' && value === undefined) {
      throw new TypeError('Expected `fieldValue` to not be `undefined`');
    }

    if (typeof key === 'object') {
      this._gtag('set', key);
    } else {
      this._gtag('set', key, value);
    }
  }

  event(event: AnalyticsEvent): void {
    const fieldsObject = {};
    if (event.category) {
      fieldsObject['event_category'] = event.category;
    }
    if (event.label) {
      fieldsObject['event_label'] = event.label;
    }
    if (event.value) {
      fieldsObject['value'] = event.value;
    }

    this._gtag('event', event.action, fieldsObject);
  }

  pageView(pageview: AnalyticsPageView): void {
    const fieldsObject = {page_path: pageview.path};
    if (pageview.location) {
      fieldsObject['page_location'] = pageview.location;
    }
    if (pageview.sendPageView) {
      fieldsObject['send_page_view'] = pageview.sendPageView;
    }
    if (pageview.title) {
      fieldsObject['page_title'] = pageview.title;
    }

    this._gtag('event', 'page_view', fieldsObject);
  }

  private _gtag(...args: unknown[]): void {
    this.queue.next(args);
  }
}
