import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePage } from './pages/home/home.page';
import { NotFoundPage } from './pages/not-found/not-found.page';
import { AuthGuard } from './shared/guards/auth/auth.guard';
import { UpdatePasswordPage } from './pages/update-password/update-password-page.component';
import { PermissionGuard } from './shared/guards/permission/permission.guard';
import { PERMISSION } from './shared/constants';
import { environment } from '../environments/environment';
import { MaintenancePage } from './pages/maintenance/maintenance.page';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomePage
  },
  {
    path: 'update-password',
    component: UpdatePasswordPage
  },
  {
    path: 'maintenance',
    component: MaintenancePage
  },
  {
    path: 'orders',
    loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: [PERMISSION.ADMIN.VIEW]
    }
  },
  {
    path: 'monitor',
    loadChildren: () => import('./monitor/monitor.module').then(m => m.MonitorModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: [PERMISSION.MONITOR.VIEW]
    }
  },
  {
    path: 'cms',
    loadChildren: () => import('./cms/cms.module').then(m => m.CmsModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: [PERMISSION.CMS.VIEW]
    }
  },
  {
    path: '**',
    component: NotFoundPage
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: environment.debug })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
