<div *transloco="let t">
  <mat-error *ngIf="formCtrl?.invalid && formCtrl?.dirty">
    <span *ngIf="formCtrl.hasError('required')">
      {{t('MESSAGES.FORM.GENERIC_REQUIRED_ERROR')}}
    </span>

    <span *ngIf="formCtrl?.hasError('maxlength')">
      {{t('MESSAGES.FORM.GENERIC_MAX_LENGTH_ERROR', {number: formCtrl.errors.maxlength.requiredLength})}}
    </span>

    <span *ngIf="formCtrl?.invalid && formCtrl?.dirty && formCtrl?.hasError('pattern')">
      {{t(patternTranslateKey)}}
    </span>
  </mat-error>
</div>
