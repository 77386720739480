import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import {
  AuthActionTypes,
  GetOrganizationsAction,
  GetOrganizationsSuccessAction,
  GetTokensAction,
  GetTokensSuccessAction,
  GetUserAction,
  GetUserSuccessAction,
  LogOutAction,
  RecoverEmailAction,
  RefreshAccessTokenAction,
  RefreshAccessTokenSuccessAction,
  RequestResetPasswordAction,
  ResetPasswordAction,
  UpdatePasswordAction,
  UpdateProfileAction,
  UpdateProfileSuccessAction,
  UpdateProfileSuccessAndLogOutAction
} from './actions';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { User } from '../../shared/models/user';
import { MODULE_CONFIG, ORGANIZATIONTYPE, PERMISSION, SETTING } from '../../shared/constants';
import { select, Store } from '@ngrx/store';
import { GenericFailureAction, GenericSuccessAction } from '../app/actions';
import { ApiService } from '../../shared/providers/api/api.service';
import { Organization } from '../../shared/models/organization';
import { GetCartAction, GetFormularyAction } from '../orders/actions';
import { selectAuthUser, selectRefreshToken } from './selectors';
import { SettingsService } from '../../shared/providers/settings/settings.service';
import { GoogleAnalyticsService } from '../../shared/providers/google-analytics/google-analytics.service';
import { LocationService } from '@shared/providers/location/location-service';

@Injectable()
export class AuthEffects {
  constructor(
    private actions: Actions,
    private apiService: ApiService,
    private router: Router,
    private store: Store<any>,
    private settingsService: SettingsService,
    private analytics: GoogleAnalyticsService,
    private locationService: LocationService
  ) {

  }


  getTokens: Observable<any> = createEffect(() => this.actions
    .pipe(
      ofType<GetTokensAction>(AuthActionTypes.GET_TOKENS),
      switchMap((action) => this.apiService.getTokens(action.payload)
        .pipe(
          map((response: any) => new GetTokensSuccessAction(response)),
          catchError(err => of(new GenericFailureAction(err)))
        )
      )
    ));


  refreshAccessToken: Observable<any> = createEffect(() => this.actions
    .pipe(
      ofType<RefreshAccessTokenAction>(AuthActionTypes.REFRESH_ACCESS_TOKEN),
      withLatestFrom(this.store.pipe(select(selectRefreshToken))),
      switchMap(([_, refreshToken]) => this.apiService.refreshAccessToken(refreshToken)
        .pipe(
          map(res => new RefreshAccessTokenSuccessAction(res)),
          catchError(err => of(new GenericFailureAction(err)))
        )
      )
    ));

  logout : Observable<any> = createEffect(() => this.actions
    .pipe(
      ofType<LogOutAction>(AuthActionTypes.LOGOUT),
      map(() => {
        let oAuthClientId = SETTING.OAUTH_CLIENT_ID;
        if (window.location.href?.startsWith('https://new.')) {
          oAuthClientId = SETTING.OAUTH_CLIENT_ID_TEMP;
        }

        var logoutUrl = `${this.settingsService.get(
          SETTING.AUTH_URL,
        )}/logout?client_id=${this.settingsService.get(oAuthClientId)}`;

        this.locationService.href = logoutUrl;

        return new GenericSuccessAction("Logout success");
      })
    ));

  getUser: Observable<any> = createEffect(() => this.actions
    .pipe(
      ofType<GetUserAction>(AuthActionTypes.GET_USER),
      switchMap(() => this.apiService.getAuthUser()
        .pipe(
          take(1),
          map(response => {
            const user: User = new User(response);
            this.analytics.set('user_id', user.id);

            // get the formulary for the facility
            if (user.hasPermission(PERMISSION.FORMULARY.VIEW) && user.organization.type === ORGANIZATIONTYPE.FACILITY) {
              this.store.dispatch(new GetFormularyAction('my'));
            }

            // get the cart (if allowed)
            if (user.hasPermission(PERMISSION.ORDERS.VIEW)) {
              this.store.dispatch(new GetCartAction(user.organization.id));
            }

            const moduleUrl = MODULE_CONFIG.filter(config => user.hasPermission(config.permission))[0]?.url;
            // navigate to admin module if the user has the right
            const redirectUrl = this.settingsService.get(SETTING.REDIRECT_URL, true);
            const url = redirectUrl ? decodeURIComponent(redirectUrl) : moduleUrl;
            // clear the redirect url
            this.settingsService.remove(SETTING.REDIRECT_URL, true);
            // navigate to the url
            this.router.navigateByUrl(url);

            return new GetUserSuccessAction(user);
          }),
          catchError(err => of(new GenericFailureAction(err)))
        )
      )
    ));


  updateProfile: Observable<any> = createEffect(() => this.actions
    .pipe(
      ofType<UpdateProfileAction>(AuthActionTypes.UPDATE_PROFILE),
      switchMap(action => this.apiService.updateProfile(action.payload.user)
        .pipe(
          tap(() => this.store.dispatch(new GenericSuccessAction('MESSAGES.API.PROFILE_UPDATE_SUCCESS'))),
          map(response => {
            const user: User = new User(response);
            if (action.payload.updateEmail) {
              return new UpdateProfileSuccessAndLogOutAction(user);
            }

            return new UpdateProfileSuccessAction(user);
          }),
          catchError(err => of(new GenericFailureAction(err)))
        )
      )
    ));

  logoutAfterUpdateEmail : Observable<any> = createEffect(() => this.actions
    .pipe(
      ofType<UpdateProfileSuccessAndLogOutAction>(AuthActionTypes.UPDATE_PROFILE_SUCCESS_AND_LOGOUT_ACTION),
      map(() => new LogOutAction())
    ));

  updateProfileSuccessAfterUpdateEmail: Observable<any> = createEffect(() => this.actions
    .pipe(
      ofType<UpdateProfileSuccessAndLogOutAction>(AuthActionTypes.UPDATE_PROFILE_SUCCESS_AND_LOGOUT_ACTION),
      map((action) => new UpdateProfileSuccessAction(action.payload))
    ));



  resetPassword: Observable<any> = createEffect(() => this.actions
    .pipe(
      ofType<ResetPasswordAction>(AuthActionTypes.RESET_PASSWORD),
      switchMap(action => this.apiService.resetPassword(action.token, action.password)
        .pipe(
          map(response => {
            this.router.navigateByUrl('/home');
            return new GenericSuccessAction(response);
          }),
          catchError(err => of(new GenericFailureAction(err)))
        )
      )
    ));


  requestResetPassword: Observable<any> = createEffect(() => this.actions
    .pipe(
      ofType<RequestResetPasswordAction>(AuthActionTypes.REQUEST_RESET_PASSWORD),
      switchMap(action => this.apiService.requestResetPassword(action.payload)
        .pipe(
          map(response => new GenericSuccessAction(response)),
          catchError(err => of(new GenericFailureAction(err)))
        )
      )
    ));


  recoverEmail: Observable<any> = createEffect(() => this.actions
    .pipe(
      ofType<RecoverEmailAction>(AuthActionTypes.RECOVER_EMAIL),
      switchMap(action => this.apiService.recoverEmail(action.payload)
        .pipe(
          map(response => new GenericSuccessAction(response)),
          catchError(err => of(new GenericFailureAction(err)))
        )
      )
    ));


  updatePassword: Observable<any> = createEffect(() => this.actions
    .pipe(
      ofType<UpdatePasswordAction>(AuthActionTypes.UPDATE_PASSWORD),
      withLatestFrom(this.store.pipe(select(selectAuthUser))),
      switchMap(([action, user]) => this.apiService.updatePassword(action.oldPassword, action.newPassword, user.email)
        .pipe(
          map(res => new GenericSuccessAction(res)),
          catchError(err => of(new GenericFailureAction(err)))
        )
      )
    ));


  getOrganizations: Observable<any> = createEffect(() => this.actions
    .pipe(
      ofType<GetOrganizationsAction>(AuthActionTypes.GET_ORGANIZATIONS),
      switchMap(() => this.apiService.getAuthOrganizations()
        .pipe(
          map(res => {
            const organizations = res.map(org => new Organization(org));
            return new GetOrganizationsSuccessAction(organizations);
          }),
          catchError(err => of(new GenericFailureAction(err)))
        )
      )
    ));
}
