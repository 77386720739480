import { MetaReducer } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { rehydrateReducer } from './rehydrate.reducer';
import { backupReducer } from './backup.reducer';
import { clearReducer } from './clear.reducer';

const metaReducers = [
  clearReducer,
  rehydrateReducer,
  backupReducer
];

if (!environment.production) {
  // metaReducers.push(logReducer);
}

export const META_REDUCERS: Array<MetaReducer<any, any>> = metaReducers;
