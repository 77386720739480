import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { CmsState } from './state';
import { Medicine } from '../../shared/models/medicine';
import { Brand } from '../../shared/models/brand';
import { Keyword } from '../../shared/models/keyword';
import { PrescribingLevel } from '../../shared/interfaces/prescribing-level.interface';
import { MedicineForm } from '../../shared/models/medicine-form';
import { UnknownMedicine } from '../../shared/interfaces/unknown-medicine.interface';
import { PricelistItem } from '../../shared/models/pricelist-item';
import { EmailTemplate } from '../../shared/models/email-template';
import { EmailTemplateVariable } from '../../shared/models/email-template-variable';
import { Pageable } from '../../shared/interfaces/pageable.interface';
import { ProductCatalog } from '../../shared/models/product-catalog';

export const selectCmsState: MemoizedSelector<object, CmsState> =
  createFeatureSelector<CmsState>('cms');

export const selectMedicines: MemoizedSelector<object, Medicine[]> =
  createSelector(selectCmsState, (state: CmsState) => state.medicines);

export const selectBrands: MemoizedSelector<object, Brand[]> =
  createSelector(selectCmsState, (state: CmsState) => state.brands);

export const selectKeywords: MemoizedSelector<object, Keyword[]> =
  createSelector(selectCmsState, (state: CmsState) => state.keywords);

export const selectPricelistItems: MemoizedSelector<object, PricelistItem[]> =
  createSelector(selectCmsState, (state: CmsState) => state.pricelistItems);

export const selectPrescribingLevels: MemoizedSelector<object, PrescribingLevel[]> =
  createSelector(selectCmsState, (state: CmsState) => state.prescribingLevels);

export const selectMedicineForms: MemoizedSelector<object, MedicineForm[]> =
  createSelector(selectCmsState, (state: CmsState) => state.medicineForms);

export const selectUnknownMedicines: MemoizedSelector<object, UnknownMedicine[]> =
  createSelector(selectCmsState, (state: CmsState) => state.unknownMedicines);

export const selectEmailTemplates: MemoizedSelector<object, EmailTemplate[]> =
  createSelector(selectCmsState, (state: CmsState) => state.emailTemplates);

export const selectEmailTemplateVariables: MemoizedSelector<object, EmailTemplateVariable[]> =
  createSelector(selectCmsState, (state: CmsState) => state.emailTemplateVariables);

export const selectProductCatalog: MemoizedSelector<object, Pageable<ProductCatalog>> =
  createSelector(selectCmsState, (state: CmsState) => state.productCatalog);
