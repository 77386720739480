import { Component, HostBinding, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared/components/base/base.component';

@Component({
  selector: 'app-maintenance',
  templateUrl: 'maintenance.page.html',
})
export class MaintenancePage extends BaseComponent implements OnInit {
  @HostBinding() class = 'page';

  ngOnInit(): void {
    super.ngOnInit();
  }
}
