import { Action } from "@ngrx/store";
import { BrandNameId } from "@shared/interfaces/brand-name-id.interface";
import { CreateProduct } from "@shared/interfaces/create-product.interface";
import { DeactivatedItems } from "@shared/interfaces/deactivated-items.interface";
import { MedicineNameId } from "@shared/interfaces/medicine-name-id.interface";
import { SupplierWithIds } from "@shared/interfaces/supplier-with-ids.interface";
import { Brand } from "@shared/models/brand";
import { Medicine } from "@shared/models/medicine";
import { PricelistItem } from "@shared/models/pricelist-item";

export enum SearchableNamesActionTypes {
  GET_ACTIVE_PRICE_LIST_ITEMS = '[SearchableNames] Get All Price List Items',
  GET_ACTIVE_PRICE_LIST_ITEMS_SUCCESS = '[SearchableNames] Get All Price List Items Success',

  GET_SUPPLIER_WITH_IDS = '[SearchableNames] Get All Supplier with ids',
  GET_SUPPLIER_WITH_IDS_SUCCESS = '[SearchableNames] Get Suppliers with ids success',

  GET_MANUFACTURER_NAMES = "[SearchableNames] Get all manufacturer names",
  GET_MANUFACTURER_NAMES_SUCCESS = "[SearchableNames] Get all manufacturer names success",

  GET_BRAND_NAMES = '[SearchableNames] Get all brand names with id',
  GET_BRAND_NAMES_SUCCESS = '[SearchableNames] Get all brand names success',

  GET_MEDICINE_NAMES = '[SearchableNames] Get all medicine names with id',
  GET_MEDICINE_NAMES_SUCCESS = '[SearchableNames] Get all medicine names success',

  UPDATE_PRICELIST_ITEM_SUCCESS = "[SearchableNames] Update price list success, update price list items",

  UPDATE_BRAND_SUCCESS = "[SearchableNames] Update brand success, set brandNames",
  UPDATE_MEDICINE_SUCCESS = "[SearchableNames] Update medicine success, set medicineNames",

  DEACTIVATE_ITEMS_SUCCESS = "[SearchableNames] Deactivate items success",

  CREATE_PRODUCT_SUCCESS = "[SearchableNames] Create Product success",
}

export class GetActivePriceListItemsAction implements Action {
  readonly type = SearchableNamesActionTypes.GET_ACTIVE_PRICE_LIST_ITEMS;

  constructor() {}
}

export class GetActivePriceListItemsSuccessAction implements Action {
  readonly type = SearchableNamesActionTypes.GET_ACTIVE_PRICE_LIST_ITEMS_SUCCESS;
  constructor(public payload: PricelistItem[]) {}
}

export class GetSupplierWithIdsAction implements Action {
  readonly type = SearchableNamesActionTypes.GET_SUPPLIER_WITH_IDS;
  constructor() {}
}

export class GetSupplierWithIdsSuccessAction implements Action {
  readonly type = SearchableNamesActionTypes.GET_SUPPLIER_WITH_IDS_SUCCESS;

  constructor(public payload: SupplierWithIds[]) {}
}

export class GetManufacturerNamesAction implements Action {
  readonly type = SearchableNamesActionTypes.GET_MANUFACTURER_NAMES;
  constructor() {}
}

export class GetManufacturerNamesSuccessAction implements Action {
  readonly type = SearchableNamesActionTypes.GET_MANUFACTURER_NAMES_SUCCESS;

  constructor(public payload: SupplierWithIds[]) {}
}

export class GetBrandNamesAction implements Action {
  readonly type = SearchableNamesActionTypes.GET_BRAND_NAMES;

  constructor() {}
}

export class GetBrandNamesSuccessAction implements Action {
  readonly type = SearchableNamesActionTypes.GET_BRAND_NAMES_SUCCESS;

  constructor(public payload: BrandNameId[]) {}
}

export class GetMedicineNamesAction implements Action {
  readonly type = SearchableNamesActionTypes.GET_MEDICINE_NAMES;

  constructor() {}
}

export class GetMedicineNamesSuccessAction implements Action {
  readonly type = SearchableNamesActionTypes.GET_MEDICINE_NAMES_SUCCESS;

  constructor(public payload: MedicineNameId[]) {}
}

export class UpdateSearchablePriceListItemsAfterPriceListItemSuccessAction implements Action {
  readonly type = SearchableNamesActionTypes.UPDATE_PRICELIST_ITEM_SUCCESS;
  constructor(public payload:
    { priceListItem: PricelistItem, oldPriceListItemId: number}) {}
}

export class UpdateBrandSuccessAction implements Action {
  readonly type = SearchableNamesActionTypes.UPDATE_BRAND_SUCCESS;

  constructor(public payload: Brand) {}
}

export class UpdateMedicineSuccessAction implements Action {
  readonly type = SearchableNamesActionTypes.UPDATE_MEDICINE_SUCCESS;

  constructor(public payload: Medicine) {}
}

export class DeactivateItemsSuccessAction implements Action {
  readonly type = SearchableNamesActionTypes.DEACTIVATE_ITEMS_SUCCESS;

  constructor(public payload: DeactivatedItems) {}
}

export class CreateProductSuccessAction implements Action {
  readonly type = SearchableNamesActionTypes.CREATE_PRODUCT_SUCCESS;

  constructor(public payload: { createProduct: CreateProduct, newPriceListItem: PricelistItem}) { }
}



export type Actions =
  | GetActivePriceListItemsAction
  | GetActivePriceListItemsSuccessAction
  | GetSupplierWithIdsAction
  | GetSupplierWithIdsSuccessAction
  | GetManufacturerNamesAction
  | GetManufacturerNamesSuccessAction
  | GetBrandNamesAction
  | GetBrandNamesSuccessAction
  | GetMedicineNamesAction
  | GetMedicineNamesSuccessAction
  | UpdateSearchablePriceListItemsAfterPriceListItemSuccessAction
  | UpdateBrandSuccessAction
  | UpdateMedicineSuccessAction
  | DeactivateItemsSuccessAction
  | CreateProductSuccessAction

