import { Entity } from './entity';

export class Permission extends Entity{
  name: string;
  label: string;
  description: string;
  category: string;
  excluded: number[];
  included: number[];

  constructor(data?: any, restore: boolean = false) {
    super(data, restore);

    if (data) {
      this.label = data.name?.split('_').join(' ');
      this.name = data.name;
      this.description = data.description;
      this.category = data.category;
      this.excluded = data.excluded;
      this.included = data.included;
    }
  }
}
