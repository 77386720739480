<div class="video-modal">
  <div class="dialog-header">
    <div class="video-title" [transloco]="title"></div>
    <button mat-stroked-button color="light" (click)="close()" transloco="BUTTONS.CLOSE"></button>
  </div>

  <div class="video-wrapper">
    <iframe width="560" height="315" [src]="safeUrl" frameborder="0" allowfullscreen></iframe>
  </div>
</div>
