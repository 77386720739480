import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '../../shared/providers/api/api.service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as actions from './actions';
import { OrderMonitorActionTypes } from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { GenericFailureAction, GenericSuccessAction } from '../app/actions';
import { Store } from '@ngrx/store';

@Injectable()
export class OrderMonitorEffects {
  constructor(private actions$: Actions, private apiService: ApiService, private store: Store) {
  }

  
  getOrderJourneys: Observable<any> = createEffect(() => this.actions$
    .pipe(
      ofType<actions.GetOrderJourneysAction>(OrderMonitorActionTypes.GET_ORDER_JOURNEYS),
      switchMap(() => this.apiService.getOrderJourneys()
        .pipe(
          map(res => new actions.GetOrderJourneysSuccessAction(res)),
          catchError(err => of(new GenericFailureAction(err)))
        ))
    ));

  
  getOrderJourney: Observable<any> = createEffect(() => this.actions$
    .pipe(
      ofType<actions.GetOrderJourneyAction>(OrderMonitorActionTypes.GET_ORDER_JOURNEY),
      switchMap((action) => this.apiService.getOrderJourney(action.payload)
        .pipe(
          map(res => new actions.GetOrderJourneySuccessAction(res)),
          catchError(err => of(new GenericFailureAction(err)))
        ))
    ));

  
  getCommissionMonitors: Observable<any> = createEffect(() => this.actions$
    .pipe(
      ofType<actions.GetFinanceMonitorsAction>(OrderMonitorActionTypes.GET_COMMISSION_MONITORS),
      switchMap(() => this.apiService.getCommissionMonitors()
        .pipe(
          map(res => new actions.GetCommissionMonitorsSuccessAction(res)),
          catchError(err => of(new GenericFailureAction(err)))
        ))
    ));

  
  getFinanceMonitors: Observable<any> = createEffect(() => this.actions$
    .pipe(
      ofType<actions.GetFinanceMonitorsAction>(OrderMonitorActionTypes.GET_FINANCE_MONITORS),
      switchMap(() => this.apiService.getFinanceMonitors()
        .pipe(
          map(res => new actions.GetFinanceMonitorsSuccessAction(res)),
          catchError(err => of(new GenericFailureAction(err)))
        ))
    ));

  
  getFinanceMonitor: Observable<any> = createEffect(() => this.actions$
    .pipe(
      ofType<actions.GetFinanceMonitorAction>(OrderMonitorActionTypes.GET_FINANCE_MONITOR),
      switchMap((action) => this.apiService.getFinanceMonitor(action.payload)
        .pipe(
          map(res => new actions.GetFinanceMonitorSuccessAction(res)),
          catchError(err => of(new GenericFailureAction(err)))
        ))
    ));

  
  contactOrderJourney: Observable<any> = createEffect(() => this.actions$
    .pipe(
      ofType<actions.SendOrderJourneyMessageAction>(OrderMonitorActionTypes.SEND_ORDER_JOURNEY_MESSAGE),
      switchMap((action) => this.apiService.contactOrderJourney(
        action.orderSupplierId,
        action.contactType,
        action.toEmail,
        action.ccEmail,
        action.bccEmail,
        action.subject,
        action.message
      )
        .pipe(
          map(res => {
            return new GenericSuccessAction(res);
          }),
          catchError(err => of(new GenericFailureAction(err)))
        ))
    ));

  
  delayOrderJourney: Observable<any> = createEffect(() => this.actions$
    .pipe(
      ofType<actions.DelayOrderJourneyAction>(OrderMonitorActionTypes.DELAY_ORDER_JOURNEY),
      switchMap((action) => this.apiService.delayOrderJourney(action.orderSupplierId, action.delay)
        .pipe(
          map(res => new actions.DelayOrderJourneySuccessAction(res)),
          catchError(err => of(new GenericFailureAction(err)))
        ))
    ));

  
  updateOrderJourneyStatus: Observable<any> = createEffect(() => this.actions$
    .pipe(
      ofType<actions.UpdateOrderJourneyStatusAction>(OrderMonitorActionTypes.UPDATE_ORDER_JOURNEY_STATUS),
      switchMap((action) => this.apiService.updateOrderJourneyStatus(action.orderSupplierId, action.status)
        .pipe(
          map(res => new actions.UpdateOrderJourneyStatusSuccessAction(res)),
          catchError(err => of(new GenericFailureAction(err)))
        ))
    ));

  
  closeOrderJourney: Observable<any> = createEffect(() => this.actions$
    .pipe(
      ofType<actions.CloseOrderJourneyAction>(OrderMonitorActionTypes.CLOSE_ORDER_JOURNEY),
      switchMap((action) => this.apiService.closeOrderJourney(action.orderSupplierId, action.message)
        .pipe(
          map(res => new actions.CloseOrderJourneySuccessAction(res)),
          catchError(err => of(new GenericFailureAction(err)))
        ))
    ));

  
  getMissingMedicines: Observable<any> = createEffect(() => this.actions$
    .pipe(
      ofType<actions.GetMissingMedicinesAction>(OrderMonitorActionTypes.GET_MISSING_MEDICINES),
      switchMap((action) => this.apiService.getMissingMedicines(action.orderId, action.includeAll)
        .pipe(
          map(res => new actions.GetMissingMedicinesSuccessAction(res)),
          catchError(err => of(new GenericFailureAction(err)))
        ))
    ));

  
  closeMissingMedicines: Observable<any> = createEffect(() => this.actions$
    .pipe(
      ofType<actions.CloseMissingMedicinesAction>(OrderMonitorActionTypes.CLOSE_MISSING_MEDICINES),
      switchMap((action) => this.apiService.closeMissingMedicines(action.orderMedicineIds, action.message)
        .pipe(
          map(res => new actions.CloseMissingMedicinesSuccessAction(res)),
          catchError(err => of(new GenericFailureAction(err)))
        ))
    ));

  
  delayMissingMedicines: Observable<any> = createEffect(() => this.actions$
    .pipe(
      ofType<actions.DelayMissingMedicinesAction>(OrderMonitorActionTypes.DELAY_MISSING_MEDICINES),
      switchMap((action) => this.apiService.delayMissingMedicines(action.orderMedicineIds, action.days)
        .pipe(
          map(res => new actions.DelayMissingMedicinesSuccessAction(res)),
          catchError(err => of(new GenericFailureAction(err)))
        ))
    ));

  
  sendMissingMedicinesMessage: Observable<any> = createEffect(() => this.actions$
    .pipe(
      ofType<actions.SendMissingMedicinesMessageAction>(OrderMonitorActionTypes.SEND_MISSING_MEDICINES_MESSAGE),
      switchMap((action) => this.apiService.sendMissingMedicinesMessage(action.contactType, action.request)
        .pipe(
          map(res => new GenericSuccessAction(res)),
          catchError(err => of(new GenericFailureAction(err)))
        ))
    ));

  
  getOrderJourneyComments: Observable<any> = createEffect(() => this.actions$
    .pipe(
      ofType<actions.GetOrderJourneyCommentsAction>(OrderMonitorActionTypes.GET_ORDER_JOURNEY_COMMENTS),
      switchMap((action) => this.apiService.getOrderJourneyComments(action.payload)
        .pipe(
          map(res => new actions.GetOrderJourneyCommentsSuccessAction(res)),
          catchError(err => of(new GenericFailureAction(err)))
        ))
    ));

  
  createOrderJourneyComment: Observable<any> = createEffect(() => this.actions$
    .pipe(
      ofType<actions.CreateOrderJourneyCommentAction>(OrderMonitorActionTypes.CREATE_ORDER_JOURNEY_COMMENT),
      switchMap((action) => this.apiService.createOrderJourneyComment(action.orderSupplierId, action.body, action.status)
        .pipe(
          map(res => new actions.CreateOrderJourneyCommentSuccessAction(res)),
          catchError(err => of(new GenericFailureAction(err)))
        ))
    ));

  
  createOrderJourneyComments: Observable<any> = createEffect(() => this.actions$
    .pipe(
      ofType<actions.CreateOrderJourneyCommentsAction>(OrderMonitorActionTypes.CREATE_ORDER_JOURNEY_COMMENTS),
      switchMap((action) => this.apiService.createOrderJourneyComments(action.form)
        .pipe(
          map(res => {
            this.store.dispatch(new GenericSuccessAction('MESSAGES.API.CREATE_COMMENTS_SUCCESS'));

            return new actions.CreateOrderJourneyCommentsSuccessAction(res);
          }),
          catchError(err => of(new GenericFailureAction(err)))
        ))
    ));
}
