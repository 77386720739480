<mat-toolbar>
  <mat-toolbar-row class="header-bar">
    <button class="d-inline-block d-md-none menu-btn" mat-icon-button (click)="openMenu()">
      <fa-icon icon="bars"></fa-icon>
    </button>

    <a class="logo" routerLink="/home"><img src="assets/images/Med4All-logo.webp"/></a>

    <span class="spacer"></span>

    <div class="user-popup-selector" [matMenuTriggerFor]="menu" #menuBtn="matMenuTrigger" *ngIf="user">
      <div class="user-avatar">{{user.initials}}</div>

      <div class="user-name-container d-none d-sm-flex">
        <span class="user-name">{{user.fullName}}</span>
        <span class="user-organization">{{user.organization?.name}}</span>
        <span class="user-role">{{user.role.name}}</span>
      </div>

      <fa-icon [icon]="menuBtn?.menuOpen ? 'chevron-up' : 'chevron-down'" class="d-none d-sm-inline-block"></fa-icon>
    </div>
  </mat-toolbar-row>

  <mat-toolbar-row class="menu-bar d-none d-md-flex" *ngIf="menuButtons.length">
    <div class="menu">
      <ng-content select="[slot=menu]"></ng-content>
    </div>
    <div class="toggle">
      <ng-content select="[slot=toggle]"></ng-content>
    </div>
  </mat-toolbar-row>


</mat-toolbar>

<mat-menu #menu="matMenu" class="profile-menu">
  <button mat-menu-item transloco="MENU.PROFILE.PROFILE" (click)="openProfile()"></button>
  <a href="assets/documents/terms_and_conditions.pdf" mat-menu-item transloco="MENU.PROFILE.TERMS" target="_blank" ></a>
  <a href="assets/documents/privacy_policy.pdf" mat-menu-item transloco="MENU.PROFILE.PRIVACY_POLICY" target="_blank" ></a>
  <a [href]="logoutUrl" mat-menu-item transloco="MENU.PROFILE.LOGOUT" ></a>
</mat-menu>

