import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  GetOrganizationsAction,
  GetTokensAction,
  GetUserAction,
  LogOutAction,
  LogOutSuccessAction,
  RecoverEmailAction,
  RefreshAccessTokenAction,
  RequestResetPasswordAction,
  ResetPasswordAction,
  UpdatePasswordAction,
  UpdateProfileAction,
} from '../../../store/auth/actions';
import { Observable } from 'rxjs';
import {
  selectAccessToken,
  selectAuthState,
  selectAuthUser,
  selectOrganizations,
} from '../../../store/auth/selectors';
import { distinctUntilChanged, map, take } from 'rxjs/operators';
import { User } from '../../models/user';
import { RecoverEmailRequest } from '../../interfaces/forget-email-request.interface';
import { Organization } from '../../models/organization';
import { PLATFORM_ROLES } from '@shared/constants';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private store: Store) {}

  logout() {
    this.store.dispatch(new LogOutAction());
  }

  loggedOut() {
    this.store.dispatch(new LogOutSuccessAction());
  }

  isAuthenticated(): Observable<boolean> {
    return this.store.pipe(
      select(selectAuthState),
      map((state) => state.user != null && state.accessToken != null),
    );
  }

  getUser(): Observable<User> {
    return this.store.pipe(select(selectAuthUser));
  }

  getTokens(code: string) {
    if (code) {
      this.store.dispatch(new GetTokensAction(code));
      this.getAccessToken().subscribe((token) => {
        if (token != null) {
          this.store.dispatch(new GetUserAction());
        }
      });
    }
  }

  resetPassword(token: string, password: string) {
    this.store.dispatch(new ResetPasswordAction(token, password));
  }

  requestResetPassword(email: string) {
    this.store.dispatch(new RequestResetPasswordAction(email));
  }

  updatePassword(oldPassword: string, password: string) {
    this.store.dispatch(new UpdatePasswordAction(oldPassword, password));
  }

  async recoverEmail(request: RecoverEmailRequest) {
    this.store.dispatch(new RecoverEmailAction(request));
  }

  getOrganizations(): Observable<Organization[]> {
    const organizations$ = this.store.pipe(select(selectOrganizations));
    this.store.dispatch(new GetOrganizationsAction());
    return organizations$;
  }

  updateProfile(profile: User, updateEmail = false) {
    this.store.dispatch(new UpdateProfileAction({user: profile, updateEmail: updateEmail}));
  }

  updateAdminConfirmPreference(receiveAdminConfirmationPopup: boolean) {
    this.getUser()
      .pipe(take(1))
      .subscribe((user) =>
        this.updateProfile(new User({ ...user, receiveAdminConfirmationPopup })),
      );
  }

  refreshAccessToken(): Observable<string> {
    const obs$: Observable<string> = this.getAccessToken().pipe(distinctUntilChanged());
    this.store.dispatch(new RefreshAccessTokenAction());
    return obs$;
  }

  getAccessToken(): Observable<string> {
    return this.store.pipe(select(selectAccessToken));
  }

  isAdmin(role: string): boolean {
    const adminRoles = [];
    adminRoles.push(PLATFORM_ROLES.SUPER_ADMIN);
    adminRoles.push(PLATFORM_ROLES.ADMIN);
    adminRoles.push(PLATFORM_ROLES.CST_ADMIN);
    adminRoles.push(PLATFORM_ROLES.CST_ADMIN_CMS);
    adminRoles.push(PLATFORM_ROLES.CST_ADMIN_PLUS);

    return adminRoles.includes(role);
  }
}
