import { Entity } from './entity';
import { Permission } from './permission';

export class EmailTemplateRecipient extends Entity {
  recipient: string;
  recipientType: string;
  permission: Permission;

  constructor(data?: any, restore: boolean = false) {
    super(data, restore);

    if (data) {
      this.recipient = data.recipient;
      this.recipientType = data.recipientType;
      this.permission = new Permission(data.permission);
    }
  }
}
