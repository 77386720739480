import {Injectable} from "@angular/core";
import {SettingsService} from "@providers/settings/settings.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute} from "@angular/router";
import {SETTING} from "@shared/constants";
import {Observable} from "rxjs";
import {PricelistItem} from "@models/pricelist-item";
import { MedicineNameId } from "@shared/interfaces/medicine-name-id.interface";
import { BrandNameId } from "@shared/interfaces/brand-name-id.interface";
import { ApiService } from "./api.service";
import { catchError } from "rxjs/operators";
import { SupplierWithIds } from "@shared/interfaces/supplier-with-ids.interface";

@Injectable({
  providedIn: 'root',
})
export class PriceUpdateOverviewService {
  private readonly API_URL: string;

  constructor(private settings: SettingsService, private http: HttpClient,
    private api: ApiService) {
    this.API_URL = `${this.settings.get(SETTING.API_URL)}`;
  }

  getActivePriceListItems(): Observable<PricelistItem[]> {
    const uri = `${this.API_URL}/price-list-items/active-pricelist-items`;
    return this.http.get<PricelistItem[]>(uri);
  }

  getSupplierWithIds() {
    const uri = `${this.API_URL}/organizations/organization-with-ids`;
    return this.http.get<SupplierWithIds[]>(uri);
  }

  getManufacturerNames() {
    const uri = `${this.API_URL}/organizations/manufacturers-with-ids`;
    return this.http.get<SupplierWithIds[]>(uri);
  }

  getBrandNames(): Observable<BrandNameId[]> {
    const uri = `${this.API_URL}/brands/active-brand-names`;
    return this.http.get<BrandNameId[]>(uri);
  }

  getMedicineNames(): Observable<MedicineNameId[]> {
    const uri = `${this.API_URL}/medicines/active-medicine-names`;
    return this.http.get<MedicineNameId[]>(uri);
  }

  updatePricelistItem(pricelistItem: PricelistItem): Observable<any> {
    const uri = `${this.API_URL}/price-list-items/${pricelistItem.id}`;
    return this.http.put<PricelistItem>(uri, pricelistItem)
    .pipe(catchError((err) => this.api._serverError(err)));
  }
}
