<div class="order-message-dialog-container">
  <div class="dialog-header">
    <h2 transloco="DIALOGS.ORDER_MESSAGE.TITLE"></h2>
    <button mat-icon-button (click)="close()">
      <fa-icon icon="times"></fa-icon>
    </button>
  </div>

  <div class="message-container" [innerHTML]="message | transloco">
  </div>

  <div class="text-center full-width mt-2">
    <button mat-button
            color="primary"
            (click)="close()"
            transloco="BUTTONS.CLOSE"
            class="mr-2"
    >
    </button>
  </div>
</div>
