import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-loader-dialog',
  templateUrl: './loader-dialog.component.html',
})
export class LoaderDialogComponent implements OnInit {
  loadingText: string;
  constructor(@Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit() {
    this.loadingText = this.data.content;
  }

}
