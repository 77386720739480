import { Entity } from './entity';

export class MedicineForm extends Entity {
  id: number;
  formName: string;
  routeOfAdministration: string;

  constructor(data?: any, restore: boolean = false) {
    super();
    if (data) {
      this.id = data.id;
      this.formName = data.formName;
      this.routeOfAdministration = data.routeOfAdministration;
    }
  }
}
