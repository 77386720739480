import { FacilityDetails } from '../interfaces/facility-details.interface';
import { Entity } from './entity';
import { Setting } from '../interfaces/setting.interface';

export class Organization extends Entity {
  name: string;
  shortName: string;
  type: string;
  facilityDetails: FacilityDetails;
  users: any[];
  settings: Setting[];
  organizationEmail: string;
  accountNumber: string;
  dsrAccountNumber: string;
  bankId: number;
  bank: Organization;
  swiftBic: string;
  processFlow: string;
  branch: string;
  whtExempt: boolean;
  minimumOrderValue: number;

  constructor(data?: any, restore: boolean = false) {
    super(data, restore);

    if (data) {
      this.name = data.name;
      this.shortName = data.shortName;
      this.type = data.type;
      this.facilityDetails = data.facilityDetails;
      this.users = data.users || [];
      this.settings = data.settings || [];
      this.organizationEmail = data.organizationEmail;
      this.accountNumber = data.accountNumber;
      this.dsrAccountNumber = data.dsrAccountNumber;
      this.bankId = data.bankId;
      this.bank = data.bank ? new Organization(data.bank) : null;
      this.swiftBic = data.swiftBic;
      this.processFlow = data.processFlow;
      this.branch = data.branch;
      this.whtExempt = data.whtExempt;
      this.minimumOrderValue = data.minimumOrderValue;
    }
  }

  getSetting(name: string): any {
    const setting = this.settings.find(_setting => _setting.name === name);
    if (setting) {
      switch (setting.dataType) {
        case 'Integer':
          return parseInt(setting.value, 10);
        case 'String':
          return setting.value;
      }
    }
  }
}
