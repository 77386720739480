import { Entity } from './entity';

export class Message extends Entity {
  title: string;
  text: string;
  messageByUserId: number;

  constructor(data?: any, restore: boolean = false) {
    super(data, restore);

    if (data) {
      this.text = data.text;
      this.title = data.title;
      this.messageByUserId = data.messageByUserId;
    }
  }
}
