import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { NotificationComponent } from '../../components/notification/notification.component';
import { take } from 'rxjs/operators';
import { NotificationConfig } from '../../interfaces/notification-config.interface';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private DEFAULT_CONFIG = {
    duration: 10000,
    panelClass: 'snackbar',
  };

  constructor(private snackBar: MatSnackBar, private translateService: TranslocoService) {}

  onMessage(config: NotificationConfig) {
    this._openSnackbar({ ...this.DEFAULT_CONFIG, ...config });
  }

  onSuccess(config: NotificationConfig) {
    this._openSnackbar({ ...this.DEFAULT_CONFIG, ...config, panelClass: 'success' });
  }

  onWarning(config: NotificationConfig) {
    this._openSnackbar({ ...this.DEFAULT_CONFIG, ...config, panelClass: 'warning' });
  }

  onError(config: NotificationConfig) {
    this._openSnackbar({ ...this.DEFAULT_CONFIG, ...config, panelClass: 'error' });
  }

  private _openSnackbar(config: NotificationConfig) {
    let snackbarRef: MatSnackBarRef<any>;
    const message: string = config.message
      ? config.message.toString()
      : 'MESSAGES.API.UNKNOWN_ERROR';
    if (config.simple) {
      snackbarRef = this.snackBar.open(
        this.translateService.translate(message, config.messageParams),
        this.translateService.translate(config.action || 'BUTTONS.CLOSE'),
        config,
      );
    } else {
      snackbarRef = this.snackBar.openFromComponent(NotificationComponent, {
        ...config,
        data: {
          message,
          messageParams: config.messageParams,
          icon: config.icon,
        },
      });
    }

    if (config.onAction) {
      snackbarRef
        .onAction()
        .pipe(take(1))
        .subscribe((res) => config.onAction(res));
    }
  }
}
