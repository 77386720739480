import { Entity } from './entity';
import { Permission } from './permission';

export class Role extends Entity{
  name: string;
  description: string;
  users: any[]; // in order to prevent circular dependency, we don't cast the users to the user model
  userIds: number[];
  permissions: Permission[];
  permissionIds: number[];
  permissionCount: number;
  userCount: number;

  constructor(data?: any, restore: boolean = false) {
    super(data);
    if (data) {
      this.name = data.name;
      this.description = data.description;
      this.users = data.users ? data.users : [];
      this.userIds = data.userIds;
      this.permissions = data.permissions ? data.permissions.map(permission => new Permission(permission, restore)) : [];
      this.permissionIds = data.permissionIds;
      this.permissionCount = data.permissionCount;
      this.userCount = data.userCount;
    }
  }

  get removable(): boolean {
    return this.userCount > 0;
  }
}
