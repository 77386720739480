<ng-container *transloco="let t">
  <div class="messages-component card">
    <h1 class="card-header">{{t('PAGES.ORDERS_HOME.MESSAGES.TITLE')}}</h1>
    <div class="row">
      <div class="col-6">
        <ul class="message-list" *ngIf="(messages$ | async) as messages; else noMessages">
          <li *ngFor="let message of messages" class="message-item">
            <div class="message-title">
              {{message.title}}
              <button *appPermission="[PERMISSION.MESSAGES.DELETE]" mat-icon-button (click)="removeMessage(message)">
                <fa-icon icon="times"></fa-icon>
              </button>
            </div>
            <div class="message-content">{{message.text}}</div>
            <div class="message-date">{{message.createdOn | date}}</div>
          </li>
        </ul>

        <ng-template #noMessages>{{t('PAGES.ORDERS_HOME.MESSAGES.NO_MESSAGES')}}</ng-template>
      </div>

      <div class="col-6" *appPermission="[PERMISSION.MESSAGES.CREATE]">
        <h3>{{t('PAGES.ORDERS_HOME.MESSAGES.CREATE_MESSAGE')}}</h3>
        <form [formGroup]="messageForm">
          <mat-form-field>
            <mat-label>{{t('PAGES.ORDERS_HOME.MESSAGES.MESSAGE_TITLE')}}</mat-label>
            <input matInput required formControlName="title">
            <mat-error *ngIf="titleCtrl.invalid && titleCtrl.dirty">{{t('MESSAGES.FORM.TITLE_REQUIRED_ERROR')}}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{t('PAGES.ORDERS_HOME.MESSAGES.MESSAGE_CONTENT')}}</mat-label>
            <textarea
              matInput
              matTextareaAutosize
              matAutosizeMinRows="3"
              required
              formControlName="text"
            ></textarea>
            <mat-error *ngIf="textCtrl.invalid && textCtrl.dirty">{{t('MESSAGES.FORM.MESSAGE_REQUIRED_ERROR')}}</mat-error>
          </mat-form-field>

          <button
            mat-raised-button
            (click)="createMessage()"
            color="primary"
            [disabled]="messageForm.invalid"
            class="float-right"
          >
            {{t('BUTTONS.SAVE')}}
          </button>
        </form>
      </div>
    </div>
  </div>
</ng-container>
