<div class="reject-order-dialog-container">
  <div class="dialog-header">
    <h2 [transloco]="title"></h2>
    <button mat-icon-button (click)="close(false)">
      <fa-icon icon="times"></fa-icon>
    </button>
  </div>

  <div class="mb-3" style="white-space: pre-line">
    {{description | transloco}}
  </div>

  <mat-form-field>
    <textarea
      #messageCtrl
      maxlength="500"
      matInput
      matTextareaAutosize
      matAutosizeMinRows="3"
      required
      [(ngModel)]="message"
    >
    </textarea>
    <mat-hint align="end">{{messageCtrl.value.length}} / 500</mat-hint>
  </mat-form-field>

  <div class="text-center full-width mt-2">
    <button mat-button
            color="primary"
            (click)="close(false)"
            [transloco]="cancelButtonText"
            class="mr-2"
    >
    </button>
    <button mat-raised-button
            color="primary"
            (click)="close(true)"
            [transloco]="okButtonText"
            [disabled]="this.message === ''"
    >
    </button>
  </div>
</div>
