import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { OrdersReducer } from './reducer';
import { OrdersEffects } from './effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('orders', OrdersReducer),
    EffectsModule.forFeature([OrdersEffects])
  ],
  providers: [OrdersEffects]
})
export class OrdersStoreModule { }
