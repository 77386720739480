import {Action} from '@ngrx/store';
import { Translation } from '@ngneat/transloco';
import { ApiValidatonError } from '../../shared/interfaces/api-validaton-error.interface';

export enum AppActionTypes {
  GENERIC_FAILURE_EXPECTED = '[App] Generic failure expected',
  GENERIC_FAILURE = '[App] Generic failure',
  GENERIC_SUCCESS = '[App] Generic success',
  VALIDATION_ERRORS = '[App] Validation errors',
  CLEAR_LOADER = '[App] Clear loader',
  GET_TRANSLATIONS = '[App] Get translations',
  GET_TRANSLATIONS_SUCCESS = '[App] Get translations success',
  SET_ALERT = '[App] Set alert',
  CLEAR_ALERT = '[App] Clear alert',
  SET_SIDENAV = '[App] Set sidenav'
}

export class GenericFailureAction implements Action {
  readonly type = AppActionTypes.GENERIC_FAILURE;
  constructor(public payload: any) {}
}

export class GenericFailureExpectedAction implements Action {
  readonly type = AppActionTypes.GENERIC_FAILURE_EXPECTED;
  constructor(public payload: any) {}
}

export class GenericSuccessAction implements Action {
  readonly type = AppActionTypes.GENERIC_SUCCESS;
  constructor(public payload: string) {}
}

export class ValidationErrorsAction implements Action {
  readonly type = AppActionTypes.VALIDATION_ERRORS;
  constructor(public payload: ApiValidatonError[]) {}
}

export class ClearLoaderAction implements Action {
  readonly type = AppActionTypes.CLEAR_LOADER;
}

export class SetAlertAction implements Action {
  readonly type = AppActionTypes.SET_ALERT;
  constructor(public message: string, public alertType: string, public params?: any) {
  }
}

export class ClearAlertAction implements Action {
  readonly type = AppActionTypes.CLEAR_ALERT;
}

export class GetTranslationsAction implements Action {
  readonly type = AppActionTypes.GET_TRANSLATIONS;
  constructor(public payload: string) {
  }
}

export class GetTranslationsSuccessAction implements Action {
  readonly type = AppActionTypes.GET_TRANSLATIONS_SUCCESS;
  constructor(public payload: Translation) {
  }
}

export class SetSideNavAction implements Action {
  readonly type = AppActionTypes.SET_SIDENAV;
  constructor(public payload?: boolean) {
  }
}

export type Actions =
  | GenericFailureAction
  | GenericFailureExpectedAction
  | GenericSuccessAction
  | ClearLoaderAction
  | GetTranslationsAction
  | GetTranslationsSuccessAction
  | ValidationErrorsAction
  | SetAlertAction
  | ClearAlertAction
  ;
