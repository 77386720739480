import {
  Component,
  ContentChildren,
  ElementRef,
  HostBinding,
  OnInit,
  QueryList,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { User } from '../../models/user';
import { SETTING } from '../../constants';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BaseComponent } from '../base/base.component';
import { SetSideNavAction } from '../../../store/app/actions';
import { AuthService } from '../../providers/auth/auth.service';
import { SettingsService } from '../../providers/settings/settings.service';
import { ProfileDialogComponent } from '../dialogs/profile-dialog/profile-dialog.component';

@Component({
  selector: 'app-auth-header',
  templateUrl: './auth-header.component.html',
})
export class AuthHeaderComponent extends BaseComponent implements OnInit {
  user: User;
  logoutUrl: string;
  @HostBinding() class = 'auth-header';
  @ContentChildren('menu') menuButtons: QueryList<ElementRef>;

  constructor(
    private auth: AuthService,
    private dialog: MatDialog,
    private store: Store,
    private settingsService: SettingsService,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.auth
      .getUser()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((res) => (this.user = res));

    let oAuthClientId = SETTING.OAUTH_CLIENT_ID;
    if (window.location.href?.startsWith('https://new.')) {
      oAuthClientId = SETTING.OAUTH_CLIENT_ID_TEMP;
    }

    this.logoutUrl = `${this.settingsService.get(
      SETTING.AUTH_URL,
    )}/logout?client_id=${this.settingsService.get(oAuthClientId)}`;

    this.route.fragment.pipe(takeUntil(this._unsubscribe$)).subscribe((fragment) => {
      if (fragment && fragment === 'profile') {
        this.openProfile();
      }
    });
  }

  openProfile() {
    this.dialog.open(ProfileDialogComponent, {
      panelClass: 'profile-modal',
      data: {
        user: this.user,
      },
    });
  }

  openMenu() {
    this.store.dispatch(new SetSideNavAction());
  }
}
