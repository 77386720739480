import { Action } from '@ngrx/store';
import { OrderJourney } from '../../monitor/shared/interfaces/order-journey.interface';
import { OrderJourneyComment } from '../../monitor/shared/interfaces/order-journey-comment.interface';
import { MissingMedicineContactRequest } from '../../monitor/shared/interfaces/missing-medicine-contact-request.interface';
import { OrderJourneyMedicine } from '../../monitor/shared/interfaces/order-journey-medicine.interface';

export enum OrderMonitorActionTypes {
  GET_ORDER_JOURNEYS = '[OrderMonitor] Get order Journeys',
  GET_ORDER_JOURNEYS_SUCCESS = '[OrderMonitor] Get Order Journeys Success',
  GET_ORDER_JOURNEY = '[OrderMonitor] Get Order Journey',
  GET_ORDER_JOURNEY_SUCCESS = '[OrderMonitor] Get Order Journey Success',
  GET_FINANCE_MONITORS = '[OrderMonitor] Get Finance Monitors',
  GET_FINANCE_MONITORS_SUCCESS = '[OrderMonitor] Get Finance Monitors Success',
  GET_COMMISSION_MONITORS = '[OrderMonitor] Get Commission Monitors',
  GET_COMMISSION_MONITORS_SUCCESS = '[OrderMonitor] Get Commission Monitors Success',
  GET_FINANCE_MONITOR = '[OrderMonitor] Get Finance Monitor',
  GET_FINANCE_MONITOR_SUCCESS = '[OrderMonitor] Get Finance Monitor Success',
  SEND_ORDER_JOURNEY_MESSAGE = '[OrderMonitor] Send Finace Monitor Message',
  DELAY_ORDER_JOURNEY = '[OrderMonitor] Delay Order Journey',
  DELAY_ORDER_JOURNEY_SUCCESS = '[OrderMonitor] Delay Order Journey Success',
  CLOSE_ORDER_JOURNEY = '[OrderMonitor] Close Order Journey',
  CLOSE_ORDER_JOURNEY_SUCCESS = '[OrderMonitor] Close Order Journey Success',
  GET_MISSING_MEDICINES = '[OrderMonitor] Get missing medicines',
  GET_MISSING_MEDICINES_SUCCESS = '[OrderMonitor] Get missing medicines Success',
  CLOSE_MISSING_MEDICINES = '[OrderMonitor] Close Missing Medicines',
  CLOSE_MISSING_MEDICINES_SUCCESS = '[OrderMonitor] Close Missing Medicines Success',
  DELAY_MISSING_MEDICINES = '[OrderMonitor] Delay Missing Medicines',
  DELAY_MISSING_MEDICINES_SUCCESS = '[OrderMonitor] Delay Missing Medicines Success',
  SEND_MISSING_MEDICINES_MESSAGE = '[OrderMonitor] Send Missing Medicines Message',
  GET_ORDER_JOURNEY_COMMENTS = '[OrderMonitor] Get order journey comments',
  GET_ORDER_JOURNEY_COMMENTS_SUCCESS = '[OrderMonitor] Get order journey comments success',
  CREATE_ORDER_JOURNEY_COMMENT = '[OrderMonitor] Create order journey comment',
  CREATE_ORDER_JOURNEY_COMMENT_SUCCESS = '[OrderMonitor] Create order journey comment success',
  CREATE_ORDER_JOURNEY_COMMENTS = '[OrderMonitor] Create order journey comments',
  CREATE_ORDER_JOURNEY_COMMENTS_SUCCESS = '[OrderMonitor] Create order journey comments success',
  UPDATE_ORDER_JOURNEY_STATUS = '[OrderMonitor] Update order journey status',
  UPDATE_ORDER_JOURNEY_STATUS_SUCCESS = '[OrderMonitor] Update order journey status success',
}

export class GetOrderJourneysAction implements Action {
  readonly type = OrderMonitorActionTypes.GET_ORDER_JOURNEYS;
}

export class GetOrderJourneysSuccessAction implements Action {
  readonly type = OrderMonitorActionTypes.GET_ORDER_JOURNEYS_SUCCESS;
  constructor(public payload: OrderJourney[]) {}
}

export class GetOrderJourneyAction implements Action {
  readonly type = OrderMonitorActionTypes.GET_ORDER_JOURNEY;
  constructor(public payload: number) {}
}

export class GetOrderJourneySuccessAction implements Action {
  readonly type = OrderMonitorActionTypes.GET_ORDER_JOURNEY_SUCCESS;
  constructor(public payload: OrderJourney[]) {}
}

export class GetCommissionMonitorsAction implements Action {
  readonly type = OrderMonitorActionTypes.GET_COMMISSION_MONITORS;
}

export class GetCommissionMonitorsSuccessAction implements Action {
  readonly type = OrderMonitorActionTypes.GET_COMMISSION_MONITORS_SUCCESS;
  constructor(public payload: OrderJourney[]) {}
}

export class GetFinanceMonitorsAction implements Action {
  readonly type = OrderMonitorActionTypes.GET_FINANCE_MONITORS;
}

export class GetFinanceMonitorsSuccessAction implements Action {
  readonly type = OrderMonitorActionTypes.GET_FINANCE_MONITORS_SUCCESS;
  constructor(public payload: OrderJourney[]) {}
}

export class GetFinanceMonitorAction implements Action {
  readonly type = OrderMonitorActionTypes.GET_FINANCE_MONITOR;
  constructor(public payload: number) {}
}

export class GetFinanceMonitorSuccessAction implements Action {
  readonly type = OrderMonitorActionTypes.GET_FINANCE_MONITOR_SUCCESS;
  constructor(public payload: OrderJourney[]) {}
}

export class SendOrderJourneyMessageAction implements Action {
  readonly type = OrderMonitorActionTypes.SEND_ORDER_JOURNEY_MESSAGE;
  constructor(
    public orderSupplierId: number,
    public contactType: string,
    public toEmail: string,
    public ccEmail: string,
    public bccEmail: string,
    public subject: string,
    public message: string
  ) {}
}

export class DelayOrderJourneyAction implements Action {
  readonly type = OrderMonitorActionTypes.DELAY_ORDER_JOURNEY;
  constructor(public orderSupplierId: number, public delay: number) {}
}

export class DelayOrderJourneySuccessAction implements Action {
  readonly type = OrderMonitorActionTypes.DELAY_ORDER_JOURNEY_SUCCESS;
  constructor(public payload: OrderJourney) {}
}

export class CloseOrderJourneyAction implements Action {
  readonly type = OrderMonitorActionTypes.CLOSE_ORDER_JOURNEY;
  constructor(public orderSupplierId: number, public message?: string) {}
}

export class CloseOrderJourneySuccessAction implements Action {
  readonly type = OrderMonitorActionTypes.CLOSE_ORDER_JOURNEY_SUCCESS;
  constructor(public payload: OrderJourney) {}
}

export class GetMissingMedicinesAction implements Action {
  readonly type = OrderMonitorActionTypes.GET_MISSING_MEDICINES;
  constructor(public orderId?: number, public includeAll?: boolean) {}
}

export class GetMissingMedicinesSuccessAction implements Action {
  readonly type = OrderMonitorActionTypes.GET_MISSING_MEDICINES_SUCCESS;
  constructor(public payload: OrderJourneyMedicine[]) {}
}

export class CloseMissingMedicinesAction implements Action {
  readonly type = OrderMonitorActionTypes.CLOSE_MISSING_MEDICINES;
  constructor(public orderMedicineIds: number[], public message?: string) {}
}

export class CloseMissingMedicinesSuccessAction implements Action {
  readonly type = OrderMonitorActionTypes.CLOSE_MISSING_MEDICINES_SUCCESS;
  constructor(public payload: OrderJourneyMedicine[]) {}
}

export class DelayMissingMedicinesAction implements Action {
  readonly type = OrderMonitorActionTypes.DELAY_MISSING_MEDICINES;
  constructor(public orderMedicineIds: number[], public days: number) {}
}

export class DelayMissingMedicinesSuccessAction implements Action {
  readonly type = OrderMonitorActionTypes.DELAY_MISSING_MEDICINES_SUCCESS;
  constructor(public payload: OrderJourneyMedicine[]) {}
}

export class SendMissingMedicinesMessageAction implements Action {
  readonly type = OrderMonitorActionTypes.SEND_MISSING_MEDICINES_MESSAGE;
  constructor(public contactType: string, public request: MissingMedicineContactRequest) {}
}

export class GetOrderJourneyCommentsAction implements Action {
  readonly type = OrderMonitorActionTypes.GET_ORDER_JOURNEY_COMMENTS;
  constructor(public payload: number) {}
}

export class GetOrderJourneyCommentsSuccessAction implements Action {
  readonly type = OrderMonitorActionTypes.GET_ORDER_JOURNEY_COMMENTS_SUCCESS;
  constructor(public payload: OrderJourneyComment[]) {}
}

export class CreateOrderJourneyCommentAction implements Action {
  readonly type = OrderMonitorActionTypes.CREATE_ORDER_JOURNEY_COMMENT;
  constructor(public orderSupplierId: number, public body: string, public status: string) {}
}

export class CreateOrderJourneyCommentSuccessAction implements Action {
  readonly type = OrderMonitorActionTypes.CREATE_ORDER_JOURNEY_COMMENT_SUCCESS;
  constructor(public payload: OrderJourneyComment) {}
}

export class CreateOrderJourneyCommentsAction implements Action {
  readonly type = OrderMonitorActionTypes.CREATE_ORDER_JOURNEY_COMMENTS;
  constructor(public form: any) {}
}

export class CreateOrderJourneyCommentsSuccessAction implements Action {
  readonly type = OrderMonitorActionTypes.CREATE_ORDER_JOURNEY_COMMENTS_SUCCESS;
  constructor(public payload: OrderJourneyComment[]) {}
}

export class UpdateOrderJourneyStatusAction implements Action {
  readonly type = OrderMonitorActionTypes.UPDATE_ORDER_JOURNEY_STATUS;
  constructor(public orderSupplierId: number, public status: string) {}
}

export class UpdateOrderJourneyStatusSuccessAction implements Action {
  readonly type = OrderMonitorActionTypes.UPDATE_ORDER_JOURNEY_STATUS_SUCCESS;
  constructor(public payload: OrderJourney) {}
}

export type Actions =
  | GetOrderJourneysAction
  | GetOrderJourneysSuccessAction
  | GetOrderJourneyAction
  | GetOrderJourneySuccessAction
  | GetCommissionMonitorsAction
  | GetCommissionMonitorsSuccessAction
  | GetFinanceMonitorsAction
  | GetFinanceMonitorsSuccessAction
  | GetFinanceMonitorAction
  | GetFinanceMonitorSuccessAction
  | DelayOrderJourneyAction
  | DelayOrderJourneySuccessAction
  | CloseOrderJourneyAction
  | CloseOrderJourneySuccessAction
  | GetMissingMedicinesAction
  | GetMissingMedicinesSuccessAction
  | CloseMissingMedicinesAction
  | CloseMissingMedicinesSuccessAction
  | DelayMissingMedicinesAction
  | DelayMissingMedicinesSuccessAction
  | GetOrderJourneyCommentsAction
  | GetOrderJourneyCommentsSuccessAction
  | CreateOrderJourneyCommentAction
  | CreateOrderJourneyCommentSuccessAction
  | CreateOrderJourneyCommentsAction
  | CreateOrderJourneyCommentsSuccessAction
  | UpdateOrderJourneyStatusAction
  | UpdateOrderJourneyStatusSuccessAction
  ;
