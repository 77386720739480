import { orderBy } from 'lodash';
import { Observable } from 'rxjs';
import { Message } from '../../models/message';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { map, take, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base/base.component';
import { OrdersService } from '../../../orders/shared/providers/orders.service';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
})
export class MessagesComponent extends BaseComponent implements OnInit {
  messages$: Observable<Message[]>;
  messageForm: UntypedFormGroup;

  get titleCtrl(): AbstractControl {
    return this.messageForm.get('title');
  }

  get textCtrl(): AbstractControl {
    return this.messageForm.get('text');
  }

  constructor(
    private ordersService: OrdersService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
  ) {
    super();

    this.messageForm = this.formBuilder.group({
      title: [null, Validators.required],
      text: [null, Validators.required],
    });
  }

  ngOnInit() {
    super.ngOnInit();

    this.messages$ = this.ordersService.getMessages().pipe(
      takeUntil(this._unsubscribe$),
      map((messages) => orderBy(messages, ['createdOn'], ['desc'])),
    );
  }

  createMessage() {
    this.ordersService.createMessage(this.messageForm.value);
    this.messageForm.reset();
  }

  removeMessage(message: Message) {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: { question: 'PAGES.ORDERS_HOME.MESSAGES.CONFIRMATION' },
      })
      .afterClosed()
      .pipe(take(1), takeUntil(this._unsubscribe$))
      .subscribe((res) => {
        if (res) {
          this.ordersService.removeMessage(message);
        }
      });
  }
}
