import { Medicine } from '../../shared/models/medicine';
import { Formulary } from '../../shared/models/formulary';
import { Brand } from '../../shared/models/brand';
import { Keyword } from '../../shared/models/keyword';
import { Pricelist } from '../../shared/models/pricelist';
import { Filter } from '../../shared/interfaces/filter.interface';
import { ORDERSTATUS, SORT_DIRECTION } from '../../shared/constants';
import { PrescribingLevel } from '../../shared/interfaces/prescribing-level.interface';
import { StockTake } from '../../shared/models/stock-take';
import { Message } from '../../shared/models/message';
import { Order } from '../../shared/models/order';
import { PaymentMethod } from '../../shared/interfaces/payment-method.interface';
import { McfInfo } from '../../shared/models/mcf-info';
import { OverviewAction } from '../../shared/interfaces/overview-action.interface';
import { Document } from '../../shared/models/document';
import { WithdrawalRequest } from '../../shared/models/withdrawal-request';
import { MedicineForm } from '../../shared/models/medicine-form';
import { Inventory } from '../../shared/models/inventory';
import { Pageable } from '../../shared/interfaces/pageable.interface';
import { Storeroom } from '../../shared/models/storeroom';
import {NewPriceListItemWithOldId} from "@interfaces/new-pricelist-item.interface";
import { PricelistItem } from '@shared/models/pricelist-item';
import { OrderSupplier } from '@shared/models/order-supplier';

export interface OrdersState {
  formulary: Formulary;
  medicines: Medicine[];
  previouslyOrderedmedicines: Medicine[];
  orderAdvice: Medicine[];
  medicineForms: MedicineForm[];
  brands: Brand[];
  keywords: Keyword[];
  pricelists: Pricelist[];
  medicineFilter: Filter;
  orderFilter: Filter;
  deliveryOrderFilter: Filter;
  documentFilter: Filter;
  prescribingLevels: PrescribingLevel[];
  stockTake: StockTake;
  stockTakes: Pageable<StockTake>;
  inventories: Pageable<Inventory>;
  messages: Message[];
  cart: Order;
  currentOrders: Order[];
  previousOrders: Order[];
  order: Order;
  paymentMethods: PaymentMethod[];
  mcfInfo: McfInfo;
  actions: OverviewAction[];
  documents: Pageable<Document>;
  reports: {id: string; data: any[]}[];
  withdrawalRequests: WithdrawalRequest[];
  storerooms: Storeroom[];

  newPriceListItems: NewPriceListItemWithOldId[];
  otherSuppliers: Map<number, PricelistItem[]>;
  priceListItemToChange: [number, PricelistItem, number];
}

export const initialOrdersState: OrdersState = {
  formulary: null,
  deliveryOrderFilter: {
    sortDirection: SORT_DIRECTION.ASC,
    sortBy: 'brand',
    searchTerm: null,
    filters: [],
  },
  medicines: null,
  previouslyOrderedmedicines: null,
  orderAdvice: null,
  medicineForms: null,
  brands: [],
  keywords: [],
  pricelists: [],
  medicineFilter: {
    sortDirection: SORT_DIRECTION.ASC,
    sortBy: 'genericName',
    filters: [],
    searchTerm: null
  },
  orderFilter: {
    sortDirection: SORT_DIRECTION.DESC,
    sortBy: 'lastUpdate',
    searchTerm: null,
    filters: [],
  },
  documentFilter: {
    sortDirection: SORT_DIRECTION.DESC,
    sortBy: 'createdOn',
    searchTerm: null,
    pageSize: 2000
  },
  prescribingLevels: null,
  stockTake: null,
  stockTakes: null,
  inventories: null,
  messages: null,
  cart: new Order({
    currentOrderStatus: {
      status: ORDERSTATUS.DRAFT
    }
  }),
  currentOrders: [],
  previousOrders: [],
  order: null,
  paymentMethods: null,
  mcfInfo: null,
  actions: null,
  documents: null,
  reports: [],
  withdrawalRequests: null,
  storerooms: [],
  newPriceListItems: [],
  otherSuppliers: null,
  priceListItemToChange: null,
};
