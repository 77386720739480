import { INIT } from '@ngrx/store';
import { Organization } from '../../shared/models/organization';
import { User } from '../../shared/models/user';
import { Role } from '../../shared/models/role';
import { RootState } from '../root-state';
import { Formulary } from '../../shared/models/formulary';
import { Medicine } from '../../shared/models/medicine';
import { Brand } from '../../shared/models/brand';
import { Keyword } from '../../shared/models/keyword';
import { Pricelist } from '../../shared/models/pricelist';
import { StockTake } from '../../shared/models/stock-take';
import { Message } from '../../shared/models/message';
import { Order } from '../../shared/models/order';
import { Document } from '../../shared/models/document';
import { WithdrawalRequest } from '../../shared/models/withdrawal-request';
import { McfInfo } from '../../shared/models/mcf-info';
import { Permission } from '../../shared/models/permission';
import { MedicineForm } from '../../shared/models/medicine-form';
import * as Flatted from 'flatted';
import { storageKey } from './storageKey';

export function rehydrateReducer(reducer) {
  return (state, action) => {
    if (action.type === INIT) {
      const raw = localStorage.getItem(storageKey);
      const isObjectRegex = new RegExp('{|\\[');
      let restoredState;
      let rehydratedState = {};

      if (raw !== null && isObjectRegex.test(raw.charAt(0))) {
        try {
          restoredState = Flatted.parse(raw);
          rehydratedState = Object.keys(restoredState).reduce((acc: any, curr: string) => {
            const slice = restoredState[curr];
            switch (curr) {
              case 'app':
                return _restoreAppState(acc, slice);
              case 'auth':
                return _restoreAuthState(acc, slice);
              case 'admin':
                return _restoreAdminState(acc, slice);
              case 'orders':
                return _restoreOrdersState(acc, slice);
              case 'cms':
                return _restoreCmsState(acc, slice);
              default:
                return _restoreDefaultState(acc, curr, slice);
            }
          }, {});

          state = {...state, ...rehydratedState};
        } catch (err) {
          console.error(`Unable to rehydrate the state, error: ${err}`);
        }
      }
    }

    return reducer(state, action);
  };
}

function _restoreAppState(state: RootState, slice) {
  return {
    ...state,
    app: {
      ...slice
    }
  };
}

function _restoreAuthState(state: RootState, slice) {
  return {
    ...state,
    auth: {
      ...slice,
      user: slice.user
        ? new User(slice.user, true)
        : null
    }
  };
}

function _restoreAdminState(state: RootState, slice) {
  return {
    ...state,
    admin: {
      ...slice,
      organizations: slice.organizations
        ? slice.organizations.map(org => new Organization(org, true))
        : null,
      organization: slice.organization
        ? new Organization(slice.organization, true)
        : null,
      roles: slice.roles
        ? slice.roles.map(role => new Role(role))
        : null,
      role: slice.role
        ? new Role(slice.role)
        : null,
      user: slice.user
        ? new User(slice.user, true)
        : null,
      users: slice.users
        ? slice.users.map(user => new User(user, true))
        : [],
      withdrawalRequests: slice.withdrawalRequests
        ? slice.withdrawalRequests.map(request => new WithdrawalRequest(request, true))
        : [],
//       documents: slice.documents
//         ? slice.documents.map(doc => new Document(doc, true))
//         : [],
      permissions: slice.permissions
        ? slice.permissions.map(permission => new Permission(permission, true))
        : [],
    }
  };
}

function _restoreOrdersState(state: RootState, slice) {
  return {
    ...state,
    orders: {
      ...slice,
      formulary: slice.formulary
        ? new Formulary(slice.formulary, true)
        : null,
      medicines: slice.medicines
        ? slice.medicines.map(medicine => new Medicine(medicine, true))
        : [],
      medicineForms: slice.medicineForms
        ? slice.medicineForms.map(form => new MedicineForm(form, true))
        : [],
      previouslyOrderedmedicines: slice.previouslyOrderedmedicines
        ? slice.previouslyOrderedmedicines.map(medicine => new Medicine(medicine, true))
        : [],
      orderAdvice: slice.orderAdvice
        ? slice.orderAdvice.map(medicine => new Medicine(medicine, true))
        : [],
      brands: slice.brands
        ? slice.brands.map(brand => new Brand(brand, true))
        : [],
      keywords: slice.keywords
        ? slice.keywords.map(keyword => new Keyword(keyword, true))
        : [],
      pricelists: slice.pricelists
        ? slice.pricelists.map(pricelist => new Pricelist(pricelist, true))
        : [],
      stockTake: slice.stockTake
        ? new StockTake(slice.stockTake, true)
        : null,
      messages: slice.messages
        ? slice.messages.map(message => new Message(message, true))
        : [],
      cart: slice.cart
        ? new Order(slice.cart, true)
        : null,
      mcfInfo: slice.mcfInfo
        ? new McfInfo(slice.mcfInfo, true)
        : null,
      order: slice.order
        ? new Order(slice.order, true)
        : null,
      previousOrders: slice.previousOrders
        ? slice.previousOrders.map(order => new Order(order, true))
        : null,
      currentOrders: slice.currentOrders
        ? slice.currentOrders.map(order => new Order(order, true))
        : null,
//       documents: slice.documents
//         ? slice.documents.map(d => new Document(d, true))
//         : null,
      withdrawalRequests: slice.withdrawalRequests
        ? slice.withdrawalRequests.map(request => new WithdrawalRequest(request, true))
        : null
    }
  };
}

function _restoreCmsState(state, slice) {
  return {
    ...state,
    medicines: slice.medicines
      ? slice.medicines.map(medicine => new Medicine(medicine, true))
      : [],
    brands: slice.brands
      ? slice.brands.map(brand => new Brand(brand, true))
      : [],
    keywords: slice.keywords
      ? slice.keywords.map(keyword => new Keyword(keyword, true))
      : [],
  };
}

function _restoreDefaultState(state: RootState, feature: string, slice) {
  return {
    ...state,
    [feature]: {
      ...slice
    }
  };
}
