import { Action } from '@ngrx/store';
import { DeactivatedItems } from '@shared/interfaces/deactivated-items.interface';
import { Brand } from '@shared/models/brand';
import { Medicine } from '@shared/models/medicine';


export enum EditOverviewActionTypes {
  GET_MEDICINE_BY_ID = '[Edit Overview page] Get Medicine By Id',
  GET_MEDICINE_BY_ID_SUCCESS = '[Edit Overview page] Get Medicine By Id Success',

  RESET_MEDICINE = '[Edit Overview page] Reset medicine',

  GET_BRAND_BY_ID = '[Edit Overview page] Get Brand By Id',
  GET_BRAND_BY_ID_SUCCESS = '[Edit Overview page] Get Brand By Id Success',

  RESET_BRAND = '[Edit Overview page] Reset brand',

  UPDATE_BRAND = "[Edit Overview Page] Update brand",
  UPDATE_BRAND_SUCCESS = "[Edit Overview Page] Update brand success",

  UPDATE_MEDICINE = "[Edit Overview] Update medicine",
  UPDATE_MEDICINE_SUCCESS = "[Edit Overview] Update medicine success",

  DEACTIVATE_MEDICINE = "[EDIT_OVERVIEW] Deactivate medicine",
  DEACTIVATE_MEDICINE_SUCCESS = "[Edit Overview] Deactivate medicine success",

  DEACTIVATE_BRAND = "[EDIT_OVERVIEW] Deactivate brand",
  DEACTIVATE_BRAND_SUCCESS = "[EDIT_OVERVIEW] Deactivate brand success",
}

export class GetMedicineByIdAction implements Action {
  readonly type = EditOverviewActionTypes.GET_MEDICINE_BY_ID;
  constructor(public payload: number) {}
}

export class GetMedicineByIdSuccessAction implements Action {
  readonly type = EditOverviewActionTypes.GET_MEDICINE_BY_ID_SUCCESS;
  constructor(public payload: Medicine) { }
}

export class ResetMedicineAction implements Action {
  readonly type = EditOverviewActionTypes.RESET_MEDICINE;
  constructor() {}
}

export class GetBrandByIdAction implements Action {
  readonly type = EditOverviewActionTypes.GET_BRAND_BY_ID;
  constructor(public payload: number) {}
}

export class GetBrandByIdSuccessAction implements Action {
  readonly type = EditOverviewActionTypes.GET_BRAND_BY_ID_SUCCESS;
  constructor(public payload: Brand) { }
}

export class ResetBrandAction implements Action {
  readonly type = EditOverviewActionTypes.RESET_BRAND;
  constructor() {}
}

export class UpdateBrandAction implements Action {
  readonly type = EditOverviewActionTypes.UPDATE_BRAND;
  constructor(public payload: Brand) {}
}

export class UpdateBrandSuccessAction implements Action {
  readonly type = EditOverviewActionTypes.UPDATE_BRAND_SUCCESS;
  constructor(public payload: Brand) {}
}

export class UpdateMedicineAction implements Action {
  readonly type = EditOverviewActionTypes.UPDATE_MEDICINE;
  constructor(public payload: Medicine) {}
}

export class UpdateMedicineSuccessAction implements Action {
  readonly type = EditOverviewActionTypes.UPDATE_MEDICINE_SUCCESS;
  constructor(public payload: Medicine) {}
}

export class DeactivateMedicineAction implements Action {
  readonly type = EditOverviewActionTypes.DEACTIVATE_MEDICINE;
  constructor(public payload: number) {}
}

export class DeactivateMedicineSuccessAction implements Action {
  readonly type = EditOverviewActionTypes.DEACTIVATE_MEDICINE_SUCCESS;
  constructor(public payload: DeactivatedItems) {}
}

export class DeactivateBrandAction implements Action {
  readonly type = EditOverviewActionTypes.DEACTIVATE_BRAND;
  constructor(public payload: number) {}
}

export class DeactivateBrandSuccessAction implements Action {
  readonly type = EditOverviewActionTypes.DEACTIVATE_BRAND_SUCCESS;
  constructor(public payload: DeactivatedItems) {}
}

export type Actions =
  | GetMedicineByIdAction
  | GetMedicineByIdSuccessAction
  | ResetMedicineAction
  | GetBrandByIdAction
  | GetBrandByIdSuccessAction
  | ResetBrandAction
  | UpdateBrandAction
  | UpdateBrandSuccessAction
  | UpdateMedicineAction
  | UpdateMedicineSuccessAction
  | DeactivateMedicineAction
  | DeactivateMedicineSuccessAction
  | DeactivateBrandAction
  | DeactivateBrandSuccessAction
