import { initialOrdersState, OrdersState } from './state';
import { Actions, OrdersActionTypes } from './actions';
import { Message } from '../../shared/models/message';
import { Order } from '../../shared/models/order';
import { ORDERSTATUS } from '../../shared/constants';
import { Document } from '../../shared/models/document';
import { cloneDeep } from 'lodash';
import { StockTake } from '../../shared/models/stock-take';
import { Pageable } from '../../shared/interfaces/pageable.interface';
import { Storeroom } from '../../shared/models/storeroom';

export function OrdersReducer(state: OrdersState = initialOrdersState, action: Actions) {
  let index: number;
  let messages: Message[];
  let orders: Order[];
  let documents: Document[];
  let stockTakes: Pageable<StockTake>;
  let storerooms: Storeroom[];

  switch (action.type) {
    case OrdersActionTypes.CREATE_FORMULARY_SUCCESS:
    case OrdersActionTypes.GET_FORMULARY_SUCCESS:
    case OrdersActionTypes.UPDATE_FORMULARY_SUCCESS:
      return {
        ...state,
        formulary: action.payload
      };
    case OrdersActionTypes.GET_MEDICINES_SUCCESS:
      return {
        ...state,
        medicines: action.payload
      };
    case OrdersActionTypes.GET_PREVIOUSLY_ORDERED_MEDICINES_SUCCESS:
      return {
        ...state,
        previouslyOrderedmedicines: action.payload
      };
    case OrdersActionTypes.GET_ORDER_ADVICE_SUCCESS:
      return {
        ...state,
        orderAdvice: action.payload
      };
    case OrdersActionTypes.GET_MEDICINE_FORMS_SUCCESS:
      return {
        ...state,
        medicineForms: action.payload
      };
    case OrdersActionTypes.GET_PRICELISTS_SUCCESS:
      return {
        ...state,
        pricelists: action.payload
      };
    case OrdersActionTypes.GET_BRANDS_SUCCESS:
      return {
        ...state,
        brands: action.payload
      };
    case OrdersActionTypes.GET_PRESCRIBING_LEVELS_SUCCESS:
      return {
        ...state,
        prescribingLevels: action.payload
      };
    case OrdersActionTypes.GET_KEYWORDS_SUCCESS:
      return {
        ...state,
        keywords: action.payload
      };
    case OrdersActionTypes.SAVE_MEDICINE_FILTER:
      return {
        ...state,
        medicineFilter: action.payload
      };
    case OrdersActionTypes.GET_LATEST_STOCK_TAKE_SUCCESS:
      return {
        ...state,
        stockTake: action.payload
      };
    case OrdersActionTypes.GET_STOCK_TAKES_SUCCESS:
      return {
        ...state,
        stockTakes: action.payload
      };
    case OrdersActionTypes.GET_INVENTORIES_SUCCESS:
      return {
        ...state,
        inventories: action.payload
      };
    case OrdersActionTypes.GET_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.payload
      };
    case OrdersActionTypes.REQUEST_RECOUNT_SUCCESS:
      stockTakes = cloneDeep(state.stockTakes);
      stockTakes.content.forEach(s => {
        const stockTakeItem = s.stockTakeItems.find(si => si.id === action.payload.id);
        if (stockTakeItem != null) {
          stockTakeItem.requiresRecount = true;
        }
      });

      return {
        ...state,
        stockTakes
      };
    case OrdersActionTypes.APPROVE_STOCK_TAKES_ACTION_SUCCESS:
      stockTakes = cloneDeep(state.stockTakes);

      action.payload.forEach(s => {
        const stockTake = stockTakes.content.find(st => st.id === s.id);
        if (stockTake != null) {
          stockTake.approved = s.approved;
        }
      });

      return {
        ...state,
        stockTakes
      };
    case OrdersActionTypes.UPDATE_STOCK_TAKE_ACTION_SUCCESS:
      stockTakes = cloneDeep(state.stockTakes);

      index = stockTakes.content.findIndex(s => s.id === action.payload.id);
      stockTakes.content[index] = action.payload;

      return {
        ...state,
        stockTakes
      };
    case OrdersActionTypes.DELETE_STOCK_TAKE_ITEM_ACTION_SUCCESS:
      stockTakes = cloneDeep(state.stockTakes);

      stockTakes.content.forEach(s => {
        index = s.stockTakeItems.findIndex(a => a.id === action.id);
        if (index === -1) return;

        s.quantity -= s.stockTakeItems[index].quantity;
        s.stockTakeItems.splice(index, 1);
      });

      return {
        ...state,
        stockTakes
      };
    case OrdersActionTypes.CREATE_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: [...state.messages, action.payload]
      };
    case OrdersActionTypes.UPDATE_MESSAGE_SUCCESS:
      index = state.messages.findIndex(_message => _message.id === action.payload.id);
      messages = [...state.messages];
      if (index !== -1) {
        messages.splice(index, 1, action.payload);
      }
      return {
        ...state,
        messages
      };
    case OrdersActionTypes.REMOVE_MESSAGE_SUCCESS:
      index = state.messages.findIndex(_message => _message.id === action.payload);
      messages = [...state.messages];
      if (index !== -1) {
        messages.splice(index, 1);
      }
      return {
        ...state,
        messages
      };
    case OrdersActionTypes.UPDATE_CART_SUCCESS:
      return {
        ...state,
        cart: action.payload
      };
    case OrdersActionTypes.GET_CURRENT_ORDERS_SUCCESS:
      return {
        ...state,
        currentOrders: action.payload
      };
    case OrdersActionTypes.GET_PREVIOUS_ORDERS_SUCCESS:
      return {
        ...state,
        previousOrders: action.payload
      };
    case OrdersActionTypes.GET_ORDER:
      return {
        ...state,
        order: null
      };
    case OrdersActionTypes.GET_ORDER_SUCCESS:
      return {
        ...state,
        order: action.payload
      };
    case OrdersActionTypes.SET_PRICELISTITEM_TO_CHANGE:
      return {
        ...state,
        priceListItemToChange: [action.oldPriceListItemId, action.newPriceListItem, action.quantity]
      };
    case OrdersActionTypes.UPDATE_ORDER_STATUS_SUCCESS:
      index = state.currentOrders.findIndex(_order => _order.id === action.payload.id);
      if (index !== -1) {
        orders = [...state.currentOrders];
        orders.splice(index, 1, action.payload);
      } else {
        orders = [...state.currentOrders, action.payload];
      }

      // clear the cart if it just has been submitted
      let cart = state.cart;
      const currentStatus = action.payload.currentOrderStatus.status;
      if (currentStatus === ORDERSTATUS.INITIATED || currentStatus === ORDERSTATUS.CANCELLED) {
        cart = new Order({});
      }

      return {
        ...state,
        currentOrders: orders,
        cart
      };
    case OrdersActionTypes.UPDATE_ORDER_SUCCESS:
    case OrdersActionTypes.SET_PAYMENT_METHODS_SUCCESS:
    case OrdersActionTypes.UPDATE_PROFORMA_SUCCESS:
    case OrdersActionTypes.UPDATE_SHIPMENT_SUCCESS:
    case OrdersActionTypes.SUBMIT_DELIVERY_SUCCESS:
    case OrdersActionTypes.CANCEL_UNDELIVERED_MEDICINES_SUCCESS:
      index = state.currentOrders.findIndex(_order => _order.id === action.payload.id);
      if (index !== -1) {
        orders = [...state.currentOrders];
        // remove the order if it is cancelled
        if (action.payload.currentOrderStatus.status === ORDERSTATUS.CANCELLED) {
          orders.splice(index, 1);
        } else {
          orders.splice(index, 1, action.payload);
        }
      } else {
        orders = [...state.currentOrders, action.payload];
      }

      return {
        ...state,
        currentOrders: orders
      };
    case OrdersActionTypes.GET_CART_SUCCESS:
      return {
        ...state,
        cart: action.payload
      };
    case OrdersActionTypes.GET_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        paymentMethods: action.payload
      };
    case OrdersActionTypes.GET_MCF_INFO_SUCCESS:
      return {
        ...state,
        mcfInfo: action.payload
      };
    case OrdersActionTypes.GET_DRAFT_ORDER_SUCCESS:
      return {
        ...state,
        cart: action.payload
      };
    case OrdersActionTypes.MODIFY_ORDER_SUCCESS:
      return {
        ...state,
        cart: action.payload
      };
    case OrdersActionTypes.GET_OVERVIEW_ACTIONS_SUCCESS:
      return {
        ...state,
        actions: action.payload
      };
    case OrdersActionTypes.SAVE_ORDER_FILTER:
      return {
        ...state,
        orderFilter: action.payload
      };
    case OrdersActionTypes.SAVE_DELIVERY_ORDER_FILTER:
      return {
        ...state,
        deliveryOrderFilter: action.payload
      };
    case OrdersActionTypes.GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: action.payload
      };
    case OrdersActionTypes.FIND_NEW_PRICE_LIST_ITEMS_IF_EXIST_SUCCESS:
      return {
        ...state,
        newPriceListItems: action.payload
      };
    case OrdersActionTypes.FIND_OTHER_SUPPLIERS_SUCCESS:
      return {
        ...state,
        otherSuppliers: action.payload
      };
    case OrdersActionTypes.UPLOAD_PRICES_SUCCES:
      return {
        ...state,
        pricelists: action.payload
      };
    case OrdersActionTypes.CREATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        documents: {
          ...state.documents,
          content : [
            ...state.documents.content,
            action.payload
         ]
        }
      };
    case OrdersActionTypes.REMOVE_DOCUMENT_SUCCESS:
      index = state.documents.content.findIndex(_document => _document.id === action.payload);
      let documentsContent = [...state.documents.content];
      if (index !== -1) {
        documentsContent.splice(index, 1);
      }
      return {
        ...state,
        documents : {
          ...state.documents,
          content: documentsContent
        }
      };
    case OrdersActionTypes.SAVE_DOCUMENT_FILTER:
      return {
        ...state,
        documentFilter: action.payload
      };
    case OrdersActionTypes.GET_REPORTS_SUCCESS:
      return {
        ...state,
        reports: action.payload
      };
    case OrdersActionTypes.GET_WITHDRAWAL_REQUESTS_SUCCESS:
      return {
        ...state,
        withdrawalRequests: action.payload
      };
    case OrdersActionTypes.CREATE_WITHDRAWAL_REQUEST_SUCCESS:
      return {
        ...state,
        withdrawalRequests: [...state.withdrawalRequests, action.payload]
      };
    case OrdersActionTypes.GET_STOREROOMS_SUCCESS:
      return {
        ...state,
        storerooms: action.payload
      };
    case OrdersActionTypes.CREATE_STOREROOM_SUCCESS:
      return {
        ...state,
        storerooms: [...state.storerooms, action.payload]
      };
    case OrdersActionTypes.UPDATE_STOREROOM_SUCCESS:
      index = state.storerooms.findIndex(s => s.id === action.payload.id);
      storerooms = [...state.storerooms];

      if (index !== -1) {
        storerooms.splice(index, 1, action.payload);
      }

      return {
        ...state,
        storerooms
      };
    case OrdersActionTypes.DELETE_STOREROOM_SUCCESS:
      index = state.storerooms.findIndex(s => s.id === action.id);
      storerooms = [...state.storerooms];

      if (index !== -1) {
        storerooms.splice(index, 1);
      }

      return {
        ...state,
        storerooms
      };
    default:
      return state;
  }
}
