import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ORDERSTATUS } from '../../../../shared/constants';

@Component({
  selector: 'app-update-order-status-dialog',
  templateUrl: './update-order-status-dialog.component.html'
})
export class UpdateOrderStatusDialogComponent implements OnInit {
  title: string;
  description: string;
  okButtonText: string;
  cancelButtonText: string;
  message = '';
  isAdminReject: boolean = false;
  orderStatus: string;

  constructor(@Inject(MAT_DIALOG_DATA) private dialogData: any, private dialogRef: MatDialogRef<UpdateOrderStatusDialogComponent>) {
    this.orderStatus = this.dialogData.status;
    this.isAdminReject = this.dialogData.isAdminReject;
  }

  ngOnInit(): void {
    this.cancelButtonText = 'BUTTONS.CANCEL';

    switch (this.orderStatus) {
      case ORDERSTATUS.REJECTED:
        this.title = 'DIALOGS.REJECT_ORDER.TITLE';
        this.description = this.isAdminReject ? 'DIALOGS.REJECT_ORDER.ADMIN_DESCRIPTION' :'DIALOGS.REJECT_ORDER.DESCRIPTION' ;
        this.okButtonText = 'BUTTONS.REJECT_AND_SEND_MESSAGE';
        break;
      case ORDERSTATUS.MODIFIED:
        this.title = 'DIALOGS.MODIFY_ORDER.TITLE';
        this.description = 'DIALOGS.MODIFY_ORDER.DESCRIPTION';
        this.okButtonText = 'BUTTONS.MODIFY_AND_SEND_MESSAGE';
        break;
      case ORDERSTATUS.PROFORMA_REFUSED:
        this.title = 'DIALOGS.REFUSE_PRO_FORMA.TITLE';
        this.description = 'DIALOGS.REFUSE_PRO_FORMA.DESCRIPTION';
        this.okButtonText = 'BUTTONS.REFUSE_PRO_FORMA';
        break;
      case ORDERSTATUS.PROFORMA_DECLINED:
        this.title = 'DIALOGS.DECLINE_PRO_FORMA.TITLE';
        this.description = 'DIALOGS.DECLINE_PRO_FORMA.DESCRIPTION';
        this.okButtonText = 'BUTTONS.DECLINE_PRO_FORMA';
        break;
      case ORDERSTATUS.CANCELLED:
        this.title = 'DIALOGS.CANCEL_ORDER.TITLE';
        this.description = 'DIALOGS.CANCEL_ORDER.DESCRIPTION';
        this.okButtonText = 'BUTTONS.CANCEL_ORDER';
        this.cancelButtonText = 'BUTTONS.BACK';
        break;
      case ORDERSTATUS.PAYMENT_REJECTED:
        this.title = 'DIALOGS.REJECT_PAYMENT.TITLE';
        this.description = 'DIALOGS.REJECT_PAYMENT.DESCRIPTION';
        this.okButtonText = 'BUTTONS.REJECT_PAYMENT';
        break;
    }
  }

  close(confirmation: boolean) {
    this.dialogRef.close({
      confirmation,
      message: this.message
    });
  }
}
