<div class="forget-password-dialog-container">
  <div class="dialog-header">
    <h2 transloco="DIALOGS.FORGET_PASSWORD.TITLE"></h2>
    <button mat-icon-button (click)="close()">
      <fa-icon icon="times"></fa-icon>
    </button>
  </div>

  <div class="form-container">
    <mat-form-field>
      <mat-label transloco="USER.EMAIL_ADDRESS"></mat-label>
      <input type="text" [formControl]="emailCtrl" required matInput />

      <mat-error *ngIf="emailCtrl.invalid && emailCtrl.dirty">
        <span *ngIf="emailCtrl.hasError('required')" transloco="MESSAGES.FORM.EMAIL_ADDRESS_REQUIRED_ERROR"></span>
        <span *ngIf="emailCtrl.hasError('email')" transloco="MESSAGES.FORM.EMAIL_ADDRESS_INVALID_ERROR"></span>
      </mat-error>
    </mat-form-field>

    <div class="text-end full-width">

      <button mat-raised-button
              type="button"
              color="primary"
              (click)="save()"
              [disabled]="emailCtrl.invalid"
              transloco="BUTTONS.CONTINUE">
      </button>
    </div>
  </div>
</div>
