import { Entity } from './entity';
import { OrderStatus } from '../interfaces/order-status.interface';
import { Brand } from './brand';
import { Medicine } from './medicine';
import { PricelistItem } from './pricelist-item';
import { OrderSupplier } from './order-supplier';

export class OrderMedicine extends Entity {
  unitPrice: number;
  brand: Brand;
  medicine: Medicine;
  quantity: number;
  orderQuantityChanged: boolean;
  totalValue: number;
  orderSupplierId: number;
  priceListItem: PricelistItem;
  orderSupplier: OrderSupplier;
  currentOrderMedicineStatus: OrderStatus;
  shipmentNumber: number;
  deliveryNumber: number;

  constructor(data?: any, restore: boolean = false) {
    super(data, restore);

    if (data) {
      this.currentOrderMedicineStatus = data.currentOrderMedicineStatus;
      this.unitPrice = data.unitPrice;
      this.totalValue = data.totalValue;
      this.quantity = data.quantity;
      this.orderQuantityChanged = data.orderQuantityChanged;
      this.brand = data.brand ? new Brand(data.brand, restore) : null;
      this.medicine = data.medicine ? new Medicine(data.medicine, restore) : null;
      this.priceListItem = data.priceListItem ? new PricelistItem(data.priceListItem, restore) : null;
      this.orderSupplier = data.orderSupplier ? new OrderSupplier(data.orderSupplier) : null;
      this.orderSupplierId = data.orderSupplierId ?? this.orderSupplier?.id;
      this.shipmentNumber = data.shipmentNumber;
      this.deliveryNumber = data.deliveryNumber;
    }
  }
}
