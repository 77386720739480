<mat-list class="side-menu">
  <ng-container *ngIf="orderMenuItems?.length > 0">
    <div mat-subheader>{{'MENU.ORDERS.HEADER' | transloco}}</div>
    <mat-list-item
      *ngFor="let item of orderMenuItems"
      routerLinkActive="active"
      [routerLink]="item.link"
      [transloco]="item.label"
      (click)="closeMenu.emit()"
    ></mat-list-item>
  </ng-container>

  <ng-container *ngIf="adminMenuItems?.length > 0">
    <div mat-subheader>{{'MENU.ADMIN.HEADER' | transloco}}</div>
    <mat-list-item
      *ngFor="let item of adminMenuItems"
      routerLinkActive="active"
      [routerLink]="item.link"
      [transloco]="item.label"
      (click)="closeMenu.emit()"
    ></mat-list-item>
  </ng-container>

  <ng-container *ngIf="monitorMenuItems?.length > 0">
    <div mat-subheader>{{'MENU.ORDER_MONITOR.HEADER' | transloco}}</div>
    <mat-list-item
      *ngFor="let item of monitorMenuItems"
      routerLinkActive="active"
      [routerLink]="item.link"
      [transloco]="item.label"
      (click)="closeMenu.emit()"
    ></mat-list-item>
  </ng-container>

  <ng-container *ngIf="cmsMenuItems?.length > 0">
    <div mat-subheader>{{'MENU.CMS.HEADER' | transloco}}</div>
    <mat-list-item
      *ngFor="let item of cmsMenuItems"
      routerLinkActive="active"
      [routerLink]="item.link"
      [transloco]="item.label"
      (click)="closeMenu.emit()"
    ></mat-list-item>
  </ng-container>
</mat-list>
