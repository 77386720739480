import { AuthActionTypes } from '../auth/actions';
import { environment } from '../../../environments/environment';
import { storageKey } from './storageKey';

export function clearReducer(reducer) {
  return (state, action) => {
    if (action.type === AuthActionTypes.LOGOUT_SUCCESS) {
      localStorage.removeItem(storageKey);
      state = undefined;
    }

    return reducer(state, action);
  };
}
