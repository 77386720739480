import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '../../shared/providers/api/api.service';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { User } from '../../shared/models/user';
import { GenericFailureAction, GenericSuccessAction } from '../app/actions';
import * as adminActions from './actions';
import * as searchableNamesAction from '../searchable-names/searchable-names.actions';
import {
  AdminActionTypes,
  GetDenominationsSuccessAction,
  GetFacilityTypesSuccessAction,
  GetRegionsSuccessAction
} from './actions';
import { select, Store } from '@ngrx/store';
import { selectAuthUser } from '../auth/selectors';
import { ORDERSTATUS, PERMISSION } from '../../shared/constants';
import { Organization } from '../../shared/models/organization';
import { Role } from '../../shared/models/role';
import { Permission } from '../../shared/models/permission';
import { Router } from '@angular/router';
import { Order } from '../../shared/models/order';
import { selectDenominations, selectFacilityTypes, selectRegions } from './selectors';
import { WithdrawalRequest } from '../../shared/models/withdrawal-request';
import { OverviewAction } from '../../shared/interfaces/overview-action.interface';
import { Document } from '../../shared/models/document';
import { Email } from '../../admin/shared/models/email';
import { PriceUpdateOverviewService } from "@providers/api/priceupdate-overview.service";
import { SupplierWithIds } from '@shared/interfaces/supplier-with-ids.interface';
import { PricelistItem } from '@shared/models/pricelist-item';
import { PriceListItemService } from '@app/orders/shared/providers/pricelistitem.service';

@Injectable()
export class AdminEffects {
  constructor(
    private actions: Actions,
    private apiService: ApiService,
    private store: Store,
    private router: Router,

    private priceUpdateOverviewService: PriceUpdateOverviewService,
  ) { }

  /*
    ========================================
                USERS EFFECTS
    ========================================
  */

  getUsers: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<adminActions.GetUsersAction>(AdminActionTypes.GET_USERS),
    switchMap(() =>
      this.apiService.getUsers().pipe(
        map((response) => {
          const users: User[] = response.map((user) => new User(user));
          return new adminActions.GetUsersSuccessAction(users);
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));


  createUser: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<adminActions.CreateUserAction>(AdminActionTypes.CREATE_USER),
    switchMap((action) =>
      this.apiService.createUser(action.payload).pipe(
        map((response) => {
          const user: User = new User(response);
          return new adminActions.CreateUserSuccessAction(user);
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));


  updateUser: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<adminActions.UpdateUserAction>(AdminActionTypes.UPDATE_USER),
    switchMap((action) =>
      this.apiService.updateUser(action.payload).pipe(
        tap(() =>
          this.store.dispatch(new GenericSuccessAction('MESSAGES.API.UPDATE_USER_SUCCESS')),
        ),
        map((response) => {
          const user: User = new User(response);
          return new adminActions.UpdateUserSuccessAction(user);
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));


  removeUser: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<adminActions.RemoveUserAction>(AdminActionTypes.REMOVE_USER),
    withLatestFrom(this.store.pipe(select(selectAuthUser))),
    switchMap(([action, user]) =>
      this.apiService.removeUser(action.payload).pipe(
        tap((response) => this.store.dispatch(new GenericSuccessAction(response))),
        map(() => {
          return user.hasPermission(PERMISSION.USERS.REACTIVATE)
            ? new adminActions.SoftRemoveUserSuccessAction(action.payload)
            : new adminActions.RemoveUserSuccessAction(action.payload);
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));


  reactivateUser: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<adminActions.ReactivateUserAction>(AdminActionTypes.REACTIVATE_USER),
    switchMap((action) =>
      this.apiService.reactivateUser(action.payload).pipe(
        map((response) => {
          this.store.dispatch(new GenericSuccessAction('MESSAGES.API.REACTIVATE_USER_SUCCESS'));
          return new adminActions.ReactivateUserSuccessAction(new User(response));
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));

  /*
    ========================================
             ORGANIZATIONS EFFECTS
    ========================================
  */

  getOrganizations: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<adminActions.GetOrganizationsAction>(AdminActionTypes.GET_ORGANIZATIONS),
    switchMap((action) =>
      this.apiService.getOrganizations(action.payload).pipe(
        map((response) => {
          const organizations: Organization[] = response.map(
            (organization) => new Organization(organization),
          );
          return new adminActions.GetOrganizationsSuccessAction(organizations);
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));


  getOrganization: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<adminActions.GetOrganizationAction>(AdminActionTypes.GET_ORGANIZATION),
    switchMap((action) =>
      this.apiService.getOrganizationById(action.payload).pipe(
        map((response) => {
          const organization: Organization = new Organization(response);
          return new adminActions.GetOrganizationSuccessAction(organization);
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));


  createOrganization: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<adminActions.CreateOrganizationAction>(AdminActionTypes.CREATE_ORGANIZATION),
    switchMap((action) =>
      this.apiService.createOrganization(action.payload).pipe(
        map((response) => {
          const organization: Organization = new Organization(response);
          return new adminActions.CreateOrganizationSuccessAction(organization);
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));


  updateOrganization: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<adminActions.UpdateOrganizationAction>(AdminActionTypes.UPDATE_ORGANIZATION),
    switchMap((action) =>
      this.apiService.updateOrganization(action.payload).pipe(
        tap(() =>
          this.store.dispatch(new GenericSuccessAction('MESSAGES.API.UPDATE_ORGANIZATION_SUCCESS')),
        ),
        map((response) => {
          this.router.navigateByUrl('/admin/organizations');
          const organization: Organization = new Organization(response);
          return new adminActions.UpdateOrganizationSuccessAction(organization);
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));


  removeOrganization: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<adminActions.RemoveOrganizationAction>(AdminActionTypes.REMOVE_ORGANIZATION),
    withLatestFrom(this.store.pipe(select(selectAuthUser))),
    switchMap(([action, user]) =>
      this.apiService.removeOrganization(action.payload).pipe(
        tap((response) => this.store.dispatch(new GenericSuccessAction(response))),
        map(() => {
          return user.hasPermission(PERMISSION.ORGANIZATIONS.REACTIVATE)
            ? new adminActions.SoftRemoveOrganizationSuccessAction(action.payload)
            : new adminActions.RemoveOrganizationSuccessAction(action.payload);
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));


  reactivateOrganization: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<adminActions.ReactivateOrganizationAction>(AdminActionTypes.REACTIVATE_ORGANIZATION),
    switchMap((action) =>
      this.apiService.reactivateOrganization(action.organizationId, action.alsoReactivatePriceListItems).pipe(
        map((response) => {
          this.store.dispatch(
            new GenericSuccessAction('MESSAGES.API.REACTIVATE_ORGANIZATION_SUCCESS'),
          );
          return new adminActions.ReactivateOrganizationSuccessAction(new Organization(response));
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));


  getDenominations: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<adminActions.GetDenominationsAction>(AdminActionTypes.GET_DENOMINATIONS),
    withLatestFrom(this.store.pipe(select(selectDenominations))),
    switchMap(([_, denominations]) => {
      if (denominations && denominations.length > 0) {
        return of(new GetDenominationsSuccessAction(denominations));
      }

      return this.apiService.getDenominations().pipe(
        map((response) => new adminActions.GetDenominationsSuccessAction(response)),
        catchError((err) => of(new GenericFailureAction(err))),
      );
    }),
  ));


  getDistricts: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<adminActions.GetDistrictsAction>(AdminActionTypes.GET_DISTRICTS),
    switchMap((action) =>
      this.apiService.getDistricts(action.payload).pipe(
        map((response) => new adminActions.GetDistrictsSuccessAction(response)),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));


  getRegions: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<adminActions.GetRegionsAction>(AdminActionTypes.GET_REGIONS),
    withLatestFrom(this.store.pipe(select(selectRegions))),
    switchMap(([_, regions]) => {
      if (regions && regions.length > 0) {
        return of(new GetRegionsSuccessAction(regions));
      }

      return this.apiService.getRegions().pipe(
        map((response) => new adminActions.GetRegionsSuccessAction(response)),
        catchError((err) => of(new GenericFailureAction(err))),
      );
    }),
  ));


  getFacilityTypes: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<adminActions.GetFacilityTypesAction>(AdminActionTypes.GET_FACILITY_TYPES),
    withLatestFrom(this.store.pipe(select(selectFacilityTypes))),
    switchMap(([_, facilityTypes]) => {
      if (facilityTypes && facilityTypes.length > 0) {
        return of(new GetFacilityTypesSuccessAction(facilityTypes));
      }

      return this.apiService.getFacilityTypes().pipe(
        map((response) => new adminActions.GetFacilityTypesSuccessAction(response)),
        catchError((err) => of(new GenericFailureAction(err))),
      );
    }),
  ));

  /*
    ========================================
             ROLES EFFECTS
    ========================================
  */

  getRoles: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<adminActions.GetRolesAction>(AdminActionTypes.GET_ROLES),
    switchMap(() =>
      this.apiService.getRoles().pipe(
        map((response) => {
          const roles: Role[] = response.map((role) => new Role(role));
          return new adminActions.GetRolesSuccessAction(roles);
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));


  getRole: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<adminActions.GetRoleAction>(AdminActionTypes.GET_ROLE),
    switchMap((action) =>
      this.apiService.getRoleById(action.payload).pipe(
        map((response) => {
          const role: Role = new Role(response);
          return new adminActions.GetRoleSuccessAction(role);
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));


  createRole: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<adminActions.CreateRoleAction>(AdminActionTypes.CREATE_ROLE),
    switchMap((action) =>
      this.apiService.createRole(action.payload).pipe(
        map((response) => {
          this.router.navigateByUrl('/admin/roles');
          const role: Role = new Role(response);
          return new adminActions.CreateRoleSuccessAction(role);
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));


  updateRole: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<adminActions.UpdateRoleAction>(AdminActionTypes.UPDATE_ROLE),
    switchMap((action) =>
      this.apiService.updateRole(action.payload).pipe(
        map((response) => {
          const role: Role = new Role(response);
          this.router.navigateByUrl('/admin/roles');
          return new adminActions.UpdateRoleSuccessAction(role);
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));


  removeRole: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<adminActions.RemoveRoleAction>(AdminActionTypes.REMOVE_ROLE),
    switchMap((action) =>
      this.apiService.removeRole(action.payload).pipe(
        map((response) => {
          this.store.dispatch(new GenericSuccessAction(response));
          return new adminActions.RemoveRoleSuccessAction(action.payload);
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));


  getPermissions: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<adminActions.GetPermissionsAction>(AdminActionTypes.GET_PERMISSIONS),
    switchMap(() =>
      this.apiService.getPermissions().pipe(
        map((response) => {
          const permissions: Permission[] = response.map(
            (permission) => new Permission(permission),
          );
          return new adminActions.GetPermissionsSuccessAction(permissions);
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));

  /*
    ========================================
            ADMIN ORDERS EFFECTS
    ========================================
  */

  getAdminOrders: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<adminActions.GetAdminOrdersAction>(AdminActionTypes.GET_ORDERS),
    switchMap((action) =>
      this.apiService.getAdminOrders(action.pageNumber, action.pageSize).pipe(
        map((pageableOrders) => {
          pageableOrders.content.map((order) => new Order(order));

          return new adminActions.GetAdminOrdersSuccessAction(pageableOrders);
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));


  getFilteredAdminOrders: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<adminActions.GetFilteredAdminOrdersAction>(AdminActionTypes.GET_FILTERED_ORDERS),
    switchMap((action) =>
      this.apiService
        .getFilteredAdminOrders(action.filter, action.pageNumber, action.pageSize)
        .pipe(
          map((pageableOrders) => {
            pageableOrders.content.map((order) => new Order(order));

            return new adminActions.GetAdminOrdersSuccessAction(pageableOrders);
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
    ),
  ));


  updateOrderStatus: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<adminActions.UpdateOrderStatusAction>(AdminActionTypes.UPDATE_ORDER_STATUS),
    switchMap((action) =>
      this.apiService
        .updateOrderStatus(action.orderId, action.status, action.message, action.orderSupplierIds)
        .pipe(
          map((res) => {
            this.store.dispatch(
              new GenericSuccessAction('MESSAGES.API.UPDATE_ORDER_STATUS_SUCCESS'),
            );
            return new adminActions.UpdateOrderStatusSuccessAction(new Order(res));
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
    ),
  ));


  resubmitPayment: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<adminActions.ResubmitPaymentAction>(AdminActionTypes.RESUBMIT_PAYMENT),
    switchMap((action) => {
      const paymentMethods = action.payload.orderParts
        .filter((op) => op.selected)
        .map((part) => ({
          paymentMethodId: part.paymentMethod.id,
          orderSupplierId: part.id,
        }));
      return this.apiService.resubmitPayment(action.payload.orderId, paymentMethods).pipe(
        map((res) => {
          this.store.dispatch(new GenericSuccessAction('MESSAGES.API.PAYMENT_CHANGED'));
          return new adminActions.ResubmitPaymentSuccessAction(new Order(res));
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      );
    }),
  ));


  retryShipment: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<adminActions.RetryShipmentAction>(AdminActionTypes.RETRY_SHIPMENT),
    switchMap((action) => {
      const orderSupplierIds = action.payload.orderParts
        .filter((part) => part.selected && part.status === ORDERSTATUS.ANNULLED)
        .map((part) => part.id);
      return this.apiService.retryShipment(action.payload.orderId, orderSupplierIds).pipe(
        map((res) => {
          this.store.dispatch(new GenericSuccessAction('MESSAGES.API.UPDATE_ORDER_STATUS_SUCCESS'));
          this.router.navigateByUrl('/admin/orders');
          return new adminActions.UpdateOrderStatusSuccessAction(new Order(res));
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      );
    }),
  ));


  retryProforma: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<adminActions.RetryProformaAction>(AdminActionTypes.RETRY_PROFORMA),
    switchMap((action) => {
      const orderSupplierIds = action.payload.orderParts
        .filter((part) => part.selected && part.status === ORDERSTATUS.PROFORMA_DECLINED)
        .map((part) => part.id);
      return this.apiService.retryDeclinedProforma(action.payload.orderId, orderSupplierIds).pipe(
        map((res) => {
          this.store.dispatch(new GenericSuccessAction('MESSAGES.API.UPDATE_ORDER_STATUS_SUCCESS'));
          this.router.navigateByUrl('/admin/orders');
          return new adminActions.UpdateOrderStatusSuccessAction(new Order(res));
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      );
    }),
  ));


  payOrder: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<adminActions.PayOrderAction>(AdminActionTypes.PAY_ORDER),
    switchMap((action) =>
      this.apiService.payOrder(action.order, action.deliveryIds, action.adminAction).pipe(
        map((res) => {
          this.store.dispatch(new GenericSuccessAction('MESSAGES.API.UPDATE_ORDER_STATUS_SUCCESS'));
          return new adminActions.PayOrderActionSuccessAction(new Order(res));
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));

  /*
    ========================================
          WITHDRAWAL REQUEST EFFECTS
    ========================================
  */

  getWithdrawalRequests: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<adminActions.GetAdminWithdrawalRequestsAction>(AdminActionTypes.GET_WITHDRAWAL_REQUESTS),
    switchMap(() =>
      this.apiService.getWithdrawalRequests().pipe(
        map((res) => {
          const requests = res.map((req) => new WithdrawalRequest(req));
          return new adminActions.GetWithdrawalRequestsSuccessAction(requests);
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));


  updateWithdrawalRequest: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<adminActions.UpdateWithdrawalRequestAction>(AdminActionTypes.UPDATE_WITHDRAWAL_REQUESTS),
    switchMap((action) =>
      this.apiService.updateWithdrawalRequest(action.requestId, action.status).pipe(
        map((res) => {
          // notify the user
          this.store.dispatch(new GenericSuccessAction(`MESSAGES.API.${action.status}_SUCCESS`));

          // update the store
          const request = new WithdrawalRequest(res);
          return new adminActions.UpdateWithdrawalRequestSuccessAction(request);
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));

  /*
    ========================================
              DOCUMENTS EFFECTS
    ========================================
  */

  getAdminDocuments: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<adminActions.GetAdminDocumentsAction>(AdminActionTypes.GET_DOCUMENTS),
    switchMap((action) =>
      this.apiService.getAdminDocuments(action.filter, action.pageNumber, action.pageSize).pipe(
        map((pageableDocuments) => {
          pageableDocuments.content.map((doc) => new Document(doc));

          // @ts-ignore, IDE gives error while parameters match
          return new adminActions.GetAdminDocumentsSuccessAction(pageableDocuments);
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));

  /*
    ========================================
              EMAILS EFFECTS
    ========================================
  */

  getEmails: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<adminActions.GetEmailsAction>(AdminActionTypes.GET_EMAILS),
    switchMap((action) =>
      this.apiService.getEmails(action.filter, action.page, action.pageSize).pipe(
        map((paginatedEmails) => {
          paginatedEmails.content.map((email) => new Email(email));

          return new adminActions.GetEmailsSuccessAction(paginatedEmails);
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));
  /*
   ========================================
              VARIOUS EFFECTS
   ========================================
 */

  getOverview: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<adminActions.GetOverviewAction>(AdminActionTypes.GET_OVERVIEW),
    switchMap((action) =>
      this.apiService.getOverview('admin').pipe(
        take(1),
        map((res) => {
          const actions: OverviewAction[] = res
            .map((_action) => {
              switch (_action.id) {
                case 'numberOfRolesWithoutUsers':
                  return {
                    ..._action,
                    label: 'PAGES.ADMIN_HOME.ACTIONS.ROLES',
                    link: '/admin/roles',
                    actionType: 'action',
                  };
                case 'numberOfOrganizationsWithoutUsers':
                  return {
                    ..._action,
                    label: 'PAGES.ADMIN_HOME.ACTIONS.ORGANIZATIONS',
                    link: '/admin/organizations',
                    actionType: 'action',
                  };
                case 'numberOfOrdersRequiringAttention':
                  return {
                    ..._action,
                    label: 'PAGES.ADMIN_HOME.ACTIONS.NEED_ATTENTION',
                    link: '/admin/orders',
                    actionType: 'action',
                  };
                case 'numberOfOrdersInPaymentSelected':
                  return {
                    ..._action,
                    label: 'PAGES.ADMIN_HOME.ORDER_UPDATES.MCF',
                    actionType: 'order',
                  };
                case 'numberOfOrdersInPipeline':
                  return {
                    ..._action,
                    label: 'PAGES.ADMIN_HOME.ORDER_UPDATES.PIPELINE',
                    actionType: 'order',
                  };
                case 'numberOfUndeliveredOrders':
                  return {
                    ..._action,
                    label: 'PAGES.ADMIN_HOME.ORDER_UPDATES.NOT_DELIVERED',
                    actionType: 'order',
                  };
                case 'numberOfDeliveredSubOrdersInPastSevenDays':
                  return {
                    ..._action,
                    label: 'PAGES.ADMIN_HOME.ORDER_UPDATES.DELIVERED',
                    actionType: 'order',
                  };
                case 'fullOverview':
                  return {
                    ..._action,
                    label: 'PAGES.ADMIN_HOME.ORDER_UPDATES.FULL_OVERVIEW',
                    link: '/admin/orders',
                  };
                case 'numberOfUnknownMedicines':
                  return {
                    ..._action,
                    label: 'PAGES.ADMIN_HOME.ORGANIZATION.UNKNOWN_MEDICINES',
                    link: '/admin/medicines',
                    actionType: 'organization',
                  };
                case 'allDocuments':
                  return {
                    ..._action,
                    label: 'PAGES.ADMIN_HOME.DOCUMENTS.ALL_DOCUMENTS',
                    link: '/admin/documents',
                  };
              }
            })
            .filter((_action) => _action != null);
          return new adminActions.GetOverviewSuccessAction(actions);
        }),
        catchError((err) => of(new GenericFailureAction(err))),
      ),
    ),
  ));
}
