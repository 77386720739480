import moment from 'moment';
import { cloneDeep } from 'lodash';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { select, Store } from '@ngrx/store';
import { User } from '../../../shared/models/user';
import { nullOrEmpty } from '../../../shared/utils';
import { Order } from '../../../shared/models/order';
import { Brand } from '../../../shared/models/brand';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { ORDERSTATUS } from '../../../shared/constants';
import * as actions from '../../../store/orders/actions';
import { Message } from '../../../shared/models/message';
import { ActivatedRoute, Router } from '@angular/router';
import { McfInfo } from '../../../shared/models/mcf-info';
import { Medicine } from '../../../shared/models/medicine';
import { Inventory } from '../../../shared/models/inventory';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { StockTake } from '../../../shared/models/stock-take';
import { selectAuthUser } from '../../../store/auth/selectors';
import { Filter } from '../../../shared/interfaces/filter.interface';
import { OrderMedicine } from '../../../shared/models/order-medicine';
import { OrderSupplier } from '../../../shared/models/order-supplier';
import { StockTakeItem } from '../../../shared/models/stock-take-item';
import { Pageable } from '../../../shared/interfaces/pageable.interface';
import { ApiFilter } from '../../../shared/interfaces/api-filter.interface';
import { WithdrawalRequest } from '../../../shared/models/withdrawal-request';
import { OrderFilter } from '../../../shared/interfaces/order-filter.interface';
import { PaymentMethod } from '../../../shared/interfaces/payment-method.interface';
import { OverviewAction } from '../../../shared/interfaces/overview-action.interface';
import { OrderViewModel } from '../../order-details/shared/order-view-model.interface';
import { DeliveryMedicine } from '../../../shared/interfaces/delivery-medicine.interface';
import { OrderMedicineCopy } from '../../order-details/shared/order-medicine-copy.interface';
import { NotificationService } from '../../../shared/providers/notification/notification.service';
import { ConfirmationDialogComponent } from '../../../shared/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { UpdateOrderStatusDialogComponent } from '../../order-details/components/update-order-status-dialog/update-order-status-dialog.component';

import {
  SubmitPodAction,
  SubmitDeliveryAction,
  UpdateProFormaAction,
  UpdateShipmentAction,
  GetOverviewActionsAction,
  CreateWithdrawalRequestAction,
  CancelUndeliveredMedicinesAction,
  CreateWithdrawalRequestAndSetPaymentMethodAction,
} from '../../../store/orders/actions';

import {
  selectCart,
  selectOrder,
  selectMcfInfo,
  selectMessages,
  selectFormulary,
  selectMedicines,
  selectStockTake,
  selectStockTakes,
  selectInventories,
  selectOrderAdvice,
  selectOrderFilter,
  selectCurrentOrders,
  selectMedicineFilter,
  selectPaymentMethods,
  selectPreviousOrders,
  selectOverviewActions,
  selectWithdrawalRequests,
  selectDeliveryOrderFilter,
  selectPreviouslyOrderedMedicines,
} from '../../../store/orders/selectors';
import { PricelistItem } from "@models/pricelist-item";
import { Organization } from '@shared/models/organization';
@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  constructor(
    private store: Store,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private transloco: TranslocoService,
    private router: Router,
    private route: ActivatedRoute,
    private notification: NotificationService,
  ) { }

  getFormulary(id: number | string) {
    const obs$ = this.store.pipe(select(selectFormulary));
    this.store.dispatch(new actions.GetFormularyAction(id));
    return obs$;
  }

  createFormulary() {
    const obs$ = this.store.pipe(select(selectFormulary));
    this.store.dispatch(new actions.CreateFormularyAction());
    return obs$;
  }

  addToFormulary(medicine: Medicine, brand: Brand, forecast: number) {
    this.store.dispatch(new actions.AddMedicineToFormularyAction(medicine, brand, forecast));
  }

  removeFromFormulary(medicine: Medicine, brand: Brand) {
    this.store.dispatch(new actions.RemoveMedicineFromFormularyAction(medicine, brand));
  }

  updateFormulary(medicine: Medicine, brand: Brand, forecast: number) {
    this.store.dispatch(new actions.UpdateFormularyAction(medicine, brand, forecast));
  }

  getMedicines(params?: ApiFilter[]): Observable<Medicine[]> {
    const obs$ = this.store.pipe(select(selectMedicines));
    this.store.dispatch(new actions.GetMedicinesAction(params));
    return obs$;
  }

  getPreviouslyOrderedMedicines(params?: ApiFilter[]): Observable<Medicine[]> {
    const obs$ = this.store.pipe(select(selectPreviouslyOrderedMedicines));
    this.store.dispatch(new actions.GetPreviouslyOrderedMedicinesAction(params));
    return obs$;
  }

  getOrderAdvice(params?: ApiFilter[]): Observable<Medicine[]> {
    const obs$ = this.store.pipe(select(selectOrderAdvice));
    this.store.dispatch(new actions.GetOrderAdviceAction(params));
    return obs$;
  }

  getLatestStockTake(): Observable<StockTake> {
    const obs$ = this.store.pipe(select(selectStockTake));
    this.store.dispatch(new actions.GetLatestStockTakeAction());
    return obs$;
  }

  getStockTakes(
    pageNumber = 0,
    pageSize = 25,
    filterCommand: Filter = null,
  ): Observable<Pageable<StockTake>> {
    const obs$ = this.store.pipe(select(selectStockTakes));
    this.dispatchGetStockTakes(pageNumber, pageSize, filterCommand);
    return obs$;
  }

  mergeStockTakes(stockTakeIds: number[]) {
    this.store.dispatch(new actions.MergeStockTakeAction(stockTakeIds));
  }

  dispatchGetStockTakes(pageNumber = 0, pageSize = 25, filterCommand: Filter = null) {
    this.store.dispatch(new actions.GetStockTakesAction(pageNumber, pageSize, filterCommand));
  }

  requestRecount(stockTakeItem: StockTakeItem) {
    this.store.dispatch(new actions.RequestRecountAction(stockTakeItem));
  }

  exportStockTakes(ids: number[]) {
    this.store.dispatch(new actions.ExportStockTakesAction(ids));
  }

  approveStockTakes(ids: number[]) {
    this.store.dispatch(new actions.ApproveStockTakesAction(ids));
  }

  updateStockTake(id: number, stockTakeItem: StockTakeItem) {
    this.store.dispatch(new actions.UpdateStockTakeAction(id, [stockTakeItem]));
  }

  deleteStockTakeItem(id: number) {
    this.store.dispatch(new actions.DeleteStockTakeItemAction(id));
  }

  getInventories(
    pageNumber = 0,
    pageSize = 25,
    filterCommand: Filter = null,
  ): Observable<Pageable<Inventory>> {
    const obs$ = this.store.pipe(select(selectInventories));
    this.dispatchGetInventories(pageNumber, pageSize, filterCommand);
    return obs$;
  }

  exportInventories(ids: number[]) {
    this.store.dispatch(new actions.ExportInventoriesAction(ids));
  }

  dispatchGetInventories(pageNumber = 0, pageSize = 25, filterCommand: Filter = null) {
    this.store.dispatch(new actions.GetInventoriesAction(pageNumber, pageSize, filterCommand));
  }

  getMessages(): Observable<Message[]> {
    const obs$ = this.store.pipe(select(selectMessages));
    this.store.dispatch(new actions.GetMessagesAction());
    return obs$;
  }

  createMessage(message: Message) {
    this.store.dispatch(new actions.CreateMessageAction(message));
  }

  updateMessage(message: Message) {
    this.store.dispatch(new actions.UpdateMessageAction(message));
  }

  removeMessage(message: Message) {
    this.store.dispatch(new actions.RemoveMessageAction(message.id));
  }

  getCart(): Observable<Order> {
    const obs$ = this.store.pipe(select(selectCart));
    this.getNewCartIfEmpty(obs$);

    return obs$;
  }

  private getNewCartIfEmpty(obs$: Observable<Order>): void {
    obs$.pipe(take(1)).subscribe((cart: Order) => {
      if (cart && cart.id != null) {
        return;
      }

      this.getNewCartWithAuthUser();
    });
  }

  private getNewCartWithAuthUser(): void {
    this.store.pipe(select(selectAuthUser)).subscribe((user: User) => {
      if (!user) {
        return;
      }

      this.store.dispatch(
        new actions.GetCartAction(
          this.route.snapshot.queryParams.organizationId ?? user.organization.id,
        ),
      );
    });
  }

  addToCart(medicine: Medicine, brand: Brand, supplier: Organization, quantity: number) {
    this.store.dispatch(new actions.AddMedicineToCartAction(medicine, brand, supplier, quantity));
  }

  removeFromCart(medicine: Medicine, brand: Brand, supplier: Organization) {
    this.store.dispatch(
      new actions.RemoveMedicineFromCartAction(medicine, brand, supplier));
  }

  changePriceListItemSupplierInCart(oldPriceListItem: PricelistItem, newPriceListItem: PricelistItem, quantity: number) {
    this.store.dispatch(
      new actions.ChangePriceListItemSupplierCartAction(oldPriceListItem, newPriceListItem, quantity));
  }

  setPricelistItemsToChange(oldPriceListItemId: number, newPriceListItem: PricelistItem, quantity: number) {
    this.store.dispatch(
      new actions.SetPriceListItemToChangeAction(oldPriceListItemId, newPriceListItem, quantity));
  }

  clearCart() {
    this.store.dispatch(new actions.ClearCartAction());
  }

  updateCart(medicine: Medicine, brand: Brand, supplier: Organization, quantity: number) {
    const orderItem = new OrderMedicine({ quantity, medicine, brand, orderSupplier: { supplier: supplier } });
    this.store.dispatch(new actions.UpdateCartAction(orderItem));
  }

  approveNewPriceListItem(order: Order, oldPriceListItemId: number, newPriceListItem: PricelistItem) {
    this.store.dispatch(new actions.ApproveNewUnitPriceAction(
      { order, oldPriceListItemId, newPriceListItem }));
  }

  removeOutdatedMedicines() {
    this.store.dispatch(new actions.FilterCartAction());
  }

  rejectOrder(orderId: number) {
    this.dialog
      .open(UpdateOrderStatusDialogComponent, { data: { status: ORDERSTATUS.REJECTED } })
      .afterClosed()
      .pipe(take(1))
      .subscribe((res) => {
        if (res.confirmation) {
          this.updateOrderStatus(orderId, ORDERSTATUS.REJECTED, res.message);
        }
      });
  }

  updateOrderStatus(
    orderId: number,
    status: string,
    message?: string,
    supplierOrderIds?: number[],
  ) {
    this.store.dispatch(
      new actions.UpdateOrderStatusAction(orderId, status, message, supplierOrderIds),
    );
  }

  updateOrder(order: Order) {
    this.store.dispatch(new actions.UpdateOrderAction(order));
  }

  updateOrderWithoutSwitching(order: Order) {
    this.store.dispatch(new actions.UpdateOrderAndDoNotSwitchAction(order));
  }

  cancelOrder(order: Order) {
    const supplierOrderIds = order.orderSuppliers.map((sup) => sup.id);
    this.dialog
      .open(UpdateOrderStatusDialogComponent, { data: { status: ORDERSTATUS.CANCELLED } })
      .afterClosed()
      .pipe(take(1))
      .subscribe((res) => {
        if (res.confirmation) {
          this.updateOrderStatus(order.id, ORDERSTATUS.CANCELLED, res.message, supplierOrderIds);
        }
      });
  }

  getMedicineFilter(): Observable<Filter> {
    return this.store.pipe(
      select(selectMedicineFilter),
      map((_filter) => cloneDeep(_filter)),
    );
  }

  saveMedicineFilter(medicineFilter: Filter) {
    this.store.dispatch(new actions.SaveMedicineFilterAction(medicineFilter));
  }

  getDeliveryOrderFilter(): Observable<Filter> {
    return this.store.pipe(
      select(selectDeliveryOrderFilter),
      map((_filter) => cloneDeep(_filter)),
    );
  }

  saveDeliveryOrderFilter(orderFilter: Filter) {
    this.store.dispatch(new actions.SaveDeliveryOrderFilterAction(orderFilter));
  }

  getOrderFilter(): Observable<Filter> {
    return this.store.pipe(
      select(selectOrderFilter),
      map((_filter) => cloneDeep(_filter)),
    );
  }

  saveOrderFilter(orderFilter: Filter) {
    this.store.dispatch(new actions.SaveOrderFilterAction(orderFilter));
  }

  getCurrentOrders(): Observable<Order[]> {
    const obs$ = this.store.pipe(select(selectCurrentOrders));
    this.store.dispatch(new actions.GetCurrentOrdersAction());
    return obs$;
  }

  getPreviousOrders(): Observable<Order[]> {
    const obs$ = this.store.pipe(select(selectPreviousOrders));
    this.store.dispatch(new actions.GetPreviousOrdersAction());
    return obs$;
  }

  getOrder(
    unsubscribe: Subject<void>,
    orderId: string,
    subOrderId?: string,
    orderMedicineStatuses?: string[],
    orderSupplierStatuses?: string[],
  ): Observable<Order> {
    const obs$ = this.store.pipe(
      select(selectOrder),
      filter((order) => order != null && order.id.toString() === orderId),
      takeUntil(unsubscribe),
      map((order) => {
        if (subOrderId) {
          const orderSupplier = order.orderSuppliers.find(
            (sup) => sup.id === parseInt(subOrderId, 10),
          );

          return this.convertOrderSupplierToOrder(order, orderSupplier);
        }

        return order;
      }),
    );

    this.store.dispatch(
      new actions.GetOrderAction(parseInt(orderId, 10), {
        subOrderId: !nullOrEmpty(subOrderId) ? parseInt(subOrderId, 10) : null,
        orderMedicineStatuses,
        orderSupplierStatuses,
      }),
    );

    return obs$;
  }

  getDraftOrder(order: Order) {
    this.store.dispatch(new actions.GetDraftOrder(order));
  }

  modifyOrder(order: Order) {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          question: 'PAGES.ORDERS.MODIFY_CONFIRMATION',
          questionParams: {
            status: this.transloco.translate(`ORDER.STATUS.${order.currentOrderStatus.status}`),
            message: this.getModifiedMessage(order),
            date: this.datePipe.transform(order.currentOrderStatus.statusUpdateDate, 'dd-MM-yyyy'),
            // User will be null if the system updated the status
            user: order.currentOrderStatus.statusUpdateBy ? `${order.currentOrderStatus.statusUpdateBy.firstName} ${order.currentOrderStatus.statusUpdateBy.lastName}` : `Med4All`
          },
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.store.dispatch(new actions.ModifyOrderAction(order.id));
        }
      });
  }

  getMcfInfo(): Observable<McfInfo> {
    const obs$ = this.store.pipe(select(selectMcfInfo));
    this.store.dispatch(new actions.GetMcfInfoAction());
    return obs$;
  }

  getPaymentMethods(): Observable<PaymentMethod[]> {
    const obs$ = this.store.pipe(select(selectPaymentMethods));
    this.store.dispatch(new actions.GetPaymentMethodsAction());
    return obs$;
  }

  setPaymentMethods(orderId: number, paymentMethods: any[]) {
    this.store.dispatch(new actions.SetPaymentMethodsAction(orderId, paymentMethods));
  }

  updateProForma(
    orderId: number,
    orderSupplierId: number,
    status: string,
    orderItems?: OrderViewModel[],
  ) {
    if (status === ORDERSTATUS.PROFORMA_DECLINED || status === ORDERSTATUS.PROFORMA_REFUSED) {
      this.dialog
        .open(UpdateOrderStatusDialogComponent, { data: { status } })
        .afterClosed()
        .pipe(take(1))
        .subscribe((res) => {
          if (res.confirmation) {
            this.store.dispatch(
              new UpdateProFormaAction(orderId, orderSupplierId, status, null, res.message),
            );
          }
        });
    } else {
      let orderMedicines;
      if (orderItems?.length > 0) {
        orderMedicines = orderItems.map((item) => ({
          quantity: item.quantity,
          orderMedicineId: item.id,
        }));
      }

      this.store.dispatch(
        new UpdateProFormaAction(orderId, orderSupplierId, status, orderMedicines),
      );
    }
  }

  updateShipment(
    orderId: number,
    orderSupplierId: number,
    orderItems: OrderViewModel[],
    status: string,
    fullName: string,
    email: string,
    orderMedicineStatuses?: string[],
    orderSupplierStatuses?: string[],
    subShipments: OrderMedicineCopy[] = [],
  ) {
    let orderMedicines;
    if (orderItems?.length > 0) {
      orderMedicines = orderItems
        .filter((orderItem) => orderItem.selected)
        .map((item) => ({
          quantity: item.quantity,
          orderMedicineId: item.id,
          status: item.status,
          message: item.message,
          batches: item.batches
            ?.filter((b) => b.batchNumber != null && b.quantity !== 0)
            .map((b) => {
              return {
                batchNumber: b.batchNumber,
                batchDate: moment(b.batchDate).format('YYYY-MM-DDTh:mm:ss'),
                quantity: b.quantity,
              };
            })
            .filter((b) => moment(b.batchDate).isValid()),
        }));
    }

    const orderFilter: OrderFilter = { orderMedicineStatuses, orderSupplierStatuses };
    const question =
      status === ORDERSTATUS.ANNULLED
        ? 'PAGES.WAYBILL.ANNUL_CONFIRMATION'
        : 'PAGES.WAYBILL.SHIP_CONFIRMATION';
    this.dialog
      .open(ConfirmationDialogComponent, { data: { question } })
      .afterClosed()
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.store.dispatch(
            new UpdateShipmentAction(
              orderId,
              orderSupplierId,
              orderMedicines,
              status,
              fullName,
              email,
              orderFilter,
              subShipments,
            ),
          );
        }
      });
  }

  submitProofOfDelivery(orderId: number, orderSupplierId: number, files: any[]) {
    this.store.dispatch(new SubmitPodAction(orderId, orderSupplierId, files));
  }

  submitDelivery(
    orderId: number,
    orderSupplierId: number,
    orderItems: OrderViewModel[],
    subDeliveries: OrderMedicineCopy[],
  ) {
    orderItems = orderItems.filter((item) => item.status !== ORDERSTATUS.NOT_DELIVERED);

    this.dialog
      .open(ConfirmationDialogComponent, {
        data: { question: 'PAGES.DELIVERY.DELIVERY_CONFIRMATION' },
        width: '500px',
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          const medicines: DeliveryMedicine[] = orderItems.map((item: OrderViewModel) => ({
            orderMedicineId: item.id,
            batches: ![ORDERSTATUS.DELIVERED, ORDERSTATUS.PARTIALLY_DELIVERED].includes(item.status)
              ? null
              : item?.batches
                ?.filter((b) => b.batchNumber != null && b.quantity !== 0)
                .map((b) => {
                  return {
                    batchNumber: b.batchNumber,
                    batchDate: moment(b.batchDate).format('YYYY-MM-DDTh:mm:ss'),
                    quantity: b.quantity,
                  };
                })
                .filter((b) => moment(b.batchDate).isValid()),
            quantityAssignedToStatus: item.quantityAssignedToStatus,
            status:
              this.shouldChangeStatusToPartiallyDelivererd(item) ? ORDERSTATUS.PARTIALLY_DELIVERED : item.status,
            message: item.message,
            changeSupplier: item.changeSupplier
          }));

          this.store.dispatch(
            new SubmitDeliveryAction(orderId, orderSupplierId, medicines, subDeliveries),
          );
        }
      });
  }

  private shouldChangeStatusToPartiallyDelivererd (item: OrderViewModel) : boolean {
    return item.status == ORDERSTATUS.DELIVERED && item.quantity > item.quantityAssignedToStatus;
  }

  cancelUndeliveredMedicines(
    orderId: number,
    orderSupplierId: number,
    orderItems: OrderViewModel[],
  ) {
    orderItems = orderItems.filter((item) => item.status === ORDERSTATUS.NOT_DELIVERED);

    this.dialog
      .open(ConfirmationDialogComponent, {
        data: { question: 'PAGES.DELIVERY.CANCEL_CONFIRMATION' },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          const medicines: DeliveryMedicine[] = orderItems.map((item: OrderViewModel) => ({
            orderMedicineId: item.id,
            message: item.message,
          }));
          this.store.dispatch(
            new CancelUndeliveredMedicinesAction(orderId, orderSupplierId, medicines),
          );
        }
      });
  }

  getOverviewActions(): Observable<OverviewAction[]> {
    const obs$ = this.store.pipe(
      select(selectOverviewActions),
      filter((_actions) => _actions != null),
    );
    this.store.dispatch(new GetOverviewActionsAction());
    return obs$;
  }

  convertOrderSupplierToOrder(order: Order, orderSupplier: OrderSupplier): Order {
    if (!orderSupplier) {
      return order;
    }

    return new Order({
      ...orderSupplier,
      id: order.id,
      subOrderId: orderSupplier.id,
      currentOrderStatus: orderSupplier.currentOrderSupplierStatus,
      orderMedicines: order.orderMedicines.filter(
        (med) => med.orderSupplier.id === orderSupplier.id,
      ),
      orderSuppliers: [orderSupplier],
      organization: order.organization,
    });
  }

  getWithdrawalRequests(): Observable<WithdrawalRequest[]> {
    const obs$ = this.store.pipe(select(selectWithdrawalRequests));
    this.store.dispatch(new actions.GetWithdrawalRequestsAction());
    return obs$;
  }

  createWithdrawalRequestAndSetPaymentMethods(
    withdrawalRequest: WithdrawalRequest,
    orderId: number,
    paymentMethods: any[],
  ) {
    this.store.dispatch(
      new CreateWithdrawalRequestAndSetPaymentMethodAction(
        withdrawalRequest,
        orderId,
        paymentMethods,
      ),
    );
  }

  createWithdrawalRequest(amount: number) {
    this.dialog
      .open(ConfirmationDialogComponent, { data: { question: 'PAGES.LOANS.REQUEST_CONFIRMATION' } })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.store.dispatch(new CreateWithdrawalRequestAction(amount));
        }
      });
  }

  checkOrderOrRedirect(order: Order, statuses: string[]) {
    if (
      statuses.includes(order?.currentOrderStatus?.status) ||
      order.hasOrderMedicinesInStatuses(statuses)
    ) {
      return;
    }

    this.notification.onWarning({ message: 'PAGES.ORDERS.ORDER_WAS_UPDATED' });
    this.router.navigateByUrl('/orders/overview');
  }

  getOrderItemFromOrderMedicinesFromPriceListItemId(orderMedicines: OrderMedicine[], pricelistItemId: number) {

    return orderMedicines.find(x => x.priceListItem.id == pricelistItemId);
  }

  getOrderItemFromOrderMedicines(orderMedicines: OrderMedicine[], medicine: Medicine, brand: Brand, supplier: Organization) {
    const orderMedicine = orderMedicines.find(
      (_item) =>
        _item.brand.id === brand.id &&
        _item.medicine.id === medicine.id &&
        _item.orderSupplier.supplier.id === supplier.id,
    );
    return orderMedicine;
  }

  getModifiedMessage(order: Order) {
    const statusMessage = order.currentOrderStatus.message ?? "";

    if (statusMessage.startsWith("REJECT_ORDER_COMBINE_ORDERS_MEDICINES_STATUS_MESSAGE")) {
      return this.createModifiedMessageTable(order);
    }

    return statusMessage + ".";
  }

  private createModifiedMessageTable(order: Order) : string {
    let message = "These medicines were rejected from a previous order based on the notes in the table below.\n";

    message += "<table>" +
                    "<tr>" +
                      "<th>Medicine</th>" +
                      "<th>Message</th>" +
                      "<th>Brand</th>" +
                      "<th>Quantity</th>" +
                    "</tr>";

    order.orderMedicines.forEach(orderMedicine => {
            message += "<tr>" +
                      "<td>" +
                        orderMedicine.medicine.genericName +
                    "</td>" +
                    "<td>" +
                        orderMedicine.currentOrderMedicineStatus.message +
                    "</td>" +
                    "<td>" +
                        orderMedicine.brand.name +
                    "</td>" +
                    "<td>" +
                        orderMedicine.quantity +
                    "</td>" +
                "</tr>";
    });

    message += "</table>";
    return message;
  }
}
