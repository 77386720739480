import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SettingsService } from "../settings/settings.service";
import { SETTING } from "@shared/constants";
import { Observable } from "rxjs";
import { Medicine } from "@shared/models/medicine";
import { Brand } from "@shared/models/brand";
import { catchError, map } from 'rxjs/operators';
import { ApiService } from "./api.service";
import { SupplierSelectorForm } from "@app/cms/create-overview/components/supplier-selector/supplier-selector.component";
import { BrandCreatorForm } from "@app/cms/create-overview/components/brand-creator-form/brand-creator-form.component";
import { CreateProduct } from "@shared/interfaces/create-product.interface";
import { DeactivatedItems } from "@shared/interfaces/deactivated-items.interface";

@Injectable({
  providedIn: 'root',
})
export class ProductApiService {
  private readonly _apiUrl: string;

  constructor(
    private http: HttpClient,
    private settings: SettingsService,
    private api: ApiService
  ) {
    this._apiUrl = this.settings.get(SETTING.API_URL);
  }

  getMedicineById(medicineId: number): Observable<any> {
    const uri = `${this._apiUrl}/medicines/${medicineId}`;
    return this.http.get<Medicine>(uri);
  }

  getBrandById(brandId: any): Observable<any> {
    const uri = `${this._apiUrl}/brands/${brandId}`;
    return this.http.get<Brand>(uri);
  }

  updateBrand(brand: Brand): Observable<any> {
    const uri = `${this._apiUrl}/brands/${brand.id}`;
    return this.http.put<Brand>(uri, brand);
  }

  deactivateBrand(id: number) {
    const uri = `${this._apiUrl}/brands/${id}/deactivate`;
    return this.http.put<DeactivatedItems>(uri, id);
  }

  updateMedicine(medicine: Medicine): Observable<any> {
    const uri = `${this._apiUrl}/medicines/${medicine.id}`;
    return this.http.put<Medicine>(uri, medicine);
  }

  deactivateMedicine(id: number) {
    const uri = `${this._apiUrl}/medicines/${id}/deactivate`;
    return this.http.put<DeactivatedItems>(uri, id);
  }

  createProduct(createProduct: CreateProduct): Observable<any> {
    return this.http.post(`${this._apiUrl}/products`, createProduct);
  }

  deactivatePriceListItem(id: number): Observable<any> {
    const uri = `${this._apiUrl}/price-list-items/${id}/deactivate`;
    return this.http.put<DeactivatedItems>(uri, id);
  }
};
