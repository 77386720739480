import { Entity } from './entity';
import { Organization } from './organization';

export class McfInfo extends Entity {
  organization: Organization;
  mcfId: number;
  totalCreditLimit: number;
  totalOutstanding: number;
  lastWithdrawalDate: Date;
  creditSpace: number;
  indicative: boolean;

  constructor(data?: any, restore: boolean = false) {
    super(data, restore);

    if (data) {
      this.organization = data.organization ? new Organization(data.organization, restore) : null;
      this.mcfId = data.mcfId;
      this.totalCreditLimit = data.totalCreditLimit;
      this.totalOutstanding = data.totalOutstanding;
      this.lastWithdrawalDate = data.lastWithdrawalDate ? new Date(data.lastWithdrawalDate) : null;
      this.creditSpace = data.creditSpace;
      this.indicative = data.indicative;
    }
  }
}
