import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, mergeMap, map } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EditOverviewActionTypes } from './edit-overview.actions';
import { ProductApiService } from '@shared/providers/api/product-api.service';

import * as editOverviewAction from './edit-overview.actions';
import * as searchableNamesAction from '../searchable-names/searchable-names.actions';
import * as selectionSearchableNamesAction from '../selection-searchable-names/selection-searchable-names.actions';
import { GenericFailureAction, GenericSuccessAction } from '../app/actions';
import { DeactivatedItems } from '@shared/interfaces/deactivated-items.interface';
@Injectable()
export class EditOverviewEffects {
  constructor(private actions: Actions, private productApiService: ProductApiService) {}

  getMedicineById: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(EditOverviewActionTypes.GET_MEDICINE_BY_ID),
      mergeMap((action: any) =>
        this.productApiService.getMedicineById(action.payload).pipe(
          map((medicine) => {
            return new editOverviewAction.GetMedicineByIdSuccessAction(medicine);
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  getBrandById: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(EditOverviewActionTypes.GET_BRAND_BY_ID),
      mergeMap((action: any) =>
        this.productApiService.getBrandById(action.payload).pipe(
          map((brand) => {
            return new editOverviewAction.GetBrandByIdSuccessAction(brand);
          }),
          catchError((err) => of(new GenericFailureAction(err))),
        ),
      ),
    ),
  );

  updateBrand: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(EditOverviewActionTypes.UPDATE_BRAND),
      mergeMap((action: any) =>
        this.productApiService.updateBrand(action.payload).pipe(
          map((brand) => {
            return new editOverviewAction.UpdateBrandSuccessAction(brand);
          }),
          catchError((err) => of(new GenericFailureAction(err.error.message))),
        ),
      ),
    ),
  );

  updateBrandSuccessMessage: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(EditOverviewActionTypes.UPDATE_BRAND_SUCCESS),
      map(() => {
        return new GenericSuccessAction('MESSAGES.API.UPDATE_BRAND');
      }),
    ),
  );

  updateSeachableNamesAfterBrandSuccess: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(EditOverviewActionTypes.UPDATE_BRAND_SUCCESS),
      mergeMap((action: any) =>
        of(new searchableNamesAction.UpdateBrandSuccessAction(action.payload)),
      ),
    ),
  );

  updateSelectionSeachableNamesAfterBrandSuccess: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(EditOverviewActionTypes.UPDATE_BRAND_SUCCESS),
      mergeMap((action: any) =>
        of(new selectionSearchableNamesAction.UpdateBrandSuccessAction(action.payload)),
      ),
    ),
  );

  updateMedicine: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(EditOverviewActionTypes.UPDATE_MEDICINE),
      mergeMap((action: any) =>
        this.productApiService.updateMedicine(action.payload).pipe(
          map((medicine) => {
            return new editOverviewAction.UpdateMedicineSuccessAction(medicine);
          }),
          catchError((err) => of(new GenericFailureAction(err.error.message))),
        ),
      ),
    ),
  );

  updateMedicineSuccessMessage: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(EditOverviewActionTypes.UPDATE_MEDICINE_SUCCESS),
      map(() => {
        return new GenericSuccessAction('MESSAGES.API.UPDATE_MEDICINE');
      }),
    ),
  );

  updateSearchableNamesAfterMedicineSuccess: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(EditOverviewActionTypes.UPDATE_MEDICINE_SUCCESS),
      mergeMap((action: any) =>
        of(new searchableNamesAction.UpdateMedicineSuccessAction(action.payload)),
      ),
    ),
  );

  updateSelectionSearchableNamesAfterMedicineSuccess: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(EditOverviewActionTypes.UPDATE_MEDICINE_SUCCESS),
      mergeMap((action: any) =>
        of(new selectionSearchableNamesAction.UpdateMedicineSuccessAction(action.payload)),
      ),
    ),
  );

  deactivateMedicine: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(EditOverviewActionTypes.DEACTIVATE_MEDICINE),
      mergeMap((action: any) =>
        this.productApiService.deactivateMedicine(action.payload).pipe(
          map((deactivatedItems: DeactivatedItems) => {
            return new editOverviewAction.DeactivateMedicineSuccessAction(deactivatedItems);
          }),
          catchError((err) => of(new GenericFailureAction(err.error.message))),
        ),
      ),
    ),
  );

  deactivateMedicineSuccessMessage: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(EditOverviewActionTypes.DEACTIVATE_MEDICINE_SUCCESS),
      mergeMap(() => of(new GenericSuccessAction('MESSAGES.API.DEACTIVATE_MEDICINE'))),
    ),
  );

  updateSearchableNamesAfterDeactivateMedicineSuccess: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(EditOverviewActionTypes.DEACTIVATE_MEDICINE_SUCCESS),
      mergeMap((action: any) =>
        of(new searchableNamesAction.DeactivateItemsSuccessAction(action.payload)),
      ),
    ),
  );

  ResetMedicineSelectionSearchableNamesAfterDeactivateMedicineSuccess: Observable<any> =
    createEffect(() =>
      this.actions.pipe(
        ofType(EditOverviewActionTypes.DEACTIVATE_MEDICINE_SUCCESS),
        mergeMap(() => of(new selectionSearchableNamesAction.ResetMedicineAction())),
      ),
    );

  deactivateBrand: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(EditOverviewActionTypes.DEACTIVATE_BRAND),
      mergeMap((action: any) =>
        this.productApiService.deactivateBrand(action.payload).pipe(
          map((deactivatedItems: DeactivatedItems) => {
            return new editOverviewAction.DeactivateBrandSuccessAction(deactivatedItems);
          }),
          catchError((err) => of(new GenericFailureAction(err.error.message))),
        ),
      ),
    ),
  );

  deactivateBrandSuccessMessage: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(EditOverviewActionTypes.DEACTIVATE_BRAND_SUCCESS),
      mergeMap(() => of(new GenericSuccessAction('MESSAGES.API.DEACTIVATE_BRAND'))),
    ),
  );

  updateSearchableNamesAfterDeactivateBrandSuccess: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(EditOverviewActionTypes.DEACTIVATE_BRAND_SUCCESS),
      mergeMap((action: any) =>
        of(new searchableNamesAction.DeactivateItemsSuccessAction(action.payload)),
      ),
    ),
  );

  ResetBrandSelectionSearchableNamesAfterDeactivateBrandSuccess: Observable<any> = createEffect(
    () =>
      this.actions.pipe(
        ofType(EditOverviewActionTypes.DEACTIVATE_BRAND_SUCCESS),
        mergeMap(() => of(new selectionSearchableNamesAction.ResetBrandAction())),
      ),
  );
}
