import { Entity } from './entity';
import { OrderStatus } from '../interfaces/order-status.interface';
import { Organization } from './organization';
import { PaymentMethod } from '../interfaces/payment-method.interface';

export class OrderSupplier extends Entity {
  currentOrderSupplierStatus: OrderStatus;
  supplier: Organization;
  paymentMethod: PaymentMethod;
  totalValue: number;
  minimumOrderValue: number;
  contactPersonName: string;
  contactPersonEmail: string;
  contactPersonPhoneNumber: string;

  constructor(data?: any, restore: boolean = false) {
    super(data, restore);

    if (data) {
      this.supplier = data.supplier ? new Organization(data.supplier, restore) : null;
      this.paymentMethod = data.paymentMethod;
      this.currentOrderSupplierStatus = data.currentOrderSupplierStatus;
      this.totalValue = data.totalValue;
      this.minimumOrderValue = this.supplier?.minimumOrderValue;
      this.contactPersonName = data.contactPersonName;
      this.contactPersonEmail = data.contactPersonEmail;
      this.contactPersonPhoneNumber = data.contactPersonPhoneNumber;
    }
  }

  get minimumOrderValueReached() {
    return this.totalValue >= this.minimumOrderValue;
  }
}
