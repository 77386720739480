import { takeUntil } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BaseComponent } from '../../components/base/base.component';
import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appDesktop]',
})
export class DesktopDirective extends BaseComponent implements OnInit {
  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private breakpointObserver: BreakpointObserver,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.breakpointObserver
      .observe('(min-width: 767px)')
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((res) => {
        if (res.matches && this.viewContainer.length === 0) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      });
  }
}
