import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { ClearAlertAction } from '../../../store/app/actions';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html'
})
export class AlertComponent {
  @Input() type: string;
  @Input() message: string;
  @Input() params: any;

  constructor(private store: Store) { }

  close(): void {
    this.store.dispatch(new ClearAlertAction());
  }

}
