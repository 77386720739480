import { Organization } from './organization';
import { McfInfo } from './mcf-info';

export class WithdrawalRequest {
  organizationId: number;
  orderId: number;
  id: number;
  requestedWithdrawalValue: number;
  status: string;
  withdrawalDate: Date;
  organization: Organization;
  mcfInfo: McfInfo;

  constructor(data?: any, restore: boolean = false) {
    if (data) {
      this.id = data.id;
      this.orderId = data.orderId;
      this.organizationId = data.organizationId;
      this.organization = data.organization ? new Organization(data.organization, restore) : null;
      this.requestedWithdrawalValue = data.requestedWithdrawalValue;
      this.withdrawalDate = data.withdrawalDate ? new Date(data.withdrawalDate) : null;
      this.status = data.status;
      this.mcfInfo = data.mcfInfo ? new McfInfo(data.mcfInfo) : null;
    }
  }
}
