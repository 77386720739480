import { Entity } from './entity';
import { PricelistItem } from './pricelist-item';

export class Pricelist extends Entity {
  startDate: Date;
  endDate: Date;
  organizationId: number;
  priceListItems: PricelistItem[];

  constructor(data?: any, restore: boolean = false) {
    super(data, restore);

    if (data) {
      this.organizationId = data.organizationId;
      this.startDate = data.startDate ? new Date(data.startDate) : null;
      this.endDate = data.endDate ? new Date(data.endDate) : null;
      this.priceListItems = data.priceListItems ? data.priceListItems.map(item => new PricelistItem(item, restore)) : null;
    }
  }
}
