export const SETTING = {
  DATE_FORMAT: 'dateFormat',
  ENVIRONMENT: 'environment',
  API_URL: 'apiUrl',
  AUTH_URL: 'authUrl',
  APP_URL: 'appUrl',
  NAMESPACE: 'namespace',
  LINKEDIN: 'linkedin',
  OAUTH_CLIENT_ID: 'oAuthClientId',
  OAUTH_CLIENT_SECRET: 'oAuthClientSecret',
  OAUTH_REDIRECT_URI: 'oAuthRedirectUri',
  OAUTH_CLIENT_ID_TEMP: 'oAuthClientIdTemp',
  OAUTH_REDIRECT_URI_TEMP: 'oAuthRedirectUriTemp',
  DEBUG: 'debug',
  INSTRUMENTATION_KEY: 'instrumentationKey',
  PRODUCTION: 'production',
  COUNTRY_CODE: 'countryCode',
  REDIRECT_URL: 'redirectUrl',
  MCF_INFO: 'mcfInfoUp2date',
  ANALYTICS: 'analytics',
};

export const ORGANIZATION_SETTING = {
  INVENTORY_FREQUENCY: 'inventoryFrequencyInDays',
  ORDER_LAST_UPDATE: 'orderLastUpdate',
};

export const ENVIRONMENT = {
  PRODUCTION: 'production',
  TESTING: 'testing',
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  LOCAL: 'local',
};

export const ORGANIZATIONTYPE = {
  ENTITY: 'entity',
  FACILITY: 'facility',
  SUPPLIER: 'supplier',
  ALL: 'all',
  BANK: 'bank',
  MANUFACTURER: 'manufacturer'
};

export const FACILITYTYPE = {
  CHPS: 'chps',
  CLINIC: 'clinic',
  HEALTH_CENTER: 'health center',
  HOSPITAL: 'hospital',
  POLYCLINIC: 'polyclinic',
  PRIMARY: 'primary health care',
};

export const REGEX = {
  PASSWORD: /^(?=^.{8,}$)(?=.*\d)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
  PHONE_NUMBER: /^[a-zA-Z0-9\-().+\s]{7,10}$/,
  EMAIL: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
  ATC_CODE: /^[A-Za-z]\d{2}[A-Za-z]{2}\d{2}$/,
  MEDICINE_CODE: /^[A-Z]{8}\d$/,
};

export const PERMISSION = {
  ROLES: {
    VIEW: 'view_roles',
    CREATE: 'create_roles',
    EDIT: 'edit_roles',
    DELETE: 'delete_roles',
    ASSIGN_ROLES: 'assign_roles',
  },
  PERMISSIONS: {
    VIEW: 'view_permissions',
    CREATE: 'create_permissions',
    EDIT: 'edit_permissions',
    DELETE: 'delete_permissions',
  },
  ORGANIZATIONS: {
    VIEW: 'view_organizations',
    CREATE: 'create_organizations',
    EDIT: 'edit_organizations',
    DELETE: 'delete_organizations',
    REACTIVATE: 'reactivate_organizations',
  },
  FINANCE_ORGANIZATIONS: {
    VIEW: 'view_finance_organizations',
    EDIT: 'edit_finance_organizations',
  },
  USERS: {
    VIEW: 'view_users',
    CREATE: 'create_users',
    EDIT: 'edit_users',
    DELETE: 'delete_users',
    REACTIVATE: 'reactivate_users',
  },
  FORMULARY: {
    VIEW: 'view_formulary',
    CREATE: 'create_formulary',
    EDIT: 'edit_formulary',
    DELETE: 'delete_formulary',
  },
  MEDICINES: {
    VIEW: 'view_medicines',
    CREATE: 'create_medicines',
    EDIT: 'edit_medicines',
    DELETE: 'delete_medicines',
  },
  INVENTORIES: {
    VIEW: 'view_inventories',
    CREATE: 'create_inventories',
    EDIT: 'edit_inventories',
    DELETE: 'delete_inventories',
  },
  STOCK_TAKES: {
    VIEW: 'view_stock_takes',
    CREATE: 'create_stock_takes',
    EDIT: 'edit_stock_takes',
    DELETE: 'delete_stock_takes',
  },
  STOREROOMS: {
    VIEW: 'view_storerooms',
    CREATE: 'create_storerooms',
    EDIT: 'edit_storerooms',
    DELETE: 'delete_storerooms',
  },
  PRICELISTS: {
    VIEW: 'view_price-lists',
    CREATE: 'create_price-lists',
    EDIT: 'edit_price-lists',
    DELETE: 'delete_price-lists',
  },
  PRICELIST_ITEMS: {
    VIEW: 'view_price-list-items',
    CREATE: 'create_price-list-items',
    EDIT: 'edit_price-list-items',
    DELETE: 'delete_price-list-items',
  },
  BRANDS: {
    VIEW: 'view_brands',
    CREATE: 'create_brands',
    EDIT: 'edit_brands',
    DELETE: 'delete_brands',
  },
  KEYWORDS: {
    VIEW: 'view_keywords',
    CREATE: 'create_keywords',
    EDIT: 'edit_keywords',
    DELETE: 'delete_keywords',
  },
  PRESCRIBING_LEVELS: {
    VIEW: 'view_prescribing-levels',
    CREATE: 'create_prescribing-levels',
    EDIT: 'edit_prescribing-levels',
    DELETE: 'delete_prescribing-levels',
  },
  DOCUMENTS: {
    VIEW: 'view_documents',
    CREATE: 'create_documents',
    EDIT: 'edit_documents',
    DELETE: 'delete_documents',
  },
  ORDERS: {
    VIEW: 'view_orders',
    CREATE: 'create_orders',
    EDIT: 'edit_orders',
    DELETE: 'delete_orders',
    APPROVE: 'approve_orders',
    REJECT: 'reject_orders',
    FORWARD: 'forward_orders',
    INITIATE: 'initiate_orders',
    REFUSE: 'refuse_orders',
    ACCEPT: 'accept_orders',
    SHIP: 'ship_orders',
    DELIVER: 'deliver_orders',
    PAY: 'pay_orders',
    CANCEL: 'cancel_orders',
    PRO_FORMA: 'proforma_orders',
    FULLFILL_PAYMENTS: 'fulfill_payments',
  },
  ADMIN: {
    VIEW: 'view_admin',
    VIEW_FACILITY_ORDERS: 'view_facility_orders',
  },
  MESSAGES: {
    VIEW: 'view_messages',
    CREATE: 'create_messages',
    EDIT: 'edit_messages',
    DELETE: 'delete_messages',
  },
  UNKNOWN_MEDICINES: {
    VIEW: 'view_unknown_medicines',
    DELETE: 'delete_unknown_medicines',
  },
  EMAIL_TEMPLATES: {
    VIEW: 'view_message_templates',
    CREATE: 'create_message_templates',
    EDIT: 'edit_message_templates',
    DELETE: 'delete_message_templates',
  },
  ADMIN_ORDERS: {
    VIEW: 'view_admin_orders',
    CHANGE_PAYMENT_METHODS: 'change_payment_methods',
  },
  FINANCE: {
    VIEW: 'view_finance',
  },
  REPORTS: {
    VIEW: 'view_reports',
  },
  MCF: {
    VIEW: 'view_mcf',
  },
  MCF_ORDERS: {
    VIEW: 'view_MCF_orders',
    APPROVE: 'approve_MCF_orders',
  },
  CMS: {
    VIEW: 'view_cms',
    VIEW_PRODUCT_CATALOG: 'view_product_catalog',
  },
  EMAILS: {
    VIEW: 'view_external_messages',
  },
  ORDER_MONITOR: 'view_order_monitor',
  MONITOR: {
    VIEW: 'view_monitor',
  },
  FINANCE_MONITOR: {
    VIEW: 'view_finance_monitor',
  },
  MISSING_MEDICINES_MONITOR: {
    VIEW: 'view_missing_medicines_monitor',
  },
  COMMISSION_MONITOR: {
    VIEW: 'view_commission_monitor',
  },
};

export const SORT_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc',
};

export const ORDERSTATUS = {
  DRAFT: 'DRAFT',
  INITIATED: 'INITIATED',
  MODIFIED: 'MODIFIED',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
  PAYMENT_SELECTED: 'PAYMENT_SELECTED',
  PAYMENT_APPROVED: 'PAYMENT_APPROVED',
  PAYMENT_REJECTED: 'PAYMENT_REJECTED',
  PAYMENT_RESUBMITTED: 'PAYMENT_RESUBMITTED',
  FORWARDED: 'FORWARDED',
  PROFORMA_SUBMITTED: 'PROFORMA_SUBMITTED',
  PARTIAL_PROFORMA: 'PARTIAL_PROFORMA',
  PROFORMA_REFUSED: 'PROFORMA_REFUSED',
  PROFORMA_ACCEPTED: 'PROFORMA_ACCEPTED',
  PROFORMA_DECLINED: 'PROFORMA_DECLINED',
  AWAITING_FUNDS: 'AWAITING_FUNDS',
  CHEQUE_RECEIVED: 'CHEQUE_RECEIVED',
  IGF_RECEIVED: 'IGF_RECEIVED',
  SHIPPED: 'SHIPPED',
  PARTIALLY_SHIPPED: 'PARTIALLY_SHIPPED',
  ANNULLED: 'ANNULLED',
  MERGED_SUB_ORDER: 'MERGED_SUB_ORDER',
  POD_SUBMITTED: 'POD_SUBMITTED',
  DELIVERED: 'DELIVERED',
  SENT_BACK: 'SENT_BACK',
  CHANGE_SUPPLIER: 'CHANGE_SUPPLIER',
  PARTIALLY_DELIVERED: 'PARTIALLY_DELIVERED',
  DELIVERY_REFUSED: 'DELIVERY_REFUSED',
  NOT_DELIVERED: 'NOT_DELIVERED',
  PAYMENT_MADE: 'PAYMENT_MADE',
  PARTIALLY_PAID: 'PARTIALLY_PAID',
  COMMISSION_PAID: 'COMMISSION_PAID',
  CANCELLED: 'CANCELLED',
};

export const ORDERSTATUSES_ARE_MODIFIABLE = [
  ORDERSTATUS.DRAFT, ORDERSTATUS.INITIATED, ORDERSTATUS.REJECTED, ORDERSTATUS.MODIFIED
];

export const ORDERSTATUSES_TO_NOT_SHOW = [
  ORDERSTATUS.MERGED_SUB_ORDER, ORDERSTATUS.CHANGE_SUPPLIER
];

export const VIEW_ORDERSTATUS = {
  OVER_DELIVERED: 'OVER_DELIVERED',
};

export const SKIP_FOR_CANCELLED_ACTION = [
  ORDERSTATUS.DRAFT,
  ORDERSTATUS.DELIVERED,
  ORDERSTATUS.PAYMENT_APPROVED,
  ORDERSTATUS.CHEQUE_RECEIVED,
  ORDERSTATUS.PAYMENT_MADE,
  ORDERSTATUS.CANCELLED
];

export const SUPPLIER_STATUSES = [
  ORDERSTATUS.FORWARDED,
  ORDERSTATUS.PROFORMA_SUBMITTED,
  ORDERSTATUS.PARTIAL_PROFORMA,
  ORDERSTATUS.PROFORMA_REFUSED,
  ORDERSTATUS.PROFORMA_ACCEPTED,
  ORDERSTATUS.PROFORMA_DECLINED,
  ORDERSTATUS.AWAITING_FUNDS,
  ORDERSTATUS.SHIPPED,
  ORDERSTATUS.PARTIALLY_SHIPPED,
  ORDERSTATUS.ANNULLED,
  ORDERSTATUS.POD_SUBMITTED,
  ORDERSTATUS.NOT_DELIVERED,
  ORDERSTATUS.PARTIALLY_DELIVERED,
  ORDERSTATUS.DELIVERY_REFUSED,
  ORDERSTATUS.DELIVERED,
  ORDERSTATUS.CHEQUE_RECEIVED,
  ORDERSTATUS.PARTIALLY_PAID,
  ORDERSTATUS.PAYMENT_MADE,
  ORDERSTATUS.IGF_RECEIVED,
  ORDERSTATUS.PAYMENT_APPROVED,
];

export const WITHDRAWALREQUESTSTATUS = {
  APPROVED: 'LOAN_APPROVED',
  REJECTED: 'LOAN_REJECTED',
  REQUESTED: 'LOAN_REQUESTED',
  DISBURSED: 'LOAN_DISBURSED',
  CANCELLED: 'LOAN_CANCELLED',
};

export const MENU_ITEMS = {
  orders: [
    {
      label: 'MENU.ORDERS.HOME',
      link: '/orders/home',
    },
    // {
    //   label: 'MENU.ORDERS.INVENTORY',
    //   link: '/orders/inventory',
    //   permissions: [PERMISSION.INVENTORIES.VIEW, PERMISSION.STOCK_TAKES.VIEW],
    // },
    // {
    //   label: 'MENU.ORDERS.STOREROOMS',
    //   link: '/orders/storerooms',
    //   permissions: [PERMISSION.STOREROOMS.VIEW],
    // },
    {
      label: 'MENU.ORDERS.ORDERS',
      link: '/orders/overview',
      permissions: [PERMISSION.ORDERS.VIEW],
    },
    {
      label: 'MENU.ORDERS.DOCUMENTS',
      link: '/orders/documents',
      permissions: [PERMISSION.DOCUMENTS.VIEW],
    },
    {
      label: 'MENU.ORDERS.FINANCE',
      link: '/orders/finance',
      permissions: [PERMISSION.FINANCE.VIEW],
    },
    {
      label: 'MENU.ORDERS.REPORTS',
      link: '/orders/reports',
      permissions: [PERMISSION.REPORTS.VIEW],
      entityType: ORGANIZATIONTYPE.FACILITY,
    },
    {
      label: 'MENU.ORDERS.SUPPORT',
      link: '/orders/support',
    },
  ],
  admin: [
    {
      label: 'MENU.ADMIN.HOME',
      link: '/admin/home',
      permissions: [PERMISSION.ADMIN.VIEW],
    },
    {
      label: 'MENU.ADMIN.USERS',
      link: '/admin/users',
      permissions: [PERMISSION.USERS.VIEW],
    },
    {
      label: 'MENU.ADMIN.ORGANIZATIONS',
      link: '/admin/organizations',
      permissions: [PERMISSION.ORGANIZATIONS.VIEW],
    },
    {
      label: 'MENU.ADMIN.ROLES',
      link: '/admin/roles',
      permissions: [PERMISSION.ROLES.VIEW],
    },
    {
      label: 'MENU.ADMIN.ORDERS',
      link: '/admin/orders',
      permissions: [PERMISSION.ADMIN_ORDERS.VIEW],
    },
    {
      label: 'MENU.ADMIN.DOCUMENTS',
      link: '/admin/documents',
      permissions: [PERMISSION.ADMIN_ORDERS.VIEW],
    },
    {
      label: 'MENU.ADMIN.EMAILS',
      link: '/admin/emails',
      permissions: [PERMISSION.EMAILS.VIEW],
    },
  ],
  orderMonitor: [
    {
      label: 'MENU.ORDER_MONITOR.ORDERS',
      link: '/monitor/orders',
      permissions: [PERMISSION.ORDER_MONITOR],
    },
    {
      label: 'MENU.ORDER_MONITOR.FINANCE',
      link: '/monitor/finances',
      permissions: [PERMISSION.FINANCE_MONITOR.VIEW],
    },
    {
      label: 'MENU.ORDER_MONITOR.MISSING_MEDICINES',
      link: '/monitor/missing-medicines',
      permissions: [PERMISSION.MISSING_MEDICINES_MONITOR.VIEW],
    },
  ],
  cms: [
    {
      label: 'MENU.CMS.HOME',
      link: '/cms/home',
      permissions: [PERMISSION.CMS.VIEW],
    },
    {
      label: 'MENU.CMS.OVERVIEW',
      link: '/cms/product-catalog',
      permissions: [PERMISSION.CMS.VIEW_PRODUCT_CATALOG],
    },
    {
      label: 'MENU.CMS.CREATE-OVERVIEW',
      link: '/cms/create-overview',
      permissions: [PERMISSION.ADMIN.VIEW],
    },
    {
      label: 'MENU.CMS.EDIT-OVERVIEW',
      link: '/cms/edit-overview',
      permissions: [PERMISSION.ADMIN.VIEW],
    },
    {
      label: 'MENU.CMS.PRICEUPDATEOVERVIEW',
      link: '/cms/priceupdate-overview',
      permissions: [PERMISSION.ADMIN_ORDERS.VIEW],
    },
    {
      label: 'MENU.CMS.UNKNOWN_MEDICINES',
      link: '/cms/unknown-medicines',
      permissions: [PERMISSION.UNKNOWN_MEDICINES.VIEW],
    },
    {
      label: 'MENU.CMS.EMAILS',
      link: '/cms/emails',
      permissions: [PERMISSION.EMAIL_TEMPLATES.VIEW],
    },
  ],
};

export const DOCUMENTTYPE = {
  PROOF_OF_DELIVERY: 'PROOF_OF_DELIVERY',
  AUTOMATICALLY_GENERATED: 'AUTOMATICALLY_GENERATED',
  PRICELIST: 'PRICELIST',
};

export const PAYMENT_METHOD = {
  IGF: 'IGF',
  IGF_90: 'IGF_90',
  MCF: 'MCF',
  A2F: 'A2F',
};

export const EMAIL_RECIPIENTS = {
  PROVIDER: 'PROVIDER',
  SUPPLIER: 'SUPPLIER',
  MED4ALL: 'MED4ALL',
  MED4ALL_PAYMENT: 'MED4ALL_PAYMENT',
  MED4ALL_ADMIN: 'MED4ALL_ADMIN',
  FIDELITY: 'FIDELITY',
  MCF: 'MCF',
  CATHOLICS: 'CATHOLICS',
};

export const EMAIL_RECIPIENT_TYPES = {
  TO: 'TO',
  CC: 'CC',
  BCC: 'BCC',
};

export const ISSUESTATUS = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  PENDING: 'PENDING',
};

export const PROCESS_FLOW = {
  INTERNATIONAL: 'INTERNATIONAL_FLOW',
  GHANA: 'GHANA_FLOW',
};

export const MODULES = {
  ADMIN: 'admin',
  ORDERS: 'orders',
  MONITOR: 'monitor',
  CMS: 'cms',
};

// if starting url is different than home and based on permission, add urls field with permission, the logic in
// module-toggle.component.ts will redirect to the first available url with the right permission
export const MODULE_CONFIG = [
  {
    module: MODULES.ADMIN,
    permission: PERMISSION.ADMIN.VIEW,
    url: '/admin/home',
  },
  {
    module: MODULES.ORDERS,
    permission: PERMISSION.ORDERS.VIEW,
    url: '/orders/home',
  },
  {
    module: MODULES.MONITOR,
    permission: PERMISSION.MONITOR.VIEW,
    url: '/monitor/orders',
    urls: [
      { url: '/monitor/orders', permission: PERMISSION.ORDER_MONITOR },
      { url: '/monitor/finances', permission: PERMISSION.FINANCE_MONITOR.VIEW },
      { url: '/monitor/missing-medicines', permission: PERMISSION.MISSING_MEDICINES_MONITOR.VIEW },
      { url: '/monitor/commissions', permission: PERMISSION.COMMISSION_MONITOR.VIEW },
    ],
  },
  {
    module: MODULES.CMS,
    permission: PERMISSION.CMS.VIEW,
    url: '/cms/home',
  },
];

export const LANGUAGE = {
  ENGLISH: 'en',
  FRENCH: 'fr',
  DUTCH: 'nl',
  CHINESE: 'ch',
  OTHER: 'ot',
};

export const OVERDUE_STATUS = {
  OKAY: 'OKAY',
  RISK: 'RISK',
  OVERDUE: 'OVERDUE',
};

export const MEDICINE_FILTERS = {
  FORMID: 'formId',
  STRENGTH: 'strength',
  GENERICNAME: 'genericName',
};

export const BRAND_FILTERS = {
  NAME: 'name',
  PACKSIZE: 'packSize',
};

export const PLATFORM_ROLES = {
  SUPER_ADMIN: 'Super Admin',
  ADMIN: 'Admin',
  CST_ADMIN: 'CST Admin',
  CST_ADMIN_CMS: 'CST Admin CMS',
  CST_ADMIN_PLUS: 'CST Admin +',
};
