import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { SettingsService } from '../settings/settings.service';
import { SETTING } from '../../constants';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, mergeMap, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private settings: SettingsService, private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // only add the Authorization header when there is a token and for API calls
    if (!req?.url.includes(this.settings.get(SETTING.API_URL))) {
      return next.handle(req.clone());
    }

    return this.authService.getAccessToken().pipe(
        mergeMap((token: string) => {
          if (token) {
            req = this._addToken(req, token);
          }

          return next.handle(req).pipe(catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
              return this._handle401Error(req, next);
            } else if (error instanceof HttpErrorResponse && error.status === 500 &&
              error.error.message.includes("You will be logged out, because user with E-mail ")) {
              // Note:: error message set in med4all-auth-server
              this.authService.logout();
              return throwError(error);
            } else {
              return throwError(error);
            }
          }));
        })
      );
  }

  private _addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private _handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshAccessToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token);
          return next.handle(this._addToken(request, token));
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => next.handle(this._addToken(request, token)))
      );
    }
  }
}
