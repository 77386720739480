import { CreateOverviewState, initialCreateOverviewState } from "./create-overview-state";
import { Actions, CreateOverviewActionTypes } from "./create-overview.actions";

export function CreateOverviewReducer(
  state: CreateOverviewState = initialCreateOverviewState, action: Actions) {
  switch (action.type) {
    case CreateOverviewActionTypes.GET_MEDICINE_BY_ID_SUCCESS:
      return {
        ...state,
        medicine: action.payload,
      }
    case CreateOverviewActionTypes.GET_BRAND_BY_ID_SUCCESS:
      return {
        ...state,
        brand: action.payload,
      }
    case CreateOverviewActionTypes.RESET_MEDICINE:
      return {
        ...state,
        medicine: null,
      }
    case CreateOverviewActionTypes.RESET_BRAND:
      return {
        ...state,
        brand: null,
      }
    case CreateOverviewActionTypes.CREATE_PRODUCT:
      return {
        ...state,
        isCreating: true,
      }
    case CreateOverviewActionTypes.CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        isCreating: false,
      }
    case CreateOverviewActionTypes.GET_PRODUCT_CATALOG_SUCCESS:
      return {
        ...state,
        productCatalog: action.payload,
      }
    default:
      return state;
  }
}
