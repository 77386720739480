import { Entity } from './entity';
import { Organization } from './organization';
import { Permission } from './permission';
import { Role } from './role';
import { Setting } from '../interfaces/setting.interface';

export class User extends Entity {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  lastLoginOn: Date;
  organization: Organization;
  permissions: Permission[];
  role: Role;
  receiveEmails: boolean;
  receiveAdminConfirmationPopup: boolean;
  settings: Setting[];

  constructor(data?: any, restore?: boolean) {
    super(data, restore);

    if (data) {
      this.email = data.email;
      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.phoneNumber = data.phoneNumber;
      this.lastLoginOn = data.lastLoginOn ? new Date(data.lastLoginOn) : null;
      this.organization = data.organization ? new Organization(data.organization, restore) : null;
      this.role = data.role ? new Role(data.role, restore) : null;
      this.permissions = data.permissions ? data.permissions.map(permission => new Permission(permission)) : [];
      this.receiveEmails = data.receiveEmails;
      this.receiveAdminConfirmationPopup = data.receiveAdminConfirmationPopup;
      this.settings = data.settings || [];
    }
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  get initials(): string {
    return this.firstName.charAt(0).toUpperCase() + this.lastName.charAt(0).toUpperCase();
  }

  public getSetting(name: string): boolean | number | string {
    const setting = this.settings.find(s => s.name === name);
    if (!setting) {
      return null;
    }

    switch (setting.dataType) {
      case 'Integer':
        return parseInt(setting.value, 10);
      case 'String':
        return setting.value;
      case 'Boolean':
        return setting.value === 'true';
      default:
        return setting.value;
    }
  }

  hasPermission(permissionName: string): boolean {
    return this.permissions && this.permissions.find(permission => {
      const isNegative = permissionName.indexOf('!') === 0;
      if (isNegative) {
        permissionName = permissionName.substr(1);
        return permission.name !== permissionName;
      }
      return permission.name === permissionName;
    }) !== undefined;
  }
}
