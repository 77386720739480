import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { select, Store } from '@ngrx/store';
import { selectLoadingMessage } from '../../../store/app/selectors';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoaderOptions } from '../../interfaces/loader-options.interface';
import { LoaderDialogComponent } from '../../components/dialogs/loader-dialog/loader-dialog.component';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ClearLoaderAction } from '../../../store/app/actions';

@Injectable()
export class LoaderService {
  loaderDialog: MatDialogRef<LoaderDialogComponent>;
  defaultOptions: any;

  constructor(
    private dialog: MatDialog,
    private translateService: TranslocoService,
    private store: Store,
  ) {
    this.defaultOptions = {
      delay: 100,
      content: 'MESSAGES.LOADER.PLEASE_WAIT',
      disableClose: true,
    };
  }

  init() {
    this.store.pipe(select(selectLoadingMessage)).subscribe((res) => {
      if (res) {
        this.load({ content: res });
      } else {
        this.dismiss();
      }
    });
  }

  load(opts: LoaderOptions = {}): Observable<any> {
    if (this.loaderDialog) {
      this.loaderDialog.close();
    }

    const options = {
      ...this.defaultOptions,
      ...opts,
      data: null,
    };

    options.data = {
      content: this.translateService.translate(options.content) + '...',
    };

    this.loaderDialog = this.dialog.open(LoaderDialogComponent, options);
    this.loaderDialog
      .backdropClick()
      .pipe(take(1))
      .subscribe(() => {
        this.loaderDialog.close();
        this.store.dispatch(new ClearLoaderAction());
      });

    return this.loaderDialog.afterClosed();
  }

  dismiss() {
    if (this.loaderDialog) {
      this.loaderDialog.close();
      this.loaderDialog = null;
    }
  }
}
