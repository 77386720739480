import { Action } from '@ngrx/store';
import { User } from '../../shared/models/user';
import { RecoverEmailRequest } from '../../shared/interfaces/forget-email-request.interface';
import { Organization } from '../../shared/models/organization';


export enum AuthActionTypes {
  GET_USER = '[Auth] Get user',
  GET_USER_SUCCESS = '[Auth] Get user Success',
  UPDATE_PROFILE = '[Auth] Update profile',
  UPDATE_PROFILE_SUCCESS = '[Auth] Update profile Success',
  LOGOUT = '[Auth] Logout',
  LOGOUT_SUCCESS = '[Auth] Logout Success',
  REQUEST_RESET_PASSWORD = '[Auth] Request reset password',
  RESET_PASSWORD = '[Auth] Reset password',
  RECOVER_EMAIL = '[Auth] Recover email',
  UPDATE_PASSWORD = '[Auth] Update password',
  GET_ORGANIZATIONS = '[Auth] Get organizations',
  GET_ORGANIZATIONS_SUCCESS = '[Auth] Get organizations success',
  GET_TOKENS = '[Auth] Get tokens',
  GET_TOKENS_SUCCESS = '[Auth] Get tokens Success',
  REFRESH_ACCESS_TOKEN = '[Auth] Refresh access token',
  REFRESH_ACCESS_TOKEN_SUCCESS = '[Auth] Refresh access token Success',
  UPDATE_PROFILE_SUCCESS_AND_LOGOUT_ACTION = "[Auth] Dispatch update profile success and logout action"
}

export class GetUserAction implements Action {
  readonly type = AuthActionTypes.GET_USER;
  constructor() {}
}

export class GetUserSuccessAction implements Action {
  readonly type = AuthActionTypes.GET_USER_SUCCESS;
  constructor(public payload: User) {
  }
}

export class UpdateProfileAction implements Action {
  readonly type = AuthActionTypes.UPDATE_PROFILE;
  constructor(public payload: { user: User, updateEmail?: boolean }) {}
}

export class UpdateProfileSuccessAction implements Action {
  readonly type = AuthActionTypes.UPDATE_PROFILE_SUCCESS;
  constructor(public payload: User) {
  }
}

export class  UpdateProfileSuccessAndLogOutAction implements Action {
  readonly type = AuthActionTypes.UPDATE_PROFILE_SUCCESS_AND_LOGOUT_ACTION;
  constructor(public payload: User) {
  }
}

export class LogOutAction implements Action {
  readonly type = AuthActionTypes.LOGOUT;
}

export class LogOutSuccessAction implements Action {
  readonly type = AuthActionTypes.LOGOUT_SUCCESS;
}

export class ResetPasswordAction implements Action {
  readonly type = AuthActionTypes.RESET_PASSWORD;
  constructor(public token: string, public password: string) {}
}

export class RequestResetPasswordAction implements Action {
  readonly type = AuthActionTypes.REQUEST_RESET_PASSWORD;
  constructor(public payload: string) {}
}

export class RecoverEmailAction implements Action {
  readonly type = AuthActionTypes.RECOVER_EMAIL;
  constructor(public payload: RecoverEmailRequest) {}
}

export class UpdatePasswordAction implements Action {
  readonly type = AuthActionTypes.UPDATE_PASSWORD;
  constructor(public oldPassword: string, public newPassword: string) {}
}

export class GetOrganizationsAction implements Action {
  readonly type = AuthActionTypes.GET_ORGANIZATIONS;
  constructor() {}
}

export class GetOrganizationsSuccessAction implements Action {
  readonly type = AuthActionTypes.GET_ORGANIZATIONS_SUCCESS;
  constructor(public payload: Organization[]) {}
}

export class GetTokensAction implements Action {
  readonly type = AuthActionTypes.GET_TOKENS;
  constructor(public payload: string) {}
}

export class GetTokensSuccessAction implements Action {
  readonly type = AuthActionTypes.GET_TOKENS_SUCCESS;
  constructor(public payload: any) {}
}

export class RefreshAccessTokenAction implements Action {
  readonly type = AuthActionTypes.REFRESH_ACCESS_TOKEN;
}

export class RefreshAccessTokenSuccessAction implements Action {
  readonly type = AuthActionTypes.REFRESH_ACCESS_TOKEN_SUCCESS;
  constructor(public payload: any) {}
}

export type Actions =
  | GetUserAction
  | GetUserSuccessAction
  | LogOutAction
  | LogOutSuccessAction
  | ResetPasswordAction
  | RequestResetPasswordAction
  | UpdatePasswordAction
  | RecoverEmailAction
  | GetOrganizationsAction
  | GetOrganizationsSuccessAction
  | UpdateProfileAction
  | UpdateProfileSuccessAction
  | UpdateProfileSuccessAndLogOutAction
  | GetTokensAction
  | GetTokensSuccessAction
  | RefreshAccessTokenAction
  | RefreshAccessTokenSuccessAction
  ;
