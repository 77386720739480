<div class="breadcrumbs-container" *transloco="let t">
  <ng-container *ngFor="let crumb of crumbs; index as i">
    <a [routerLink]="crumb.link" queryParamsHandling="merge" *ngIf="crumb.link; else noCrumbLink">
      <h1>{{t(crumb.label)}}</h1>
    </a>
    <ng-template #noCrumbLink>
      <h1>
        {{t(crumb.label)}}
        <span *ngIf="crumb.orderId && !crumb.subOrderId">&nbsp;({{crumb.orderId}})</span>
        <span *ngIf="crumb.orderId && crumb.subOrderId">&nbsp;({{crumb.orderId}} - {{crumb.subOrderId}})</span>
      </h1>
    </ng-template>

    <span class="mat-h1 crumb-divider" *ngIf="i < crumbs.length - 1">/</span>
  </ng-container>
</div>
