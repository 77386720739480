import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { take, takeUntil } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { User } from '../../models/user';
import { BaseComponent } from '../../components/base/base.component';
import { DialogType } from '../../models/dialog-type';
import { UpdateOrderStatusDialogComponent } from '../../../orders/order-details/components/update-order-status-dialog/update-order-status-dialog.component';

@Injectable()
export class ConfirmDialog extends BaseComponent {
  user: User;

  constructor(private dialog: MatDialog, private authService: AuthService) {
    super();

    this.authService?.getUser()
      ?.pipe(takeUntil(this._unsubscribe$))
      ?.subscribe(user => this.user = user);
  }

  public confirmAndRun(questionKey: string, runnable: () => void, dialogType = DialogType.ADMIN, questionParams = null) {
    if (this.runIfNoConfirmationNeeded(runnable, dialogType)) {
      return;
    }

    this.dialog.open(ConfirmationDialogComponent, {
      data: {question: questionKey, questionParams, dialogType},
      panelClass: 'confirmation-dialog'
    })
      .afterClosed()
      .pipe(take(1))
      .subscribe(res => {
        if (res) {
          runnable();
        }
      });
  }

  public okDialog(body: string, dialogTitle: string) {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        question: body, title: dialogTitle, actions: [{
          label: 'BUTTONS.OK',
          color: 'primary'
        }]
      },
      panelClass: 'confirmation-dialog'
    });
  }

  public confirmOrderStatusAndRun(status: string, isAdminReject: boolean, runnable: (message: string) => void) {
    this.dialog
      .open(UpdateOrderStatusDialogComponent, {data: {status, isAdminReject}})
      .afterClosed()
      .pipe(take(1))
      .subscribe(res => {
        if (res.confirmation) {
          runnable(res.message);
        }
      });
  }

  private runIfNoConfirmationNeeded(runnable: () => void, dialogType: DialogType): boolean {
    if (dialogType !== DialogType.ADMIN || this.user == null || this.user.getSetting('receiveAdminConfirmationPopup')) {
      return false;
    }

    runnable();

    return true;
  }
}
