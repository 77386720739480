<div class="recover-email-dialog-container">
  <div class="dialog-header">
    <h2 transloco="DIALOGS.RECOVER_EMAIL.TITLE"></h2>
    <button mat-icon-button (click)="close()">
      <fa-icon icon="times"></fa-icon>
    </button>
  </div>

  <div class="form-container">
    <form [formGroup]="recoverEmailForm" class="recover-form">
      <mat-form-field>
        <mat-label transloco="USER.FIRST_NAME"></mat-label>
        <input type="text" formControlName="firstName" id="firstNameInput" required matInput/>
        <mat-error *ngIf="firstNameCtrl.invalid && firstNameCtrl.dirty">
          <span *ngIf="firstNameCtrl.hasError('required')" transloco="MESSAGES.FORM.FIRST_NAME_REQUIRED_ERROR"></span>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label transloco="USER.LAST_NAME"></mat-label>
        <input type="text" id="lastNameInput" formControlName="lastName" required matInput />
        <mat-error *ngIf="lastNameCtrl.invalid && lastNameCtrl.dirty">
          <span *ngIf="lastNameCtrl.hasError('required')" transloco="MESSAGES.FORM.LAST_NAME_REQUIRED_ERROR"></span>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label transloco="USER.EMAIL_ADDRESS"></mat-label>
        <input type="text" formControlName="email" id="usernameInput" required  matInput />
        <mat-error *ngIf="emailCtrl.invalid && emailCtrl.dirty">
          <span *ngIf="emailCtrl.hasError('required')" transloco="MESSAGES.FORM.EMAIL_ADDRESS_REQUIRED_ERROR"></span>
          <span *ngIf="emailCtrl.hasError('email')" transloco="MESSAGES.FORM.EMAIL_ADDRESS_INVALID_ERROR"></span>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label transloco="USER.ORGANIZATION"></mat-label>
        <mat-select formControlName="organizationId" class="organizations-container" required>
          <mat-option *ngFor="let organization of organizations$ | async" [value]="organization.id">
            {{organization.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="organizationCtrl.invalid && organizationCtrl.dirty">
          <span *ngIf="organizationCtrl.hasError('required')" transloco="MESSAGES.FORM.ORGANIZATION_REQUIRED_ERROR"></span>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label transloco="DIALOGS.RECOVER_EMAIL.COMMENTS"></mat-label>
        <textarea id="commentInput" matInput maxlength=200 formControlName="comment" required></textarea>
        <mat-error *ngIf="commentCtrl.invalid && commentCtrl.dirty">
          <span *ngIf="commentCtrl.hasError('required')" transloco="MESSAGES.FORM.COMMENT_REQUIRED_ERROR"></span>
        </mat-error>
      </mat-form-field>
    </form>
  </div>

  <div class="margin-top text-end full-width">
    <button mat-raised-button
            type="button"
            color="primary"
            (click)="save()"
            [disabled]="recoverEmailForm.invalid"
            transloco="BUTTONS.SEND_MESSAGE">
    </button>
  </div>
</div>
