import { Action } from "@ngrx/store";
import { BrandNameId } from "@shared/interfaces/brand-name-id.interface";
import { DeactivatedItems } from "@shared/interfaces/deactivated-items.interface";
import { MedicineNameId } from "@shared/interfaces/medicine-name-id.interface";
import { SupplierWithIds } from "@shared/interfaces/supplier-with-ids.interface";
import { Brand } from "@shared/models/brand";
import { Medicine } from "@shared/models/medicine";
import { PricelistItem } from "@shared/models/pricelist-item";

export enum SelectionSearchableNamesActionTypes {
  SELECT_PRICELIST_ITEM = '[SelectionSearchableNames] Select Price List Item',
  UPDATE_PRICELIST_ITEM = '[SelectionSearchableNames] Update Price List Item',
  UPDATE_PRICELIST_ITEM_SUCCESS = '[SelectionSearchableNames] Update Price List Item Success',

  DEACTIVATE_PRICELIST_ITEM = "[SelectionSearchable] Deactivate Price List Item",
  DEACTIVATE_PRICELIST_ITEM_SUCCESS = "[SelectionSearchable] Deactivate Price List Item success",

  SELECT_SUPPLIER = '[SelectionSearchableNames] Select Supplier',
  FILTER_SUPPLIER = '[SelectionSearchableNames] Filter supplier searchable names',
  RESET_SUPPLIER = '[SelectionSearchableNames] Reset supplier searchable names',

  SELECT_MANUFACTURER = '[SelectionSearchableNames] Select Manufacturer',
  FILTER_MANUFACTURER = '[SelectionSearchableNames] Filter manufacturer searchable names',
  RESET_MANUFACTURER = "[SelectionSearchableNames] Reset manufacturer",

  SELECT_BRAND = '[SelectionSearchableNames] Select brand',
  FILTER_BRAND = '[SelectionSearchableNames] Filter brand searchable names',
  RESET_BRAND = '[SelectionSearchableNames] Reset brand searchable names',

  SELECT_MEDICINE = '[SelectionSearchableNames] Select medicine',
  FILTER_MEDICINE = '[SelectionSearchableNames] Filter medicine searchable names',
  RESET_MEDICINE = '[SelectionSearchableNames] Reset medicine searchable names',

  RESET_STATE = '[SelectionSearchableNames] Reset state',

  RESET_BRAND_AND_MANUFACTURER = "[SelectionSearchableNames] Reset brand and manufacturer",

  UPDATE_BRAND_SUCCESS = "[SelectionSearchableBrandNames] Update brand success update brand name",
  UPDATE_MEDICINE_SUCCESS = "[SelectionSearchableBrandNames] Update medicine success update medicine name",
}

export class SelectPriceListItemAction implements Action {
  readonly type = SelectionSearchableNamesActionTypes.SELECT_PRICELIST_ITEM;
  constructor(public payload: PricelistItem) {}
}

export class UpdatePriceListItemAction implements Action {
  readonly type = SelectionSearchableNamesActionTypes.UPDATE_PRICELIST_ITEM;
  constructor(public payload: PricelistItem) {}
}

export class DeactivatePriceListItemAction implements Action {
  readonly type = SelectionSearchableNamesActionTypes.DEACTIVATE_PRICELIST_ITEM;

  constructor(public payload: number) {}
}

export class DeactivatePriceListItemSuccessAction implements Action {
  readonly type = SelectionSearchableNamesActionTypes.DEACTIVATE_PRICELIST_ITEM_SUCCESS;

  constructor(public payload: DeactivatedItems) {}
}

export class UpdatePriceListItemSuccessAction implements Action {
  readonly type = SelectionSearchableNamesActionTypes.UPDATE_PRICELIST_ITEM_SUCCESS;
  constructor(public payload:
    { priceListItem: PricelistItem, oldPriceListItemId: number}) {}
}

export class SelectSupplierAction implements Action {
  readonly type = SelectionSearchableNamesActionTypes.SELECT_SUPPLIER;
  constructor(public payload: SupplierWithIds) {}
}

export class FilterSupplierAction implements Action {
  readonly type = SelectionSearchableNamesActionTypes.FILTER_SUPPLIER;

  constructor(public payload: string) {}
}

export class SelectManufacturerAction implements Action {
  readonly type = SelectionSearchableNamesActionTypes.SELECT_MANUFACTURER;
  constructor(public payload: SupplierWithIds) {}
}

export class FilterManufacturerAction implements Action {
  readonly type = SelectionSearchableNamesActionTypes.FILTER_MANUFACTURER;

  constructor(public payload: string) {}
}

export class SelectBrandAction implements Action {
  readonly type = SelectionSearchableNamesActionTypes.SELECT_BRAND;

  constructor(public payload: BrandNameId) {}
}

export class FilterBrandAction implements Action {
  readonly type = SelectionSearchableNamesActionTypes.FILTER_BRAND;

  constructor(public payload: string) {}
}


export class SelectMedicineAction implements Action {
  readonly type = SelectionSearchableNamesActionTypes.SELECT_MEDICINE;

  constructor(public payload: MedicineNameId) {}
}

export class FilterMedicineAction implements Action {
  readonly type = SelectionSearchableNamesActionTypes.FILTER_MEDICINE;

  constructor(public payload: string) {}
}

export class ResetBrandAction implements Action {
  readonly type = SelectionSearchableNamesActionTypes.RESET_BRAND;

  constructor() {}
}

export class ResetMedicineAction implements Action {
  readonly type = SelectionSearchableNamesActionTypes.RESET_MEDICINE;

  constructor() {}
}

export class ResetSupplierAction implements Action {
  readonly type = SelectionSearchableNamesActionTypes.RESET_SUPPLIER;

  constructor() {}
}

export class ResetManufacturerAction implements Action {
  readonly type = SelectionSearchableNamesActionTypes.RESET_MANUFACTURER;

  constructor() {}
}

export class ResetBrandAndManufacturerAction implements Action {
  readonly type = SelectionSearchableNamesActionTypes.RESET_BRAND_AND_MANUFACTURER;

  constructor() {}
}

export class ResetStateAction implements Action {
  readonly type = SelectionSearchableNamesActionTypes.RESET_STATE;

  constructor() {}
}

export class UpdateBrandSuccessAction implements Action {
  readonly type = SelectionSearchableNamesActionTypes.UPDATE_BRAND_SUCCESS;

  constructor(public payload: Brand) {}
}

export class UpdateMedicineSuccessAction implements Action {
  readonly type = SelectionSearchableNamesActionTypes.UPDATE_MEDICINE_SUCCESS;

  constructor(public payload: Medicine) {}
}

export type Actions =
  | SelectPriceListItemAction
  | UpdatePriceListItemAction
  | UpdatePriceListItemSuccessAction
  | DeactivatePriceListItemAction
  | DeactivatePriceListItemSuccessAction
  | SelectSupplierAction
  | FilterSupplierAction
  | SelectManufacturerAction
  | FilterManufacturerAction
  | SelectBrandAction
  | FilterBrandAction
  | SelectMedicineAction
  | FilterMedicineAction
  | ResetBrandAction
  | ResetMedicineAction
  | ResetSupplierAction
  | ResetManufacturerAction
  | ResetBrandAndManufacturerAction
  | ResetStateAction
  | UpdateBrandSuccessAction
  | UpdateMedicineSuccessAction;
