import { DomSanitizer } from '@angular/platform-browser';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'safeStyle',
  pure: true
})
export class SafeStylePipe implements PipeTransform  {
    constructor(private sanitizer: DomSanitizer) {}
    transform(value) {
        return this.sanitizer.bypassSecurityTrustStyle(value);
    }
}
