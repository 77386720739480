import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-video-dialog',
  templateUrl: './video-dialog.component.html',
})
export class VideoDialogComponent {
  safeUrl: SafeResourceUrl;
  @Input() set url(val: string) {
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(val);
  }

  @Input() title: string;

  constructor(private dialogRef: MatDialogRef<VideoDialogComponent>, private sanitizer: DomSanitizer) { }

  close() {
    this.dialogRef.close();
  }

}
