import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ofType } from '@ngrx/effects';
import { filter, take, takeUntil } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppActionTypes, ValidationErrorsAction } from '../../../../store/app/actions';
import { BaseComponent } from '../../base/base.component';
import { User } from '../../../models/user';
import { AuthActionTypes } from '../../../../store/auth/actions';
import { AuthService } from '../../../providers/auth/auth.service';
import { ActionsSubject } from '@ngrx/store';
import { REGEX } from '../../../constants';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-profile-dialog',
  templateUrl: './profile-dialog.component.html',
})
export class ProfileDialogComponent extends BaseComponent implements OnInit {
  @ViewChild('emailInput') emailInput: ElementRef;
  user: User;
  profileForm: UntypedFormGroup;
  passwordForm: UntypedFormGroup;

  get firstName(): AbstractControl {
    return this.profileForm.get('firstName');
  }

  get lastName(): AbstractControl {
    return this.profileForm.get('lastName');
  }

  get email(): AbstractControl {
    return this.profileForm.get('email');
  }

  get confirmEmail(): AbstractControl {
    return this.profileForm.get('confirmEmail');
  }

  get phoneNumber(): AbstractControl {
    return this.profileForm.get('phoneNumber');
  }

  get oldPassword(): AbstractControl {
    return this.passwordForm.get('oldPassword');
  }

  get newPassword(): AbstractControl {
    return this.passwordForm.get('newPassword');
  }

  get confirmPassword(): AbstractControl {
    return this.passwordForm.get('confirmPassword');
  }

  constructor(
    private auth: AuthService,
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ProfileDialogComponent>,
    private actions$: ActionsSubject,
    @Inject(MAT_DIALOG_DATA) private dialogData: any,
  ) {
    super();

    this.user = this.dialogData.user;

    this.profileForm = this.formBuilder.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      email: [{ value: null }, [Validators.required, Validators.email]],
      confirmEmail: [{ value: null }, [Validators.email]],
      role: [{ value: null, disabled: true }],
      phoneNumber: null,
      receiveEmails: null,
      receiveAdminConfirmationPopup: null,
    });

    this.passwordForm = this.formBuilder.group({
      oldPassword: ['', [Validators.required, Validators.pattern(REGEX.PASSWORD)]],
      newPassword: ['', [Validators.required, Validators.pattern(REGEX.PASSWORD)]],
      confirmPassword: ['', [Validators.required, Validators.pattern(REGEX.PASSWORD)]],
    });
  }

  ngOnInit() {
    super.ngOnInit();

    this.profileForm.patchValue({
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      email: this.user.email,
      confirmEmail: "",
      role: this.user.role.name,
      phoneNumber: this.user.phoneNumber,
      receiveEmails: this.user.receiveEmails,
      receiveAdminConfirmationPopup: this.user.getSetting('receiveAdminConfirmationPopup'),
    });

    // display validation errors
    this.actions$
      .pipe(
        ofType<ValidationErrorsAction>(AppActionTypes.VALIDATION_ERRORS),
        filter((action) => action.payload && action.payload.length > 0),
        takeUntil(this._unsubscribe$),
      )
      .subscribe((action) =>
        action.payload.forEach((error) =>
          this.profileForm.get(error.field).setErrors({ [error.type]: true }),
        ),
      );
  }

  save() {
    this.actions$
      .pipe(ofType(AuthActionTypes.UPDATE_PROFILE_SUCCESS), take(1), takeUntil(this._unsubscribe$))
      .subscribe(() => this.close());

    const updateEmail = this.user.email != this.profileForm.value.email;
    this.auth.updateProfile({
      ...this.user,
      ...this.profileForm.value,
    }, updateEmail);
  }

  close() {
    this.dialogRef.close();
  }

  updatePassword() {
    if (this.passwordForm.valid) {
      this.auth.updatePassword(this.oldPassword.value, this.newPassword.value);
      this.passwordForm.reset();
    }
  }

  protected isEmailEdited() {
    return this.user.email !== this.email.value;
  }

  protected areEmailsEqual() {
    return this.email.value === this.confirmEmail.value;
  }

  protected isProfileFromInvalid() {
    return this.profileForm.invalid || (this.isEmailEdited() && !this.areEmailsEqual());
  }

  protected arePasswordsEqual() {
    return this.confirmPassword.value === this.newPassword.value
  }

  protected isPasswordFormInValid()  {
    return this.passwordForm.invalid || !this.arePasswordsEqual()
  }
}
