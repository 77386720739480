import { Actions, CmsActionTypes } from './actions';
import { CmsState, initialCmsState } from './state';

export function CmsReducer(state: CmsState = initialCmsState, action: Actions) {
  let idx: number;

  switch (action.type) {
    case CmsActionTypes.GET_MEDICINES_SUCCESS:
      return {
        ...state,
        medicines: action.payload
      };
    case CmsActionTypes.UPDATE_MEDICINE_SUCCESS:
      idx = state.medicines.findIndex(_med => _med.id === action.payload.id);
      const medicines = [...state.medicines];

      if (idx !== -1) {
        medicines.splice(idx, 1, action.payload);
      }
      return {
        ...state,
        medicines
      };
    case CmsActionTypes.DELETE_UNKNOWN_MEDICINE_SUCCESS:
      idx = state.unknownMedicines.findIndex(_med => _med.id === action.payload);
      const unknownMedicinesState = [...state.unknownMedicines];
      if (idx !== -1) {
        unknownMedicinesState.splice(idx, 1);
      }

      return {
        ...state,
        unknownMedicines: unknownMedicinesState
      };
    case CmsActionTypes.GET_BRANDS_SUCCESS:
      return {
        ...state,
        brands: action.payload
      };
    case CmsActionTypes.UPDATE_BRAND_SUCCESS:
      idx = state.brands.findIndex(_brand => _brand.id === action.payload.id);
      const brands = [...state.brands];

      if (idx !== -1) {
        brands.splice(idx, 1, action.payload);
      }
      return {
        ...state,
        brands
      };
    case CmsActionTypes.GET_KEYWORDS_SUCCESS:
      return {
        ...state,
        keywords: action.payload
      };
    case CmsActionTypes.UPDATE_KEYWORD_SUCCESS:
      idx = state.keywords.findIndex(_keyword => _keyword.id === action.payload.id);
      const keywords = [...state.keywords];
      if (idx !== -1) {
        keywords.splice(idx, 1, action.payload);
      }
      return {
        ...state,
        keywords
      };
    case CmsActionTypes.GET_PRESCRIBING_LEVELS_SUCCESS:
      return {
        ...state,
        prescribingLevels: action.payload
      };
    case CmsActionTypes.GET_MEDICINE_FORMS_SUCCESS:
      return {
        ...state,
        medicineForms: action.payload
      };
    case CmsActionTypes.GET_UNKNOWN_MEDICINES_SUCCESS:
      return {
        ...state,
        unknownMedicines: action.payload
      };
    case CmsActionTypes.UPDATE_UNKNOWN_MEDICINE_SUCCESS:
      const unknownMedicines = [...state.unknownMedicines];
      idx = unknownMedicines.findIndex(med => med.id === action.payload.id);

      // remove the identified medicine from the list
      if (idx !== -1) {
        unknownMedicines.splice(idx, 1);
      }

      return {
        ...state,
        unknownMedicines
      };
    case CmsActionTypes.GET_PRICELIST_ITEMS_SUCCESS:
      return {
        ...state,
        pricelistItems: action.payload
      };
    case CmsActionTypes.GET_PRODUCT_CATALOG_SUCCESS:
      return {
        ...state,
        productCatalog: action.payload
      };
    case CmsActionTypes.UPDATE_PRICELIST_ITEM_SUCCESS:
      idx = state.pricelistItems.findIndex(_pricelistItem => _pricelistItem.id === action.payload.id);
      const pricelistItems = [...state.pricelistItems];
      if (idx !== -1) {
        pricelistItems.splice(idx, 1, action.payload);
      }
      return {
        ...state,
        pricelistItems
      };
    case CmsActionTypes.GET_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        emailTemplates: action.payload
      };
    case CmsActionTypes.GET_EMAIL_TEMPLATE_VARIABLES_SUCCESS:
      return {
        ...state,
        emailTemplateVariables: action.payload
      };
    case CmsActionTypes.UPDATE_EMAIL_TEMPLATE_SUCCESS:
      idx = state.emailTemplates.findIndex(_emailTemplate => _emailTemplate.id === action.payload.id);
      const emailTemplates = [...state.emailTemplates];

      if (idx !== -1) emailTemplates.splice(idx, 1, action.payload);

      return {
        ...state,
        emailTemplates
      };
    default:
      return state;
  }
}
