import { Injectable } from '@angular/core';
import { User } from '../../models/user';
import { BehaviorSubject, Subject } from 'rxjs';
import { SETTING } from '../../constants';
import { environment } from '../../../../environments/environment';

@Injectable()
export class SettingsService {
  _config: any;
  private _subscriptions: { [key: string]: BehaviorSubject<any> };
  private readonly _namespace: string;

  constructor() {
    this._config = environment;
    this._namespace = this.get(SETTING.NAMESPACE);
    this._subscriptions = {};
  }

  get(key: string, restore: boolean = false): any {
    if (this._config) {
      if (!this._config[key] && restore) {
        this.restore(key);
      }
      return this._config[key];
    }
  }

  set(key: string, value: any, store: boolean = false) {
    if (this._config) {
      this._config[key] = value;
    }

    if (store) {
      this.store(key, value);
    }

    if (this._subscriptions[key]) {
      this._subscriptions[key].next(value);
    }
  }

  remove(key: string, store: boolean = false) {
    if (this._config) {
      delete this._config[key];
    }

    if (store) {
      localStorage.removeItem(`${this._namespace}_${key}`);
    }

    if (this._subscriptions[key]) {
      this._subscriptions[key].next(undefined);
    }
  }

  store(key: string, value: any) {
    if (value !== null && value !== undefined) {
      localStorage.setItem(`${this._namespace}_${key}`, JSON.stringify(value));
    }
  }

  restore(key: string) {
    const prefs = {};
    let storedData = localStorage.getItem(`${this._namespace}_${key}`);
    if (storedData && storedData !== 'undefined') {
      storedData = JSON.parse(storedData);
      prefs[key] = storedData;
    }

    this._config = {...this._config, ...prefs};
  }

  restoreAll() {
    for (const item in localStorage) {
      if (item.includes(this._namespace)) {
        this.restore(item.substring(this._namespace.length + 1));
      }
    }
  }

  listen(key): BehaviorSubject<any> {
    if (!this._subscriptions[key]) {
      this._subscriptions[key] = new BehaviorSubject<any>(this._config[key]);
    }
    return this._subscriptions[key];
  }
}
