import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  AppActionTypes,
  GenericFailureAction,
  GenericFailureExpectedAction,
  GenericSuccessAction,
  GetTranslationsAction,
  GetTranslationsSuccessAction
} from './actions';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { NotificationService } from '../../shared/providers/notification/notification.service';
import { ApiService } from '../../shared/providers/api/api.service';
import { Store } from '@ngrx/store';
import { SettingsService } from '../../shared/providers/settings/settings.service';

@Injectable()
export class AppEffects {
  constructor(
    private _actions: Actions,
    private _notificationService: NotificationService,
    private _apiService: ApiService,
    private _store: Store,
    private _settings: SettingsService
  ) {
  }

  handleError: Observable<any> = createEffect(() => this._actions
    .pipe(
      ofType<GenericFailureAction>(AppActionTypes.GENERIC_FAILURE),
      tap(err => this._notificationService.onError({message: err.payload}))
    ), {dispatch: false});

  handleNonExpectedError: Observable<any> = createEffect(() => this._actions
    .pipe(
      ofType<GenericFailureExpectedAction>(AppActionTypes.GENERIC_FAILURE_EXPECTED),
      tap(err => this._notificationService.onError({message: err.payload.split(":").slice(1)}))
    ), {dispatch: false});

  handleSuccess: Observable<any> = createEffect(() => this._actions
    .pipe(
      ofType<GenericSuccessAction>(AppActionTypes.GENERIC_SUCCESS),
      tap(res => this._notificationService.onSuccess({message: res.payload}))
    ), {dispatch: false});


  getTranslations: Observable<any> = createEffect(() => this._actions
    .pipe(
      ofType<GetTranslationsAction>(AppActionTypes.GET_TRANSLATIONS),
      switchMap((action) => {
          return this._apiService.getTranslations(action.payload)
            .pipe(
              map(response => new GetTranslationsSuccessAction(response)),
              catchError(err => of(new GenericFailureAction({message: err})))
            );
        }
      )
    ));
}
