<div class="faq-component" *transloco="let t">
  <mat-accordion class="faq-list" multi>
    <mat-expansion-panel *ngFor="let question of questions">
      <mat-expansion-panel-header class="question">
        <span [innerHTML]="t(question.question)"></span>
      </mat-expansion-panel-header>
      <span [innerHTML]="t(question.answer)"></span>
    </mat-expansion-panel>
  </mat-accordion>
</div>
